import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import {
  Image,
  ImageBackground,
  Keyboard,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native';
import {connect} from 'react-redux';
import BaseComponent from '../Components/BaseComponent';
import LoadingComponent from '../Components/LoadingComponent';
import Constants from '../Lib/Constants';
import * as Helper from '../Lib/Helper';
import ActionCreators from '../Redux/actions';
import Api from '../Services/Api';
import {Images} from '../Themes';
import styles from './Styles/SignupScreenStyles';
import LinkPopUpComponent from '../Components/LinkPopUpComponent';
import { StorageOperation } from '../storage/asyncStorage';

class SignupCreatePasswordScreen extends BaseComponent {
  static navigationOptions = ({navigation}) => ({
    headerRight: () => (
      <TouchableOpacity
        activeOpacity={1}
        style={styles.rightIcon}
        onPress={() => navigation.state.params.onCreateAccount()}>
        <Text style={styles.headerText}>Create Account</Text>
        <Image source={Images.arrowRight} style={[styles.arrow]} />
      </TouchableOpacity>
    ),
  });

  constructor(props) {
    super(props);
    this.state = {
      password: this.props.userDetail.password ? this.props.userDetail.password : '',
      isVisible: false,
      loading: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.props.navigation.setParams({
      onCreateAccount: this.onPressCreateAccount.bind(this),
    });
  }

  toggleNotification = () => {
    // FirebaseService.getNotificationToken().then(token => {
    //   FirebaseService.hasNotificationPermission().then(hasPermission => {
    //     const params = {
    //       isAllow: hasPermission,
    //       fcmToken: token,
    //     };
    //     const apiCall = Api.createSecure();
    //     apiCall
    //       .toggleNotification(params)
    //       .then(response => {
    //         this.setState({loading: false}, () => {
    //           Helper.resetNavigationToScreen(
    //             this.props.navigation,
    //             'HomeStack',
    //           );
    //         });
    //       })
    //       .catch(err => {
    //         this.setState({loading: false}, () => {
    //           Helper.resetNavigationToScreen(
    //             this.props.navigation,
    //             'HomeStack',
    //           );
    //         });
    //       });
    //   });
    // });
  };

  onPressCreateAccount = () => {
    Keyboard.dismiss();

    const password = this.state.password;

    if (password == '') {
      Helper.showAlert(Constants.ERROR_MESSAGES.PASSWORD);
    } else if (password && !Helper.validatePassword(password)) {
      Helper.showAlert(Constants.ERROR_MESSAGES.VALID_PASSWORD);
    } else {
      this.setState({loading: true});
      const mApi = Api.create();
      console.log('this.props.userDetail',this.props.userDetail);
      mApi
        .signUp(this.props.userDetail)
        .then(response => {
          if (response.ok) {
            if (response.data.success) {
              try {
                StorageOperation.setData([
                  [Constants.KEY_IS_LOGIN, "1"],
                  [Constants.KEY_TOKEN, response.data.data.token],
                ]).then(() => {
                  this.props.setUser(response.data.data);
                  //this.toggleNotification();
                  console.log("going to homestack");
                  Helper.resetNavigationToScreen(
                    this.props.navigation,
                    'HomeStack',
                  );
                });
              } catch (error) {
                console.log('AsyncStorage error: ', error);
              }
            } else {
              Helper.showAlert(response.data.message);
            }
          } else {
            Helper.showAlert(response.problem);
          }

          this.setState({loading: false});
        })
        .catch(err => {
          console.log('error: ' + err);
          this.setState({loading: false});
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userDetail.password != this.props.userDetail.password) {
      this.setState({password: this.props.userDetail.password});
    }
  }

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}>
        <SafeAreaView
          style={styles.mainContainer}>
          <View style={[styles.content]}>
            <View style={styles.frm}>
              <Text style={[styles.h1, styles.marginBottom, {
                marginTop:30
              }]}>
                Create a password
              </Text>
              <View style={styles.passwordRow}>
                <View style={{flex: 1}}>
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="Password"
                    placeholderTextColor="rgba(255, 255, 255, 0.7)"
                    style={styles.TextInput}
                    secureTextEntry={!this.state.isVisible}
                    onChangeText={password => this.props.setPassword(password)}
                    value={this.state.password}
                    returnKeyType="go"
                    onSubmitEditing={event => this.onPressCreateAccount()}
                  />
                </View>
                <TouchableOpacity
                  style={styles.visibilityTouch}
                  onPress={() =>
                    this.setState({isVisible: !this.state.isVisible})
                  }>
                  <Image
                    source={
                      this.state.isVisible
                        ? Images.visibilityOn
                        : Images.visibilityOff
                    }
                    style={styles.visibility}
                  />
                </TouchableOpacity>
              </View>
              <Text style={styles.msg}>Use at least 8 characters</Text>
            </View>
            <View style={styles.signupFooter}>
              <TouchableOpacity
                style={[styles.button, {marginBottom: 30}]}
                onPress={() => this.onPressCreateAccount()}>
                <Text style={styles.buttonText}>Create Account</Text>
              </TouchableOpacity>
              <Text
                style={[styles.msg, styles.marginBottom, styles.alignCenter]}>
                By creating an account you agree to our{' '}
                <Text
                  style={styles.bold}
                  onPress={() =>
                    this.toggleLinkPopUp(true, Constants.TERMS_CONDITION)
                  }>
                  Terms and Conditions
                </Text>
              </Text>
              <Text
                style={[styles.msg, styles.marginBottom, styles.alignCenter]}>
                For more information about how Tengah collects, uses, shares and
                protects your personal data, read our{' '}
                <Text
                  style={styles.bold}
                  onPress={() =>
                    this.toggleLinkPopUp(true, Constants.PRIVACY_POLICY)
                  }>
                  Privacy Policy
                </Text>
              </Text>
            </View>
          </View>
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
        <LinkPopUpComponent
          visible={this.state.linkPopUpVisible}
          url={this.state.link}
          onClose={() => this.toggleLinkPopUp(false)}
        />
      </ImageBackground>
    );
  }

  toggleLinkPopUp = (visible, url = '') => {
    this.setState({
      linkPopUpVisible: visible,
      link: url,
    });
  };
}

export default SignupCreatePasswordScreen;
