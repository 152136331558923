import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,       
    profileArea:{
        backgroundColor:'rgba(255,255,255,0.2)',
        padding:20,
        flexDirection:'row',
        flexWrap:'wrap'
    },
    profileRow:{
        flexDirection:'row',
        alignItems:'center',
        width:'50%',
        paddingTop:15,
        paddingBottom:15,        
    },   
    profileRowFull:{
        width:'100%'
    },
    profileIcon:{
        width: (Metrics.screenWidth * 25) / 414,
        height: (Metrics.screenWidth * 25) / 414,
        tintColor: '#fff',
        resizeMode: 'contain',        
    },
    profileBody:{
        flex:1,
        paddingLeft:15,
        paddingRight:15,
    },
    accountList:{
        paddingLeft:20,
        paddingRight:20,
        paddingBottom:20
    },
    accountItem:{
        flexDirection:'row',
        alignItems:'center',
        paddingTop: (Metrics.screenWidth * 20) / 414,
        paddingBottom: (Metrics.screenWidth * 20) / 414,
        borderBottomWidth: 1,
        borderColor: 'rgba(242,242,242,0.2)'
    },
    accountItemBody:{
        flex:1
    },
    visibility: {
        width: (Metrics.screenWidth * 28) / 414,
        height: (Metrics.screenWidth * 28) / 414,
        resizeMode: 'contain'
    },
    accountItemIcon:{
        width: (Metrics.screenWidth * 25) / 414,
        height: (Metrics.screenWidth * 25) / 414,
        resizeMode: 'contain',
        marginRight:15
    },
})
