import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './Styles/LinkPopUpComponentStyles'


export default class NoEntriesComponent extends Component {
    render() {
        return (
            <View style={styles.notFound}>
                <Text style={styles.notFoundText}>There are no entries in your area yet. Start Tengah in this city by adding a price!</Text>
                <TouchableOpacity style={[styles.button, styles.buttonAddPrice]} onPress={() => this.props.navigation.navigate('AddPriceScreen')}>
                    <Text style={styles.buttonText}>Enter a price</Text>
                </TouchableOpacity>
            </View>
        )
    }
}