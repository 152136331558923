import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { Component, useDebugValue } from "react";
import {
  Image,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { connect } from "react-redux";
import FooterNavComponents from "../Components/FooterNavComponents";
import LoadingComponent from "../Components/LoadingComponent";
import stepNumberComponent from "../Components/stepNumberComponent";
import TooltipComponents from "../Components/TooltipComponents";
import NoEntriesComponent from "../Components/NoEntriesComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import { Images, Metrics } from "../Themes";
import styles from "./Styles/HomeScreenStyles";
import OfflineBannerBottom from "../Components/OfflineBannerBottom";
import DownloadingBannerTop from "../Components/DownloadingBannerTop";
import Geolocation from "react-native-geolocation-service";
import * as OnlineHelper from "../Lib/OnlineHelper";
import { categoryActions } from "../Redux/actions/Category";
import { popularItemsActions } from "../Redux/actions/PopularItems";
import { apiRequests } from "../apiRequests";
import { useIsFocused } from "@react-navigation/native";
const categories = require("../constants/categories.json");


const WalkthroughableView = walkthroughable(View);

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCategoryTabSelected: true,
      loading: false,
      arrCategory: [],
      arrPopular: [],
      userDetail: null,
      isHomeTooltipShown: null,
      liked: false,
      isOfflineEnabled: false,
      isAsyncTaskComplete: false,
      isDownloading: false,
      isOnline: null,
      shouldDownloadData: false,
      activityUnreadCount: 0,
      forceUpdateOfflineData: false,
    };
    this.getUserDetail = this.getUserDetail.bind(this);
    this.getPopularItem = this.getPopularItem.bind(this);
  }

  state = {
    secondStepActive: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused == false && this.props.isFocused == true) {
      this.getUserDetail();
    }
  }

  selectedTab = (isCategoryTabSelected) => {
    this.setState({ isCategoryTabSelected });
  };

  async getPopularItem(user) {
    console.log("Calling api getPopularItem");
    console.log("get user detail from state: ", user);
    let popular = await popularItemsActions.getPopularItem(
      user.search_latitude,
      user.search_longitude,
      user.search_radius * 1000,
      user.search_months
    );
    // console.log("popular: ", popular);
    this.setState({
      arrPopular: popular,
      isAsyncTaskComplete: true,
    });
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      console.log("userdetails: ", userdetails);
      this.setState({
        userDetail: userdetails,
      });
      this.getCategories(userdetails);
      this.getPopularItem(userdetails);
    }
  }

  componentDidMount() {
    console.log("DB Connected");
    this.getUserDetail();
    console.log("User Detail");
    this.getHomeTooltipDetails();

    this.getOfflineDetails();
    console.log("Offline Details");

    this.getCurrentLocation();
    console.log("got current location");
    // ActionCreators.offlineStateActions.addOfflineStateListner();
    // this.props.copilotEvents.on("stepChange", (currentStep) => {
    //   if (currentStep.order == 5) {
    //     try {
    //       AsyncStorage.setItem(Constants.HOME_TOOLTIP, JSON.stringify(1));
    //     } catch (error) {
    //       console.log("AsyncStorage Error: ", error);
    //     }
    //   }
    // });
  }

  componentWillUnmount() {
    // Don't forget to disable event handlers to prevent errors
    this.props.copilotEvents.off("stepChange");
  }

  startTooltip = async () => {
    const isHomeTooltipShown = await AsyncStorage.getItem(
      Constants.HOME_TOOLTIP
    );
    console.log("isHomeTooltipShown", isHomeTooltipShown);
    if (isHomeTooltipShown != "1" && this.state.isHomeTooltipShown != true) {
      this.state.isHomeTooltipShown = true;
      this.props.copilotEvents.on("stop", this.handleStepStop);
      setTimeout(() => {
        this.props.start();
      }, 300);
    }
  };

  handleStepStop = (stop) => {
    this.props.copilotEvents.off("stop");
    if (!this.state.isHomeTooltipShown) {
      AsyncStorage.setItem(Constants.HOME_TOOLTIP, JSON.stringify(1));
      this.setState({
        isHomeTooltipShown: true,
      });
    }
  };

  getOfflineDetails = async () => {
    var isOfflineEnabled = await AsyncStorage.getItem(Constants.OFFLINE_SYNC);
    var lastDownloadDate = await AsyncStorage.getItem(
      Constants.LAST_OFFLINE_DATA_DOWNLOAD_DATE
    );

    var shouldDownloadData = true;
    if (isOfflineEnabled == "1") {
      isOfflineEnabled = true;
    } else {
      isOfflineEnabled = false;
    }
    if (lastDownloadDate) {
      const todaysDate = Helper.getTodaysDate();
      if (
        todaysDate - lastDownloadDate >
        Constants.OFFLINE_SYNC_DIFFRENCE_DAYS
      ) {
        shouldDownloadData = true;
      } else {
        shouldDownloadData = false;
      }
    }
    this.state.isOfflineEnabled = isOfflineEnabled;

    this.manageOfflineInsertions(isOfflineEnabled, shouldDownloadData, false);
  };

  manageOfflineInsertions = async (
    isOfflineEnabled = true,
    shouldDownloadData = true,
    isForceLoad = true
  ) => {
    var offlineInsertDetail = await OfflineHelper.getOfflineInsertions();
    if (offlineInsertDetail.success) {
      shouldDownloadData = false;
    }
    // setTimeout(() => {
    //     this.setState({
    //         isAsyncTaskComplete: true,
    //         isOfflineEnabled: isOfflineEnabled,
    //         shouldDownloadData: shouldDownloadData
    //     },() => this.syncDataWithOnlineDB(offlineInsertDetail));
    //     if (!isForceLoad) {
    //         this.props.getActivityUnreadCount();
    //     }
    // }, 1000);
    this.setState({
      isAsyncTaskComplete: false,
    });
  };

  syncDataWithOnlineDB = async (offlineInsertDetail) => {
    console.log(
      "offlineInsertDetail offlineInsertDetail ------>",
      offlineInsertDetail
    );
    if (offlineInsertDetail.success) {
      var arrData = offlineInsertDetail.data;
      arrData.forEach(async (element) => {
        const paramsData = await OfflineHelper.getSyncDataFromOfflineDB(
          element
        );
        console.log("offlineInsertDetail paramsData ------>", paramsData.data);
        var wsResponse = {};
        wsResponse.success = false;
        const tableFields = paramsData.data;
        if (element.table_name == "entries") {
          wsResponse = await OnlineHelper.entryAdd_offline(
            element.id,
            tableFields
          );
        } else if (element.table_name == "tags") {
          wsResponse = await OnlineHelper.categoryAdd_offline(
            element.id,
            tableFields
          );
        } else if (element.table_name == "favourites") {
          wsResponse = await OnlineHelper.favouriteAdd_offline(
            element.id,
            tableFields
          );
        } else if (element.table_name == "recent_searches") {
          wsResponse = await OnlineHelper.addRecentSearch_offline(
            element.id,
            tableFields
          );
        }

        if (wsResponse.success) {
          const deleteSyncedQuery = `DELETE from offline_insertions where id = ${wsResponse.offlineInsertId}`;
          SQLHelper.performTransactionWithQuery(
            Constants.dbRefrence,
            deleteSyncedQuery,
            (deleted) => {}
          );
        } else if (!wsResponse.success) {
          const updateSyncFieldQuery = `update offline_insertions set isSynced = 0 where id = ${wsResponse.offlineInsertId}`;
          SQLHelper.performTransactionWithQuery(
            Constants.dbRefrence,
            updateSyncFieldQuery,
            (updated) => {}
          );
        }
        console.log("offlineInsertDetail wsResponse ------>", wsResponse);
      });
    } else {
      console.log("offlineInsertDetail Failure Please check ------>");
    }
  };

  getHomeTooltipDetails = async () => {
    const isHomeTooltipShown = await AsyncStorage.getItem(
      Constants.HOME_TOOLTIP
    );
    if (isHomeTooltipShown == "1") {
      this.state.isHomeTooltipShown = true;
    } else {
      this.state.isHomeTooltipShown = false;
    }
    this.setState({});
  };

  getCategories = async (user) => {
    console.log("Calling api getCategories");
    // let categories = await categoryActions.getCategories(user.search_latitude, user.search_longitude, (user.search_radius * 1000));
    console.log("categories: ", categories);
    var categoryWithIcon = [];
    for (let index = 0; index < categories.values.length; index++) {
      const element = categories.values[index];
      categoryWithIcon.push({
        name: element.name,
        icon: Images[element.icon],
      });
    }
    this.setState({
      arrCategory: categoryWithIcon,
    });
    //this.props.getCategoryList(this.props.navigation)
  };

  watchCurrentLocation = async () => {
    // console.log('checkTraveledOutside')
    const isUsingCurrentLocation = await AsyncStorage.getItem(
      Constants.IS_CURRENT_LOCATION
    );
    // console.log('checkTraveledOutside isUsingCurrentLocation',isUsingCurrentLocation)
    if (isUsingCurrentLocation) {
      // console.log('watchCurrentLocation', this.state.userDetail)
      this.checkTraveledOutside();
      setInterval(() => {
        this.checkTraveledOutside();
      }, 1000 * 60);
    } else {
      setTimeout(() => {
        // this.getCategories()
        this.getPopularItem();
      }, 1500);
    }
  };

  checkTraveledOutside = async () => {
    console.log("checkTraveledOutside");
    const isUsingCurrentLocation = await AsyncStorage.getItem(
      Constants.IS_CURRENT_LOCATION
    );
    console.log(
      "checkTraveledOutside isUsingCurrentLocation",
      isUsingCurrentLocation
    );
    this.setState({
      isAsyncTaskComplete: true,
    }); // if (isUsingCurrentLocation) {
    //     console.log("user details: ",this.state.userDetail);
    //     if (this.state.userDetail && this.state.userDetail.search_radius && this.state.userDetail.search_latitude && this.state.userDetail.search_longitude) {
    //         console.log('checkTraveledOutside this.state.userDetail',this.state.userDetail)
    //         Geolocation.getCurrentPosition(info => {
    //             console.log('checkTraveledOutside Geolocation ', info)
    //             if (info && info.coords) {
    //                 const toLat = info.coords.latitude
    //                 const toLon = info.coords.longitude
    //                 const earthRadius = 6378137;
    //                 const fromLat = this.state.userDetail.search_latitude;
    //                 const fromLng = this.state.userDetail.search_longitude;
    //                 const robustAcos = (value) => {
    //                     return value > 1 ? 1 : value < -1 ? -1 : value
    //                 };
    //                 const distance =
    //                     Math.acos(
    //                         robustAcos(
    //                             Math.sin(Helper.getRadiansFrom(toLat)) * Math.sin(Helper.getRadiansFrom(fromLat)) +
    //                             Math.cos(Helper.getRadiansFrom(toLat)) *
    //                             Math.cos(Helper.getRadiansFrom(fromLat)) *
    //                             Math.cos(Helper.getRadiansFrom(fromLng) - Helper.getRadiansFrom(toLon))
    //                         )
    //                     ) * earthRadius;
    //                 const finalDistance = Math.round(distance)
    //                 console.log('checkTraveledOutside Distance is ', finalDistance);
    //                 if (finalDistance > this.state.userDetail.search_radius) {
    //                     this.getCurrentLocation()
    //                 }
    //             }
    //         })
    //     }
    // }
    // else{
    //     console.log('checkTraveledOutside Not Using Current Location')
    // }
  };

  // goOnScreen(screen) {
  //     this.props.navigation.navigate(screen)
  //     console.log('====================================');
  //     console.log(screen);
  //     console.log('====================================');
  // }

  

  getCurrentLocation = async () => {
    await AsyncStorage.setItem(
      Constants.IS_CURRENT_LOCATION,
      JSON.stringify("true")
    );
    console.log("get location data: ")
      // this.setState({ loading: true })
    Helper.getCurrentLocationData((success, locationData) => {
        if (success) {
            // setSearchLocation(locationData)
            // ActionCreators.searchLocationActions.setSearchLocation(locationData);
        }
        this.setState({ loading: false })
    })

  };

  onToggleOfflineSync = () => {
    this.state.isOfflineEnabled = !this.state.isOfflineEnabled;
    this.setState({});
  };

  onOfflineSyncComplete = () => {
    const todaysDate = Helper.getTodaysDate();
    setTimeout(() => {
      AsyncStorage.setItem(
        Constants.LAST_OFFLINE_DATA_DOWNLOAD_DATE,
        JSON.stringify(todaysDate)
      );
      this.getOfflineDetails();
    }, 22000);
    // this.getOfflineDetails()
  };

  renderItemCategories = (item, index) => {
    return (
      <TouchableOpacity
        style={styles.gridItem}
        key={`category${index}`}
        onPress={() =>
          this.props.navigation.navigate("SearchScreen", {
            searchItem: item.name,
          })
        }
      >
        <View style={styles.listBody}>
          {/* <Text style={styles.listItemBigText}>{item.categories.split(",").join(", ")}</Text> */}
          <Text style={styles.gridItemText}>{item.name}</Text>
        </View>
        <View style={styles.listItemRight}>
          <Image source={item.icon} style={styles.gridItemIcon} />
        </View>
      </TouchableOpacity>
    );
  };

  renderPopularRowText(item) {
    // if (item.other_tag) {
    //     return(
    //         <View style={styles.listBody}>
    //             <Text style={styles.smallText}>{item.category}</Text>
    //             <Text style={styles.listItemBigText}>{item.main_tag}</Text>
    //             <Text style={styles.normalText}>{item.other_tags}</Text>
    //         </View>
    //     )
    // }
    // else {
    //     try {
    //         const lastOccuranceIndex = item.category.lastIndexOf(" - ")
    //         const otherTags = item.category.substring(lastOccuranceIndex + 3);
    //         const category = item.category.substring(0,lastOccuranceIndex);
    //         return (
    //             <View style={styles.listBody}>
    //                 <Text style={styles.smallText}>{category}</Text>
    //                 <Text style={styles.listItemBigText}>{otherTags}</Text>
    //                 <Text style={styles.normalText}>{item.main_tag}</Text>
    //             </View>
    //         )
    //     } catch (error) {
    //         console.log('error',error);
    //         return (
    //             <View style={styles.listBody}>
    //                 <Text style={styles.smallText}>{item.category}</Text>
    //                 <Text style={styles.listItemBigText}>{item.main_tag}</Text>
    //                 <Text style={styles.normalText}>{item.other_tags}</Text>
    //             </View>
    //         )
    //     }

    // }
    return (
      <View style={styles.listBody}>
        <Text style={styles.smallText}>
          {item.categories.split(",").join(", ")}
        </Text>
        <Text style={styles.listItemBigText}>
          {item.tags.split(",").join(", ")}
        </Text>
      </View>
    );
  }

  renderItemPopular = (item, index) => {
    console.log("item.populare: ", item);
    return (
      <TouchableOpacity
        style={styles.listItem}
        key={`popular${index}`}
        onPress={() =>
          this.props.navigation.navigate("SearchScreen", {
            searchItem: item.categories,
            searchTags: item.tags,
          })
        }
      >
        {this.renderPopularRowText(item)}
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entryCount}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    if (!this.state.isAsyncTaskComplete) {
      return (
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          <LoadingComponent visible={true} />
        </ImageBackground>
      );
    } else {
      console.log(
        "this.state.isOfflineEnabled && this.state.shouldDownloadData",
        this.state.isOfflineEnabled,
        this.state.shouldDownloadData
      );
      const showDownloadBanner =
        this.state.isOfflineEnabled &&
        this.state.shouldDownloadData &&
        this.state.isOnline;
      return (
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          <SafeAreaView style={styles.container}>
            {showDownloadBanner ? (
              <DownloadingBannerTop
                isVisible={this.state.isDownloading}
                dbRefrence={this._db}
                isOnline={this.state.isOnline}
                onSyncStart={() => this.setState({ isDownloading: true })}
                onSyncComplete={() => this.onOfflineSyncComplete()}
              />
            ) : null}
            <View style={styles.header}>
              <Text style={styles.nevHeading}>Search</Text>
              <Image source={Images.logo} style={styles.headerLogo} />
            </View>
            <ScrollView contentContainerStyle={styles.ScrollView}>
              <View style={[styles.content]}>
                {/* <CopilotStep
                                    order={6}
                                    name="Search by typing "
                                    text="Search for the average price of products and services by typing key words you’re looking for.">
                                    <WalkthroughableView> */}
                <TouchableOpacity
                  style={styles.searchLarge}
                  onPress={() => this.props.navigation.navigate("SearchScreen")}
                >
                  <Image
                    source={Images.search}
                    style={styles.searchLargeIcon}
                  />
                  <Text
                    style={[
                      styles.searchLargeInput,
                      { color: "rgba(255, 255, 255, 0.7)" },
                    ]}
                  >
                    Find the average price for...
                  </Text>
                </TouchableOpacity>
                {/* </WalkthroughableView>
                                </CopilotStep> */}
                <CopilotStep
                  order={6}
                  name="Location"
                  text="Set search results for your current location, or input a location of your choice."
                >
                  <WalkthroughableView>
                    <TouchableOpacity
                      style={styles.locationContent}
                      onPress={() =>
                        this.props.navigation.navigate("SearchLocationScreen")
                      }
                    >
                      <Image source={Images.location} style={styles.location} />
                      <Text style={styles.locationText}>
                        {this.state.userDetail.search_location}
                      </Text>
                    </TouchableOpacity>
                  </WalkthroughableView>
                </CopilotStep>
                <View style={styles.tabContainer}>
                  <View style={styles.tabNav}>
                    <TouchableOpacity
                      style={[
                        styles.tabNavItem,
                        this.state.isCategoryTabSelected
                          ? styles.tabNavItemselected
                          : null,
                      ]}
                      onPress={() => this.selectedTab(true)}
                    >
                      <Text
                        style={[
                          styles.tabNavItemText,
                          this.state.isCategoryTabSelected
                            ? styles.selected
                            : null,
                        ]}
                      >
                        Categories
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.tabNavItem,
                        !this.state.isCategoryTabSelected
                          ? styles.tabNavItemselected
                          : null,
                      ]}
                      onPress={() => this.selectedTab(false)}
                    >
                      <Text
                        style={[
                          styles.tabNavItemText,
                          !this.state.isCategoryTabSelected
                            ? styles.selected
                            : null,
                        ]}
                      >
                        Popular
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {/* <CopilotStep
                                        order={7}
                                        name="Search by category"
                                        text="Search for the average price of products and services by clicking on the primary categories."
                                    >
                                        <WalkthroughableView> */}
                  {this.state.isCategoryTabSelected ? (
                    // Categories
                    <FlatList
                      data={this.state.arrCategory}
                      numColumns={2}
                      renderItem={({ item, index }) =>
                        this.renderItemCategories(item, index)
                      }
                      keyExtractor={(item, index) => index + ""}
                      ListEmptyComponent={
                        <NoEntriesComponent
                          navigation={this.props.navigation}
                        />
                      }
                      extraData={this.state}
                      key={"category"}
                    />
                  ) : (
                    // Popular
                    <FlatList
                      data={this.state.arrPopular}
                      renderItem={({ item, index }) =>
                        this.renderItemPopular(item, index)
                      }
                      keyExtractor={(item, index) => index + ""}
                      ListEmptyComponent={
                        <NoEntriesComponent
                          navigation={this.props.navigation}
                        />
                      }
                      key={"popular"}
                      extraData={this.state}
                    />
                  )}
                  {/* </WalkthroughableView>
                                    </CopilotStep> */}
                </View>
              </View>
            </ScrollView>
            <OfflineBannerBottom
              onToggleOfflineSync={() => this.onToggleOfflineSync()}
              isOnline={this.state.isOnline}
              isOfflineEnabled={this.state.isOfflineEnabled}
            />
          </SafeAreaView>
          {/* <FooterNavComponents property={this.props} isHomeTooltipShown={this.state.isHomeTooltipShown} activityUnreadCount={this.state.activityUnreadCount}/> */}
          {this.state.loading ? <LoadingComponent /> : null}
        </ImageBackground>
      );
    }
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> Home Screen', state);
//     return {
//         userDetail: typeof state.userReducer.userDetail == 'object' ? state.userReducer.userDetail : JSON.parse(state.userReducer.userDetail),
//         searchLocationReducer: state.searchLocationReducer,
//         popularItemsReducer: state.popularItemsReducer,
//         categoryReducer: state.categoryReducer,
//         isOnline: state.offlineStateReducer.isOnline,
//         activityUnreadCount: state.activityReducer.activityUnreadCount,
//         forceUpdateOfflineData: state.userReducer.forceUpdate,
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         getUserDetail: () => dispatch(ActionCreators.userActions.getUserDetail()),
//         setUser: (userDetail) => dispatch(ActionCreators.userActions.setUserDetail(userDetail)),
//         setSearchLocation: (locationData) => dispatch(ActionCreators.searchLocationActions.setSearchLocation(locationData)),
//         getPopularItemList: () => dispatch(ActionCreators.popularItemsActions.getPopularItem()),
//         getCategoryList: (navigation) => dispatch(ActionCreators.categoryActions.getCategories('','',navigation)),
//         addOfflineStateListner: () => dispatch(ActionCreators.offlineStateActions.addOfflineStateListner()),
//         getActivityUnreadCount: () => dispatch(ActionCreators.activityActions.getActivityUnreadCount())
//     }
// }

const HomeObject = copilot({
  tooltipStyle: styles.tooltipStyle,
  animated: false,
  overlay: "svg",
  // svgMaskPath: circleSvgPath,
  byPassAlignment: true,
  marginTopExtra: Platform.OS === "ios" ? 0 : 8,
  tooltipComponent: TooltipComponents,
  stepNumberComponent: stepNumberComponent,
  backdropColor: "rgba(0,0,0,0.30)",
  verticalOffset: Platform.OS === "ios" ? 0 : 25,
})(HomeScreen);

Object.assign(HomeObject, {
  navigationOptions: HomeScreen.navigationOptions,
});

export default function (props) {
  const isFocused = useIsFocused();
  return <HomeObject {...props} isFocused={isFocused} />;
}
