import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,
    ListingTopHeader:{
        justifyContent:'center',
        alignItems:'center',
        padding:20
    },
    largeSizeFont: {
        fontSize: (Metrics.screenWidth * 36) / 414,
        color: '#fff',
        textAlign: 'center',
        fontFamily: "semiBold",
    },
    mediumSizeFont: {
        fontSize: (Metrics.screenWidth * 22) / 414,
        color: '#fff',
        textAlign: 'center',
        fontFamily: "base",
    },
    ListingPriceRow: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingVertical: 5
    },
    smallText: {
        fontSize: (Metrics.screenWidth * 12) / 414,
        fontFamily: "robotoLight",
        color: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 3,
        marginHorizontal: 10
    },
    fullImage:{
        flex:1,
        justifyContent:'center',
        alignItems:'center'
    },
    zoomImage:{
        width:Metrics.screenWidth,
        height: Metrics.screenWidth,
        resizeMode:'contain'
    },
})
