import React from "react";
import { Text, TouchableOpacity, View } from 'react-native';
import styles from './Styles/FooterNavComponentsStyles';

const TooltipComponents = ({
    isLastStep,
    handleNext,
    handleStop,
    currentStep,
}: Props) => (
        <View  style={styles.tooltipMainContainerStyle}>
            <View style={styles.tooltipStyleContent}>
                <Text style={styles.tooltipTitle}>{currentStep.name}</Text>
                <Text style={styles.tooltipText}>{currentStep.text}</Text>
            </View>
            <TouchableOpacity style={styles.button} onPress={isLastStep ? handleStop : handleNext}>
                <Text style={styles.buttonText}>{isLastStep ? 'Done' : 'Next'}</Text>
            </TouchableOpacity>
        </View>
    )


export default TooltipComponents
