import ActionTypes from "../Redux/actions/types";
import Constants from "../Lib/Constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Helper from "../Lib/Helper";
import NetInfo from "@react-native-community/netinfo";
import { api } from "../../src/utils/api";

const apiRequests = {
  updateLocation,
};

async function updateLocation(locationData) {
  let network = await NetInfo.fetch();
  if (network.isConnected) {
    var userDetails = await AsyncStorage.getItem("user");
    userDetails = JSON.parse(userDetails);
    var search_months = userDetails.search_months;

    const data = {};
    data.search_location = locationData.location;
    data.search_latitude = locationData.latitude;
    data.search_longitude = locationData.longitude;
    data.search_country_name = locationData.country;
    data.user_id = userDetails.id;
    data.short_name = locationData.short_name;

    if (search_months) {
      data.search_months = search_months;
    }

    if (locationData.radius) {
      data.search_radius = locationData.radius;
    }

    if (locationData.homeCountry) {
      data.country_name = locationData.homeCountry;
    }

    let updateSearchLocation = await api("v1/api/request", "POST", {
      endpoint: "user/preference/update",
      type: "POST",
      data: data,
    });
    // let updateSearchLocation = await api(locationData,search_months ? search_months : 12);

    // console.log("user data afte rupdate: ", updateSearchLocation.data.data);
    var userDetails = await AsyncStorage.setItem(
      "user",
      JSON.stringify(updateSearchLocation.data.data)
    );

    return updateSearchLocation;

    // userActions.setUserDetail(response.data.data)

    // mApi.updateSearchLocation(locationData,search_months ? search_months : 12).then(response => {
    //     if (response.ok) {
    //         if (response.data.success) {
    //             try {
    //                 AsyncStorage.setItem(Constants.KEY_FIRST_TIME_LOCATION,JSON.stringify(1))

    //                 dispatch(userActions.setUserDetail(response.data.data))

    //                 dispatch({
    //                     type: ActionTypes.SEARCH_LOCATION,
    //                     isSearchLocationUpdated: true,
    //                 })

    //             } catch (error) {
    //                 console.log('AsyncStorage error: ', error);

    //             }
    //         } else {
    //             Helper.showAlert(response.data.message)
    //         }
    //     } else {
    //         Helper.showAlert(response.problem)
    //     }
    //     dispatch({
    //         type: ActionTypes.SEARCH_LOCATION_LOADING,
    //         loading: false,
    //     })
    // }).catch(error => {
    //     console.log('error: ', error);
    //     dispatch({
    //         type: ActionTypes.SEARCH_LOCATION_LOADING,
    //         loading: false,
    //     })
    // })
  } else {
    return Helper.showAlert("No internet connection!");
  }

  return "Updated";
}

export { apiRequests };
