import { StyleSheet, Platform } from "react-native";
import { Metrics, ApplicationStyles, Colors, Fonts } from "../../Themes/";
export default StyleSheet.create({
  ...ApplicationStyles.screen,
  rightIcon: {
    paddingHorizontal: 10,
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  ListingTopHeader: {
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
  },
  ListingPriceArea: {
    backgroundColor: "rgba(255,255,255,0.2)",
    padding: 15,
  },
  largeSizeFont: {
    fontSize: (Metrics.screenWidth * 48) / 414,
    color: "#fff",
    textAlign: "center",
    fontFamily: "semiBold",
    lineHeight: 60,
  },
  mediumSizeFont: {
    fontSize: (Metrics.screenWidth * 36) / 414,
    color: "#fff",
    textAlign: "center",
    fontFamily: "base",
  },
  ListingPriceRow: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingVertical: 0,
  },
  homeListingPrice: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    paddingVertical: 0,
    marginTop: 5,
  },
  smallText: {
    fontSize: (Metrics.screenWidth * 12) / 414,
    fontFamily: "robotoLight",
    color: "#fff",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: (Metrics.screenWidth * 15) / 414,
    marginHorizontal: 10,
  },
  italic: {
    fontFamily: "robotoLightItalic",
  },
  span: {
    fontSize: (Metrics.screenWidth * 13) / 414,
    fontFamily: "robotoLightItalic",
    color: "#fff",
    textAlign: "center",
  },
  ListingPriceFooter: {
    padding: 20,
  },
  priceEntriesBtn: {
    flexDirection: "row",
    alignItems: "center",
    height: (Metrics.screenWidth * 80) / 414,
    marginTop: 40,
  },
  userEntriesImg: {
    width: (Metrics.screenWidth * 30) / 414,
    height: (Metrics.screenWidth * 30) / 414,
    tintColor: "#fff",
    resizeMode: "contain",
  },
  userEntriesBody: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  priceEntriesBtnText: {
    fontSize: (Metrics.screenWidth * 18) / 414,
    fontFamily: "robotoBlack",
    color: "#fff",
    marginVertical: 2,
  },
  priceEntriesText: {
    fontSize: (Metrics.screenWidth * 16) / 414,
    fontFamily: "robotoBase",
    color: "#fff",
    marginVertical: 2,
  },
  ScrollView: {
    flexGrow: 1,
  },
  currencyIcon: {
    width: (Metrics.screenWidth * 25) / 414,
    height: 60,
    tintColor: "#fff",
    resizeMode: "contain",
    justifyContent: "center",
  },
  tagsContainer: {
    flexGrow: 1,
    marginTop: 20,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 30,
    paddingBottom: 50,
  },
  tagsParent: {
    flexGrow: 1,
    borderTopColor: "rgba(255,255,255,0.5)",
    borderTopWidth: 0.5,
    borderBottomColor: "rgba(255,255,255,0.5)",
    borderBottomWidth: 0.5,
    minHeight: 200,
    height: "auto",
  },
  seachTags: {
    width: "100%",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start", // if you want to fill rows left to right
    justifyContent: "center",
  },
  singleTag: {
    width: "45%",
    alignItems: "flex-start",
    backgroundColor: "rgba(255,255,255,0.35)",
    marginVertical: 5,
    marginHorizontal: 5,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 4,
  },
  singleTagText: {
    color: "white",
    fontWeight: "700",
  },
  activeSingleTag: {
    backgroundColor: "#1a89c9",
  },
  noResultText: {
    color: "white",
    width: 160,
    textAlign: "center",
    fontWeight: "600",
  },
  noResultFoundContainer: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: 170,
    backgroundColor: "rgba(255,255,255,0.35)",
    flexDirection: "column",
    width: "93%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
    borderRadius: 4,
  },
  fixedAddPrice: {
    marginTop: 20,
  },
  allPricesSection: {
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 15,
    paddingBottom: 20,
    marginTop: 20,
  },
  allPriceHeading: {
    color: "white",
    fontSize: 12,
    fontWeight: "300",
    fontStyle: "italic",
  },
  flexRow: {
    flexDirection: "row",
  },
  smallIcon: {
    width: 15,
    height: 15,
    resizeMode: "contain",
  },
  filterIcon: {
    width: 25,
    height: 25,
    resizeMode: "contain",
  },
  tagFilterRow: {
    marginVertical: 5,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterTagInput: {
    backgroundColor: "rgba(255,255,255,0.35)",
    marginVertical: 5,
    marginHorizontal: 5,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 4,
    width: "84%",
    color: "white",
    zIndex: 1,
  },
  tagEmpty: {
    height: 35,
  },
});
