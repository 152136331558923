import {StyleSheet, Platform} from 'react-native';
import {Metrics, ApplicationStyles, Colors, Fonts} from '../../Themes/';
export default StyleSheet.create({
  ...ApplicationStyles.screen,
  activitylistItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderColor: 'rgba(242,242,242,0.2)',
  },
  activityListLeft: {
    justifyContent: 'center',
  },
  activityListBody: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
  },
  activityListIcon: {
    width: (Metrics.screenWidth * 30) / 414,
    height: (Metrics.screenWidth * 30) / 414,
    resizeMode: 'contain',
    marginTop: 4,
    marginBottom: 4,
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: '#FFBE45',
    alignSelf: 'center',
  },
});
