import React from "react";
import {
  FlatList,
  Image,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import ImagePickerComponent from "../Components/ImagePickerComponent";
import * as ImagePicker from 'expo-image-picker';
import LoadingComponent from "../Components/LoadingComponent";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import Api from "../Services/Api";
import { Images } from "../Themes";
import styles from "./Styles/EnterPriceScreenStyles";
import { StackActions, NavigationActions } from "react-navigation";

var timerId = "";

class EnterPriceScreen extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.route.params;

    this.state = {
      isFromTellUs: param && param.isFromTellUs ? param.isFromTellUs : false,
      priceDetail: param && param.priceDetail ? param.priceDetail : "",
      specificListing:
        param && param.specificListing ? param.specificListing : "",
      showTagAddedPopup:
        param && param.showTagAddedPopup ? param.showTagAddedPopup : false,
      userDetail: {},
      loading: true,
      imagePickerVisible: false,
      searchPrice: "",
      homePrice: "",
      description: "",
      arrMedia: [],
      popUpVisible: false,
      popupDialogMessage: "",
      isOnline: Constants.isOnline,
      search_country: "",
      country: "",
      selectedLocation: "", // search_latitude, search_longitude, search_country_id, search_location,
      isLocationModified: false, //True if location modified
      tagInputDisable: false,
    };
    this.getUserDetails = this.getUserDetails.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.convertPrice = this.convertPrice.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getUserDetails();
    console.log("this.state.priceDetail.tags", this.state.priceDetail);
    if (this.state.showTagAddedPopup) {
      this.setState(
        { popUpVisible: true, popupDialogMessage: Constants.TAG_ADDED },
        () =>
          setTimeout(() => {
            this.setState({ popUpVisible: false, popupDialogMessage: "" });
          }, Constants.TIME_FOR_POP_UPS)
      );
    }
  }

  getUserDetails = async () => {
    let user = await this.props.route.params.getUserDetails();
    this.setState({
      userDetail: user,
      search_country: user.search_country,
      country: user.country,
      loading: false,
    });

    if (
      this.props.route.params.enteredValues &&
      this.props.route.params.enteredValues !== null
    ) {
      var enteredValues = this.props.route.params.enteredValues;
      //   if (user.search_country.currency_code == enteredValues.currencyCode) {
      this.setState({
        searchPrice: String(enteredValues.price),
      });
      //   }

      this.convertPrice(
        {
          currency_code: this.props.route.params.enteredValues.currencyCode,
        },
        user.country
      );

      console.log("CONVERT PRICE 1");
    }
  };

  updateLocation = (locationData, status) => {
    console.log("Update user location");
    console.log(locationData);
    console.log(status);

    let location = {
      search_country: locationData.country,
      search_latitude: locationData.latitude,
      search_longitude: locationData.longitude,
      search_location: locationData.location,
    };

    this.setState({
      isLocationModified: status,
      selectedLocation: location,
    });
  };

  convertPrice = (search = false, user = false) => {
    console.log("search search: ", search);
    const mApi = Api.create();
    var search_country,
      country = null;
    if (search) {
      search_country = search;
      country = this.state.country;
    } else if (search && user) {
      search_country = search;
      country = user;
    } else {
      search_country = this.state.search_country;
      country = this.state.country;
    }

    if (search_country.currency_code == country.currency_code) {
      this.setState({ homePrice: this.state.searchPrice });
    } else if (this.state.searchPrice != "") {
      console.log("convert price: ", search);
      console.log("home price: ", country);
      mApi
        .convertPrice(
          this.state.searchPrice,
          search_country.currency_code,
          country.currency_code
        )
        .then((response) => {
          console.log("send obj: ", {
            stateprice: this.state.searchPrice,
            search: search_country.currency_code,
            country: country.currency_code,
          });
          if (response.ok) {
            if (response.data.success) {
              this.setState({
                homePrice: response.data.data.converted_price,
              });
            } else {
              Helper.showAlert(
                response.data.message,
                this.props.navigation,
                response.data.status
              );
            }
          } else {
            console.warn(response);
            Helper.showAlert(response.problem, this.props.navigation);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
    // else if (this.state.searchPrice != "") {
    //   const associatedMediaQuery = `select * from currency_rates where base = "${search_country.currency_code}" and target_currency = "${country.currency_code}" limit 1`;
    //   SQLHelper.performTransactionWithQuery(
    //     Constants.dbRefrence,
    //     associatedMediaQuery,
    //     (arrCurrency) => {
    //       if (arrCurrency.length > 0) {
    //         const currencyDetails = arrCurrency[0];
    //         const finalPrice =
    //           parseFloat(this.state.searchPrice) *
    //           currencyDetails.target_currency_rate;
    //         this.setState({ homePrice: `${finalPrice.toFixed(2)}` });
    //         console.log(
    //           "convertPrice arrCurrency arrCurrency finalPrice",
    //           `${finalPrice}`
    //         );
    //       }
    //     }
    //   );
    // }
  };

  onPressDone = async () => {
    const locationData = this.state.isLocationModified
      ? this.state.selectedLocation
      : this.state.userDetail;
    const param = {
      listing: this.state.specificListing,
      price: this.state.searchPrice,
      categories: this.state.priceDetail.categories,
      tags: this.state.priceDetail.tags,
      location: locationData.search_location,
      latitude: locationData.search_latitude,
      longitude: locationData.search_longitude,
      countryId: locationData.search_country_id,
      description: this.state.description,
    };

    console.log("send params: ", param);

    Constants.hideTellUsWhatYouPaid = true;
    let editListing = this.props.route.params?.editPrice;

    let addEntry = {
      status: 400,
    };
    if (editListing) {
      addEntry = await ActionCreators.entryActions.entryUpdate(
        param,
        this.state.userDetail.id
      );
    } else {
      addEntry = await ActionCreators.entryActions.entryAdd(
        param,
        this.state.userDetail.id
      );
    }
    console.log(addEntry.data);
    if (addEntry.status == 200) {
      if (addEntry.data.success) {
        this.props.route.params.showPopup(true, Constants.PRICE_ADDED);
        if (!editListing) {
          this.props.route.params.getListingDetails();
        }
        this.props.route.params.searchKeyword(this.props.route.params.keyword);
        setTimeout(() => {
          this.props.navigation.goBack();
        }, 1000);
      }
    }
    //this.props.addEntry(param,this.props.navigation)
  };

  openGallery = async () => {
    this.setImagePicker(false);
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      this.onClose();
      this.props.onImageSelect(image);
    }
  };

  openCamera = async () => {
    this.setImagePicker(false);
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      this.onClose();
      this.props.onImageSelect(image);
    }
  };

  onPressSelectCategory = () => {
    this.props.navigation.push("AddPriceScreen", { isEditing: true });
  };

  updateTagState = (state) => {
    console.log("input state: ", state);
    let updateStateVal = state.tag;
    updateStateVal = updateStateVal.replace(/[^a-zA-Z0-9\- ]/g, "");
    state.tag = updateStateVal;

    if (state.tagsArray.length >= 5) {
      this.setState({
        tagInputDisable: true,
      });
    } else {
      this.setState({
        tagInputDisable: false,
      });
    }

    if (typeof state.tagsArray !== "string") {
      state.tagsArray = state.tagsArray.toString();
    }

    var priceDetail = this.state.priceDetail;
    priceDetail.tags = state.tagsArray;

    console.log("priceDetail: ", priceDetail);

    this.setState({
      priceDetail: priceDetail,
      tagValue: state.tag,
    });
  };

  onPressSelectTags = () => {
    const { cat_id, category, parentName, entryCount } = this.state.priceDetail;
    this.props.navigation.push("ProductListScreen", {
      catId: cat_id,
      title: category,
      selectedName: parentName,
      isFromAddPrice: true,
      entryCount: entryCount,
      isEditing: true,
    });
  };

  onPressSelectLocation = () => {
    this.props.navigation.push("SearchCityScreen", {
      isEditing: true,
      updateLocation: this.updateLocation,
    });
  };

  render() {
    const locationData = this.state.isLocationModified
      ? this.state.selectedLocation
      : this.state.userDetail;
    const parentThis = this;
    console.log("hello there im here");
    if (this.state.loading) {
      return <LoadingComponent />;
    } else {
      return (
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          <SafeAreaView style={styles.container}>
            <View style={[styles.header, { paddingLeft: 0, paddingRight: 0 }]}>
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.rightIcon}
                  onPress={() => this.handleBackButtonClick()}
                >
                  <Image source={Images.plus} style={styles.Modalclose} />
                </TouchableOpacity>
              </View>
              <View style={styles.headerBody}>
                <Text style={styles.headerTitle}>
                  {this.state.isFromTellUs
                    ? "Tell us what you paid"
                    : "How much did you pay?"}
                </Text>
              </View>
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.rightIconWithText}
                  onPress={() => this.onPressDone()}
                >
                  <Text style={styles.headerText}>Done</Text>
                  <Image source={Images.arrowRight} style={styles.arrow} />
                </TouchableOpacity>
              </View>
            </View>
            <KeyboardAvoidingView
              style={styles.container}
              keyboardVerticalOffset={0}
              behavior={Platform.OS == "android" ? "height" : "padding"}
            >
              <ScrollView contentContainerStyle={styles.ScrollView}>
                <View style={[styles.content]}>
                  <View style={styles.list}>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Categories*</Text>
                        <Text style={styles.listItemText}>
                          {this.state.priceDetail.categories
                            ? this.state.priceDetail.categories
                                .split(",")
                                .join(", ")
                            : ""}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Tags*</Text>
                        {/* <Text style={styles.listItemText}>
                          {this.state.priceDetail.tags
                            ? this.state.priceDetail.tags.split(",").join(", ")
                            : ""}
                        </Text> */}
                        {/* <TagInput
                          updateState={this.updateTagState}
                          tags={{
                            tag: this.state.tagValue,
                            tagsArray: this.state.priceDetail.tags.split(","),
                          }}
                          label="Press , to add a tag"
                          labelStyle={{ color: "#fff" }}
                          tagStyle={styles.tag}
                          tagTextStyle={styles.tagText}
                          inputStyle={styles.inputText}
                          inputContainerStyle={styles.inputStyle}
                          disabled={this.state.tagInputDisable}
                          disabledInputStyle={styles.disabledInputStyle}
                          autoCorrect={false}
                          keysForTag={","}
                        /> */}
                      </View>
                    </View>
                    <TouchableOpacity
                      style={styles.listItem}
                      onPress={() => this.onPressSelectLocation()}
                    >
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Location*</Text>
                        <Text style={styles.listItemText}>
                          {locationData.search_location}
                        </Text>
                      </View>
                      <View style={styles.listItemRight}>
                        <Image
                          source={Images.arrowRight}
                          style={styles.listItemArrow}
                        />
                      </View>
                    </TouchableOpacity>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Price*</Text>
                        <View style={styles._row}>
                          <TextInput
                            underlineColorAndroid="transparent"
                            placeholder="0.00"
                            placeholderTextColor="#fff"
                            style={styles.listInputIDR}
                            keyboardType="numeric"
                            value={this.state.searchPrice}
                            onChangeText={(searchPrice) => {
                              this.setState({ searchPrice });
                              setTimeout(() => {
                                console.log("CONVERT PRICE 2");
                                this.convertPrice(
                                  this.props.route.params.enteredValues
                                    .currencyCode
                                    ? {
                                        currency_code:
                                          this.props.route.params.enteredValues
                                            .currencyCode,
                                      }
                                    : false,
                                  false
                                );
                              }, 400);
                            }}
                            onSubmitEditing={(event) =>
                              this.convertPrice(
                                parentThis.props.route.params.enteredValues
                                  .currencyCode
                                  ? {
                                      currency_code:
                                        parentThis.props.route.params
                                          .enteredValues.currencyCode,
                                    }
                                  : false
                              )
                            }
                          />
                          <Text style={styles.smallText}>
                            {this.props.route.params.enteredValues
                              ? this.props.route.params.enteredValues
                                  .currencyCode
                              : this.state.search_country.currency_code}
                          </Text>
                        </View>
                        <View style={styles._row}>
                          <TextInput
                            underlineColorAndroid="transparent"
                            placeholder={`${this.state.country.currency_symbol}0.00`} //Nochange
                            placeholderTextColor="#fff"
                            style={styles.listInputAUD}
                            editable={false}
                            value={this.state.homePrice}
                          />
                          <Text style={styles.smallText}>
                            {this.state.country.currency_code}
                          </Text>
                          {/* Nochange */}
                        </View>
                      </View>
                    </View>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Description</Text>
                        <TextInput
                          underlineColorAndroid="transparent"
                          placeholder="Tell us more about your purchase"
                          placeholderTextColor="#fff"
                          style={styles.listInput}
                          value={this.state.description}
                          onChangeText={(description) =>
                            this.setState({ description })
                          }
                        />
                      </View>
                    </View>

                    {this.state.isOnline ? (
                      <TouchableOpacity
                        style={styles.listItem}
                        onPress={() => {
                          if (this.state.arrMedia.length == 0) {
                            this.setImagePicker(true);
                          } else {
                            this.props.navigation.navigate("AddImageScreen", {
                              arrMedia: this.state.arrMedia,
                              onBack: this.onBackSelectedImages.bind(this),
                            });
                          }
                        }}
                      >
                        <View style={styles.listBody}>
                          <Text style={styles.normalText}>Image</Text>
                          {this.state.arrMedia.length > 0 ? (
                            <FlatList
                              data={this.state.arrMedia}
                              style={{ marginTop: 10 }}
                              horizontal
                              extraData={this.state}
                              keyExtractor={(item, index) => index + ""}
                              renderItem={({ item, index }) => {
                                return (
                                  <View style={styles.imagePlaceholder}>
                                    <Image
                                      source={{ uri: item.path }}
                                      style={styles.uploadedImage}
                                    />
                                  </View>
                                );
                              }}
                            />
                          ) : (
                            <View style={styles.imageUpload}>
                              <View style={styles.imagePlaceholder}>
                                <Image
                                  source={Images.camera}
                                  style={styles.placeholderImg}
                                />
                              </View>
                              <Text style={styles.normalText}>
                                Tap to add image
                              </Text>
                            </View>
                          )}
                        </View>
                        <View style={styles.listItemRight}>
                          <Image
                            source={Images.arrowRight}
                            style={styles.listItemArrow}
                          />
                        </View>
                      </TouchableOpacity>
                    ) : null}
                  </View>
                </View>
              </ScrollView>
            </KeyboardAvoidingView>
          </SafeAreaView>

          <ImagePickerComponent
            visible={this.state.imagePickerVisible}
            onClose={() => this.setImagePicker(false)}
            onSelectedImage={(image) => this.onImageSelect(image)}
            openGallery={() => this.openGallery()}
            openCamera={() => this.openCamera()}
          />

          <PopupDialogComponent
            visible={this.state.popUpVisible}
            message={this.state.popupDialogMessage}
          />

          {this.state.loading ? <LoadingComponent /> : null}
        </ImageBackground>
      );
    }
  }

  onBackSelectedImages = (arrImages) => {
    console.log("onBackSelectedImages");

    this.setState({ arrMedia: arrImages });
  };

  setImagePicker = (visible) => {
    this.setState({ imagePickerVisible: visible });
  };

  onImageSelect = (image) => {
    console.log("image", image);
    this.state.arrMedia.push(image);
    this.setState({});
  };
}


export default EnterPriceScreen;
