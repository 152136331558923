import * as Helper from "../../Lib/Helper";
import Api from "../../Services/Api";
import ActionTypes from "./types";
import Constants from "../../Lib/Constants";
import moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { api } from "../../../src/utils/api";

const mApi = Api.createSecure();

const entryActions = {
  entryPrice,
  entryList,
  entryDetail,
  entryAdd,
  myEntries,
  entryUpdate,
};

function entryPrice(catId, tagIds = "", navigation) {
  return (dispatch) => {
    if (Constants.isOnline) {
      dispatch({
        type: ActionTypes.ENTRY_PRICE_LOADING,
        loading: true,
      });

      mApi
        .entryPrice(catId, tagIds)
        .then((response) => {
          if (response.ok) {
            if (response.data.success) {
              dispatch({
                type: ActionTypes.ENTRY_PRICE_DETAIL,
                entryPriceDetail: response.data.data,
              });
            } else {
              Helper.showAlert(
                response.data.message,
                navigation,
                response.data.status
              );
            }
          } else {
            Helper.showAlert(response.problem, navigation);
          }
          dispatch({
            type: ActionTypes.ENTRY_PRICE_LOADING,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error:", error);
          dispatch({
            type: ActionTypes.ENTRY_PRICE_LOADING,
            loading: false,
          });
        });
    } else {
      OfflineHelper.getUserEntriesByDistance(async (arrEntryId) => {
        const user = await AsyncStorage.getItem(Constants.KEY_USER_DETAIL);
        const userDetails = JSON.parse(user);
        const user_currency_code = userDetails.country.currency_code;
        const user_currency_symbol = userDetails.country.currency_symbol;
        const user_search_currency_code =
          userDetails.search_country.currency_code;
        const user_search_currency_symbol =
          userDetails.search_country.currency_symbol;
        const monthsAgoDate = moment(new Date())
          .subtract(userDetails.search_months, "months")
          .format(Constants.FULL_DATE_TIME_STAMP_FORMAT);
        // alert(monthsAgoDate)
        var queryEntries = `SELECT entries.*,categories.unit_measure,countries.currency,countries.currency_code,countries.currency_symbol from entries LEFT JOIN countries ON countries.id = entries.country_id LEFT JOIN categories ON categories.id = entries.cat_id where entries.cat_id = ${catId} and date(entries.created_at) > ${monthsAgoDate} and entries.id in (${arrEntryId})`;
        if (tagIds != "") {
          var tag_ids = tagIds.split(",");
          tag_ids.sort((a, b) => a - b);
          const sortedTagIds = tag_ids.join(",");
          // append  `where('tag_ids',implode(",",$tag_ids)` exact match to requested tags
          queryEntries += ` and entries.tag_ids LIKE "${sortedTagIds}"`;
        }
        queryEntries += ` order by price asc`;
        SQLHelper.performTransactionWithQuery(
          Constants.dbRefrence,
          queryEntries,
          (finalentryArr) => {
            console.log("queryEntries :", finalentryArr);
            const totalEntry = finalentryArr.length;
            if (totalEntry > 0) {
              var dictData = {};
              dictData["cat_id"] = catId;
              dictData["main_tag"] = "";
              dictData["tag_ids"] = "";
              OfflineHelper.getCategoryHierarchy(
                catId,
                (category, categoryArr) => {
                  dictData["category"] = category;
                  dictData["categoryArr"] = categoryArr;

                  const firstEntry = finalentryArr[0];
                  const lastEntry = finalentryArr[finalentryArr.length - 1];
                  dictData["unit_measure"] = firstEntry.unit_measure;
                  var lowSearchPrice = "";
                  var lowHomePrice = "";
                  var avrageSearchPrice = "";
                  var avrageHomePrice = "";
                  var highSearchPrice = "";
                  var highHomePrice = "";

                  const validateAndPass = () => {
                    console.log("validateAndPass validateAndPass ----- -__> ");

                    if (
                      lowSearchPrice != "" &&
                      lowHomePrice != "" &&
                      avrageSearchPrice != "" &&
                      avrageHomePrice != "" &&
                      highSearchPrice != "" &&
                      highHomePrice != ""
                    ) {
                      console.log(
                        " *__* *__* *__* validateAndPass ----- *__* "
                      );
                      console.log("validateAndPass dictData ", dictData);

                      var dictLowData = {};
                      dictLowData["search_price"] = lowSearchPrice;
                      dictLowData["home_price"] = lowHomePrice;
                      dictLowData["search_currency"] =
                        user_search_currency_code;
                      dictLowData["search_symbol"] =
                        user_search_currency_symbol;
                      dictLowData["home_currency_code"] = user_currency_code;
                      dictData["low"] = dictLowData;

                      var dictAvgData = {};
                      dictAvgData["search_price"] = avrageSearchPrice;
                      dictAvgData["home_price"] = avrageHomePrice;
                      dictAvgData["search_currency"] =
                        user_search_currency_code;
                      dictAvgData["search_symbol"] =
                        user_search_currency_symbol;
                      dictAvgData["home_currency_code"] = user_currency_code;
                      dictAvgData["home_currency_symbol"] =
                        user_currency_symbol;
                      dictData["average"] = dictAvgData;

                      var dictHighData = {};
                      dictHighData["search_price"] = highSearchPrice;
                      dictHighData["home_price"] = highHomePrice;
                      dictHighData["search_currency"] =
                        user_search_currency_code;
                      dictHighData["search_symbol"] =
                        user_search_currency_symbol;
                      dictHighData["home_currency_code"] = user_currency_code;
                      dictHighData["home_currency_symbol"] =
                        user_currency_symbol;
                      dictData["high"] = dictHighData;

                      if (dictData["main_tag"] == "") {
                        dictData["main_tag"] = categoryArr[0].name;
                      }
                      dictData["is_cat_search"] = tagIds != "" ? 0 : 1;
                      const tagCondition =
                        dictData["tag_ids"] == ""
                          ? `tag_ids IS NULL`
                          : `tag_ids LIKE "${dictData["tag_ids"]}"`;
                      const favouritesQuery = `SELECT * from favourites where user_id = ${userDetails.id} and cat_id = ${catId} and ${tagCondition} limit 1`;
                      SQLHelper.performTransactionWithQuery(
                        Constants.dbRefrence,
                        favouritesQuery,
                        (arrTagDetails) => {
                          dictData["is_favourite"] = arrTagDetails.length;
                          dictData["favourite_id"] =
                            arrTagDetails.length > 0 ? arrTagDetails[0].id : 0;
                          console.log(
                            "validateAndPass dictData dictData",
                            dictData
                          );
                          dispatch({
                            type: ActionTypes.ENTRY_PRICE_DETAIL,
                            entryPriceDetail: dictData,
                          });
                          const deleteUserViewEntryQuery = `DELETE from user_view_entries where (user_id = ${userDetails.id} and cat_id = ${catId} and ${tagCondition})`;
                          SQLHelper.performTransactionWithQuery(
                            Constants.dbRefrence,
                            deleteUserViewEntryQuery,
                            (deleteResponse) => {
                              const currentUTCTimeStamp = Helper.getUTCTime();
                              const insertUserViewEntryQuery = `insert into user_view_entries (user_id, cat_id, tag_ids, is_cat_search, main_tag, is_price_entered, updated_at, created_at) values 
                                            (${userDetails.id},${catId},"${dictData["tag_ids"]}",${dictData["is_cat_search"]},"${dictData["main_tag"]}",0,"${currentUTCTimeStamp}","${currentUTCTimeStamp}")`;
                              SQLHelper.performTransactionWithQuery(
                                Constants.dbRefrence,
                                insertUserViewEntryQuery,
                                (insertResponse) => {}
                              );
                            }
                          );
                        }
                      );
                    }
                  };

                  var tempPrice = 0;
                  var tgArr = [];
                  var eIdArr = [];
                  var tempTagArr = [];
                  var entryTagIds = "";

                  finalentryArr.forEach((entry) => {
                    eIdArr.push(entry.id);
                    console.log("forEach finalentryArr -------->", entry);
                    tempPrice += entry.price;
                    entryTagIds += `,${entry.tag_ids}`;
                  });

                  console.log("forEach entryTagIds -------->", entryTagIds);
                  if (entryTagIds != "") {
                    entryTagIds = entryTagIds.substring(1);
                  }
                  console.log(
                    "forEach removed first char entryTagIds -------->",
                    entryTagIds
                  );

                  const avgPrice = tempPrice / totalEntry;

                  var uniqueTagIds = [...new Set(entryTagIds.split(","))];
                  uniqueTagIds.sort((a, b) => a - b);
                  console.log(
                    "forEach uniqueTagIds.join() -------->",
                    uniqueTagIds.join(",")
                  );
                  eIdArr.sort((a, b) => a - b);
                  if (uniqueTagIds.length > 0) {
                    const strUniqueTagIds = uniqueTagIds.join(",");
                    const associatedTagsQuery = `select * from tags where id in (${strUniqueTagIds})`;
                    SQLHelper.performTransactionWithQuery(
                      Constants.dbRefrence,
                      associatedTagsQuery,
                      (arrTagDetails) => {
                        console.log(
                          "associatedTagsQuery 11111111",
                          arrTagDetails
                        );
                        var dictonaryTags = Helper.covertArrayToDictionary(
                          arrTagDetails,
                          "id"
                        );
                        let tagIdToExclude = uniqueTagIds[0];
                        dictData["main_tag_id"] =
                          dictonaryTags[tagIdToExclude].id;
                        dictData["main_tag"] =
                          dictonaryTags[tagIdToExclude].name;
                        console.log(
                          "associatedTagsQuery 11111111",
                          dictonaryTags
                        );
                        delete dictonaryTags[uniqueTagIds[0]];
                        arrTagDetails.forEach((tagDetails) => {
                          if (tagDetails.id != tagIdToExclude) {
                            tgArr.push(tagDetails.name);
                          }
                        });
                        dictData["other_tags"] = tgArr.join(",");
                        dictData["entry_ids"] = eIdArr.join(",");
                        dictData["tag_ids"] = uniqueTagIds.join(",");
                        dictData["entry_count"] = totalEntry;

                        console.log(
                          "associatedTagsQuery dictData dictData",
                          dictData
                        );

                        OfflineHelper.convertPrice(
                          avgPrice,
                          firstEntry.currency_code,
                          userDetails.search_country.currency_code,
                          (newPrice) => {
                            avrageSearchPrice = newPrice;
                            validateAndPass();
                          }
                        );

                        OfflineHelper.convertPrice(
                          avgPrice,
                          firstEntry.currency_code,
                          userDetails.country.currency_code,
                          (newPrice) => {
                            avrageHomePrice = newPrice;
                            validateAndPass();
                          }
                        );
                      }
                    );
                  }

                  OfflineHelper.convertPrice(
                    firstEntry.price,
                    firstEntry.currency_code,
                    userDetails.search_country.currency_code,
                    (newPrice) => {
                      lowSearchPrice = newPrice;
                      validateAndPass();
                    }
                  );

                  OfflineHelper.convertPrice(
                    firstEntry.price,
                    firstEntry.currency_code,
                    userDetails.country.currency_code,
                    (newPrice) => {
                      lowHomePrice = newPrice;
                      validateAndPass();
                    }
                  );

                  OfflineHelper.convertPrice(
                    lastEntry.price,
                    lastEntry.currency_code,
                    userDetails.search_country.currency_code,
                    (newPrice) => {
                      highSearchPrice = newPrice;
                      validateAndPass();
                    }
                  );

                  OfflineHelper.convertPrice(
                    lastEntry.price,
                    lastEntry.currency_code,
                    userDetails.country.currency_code,
                    (newPrice) => {
                      highHomePrice = newPrice;
                      validateAndPass();
                    }
                  );
                }
              );
            }
          }
        );
      });
    }
  };
}

async function entryUpdate(item, userid) {
  let data = {};
  data.listingid = item.listing;
  data.price = item.price;
  data.description = item.description;
  data.user_id = userid;
  data.tags = item.tags;
  data.categories = item.categories;

  const resp = await api("v1/api/request", "POST", {
    endpoint: "entry/update",
    type: "POST",
    data: data,
  });

  return resp;
}

function entryList(catId, tagIds = "", navigation) {
  return (dispatch) => {
    if (Constants.isOnline) {
      dispatch({
        type: ActionTypes.ENTRY_LIST_LOADING,
        loading: true,
      });

      mApi
        .entryList(catId, tagIds)
        .then((response) => {
          if (response.ok) {
            if (response.data.success) {
              dispatch({
                type: ActionTypes.ENTRY_LIST,
                entryList: response.data.data,
              });
            } else {
              Helper.showAlert(
                response.data.message,
                navigation,
                response.data.status
              );
            }
          } else {
            Helper.showAlert(response.problem, navigation);
          }
          dispatch({
            type: ActionTypes.ENTRY_LIST_LOADING,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error:", error);
          dispatch({
            type: ActionTypes.ENTRY_LIST_LOADING,
            loading: false,
          });
        });
    } else {
      console.log(
        "Startttttt entryList entryList entryList ------------->",
        catId,
        tagIds
      );
      OfflineHelper.getUserEntriesByDistance(async (arrEntryId) => {
        const user = await AsyncStorage.getItem(Constants.KEY_USER_DETAIL);
        const userDetails = JSON.parse(user);
        const user_search_currency_code =
          userDetails.search_country.currency_code;
        const monthsAgoDate = moment(new Date())
          .subtract(userDetails.search_months, "months")
          .format(Constants.FULL_DATE_TIME_STAMP_FORMAT);
        // alert(monthsAgoDate)
        let mediaCountQuery = `(select count(*) from entry_media where entry_media.entry_id = entries.id and entry_media.entry_id IS NOT NULL) as photo_count`;
        let usersFields = `(select name from users where users.id = entries.user_id) as user_name`;
        let categoryFields = `categories.name as category_name`;
        let countriesFields = `countries.currency,countries.currency_code,countries.currency_symbol`;
        let countriesLeftJoin = `LEFT JOIN countries ON countries.id = entries.country_id`;
        let categoryLeftJoin = `LEFT JOIN categories ON categories.id = entries.cat_id`;
        let userLeftJoin = `LEFT JOIN users ON users.id = entries.user_id`;
        var queryEntries = `SELECT entries.*,${usersFields},${mediaCountQuery},${categoryFields},${countriesFields} from entries ${countriesLeftJoin} ${categoryLeftJoin} where entries.cat_id = ${catId} and date(entries.created_at) > ${monthsAgoDate} and entries.id in (${arrEntryId})`;
        if (tagIds != "") {
          var tag_ids = tagIds.split(",");
          tag_ids.sort((a, b) => a - b);
          const sortedTagIds = tag_ids.join(",");
          // append  `where('tag_ids',implode(",",$tag_ids)` exact match to requested tags
          queryEntries += ` and entries.tag_ids LIKE "${sortedTagIds}"`;
        }
        queryEntries += ` order by price asc`;
        SQLHelper.performTransactionWithQuery(
          Constants.dbRefrence,
          queryEntries,
          (finalentryArr) => {
            console.log("queryEntries :", finalentryArr);
            const totalEntry = finalentryArr.length;
            if (totalEntry > 0) {
              var dictData = {};
              var data = [];
              dictData["entry_count"] = totalEntry;
              var currentIndex = -1;
              var responseCount = 0;

              finalentryArr.forEach((entry) => {
                currentIndex++;
                var dictEntryData = {};
                var tgArr = [];
                var uniqueTagIds = [...new Set(entry.tag_ids.split(","))];

                uniqueTagIds.sort((a, b) => a - b);
                if (uniqueTagIds.length > 0) {
                  const strUniqueTagIds = uniqueTagIds.join(",");
                  const associatedTagsQuery = `select * from tags where id in (${strUniqueTagIds})`;
                  SQLHelper.performTransactionWithQuery(
                    Constants.dbRefrence,
                    associatedTagsQuery,
                    (arrTagDetails) => {
                      var dictonaryTags = Helper.covertArrayToDictionary(
                        arrTagDetails,
                        "id"
                      );
                      // let tagIdToExclude = uniqueTagIds[0]
                      // dictData['main_tag_id'] = dictonaryTags[tagIdToExclude].id;
                      // dictData['main_tag'] = dictonaryTags[tagIdToExclude].name;
                      // console.log('associatedTagsQuery 11111111', dictonaryTags);
                      delete dictonaryTags[uniqueTagIds[0]];
                      arrTagDetails.forEach((tagDetails) => {
                        // if (tagDetails.id != tagIdToExclude) {
                        tgArr.push(tagDetails.name);
                        // }
                      });
                      dictEntryData["tags"] = tgArr.join(",");
                      data[currentIndex] = dictEntryData;
                    }
                  );
                }

                dictEntryData["id"] = entry.id;
                dictEntryData["currency"] = user_search_currency_code;
                dictEntryData["category"] = entry.category_name;
                dictEntryData["description"] = entry.description ?? "";
                dictEntryData["photo_cnt"] = entry.photo_count;
                dictEntryData["user_name"] = entry.user_name ?? "";
                data[currentIndex] = dictEntryData;

                OfflineHelper.convertPrice(
                  entry.price,
                  entry.currency_code,
                  user_search_currency_code,
                  (newPrice) => {
                    responseCount++;
                    dictEntryData["price"] = newPrice;
                    data[currentIndex] = dictEntryData;
                    if (responseCount == finalentryArr.length) {
                      dictData["entries"] = data;
                      console.log(
                        "dictEntry List Data dictData dictData",
                        dictData
                      );
                      dispatch({
                        type: ActionTypes.ENTRY_LIST,
                        entryList: dictData,
                      });
                    }
                  }
                );
                // tempPrice += entry.price;
                // entryTagIds += entry.tag_ids
              });
            }
          }
        );
      });
    }
  };
}

function entryDetail(entryId, navigation) {
  return (dispatch) => {
    if (Constants.isOnline) {
      dispatch({
        type: ActionTypes.ENTRY_DETAIL_LOADING,
        loading: true,
      });
      mApi
        .entryDetail(entryId)
        .then((response) => {
          if (response.ok) {
            if (response.data.success) {
              dispatch({
                type: ActionTypes.ENTRY_DETAIL,
                entryDetail: response.data.data,
              });
            } else {
              Helper.showAlert(
                response.data.message,
                navigation,
                response.data.status
              );
            }
          } else {
            Helper.showAlert(response.problem, navigation);
          }
          dispatch({
            type: ActionTypes.ENTRY_DETAIL_LOADING,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error:", error);
          dispatch({
            type: ActionTypes.ENTRY_DETAIL_LOADING,
            loading: false,
          });
        });
    } else {
      AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
        const user = JSON.parse(result);
        const user_currency_code = user.country.currency_code;
        const user_currency_symbol = user.country.currency_symbol;
        const user_search_currency_code = user.search_country.currency_code;
        const user_search_currency_symbol = user.search_country.currency_symbol;
        let mediaCountQuery = `(select count(*) from entry_media where entry_media.entry_id = entries.id and entry_media.entry_id IS NOT NULL) as photo_cnt`;
        let usersFields = `(select name from users where users.id = entries.user_id) as user_name`;
        let userLeftJoin = `LEFT JOIN users ON users.id = entries.user_id`;
        let countriesFields = `countries.currency,countries.currency_code,countries.currency_symbol`;
        let countriesLeftJoin = `LEFT JOIN countries ON countries.id = entries.country_id`;

        let entryDetailQuery = `select *, ${mediaCountQuery},${countriesFields}, ${usersFields} from entries ${countriesLeftJoin} where entries.id = ${entryId}`;
        SQLHelper.performTransactionWithQuery(
          Constants.dbRefrence,
          entryDetailQuery,
          (arrEntryDetails) => {
            console.log(
              "arrEntryDetails entries entries entries",
              arrEntryDetails
            );
            if (arrEntryDetails.length > 0) {
              const entry = arrEntryDetails[0];
              var searchPrice = "";
              var homePrice = "";
              const validateAndPass = () => {
                if (searchPrice != "" && homePrice != "") {
                  entry["search_price"] = searchPrice;
                  entry["home_price"] = homePrice;
                  entry["search_currency"] = user_search_currency_code;
                  entry["search_symbol"] = user_search_currency_symbol;
                  entry["home_currency_code"] = user_currency_code;
                  entry["home_currency_symbol"] = user_currency_symbol;
                  console.log("entry entry entry entry", entry);
                  dispatch({
                    type: ActionTypes.ENTRY_DETAIL,
                    entryDetail: entry,
                  });
                }
              };

              if (entry.photo_cnt > 0) {
                const associatedMediaQuery = `select * from entry_media where entry_media.entry_id = ${entry.id} and entry_media.entry_id is not null`;
                SQLHelper.performTransactionWithQuery(
                  Constants.dbRefrence,
                  associatedMediaQuery,
                  (arrMedia) => {
                    entry["media"] = arrMedia;
                  }
                );
              } else {
                entry["media"] = [];
              }

              var uniqueTagIds = [...new Set(entry.tag_ids.split(","))];
              uniqueTagIds.sort((a, b) => a - b);
              if (uniqueTagIds.length > 0) {
                const strUniqueTagIds = uniqueTagIds.join(",");
                const associatedTagsQuery = `select * from tags where id in (${strUniqueTagIds})`;
                SQLHelper.performTransactionWithQuery(
                  Constants.dbRefrence,
                  associatedTagsQuery,
                  (arrTags) => {
                    var arrTagNames = [];

                    if (arrTags.length > 0) {
                      arrTags.forEach((tag) => {
                        arrTagNames.push(tag.name);
                      });
                    }
                    entry["tags"] = arrTagNames.join(",");
                  }
                );
              } else {
                entry["tags"] = "";
              }

              OfflineHelper.convertPrice(
                entry.price,
                entry.currency_code,
                user_search_currency_code,
                (newPrice) => {
                  searchPrice = newPrice;
                  validateAndPass();
                }
              );

              OfflineHelper.convertPrice(
                entry.price,
                entry.currency_code,
                user_currency_code,
                (newPrice) => {
                  homePrice = newPrice;
                  validateAndPass();
                }
              );
            }
          }
        );
      });
    }
  };
}

async function entryAdd(param, userid) {
  // mApi.entryAdd
  // return dispatch => {
  //     if (Constants.isOnline) {
  //         dispatch({
  //             type: ActionTypes.ENTRY_ADD_LOADING,
  //             loading: true
  //         })
  //         dispatch({
  //             type: ActionTypes.ENTRY_ADD,
  //             isEntryAdded: false
  //         })
  //         mApi.entryAdd(param).then(response => {
  //             if (response.ok) {
  //                 if (response.data.success) {
  //                     dispatch(entryPrice(param.catId, param.tagIds, navigation))
  //                     dispatch({
  //                         type: ActionTypes.ENTRY_ADD,
  //                         isEntryAdded: true
  //                     })
  //                 } else {
  //                     Helper.showAlert(response.data.message,navigation,response.data.status)
  //                 }
  //             } else {
  //                 Helper.showAlert(response.problem,navigation)
  //             }
  //             dispatch({
  //                 type: ActionTypes.ENTRY_ADD_LOADING,
  //                 loading: false
  //             })
  //         }).catch(error => {
  //             console.log('error', error);
  //             dispatch({
  //                 type: ActionTypes.ENTRY_ADD_LOADING,
  //                 loading: false
  //             })
  //         })
  //     } else {
  //         console.log('PARMAS ==>', param);
  //         AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
  //             const user = JSON.parse(result)
  //             const { price, catId, location, latitude, longitude, countryId, tagIds, description } = param
  //             const currentDateTime = Helper.getUTCTime()
  //             const radiansLat = Helper.getRadiansFrom(latitude)
  //             const radiansLng = Helper.getRadiansFrom(longitude)
  //             const cos_latitude = Math.cos(radiansLat)
  //             const cos_longitude = Math.cos(radiansLng)
  //             const sin_latitude = Math.sin(radiansLat)
  //             const sin_longitude = Math.sin(radiansLng)
  //             const insertEntryQuery = `insert into entries (price, cat_id, location, latitude, longitude, cos_latitude, cos_longitude, sin_latitude, sin_longitude, country_id, tag_ids, description, user_id, updated_at, created_at) values (${price},${catId},"${location}",${latitude},${longitude},${cos_latitude},${cos_longitude},${sin_latitude},${sin_longitude},${countryId},"${tagIds}","${description}",${user.id},"${currentDateTime}","${currentDateTime}" )`
  //             SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertEntryQuery, (insertId) => {
  //                 if (insertId) {
  //                     const insertOfflineEntryQuery = `insert into offline_insertions (table_name, row_insert_id) values ("entries",${insertId})`
  //                     SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertOfflineEntryQuery, (offlineInsertId) => {})
  //                     var uniqueTagIds = [...new Set(tagIds.split(','))];
  //                     uniqueTagIds.sort((a, b) => a - b);
  //                     uniqueTagIds.forEach(tag => {
  //                         const selectEntryTagQuery = `select * from entry_tags where (entry_id = ${insertId} and tag_id = ${tag}) limit 1`
  //                         SQLHelper.performTransactionWithQuery(Constants.dbRefrence, selectEntryTagQuery, (tagDetails) => {
  //                             if (tagDetails.length == 0) {
  //                                 const insertEntryTagQuery = `insert into entry_tags (entry_id, tag_id, updated_at, created_at) values (${insertId}, ${tag}, "${currentDateTime}", "${currentDateTime}")`
  //                                 SQLHelper.performTransactionWithQuery(Constants.dbRefrence, insertEntryTagQuery, (responseInsertTag) => {})
  //                             }
  //                         })
  //                     });
  //                     var activityText = "You entered a price for ";
  //                     var category_name = ""
  //                     var tag_name = ""
  //                     var responseCount = 0
  //                     const validateResponse = () => {
  //                         if (responseCount == 2) {
  //                             activityText += category_name + " (" + tag_name + " )"
  //                             const sub_text = "Thank you! Your price contribution has helped countless travellers all over the world.";
  //                             const inserActivityQuery = `insert into activities (user_id, text, sub_text, type, is_read, cat_id, tag_ids, is_cat_search, updated_at, created_at) values (${user.id}, "${activityText}", "${sub_text}", "New Entry", 0, ${catId}, "${tagIds}", 0, "${currentDateTime}", "${currentDateTime}")`
  //                             SQLHelper.performTransactionWithQuery(Constants.dbRefrence, inserActivityQuery, (response) => {
  //                                 const updateUserViewEntriesQuery = `update user_view_entries set is_price_entered = ${1}, updated_at = "${currentDateTime}" where user_id = ${user.id} and cat_id = ${catId} and tag_ids = "${tagIds}"`
  //                                 SQLHelper.performTransactionWithQuery(Constants.dbRefrence, updateUserViewEntriesQuery, (response) => {
  //                                     dispatch(entryPrice(catId, tagIds,navigation))
  //                                     dispatch({
  //                                         type: ActionTypes.ENTRY_ADD,
  //                                         isEntryAdded: true
  //                                     })
  //                                 });
  //                             });
  //                         }
  //                     }

  //                     const selectCategoryQuery = `select name from categories where categories.id = ${catId} limit 1`
  //                     SQLHelper.performTransactionWithQuery(Constants.dbRefrence, selectCategoryQuery, (categoryDetails) => {
  //                         console.log('selectCategoryQuery --->', categoryDetails);
  //                         responseCount++;
  //                         if (categoryDetails.length > 0) {
  //                             category_name = categoryDetails[0].name
  //                         }
  //                         validateResponse()
  //                     })

  //                     if (uniqueTagIds.length > 0) {
  //                         const selectTagQuery = `select name from tags where tags.id = ${uniqueTagIds[0]} limit 1`
  //                         SQLHelper.performTransactionWithQuery(Constants.dbRefrence, selectTagQuery, (tagDetails) => {
  //                             responseCount++;
  //                             console.log('selectTagQuery --->', tagDetails);
  //                             if (tagDetails.length > 0) {
  //                                 tag_name = tagDetails[0].name
  //                             }
  //                             validateResponse()
  //                         })
  //                     }
  //                 }
  //             })
  //         });
  //     }
  // }
  let data = {};
  data.latitude = param.latitude;
  data.longitude = param.longitude;
  data.distanceInMetres = param.user_radius * 1000;
  data.user_id = userid;

  const resp = await api("v1/api/request", "POST", {
    endpoint: "entry/add",
    type: "POST",
    data: data,
  });

  return resp;
}

async function myEntries(param, userid) {
  let data = {};
  data.latitude = param.latitude;
  data.longitude = param.longitude;
  data.distanceInMetres = param.distanceInMetres * 1000;
  data.user_id = userid;

  const resp = await api("v1/api/request", "POST", {
    endpoint: "user/entries",
    type: "POST",
    data: data,
  });

  return resp;
  // return dispatch => {
  //     if (Constants.isOnline) {
  //         dispatch({
  //             type: ActionTypes.MY_ENTRIES_LOADING,
  //             loading: true
  //         })

  //         mApi.myEntries().then(response => {
  //             if (response.ok) {
  //                 if (response.data.success) {
  //                     dispatch({
  //                         type: ActionTypes.MY_ENTRIES,
  //                         arrMyEntry: response.data.data
  //                     })
  //                 } else {
  //                     Helper.showAlert(response.data.message,navigation,response.data.status)
  //                 }
  //             } else {
  //                 Helper.showAlert(response.problem,navigation)
  //             }
  //             dispatch({
  //                 type: ActionTypes.MY_ENTRIES_LOADING,
  //                 loading: false
  //             })
  //         }).catch(error => {
  //             console.log('error', error);
  //             dispatch({
  //                 type: ActionTypes.MY_ENTRIES_LOADING,
  //                 loading: false
  //             })
  //         })
  //     } else {
  //         AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
  //             const userDetails = JSON.parse(result)
  //             let mediaCountQuery = `(select count(*) from entry_media where entry_media.entry_id = entries.id and entry_media.entry_id IS NOT NULL) as media_count`
  //             // let usersFields = `users.name as user_name`
  //             let categoryFields = `categories.name as categories_name, categories.parent_id as categories_parent_id, categories.type as categories_type, categories.special_message as categories_special_message, categories.unit_measure as categories_unit_measure`
  //             let countriesFields = `countries.name as countries_name, countries.currency_code as countries_currency_code, countries.currency_symbol as countries_currency_symbol, countries.iso_code_2 as countries_iso_code_2, countries.iso_code_3 as countries_iso_code_3, countries.currency as countries_currency, countries.country_code as countries_country_code`
  //             let countriesLeftJoin = `LEFT JOIN countries ON countries.id = entries.country_id`
  //             let categoryLeftJoin = `LEFT JOIN categories ON categories.id = entries.cat_id`
  //             var queryMyEntries = `SELECT entries.*, ${mediaCountQuery}, ${categoryFields}, ${countriesFields} from entries  ${countriesLeftJoin} ${categoryLeftJoin} where entries.user_id = ${userDetails.id}`
  //             SQLHelper.performTransactionWithQuery(Constants.dbRefrence, queryMyEntries, (arrMyEntry) => {
  //                 console.log('arrMyEntry ---> ', arrMyEntry);
  //                 var data = []
  //                 var currentIndex = -1;
  //                 var responseCount = 0;
  //                 var validateAndPass = () => {
  //                     let maxCount = arrMyEntry.length * 3
  //                     console.log(`validateAndPass performing responseCount ---> ${responseCount} maxCount ----> ${maxCount}`);
  //                     if (responseCount == (maxCount)) {
  //                         console.log(`validateAndPass Done performing data ---> `, data);
  //                         dispatch({
  //                             type: ActionTypes.MY_ENTRIES,
  //                             arrMyEntry: data.reverse()
  //                         })
  //                     }
  //                 }
  //                 arrMyEntry.forEach(entry => {
  //                     currentIndex++;
  //                     console.log('ArrMyEntry entry entry entry ---> ', entry, currentIndex);
  //                     if (entry.media_count > 0) {
  //                         const associatedMediaQuery = `select * from entry_media where entry_media.entry_id = ${entry.id} and entry_media.entry_id is not null`
  //                         SQLHelper.performTransactionWithQuery(Constants.dbRefrence, associatedMediaQuery, (arrMedia) => {
  //                             responseCount++
  //                             entry['media'] = arrMedia
  //                             data[currentIndex] = entry
  //                         })
  //                     }
  //                     else {
  //                         responseCount++
  //                         entry['media'] = [];
  //                         data[currentIndex] = entry
  //                     }

  //                     // var dicCategory = {}
  //                     // dicCategory['name'] = entry.categories_name
  //                     // dicCategory['parent_id'] = entry.categories_parent_id
  //                     // dicCategory['type'] = entry.categories_type
  //                     // dicCategory['special_message'] = entry.categories_special_message
  //                     // dicCategory['unit_measure'] = entry.categories_unit_measure
  //                     // entry['category'] = dicCategory

  //                     var dicCountry = {}
  //                     dicCountry['name'] = entry.countries_name
  //                     dicCountry['currency_code'] = entry.countries_currency_code
  //                     dicCountry['currency_symbol'] = entry.countries_currency_symbol
  //                     dicCountry['iso_code_2'] = entry.countries_iso_code_2
  //                     dicCountry['iso_code_3'] = entry.countries_iso_code_3
  //                     dicCountry['currency'] = entry.countries_currency
  //                     dicCountry['country_code'] = entry.countries_country_code
  //                     entry['country'] = dicCountry
  //                     data[currentIndex] = entry

  //                     entry['created_date'] = moment(entry.created_at).format(Constants.DATE_FORMAT_DMY)
  //                     if (entry.tag_ids == null) {
  //                         responseCount++;
  //                         entry['tags'] = [];
  //                         data[currentIndex] = entry
  //                         validateAndPass()
  //                     } else {
  //                         var uniqueTagIds = [...new Set(entry.tag_ids.split(','))];
  //                         uniqueTagIds.sort((a, b) => a - b);
  //                         if (uniqueTagIds.length > 0) {
  //                             const strUniqueTagIds = uniqueTagIds.join(',')
  //                             const associatedTagsQuery = `select * from tags where id in (${strUniqueTagIds})`
  //                             SQLHelper.performTransactionWithQuery(Constants.dbRefrence, associatedTagsQuery, (arrTags) => {
  //                                 responseCount++;
  //                                 entry['tags'] = arrTags
  //                                 data[currentIndex] = entry
  //                                 validateAndPass()
  //                             })
  //                         }
  //                         else {
  //                             responseCount++;
  //                             entry['tags'] = [];
  //                             data[currentIndex] = entry
  //                             validateAndPass()
  //                         }
  //                     }

  //                     OfflineHelper.getCategoryHierarchy(entry.cat_id, (category, categoryArr) => {
  //                         responseCount++;
  //                         entry['category'] = category;
  //                         entry['categoryArr'] = categoryArr;
  //                         data[currentIndex] = entry
  //                         validateAndPass()
  //                     })
  //                 })
  //             })
  //         })
  //     }
  // }
}
export { entryActions };
