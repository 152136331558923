import * as Helper from '../../Lib/Helper';
import Api from '../../Services/Api';
import ActionTypes from './types';
import { entryActions } from './Entry';
import Constants from '../../Lib/Constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../../src/utils/api';


const mApi = Api.createSecure();

const activityActions = {
  activityList,
  toggleNotification,
  getActivityUnreadCount,
  setAllActivityAsRead,
  setActivityAsRead
};

async function activityList(user) {
  console.log("send data: ", {
    user_id: user.id,
      latitude: user.search_latitude,
      longitude: user.search_longitude,
      distanceInMetres: (user.search_radius * 1000)
  })
  let activities = await api("v1/api/request", "POST", {
    endpoint: "user/activities",
    type: "POST",
    data: {
      user_id: user.id,
      latitude: user.search_latitude,
      longitude: user.search_longitude,
      distanceInMetres: (user.search_radius * 1000)
    }
  });
  return activities;
  // return dispatch => {
  //   if (Constants.isOnline) {
  //     dispatch({
  //       type: ActionTypes.ACTIVITY_LOADING,
  //       loading: true,
  //     });

  //     mApi
  //       .activityList()
  //       .then(response => {
  //         if (response.ok) {
  //           if (response.data.success) {
  //             dispatch({
  //               type: ActionTypes.ACTIVITY_LIST,
  //               arrActivity: response.data.data,
  //             });
  //           } else {
  //             Helper.showAlert(response.data.message,navigation,response.data.status)
  //           }
  //         } else {
  //           Helper.showAlert(response.problem,navigation);
  //         }
  //         dispatch({
  //           type: ActionTypes.ACTIVITY_LOADING,
  //           loading: false,
  //         });
  //       })
  //       .catch(error => {
  //         console.log('error:', error);
  //         dispatch({
  //           type: ActionTypes.ACTIVITY_LOADING,
  //           loading: false,
  //         });
  //       });
  //   } else {
  //     AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
  //       const userDetails = JSON.parse(result)
  //       SQLHelper.selectTable(Constants.dbRefrence, Constants.SQL_TABLE_NAMES.ACTIVITIES_6, `user_id = ${userDetails.id}`, (arrActivity) => {
  //         dispatch({
  //           type: ActionTypes.ACTIVITY_LIST,
  //           arrActivity: arrActivity.reverse(),
  //         })
  //       })
  //     })
  //   }
  // };
}

function toggleNotification(isAllow) {
  return dispatch => {
    if (Constants.isOnline) {
      dispatch({
        type: ActionTypes.ACTIVITY_LOADING,
        loading: true,
      });

      mApi
        .toggleNotification(isAllow)
        .then(response => {
          if (response.ok) {
            if (response.data.success) {
              dispatch({
                type: ActionTypes.TOGGLE_NOTIFICATION,
                isNotificationAllow: isAllow,
              });
            } else {
              Helper.showAlert(response.data.message);
            }
          } else {
            Helper.showAlert(response.problem);
          }
          dispatch({
            type: ActionTypes.ACTIVITY_LOADING,
            loading: false,
          });
        })
        .catch(error => {
          console.log('error:', error);
          dispatch({
            type: ActionTypes.ACTIVITY_LOADING,
            loading: false,
          });
        });
    } else{            
      // Helper.showAlert('No internet connection!');
    }
  }
}

function getActivityUnreadCount() {
  return dispatch => {
    if (Constants.isOnline) {
      mApi.getActivityUnreadCount().then(response => {
        console.log('getActivityUnreadCount',response);
        if (response.ok) {
          if (response.data.success) {
            dispatch({
              type: ActionTypes.ACTIVITY_UNREAD_COUNT,
              activityUnreadCount: response.data.data.unread_cnt,
            });
            Constants.activityUnreadCount = response.data.data.unread_cnt
          }
        }
      })
      .catch(error => {
        console.log('error:', error);
      });
    } else {
      AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
        const userDetails = JSON.parse(result)
        const countQuery = `select count(*) as unread_cnt from activities where user_id = ${userDetails.id} and (is_read IS NULL OR is_read = 0)`
        SQLHelper.performTransactionWithQuery(Constants.dbRefrence, countQuery , (response) => {
          if (response.length > 0) {
            const unread_cnt = response[0].unread_cnt
            dispatch({
              type: ActionTypes.ACTIVITY_UNREAD_COUNT,
              activityUnreadCount: unread_cnt,
            });
            Constants.activityUnreadCount = unread_cnt
          }          
        })
      })
    }
  }
}

function setAllActivityAsRead() {
  return dispatch => {
    if (Constants.isOnline) {
      mApi.setAllActivityAsRead().then(response => {
        if (response.ok) {
          if (response.data.success) {
            dispatch({
              type: ActionTypes.ACTIVITY_UNREAD_COUNT,
              activityUnreadCount: 0,
            });
          }
        }
      }).catch(error => {});
    } else {
      AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
        const userDetails = JSON.parse(result)
        const updateReadFieldQuery = `update activities set is_read = 1 where user_id = ${userDetails.id} `
        SQLHelper.performTransactionWithQuery(Constants.dbRefrence, updateReadFieldQuery , (response) => {
          if (response.length > 0) {
            dispatch({
              type: ActionTypes.ACTIVITY_UNREAD_COUNT,
              activityUnreadCount: 0,
            });
          }          
        })
      })
    }
  }
}


async function setActivityAsRead(activityId, user) {
  console.log("set read: ", activityId)
  console.log("set read user: ", user)
  let setRead = await api("v1/api/request", "POST", {
    endpoint: "user/activity/read_status",
    type: "POST",
    data: {
      user_id: user.id,
      activity_id: activityId
    }
  });

  return setRead;
  // return dispatch => {
  //   if (Constants.isOnline) {
  //     mApi.setActivityAsRead(activityId).then(response => {}).catch(error => {});
  //   } else {
  //     AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
  //       const userDetails = JSON.parse(result)
  //       const updateReadFieldQuery = `update activities set is_read = 1 where user_id = ${userDetails.id} and id = ${activityId}`
  //       SQLHelper.performTransactionWithQuery(Constants.dbRefrence, updateReadFieldQuery , (response) => {
  //         Constants.activityUnreadCount = Constants.activityUnreadCount - 1
  //         dispatch({
  //           type: ActionTypes.ACTIVITY_UNREAD_COUNT,
  //           activityUnreadCount: Constants.activityUnreadCount,
  //         });
  //       })
  //     })
  //   }
  // }
}

export { activityActions };
