//chuyen thanh onboarding
import React, { useEffect, useState } from "react";
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  Button,
  Pressable,
  Image,
  Platform,
  ActivityIndicator,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { ScreenNames, NavigatorName } from "../../../src/routes/screen";
import { styles } from "./style";
import * as AuthSession from "expo-auth-session";
import toQueryString from "to-querystring";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { Images } from "../../../App/Themes";
import { LinearGradient } from "expo-linear-gradient";
import { api } from "../../../src/utils/api";
import getUserLocation from "../../../App/Services/getUserLocation";
import { useIsFocused } from "@react-navigation/native";
import * as Location from "expo-location";
import { apiRequests } from "../../../App/apiRequests";
import secrets from "../../../secrets.json";
import auth0 from 'auth0-js';
import axios from "axios";
import { getIpLocation } from 'react-ipgeolocation';


const redirectURL = "https://web.tengah.com";
// const redirectURL = "https://localhost:19006/";
const IP_GEOLOCATION_API_URL = 'https://ipapi.co/json/';


export default function Landing1({ ...props }) {
  const redirectUrl = AuthSession.makeRedirectUri({ useProxy: false });
  const [loading, setLoading] = useState(true);
  const [locationData, setLocationData] = useState(null);
  const isFocused = useIsFocused();
  const checkAuth = async () => {
    const token = await AsyncStorage.getItem("token");
    console.log("is token available? ", token);
    if (token) {
      let user = await getUser();
      if (user?.success) {
        await AsyncStorage.setItem("user", JSON.stringify(user.data));
        // await getUserLocation();
        // await getLocationDetails();
        let location = await getLocationDetails();
          // alert(JSON.stringify(location));
        await setUserLocation(location);
        props.navigation.navigate(NavigatorName.HomeNavigator);
        // props.navigation.navigate(ScreenNames.Home);
        //go to screen after login
      } else {
        if (user?.error) {
          console.log(user.error);
          //alert(user.error);
        } else if (user == undefined) {
          setLoading(false);
        } else {
          console.log("nav name: ", NavigatorName.RegisterNavigator);
          props.navigation.navigate(NavigatorName.RegisterNavigator);
        }
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getUser = async () => {
    try {
      let resp = await api("v1/api/request?getUser=true", "POST", {
        endpoint: "check_email",
        type: "POST",
        data: null,
      });

      // alert(JSON.stringify(resp));

      console.log("user data: ", resp);
      if(resp){
        return resp?.data;
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error: ", error);
      // alert(JSON.stringify(error));
      return false;
    }
  };

  const setRefreshToken = async (code) => {
    const redirectUrl = AuthSession.makeRedirectUri({ useProxy: false });
    var options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        grant_type: "authorization_code",
        client_id: secrets.auth0ClientId,
        client_secret: secrets.auth0ClientSecret,
        code: code,
        redirect_uri: redirectUrl,
        // redirect_uri: redirectUrl + "home",
      }),
    };

    console.log("code: ", code);

    const getAuth = fetch(secrets.auth0Domain + "/oauth/token", options)
      .then((res) => res.json())
      .then((result) => {
        // console.log("efresh token result: ", result);
        if (result.access_token) {
          console.log("GET NEW TOKEN:::::");
          console.log(result);
          AsyncStorage.setItem("token", result.access_token);
          AsyncStorage.setItem("refreshToken", result.refresh_token);
          let expiry = moment()
            .add(result.expires_in, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
          AsyncStorage.setItem("expires_in", String(expiry));
          console.log("refreshToken: ", result.refresh_token);
        } else {
          console.log(result);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        console.log("Using Code to get Auth");
      });
  };

  var webAuth = new auth0.WebAuth({
    domain: secrets.auth0Domain,
    clientID: secrets.auth0ClientId,
    audience: secrets.auth0Audience,
    responseType: 'token code',
    redirectUri: redirectURL,
    responseMode: 'fragment',
    scope:
      'openid profile email client_credentials offline_access read:current_user update:users update:current_user_identities create:current_user_metadata update:current_user_metadata delete:current_user_metadata create:current_user_device_credentials delete:current_user_device_credentials',
  });

  const goToAuth0 = async (hint) => {
    // const request = new AuthSession.AuthRequest({
    //   clientId: `${secrets.auth0ClientId}`,
    //   redirectUri: redirectUrl,
    //   prompt: "login",
    //   extraParams: {
    //     screen_hint: `${hint}`,
    //     audience: `${secrets.auth0Audience}`,
    //   },
    //   responseType: "token code",
    //   scopes: [
    //     "openid",
    //     "profile",
    //     "email",
    //     "client_credentials",
    //     "offline_access",
    //     "read:current_user",
    //     "update:users",
    //     "update:current_user_identities",
    //     "create:current_user_metadata",
    //     "update:current_user_metadata",
    //     "delete:current_user_metadata",
    //     "create:current_user_device_credentials",
    //     "delete:current_user_device_credentials",
    //   ],
    //   usePKCE: false,
    // });

    // const result = await request.promptAsync({
    //   authorizationEndpoint: `${secrets.auth0Domain}/authorize`,
    // });

    // // alert(JSON.stringify(result));

    // if (result.params) {
    //   const response = result.params;

    //   if (!result.errorCode) {
    //     await AsyncStorage.setItem("token", response.access_token);
    //     console.log("auth0 code: ", response.code);
    //     console.log("auth0 access_token: ", response.access_token);
    //     setRefreshToken(response.code);
    //     // getUser(response.access_token);
    //     let user = await getUser();
    //     console.log("get user for check: ", user);
    //     // alert(JSON.stringify(user));
    //     if (user?.success) {
    //       await AsyncStorage.setItem("user", JSON.stringify(user.data));
    //       // props.navigation.navigate(NavigatorName.HomeTab);
    //       //go to screen after login
    //       // await getUserLocation();
    //       let location = await getLocationDetails();
    //       // alert(JSON.stringify(location));
    //       await setUserLocation(location);
    //       props.navigation.navigate(NavigatorName.HomeNavigator);
    //     } else {
    //       if (user?.error) {
    //         console.log(user.error);
    //         //alert(user.error);
    //       } else if (user == undefined) {
    //         setLoading(false);
    //       } else {
    //         console.log("nav name: ", NavigatorName.RegisterNavigator);
    //         props.navigation.navigate(NavigatorName.RegisterNavigator);
    //       }
    //       setLoading(false);
    //     }
    //   } else {
    //     // alert("Error occured: " + JSON.stringify(result.error));
    //     setLoading(false);
    //   }
    // } else {
    //   console.log("Failed Login");
    //   console.log(result);
    //   setLoading(false);
    // }
    webAuth.authorize({
      prompt: 'login',
    });
  };

  const getLocationDetails = async () => {
    let setUserLocation = {};
    let userLocation = {};
  
      // For web, use the navigator.geolocation API
      const getCurrentPosition = () => {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              console.log("Geolocation success: ", position);
              resolve(position);
            },
            error => {
              console.error("Geolocation error: ", error);
              reject(error);
            },
            {
              enableHighAccuracy: false, // Set to false for low accuracy
              timeout: 5000, // 30 seconds timeout
              maximumAge: 0
            }
          );
        });
      };
    
      const getIpLocation = async () => {
        try {
          const response = await axios.get(IP_GEOLOCATION_API_URL);
          const data = response.data;
    
          if (data && data.latitude && data.longitude) {
            return {
              coords: {
                latitude: data.latitude,
                longitude: data.longitude,
              },
            };
          } else {
            throw new Error("IP Geolocation failed");
          }
        } catch (error) {
          console.error("IP Geolocation error: ", error);
          return null;
        }
      };
  
      try {
        const position = await getCurrentPosition();
        setUserLocation = {
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        };
      } catch (error) {
        console.error("Falling back to IP-based geolocation");
        setUserLocation = await getIpLocation();
      }
  
      if (!setUserLocation) {
        console.error("Failed to obtain geolocation data");
        return null;
      }
  
      const address = await getReverseGeocodingData(
        setUserLocation.coords.latitude,
        setUserLocation.coords.longitude
      );
  
      console.log("Current address: ", address);
  
      if (address) {
        let locationData = {
          country: address.country,
          latitude: setUserLocation.coords.latitude,
          location: `${address.city}, ${address.region}`,
          longitude: setUserLocation.coords.longitude,
          short_name: address.country_code,
        };
  
        return locationData;
      } else {
        console.log("No address found");
        return null;
      }
  };

  const getReverseGeocodingData = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
  
    try {
      const response = await axios.get(url);
      const data = response.data;
  
      if (data && data.address) {
        const address = {
          country: data.address.country,
          city: data.address.city || data.address.town || data.address.village,
          region: data.address.state,
          country_code: data.address.country_code.toUpperCase(),
        };
  
        return address;
      } else {
        return null;
      }
    } catch (error) {
      console.log('Error in getReverseGeocodingData: ', error);
      return null;
    }
  };

  const setUserLocation = async (locationData) => {
    let updateLocation = await apiRequests.updateLocation(locationData);
    if (updateLocation.status == 200) {
      if (updateLocation.data.success) {
        console.log("location was updated: ", updateLocation.data);
      }
    }
  };


  useEffect(() => {
    if (isFocused) {
      const hash = window.location.hash;
      const searchParams = new URLSearchParams(hash.substring(1));
      const token = searchParams.get("access_token");
      const expires_in = searchParams.get("expires_in");
  
      if (token) {
        AsyncStorage.setItem("token", token);
        AsyncStorage.setItem("expires_in", expires_in);
        checkAuth();
      } else {
        checkAuth();
      };
    }
  }, [isFocused]);

  return (
    <ImageBackground
      source={Images.splashBackground}
      style={[styles.ImageBackground]}
    >
      <LinearGradient
        colors={[
          "rgba(26,136,201,0.65)",
          "rgba(62,168,206,0.65)",
          "rgba(101,200,202,0.65)",
          "rgba(107,200,190,0.65)",
          "rgba(116,200,175,0.65)",
        ]}
        locations={[0, 0.27, 0.5, 0.75, 1]}
        style={styles.LinearGradient}
      >
        <SafeAreaView style={styles.mainContainer}>
          <View style={styles.landingContent}>
            <View>
              <View style={[styles.topLaunch, styles.SpaceLaunch]}>
                <Image source={Images.logo} style={styles.landingLogo} />
              </View>
              <View style={[styles.middleLaunch, styles.SpaceLaunch]}>
                <Text style={styles.h1}>Pay the fair price</Text>
                <Text style={styles.h5}>
                  Find out what others have paid for those tickets, shoes,
                  taxi... before you buy it.
                </Text>
              </View>
            </View>
            {loading ? (
              <>
                <ActivityIndicator color={"#FFF"} size={"large"} />
              </>
            ) : (
              <View style={styles.btnWapper}>
                <Pressable
                  style={[styles.socialButton, styles.SignInButtons]}
                  onPress={() => goToAuth0("signup")}
                >
                  <Text style={[styles.buttonText]}>Sign Up</Text>
                </Pressable>
                <Pressable
                  style={[styles.loginButton, styles.SignInButtons]}
                  onPress={() => goToAuth0("login")}
                >
                  <Text style={styles.buttonText}>Log In</Text>
                </Pressable>
              </View>
            )}
          </View>
        </SafeAreaView>
      </LinearGradient>
    </ImageBackground>
  );
}
