import * as Helper from '../../Lib/Helper';
import Api from '../../Services/Api';
import ActionTypes from './types';
import Constants from '../../Lib/Constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../../src/utils/api';

const mApi = Api.createSecure()

const searchActions = {
    search, getRecentSearch, addRecentSearch, getAllTags
}

async function search(keyword, lat, lng, distance, tags, months) {
    const searchResp = await api("v1/api/request", "POST", {
        endpoint: "search",
        type: "POST",
        data: {
            kwd: keyword,
            latitude: lat,
            longitude: lng,
            distanceInMetres: distance,
            tags: tags,
            search_months: months
        }
    });

    // console.log(searchResp.data);
    return searchResp;

}

async function getAllTags(keyword, lat, lng, distance, months) {
    const searchResp = await api("v1/api/request", "POST", {
        endpoint: "all-tags",
        type: "POST",
        data: {
            kwd: keyword,
            latitude: lat,
            longitude: lng,
            distanceInMetres: distance,
            search_months: months,
        }
    });

    // console.log(searchResp.data);
    return searchResp;

}

function getRecentSearch() {
    return dispatch => {
        if (Constants.isOnline) {
            dispatch({
                type: ActionTypes.RECENT_SEARCH_LOADING,
                loading: true,
            })

            mApi.getRecentSearches().then(response => {
                if (response.ok) {
                    if (response.data.success) {
                        dispatch({
                            type: ActionTypes.RECENT_SEARCH_RESPONSE,
                            arrRecentSearch: response.data.data,
                        })
                    } else {
                        Helper.showAlert(response.data.message)
                    }
                } else {
                    Helper.showAlert(response.problem)
                }
                dispatch({
                    type: ActionTypes.RECENT_SEARCH_LOADING,
                    loading: false,
                })
            }).catch(error => {
                console.log('error:', error);
                dispatch({
                    type: ActionTypes.RECENT_SEARCH_LOADING,
                    loading: false,
                })
            })
        } else {
            AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
                const userDetails = JSON.parse(result)
                const recentSearchQuery = `select * from recent_searches where user_id = ${userDetails.id}`
                SQLHelper.performTransactionWithQuery(Constants.dbRefrence, recentSearchQuery, (arrResentSearch) => {
                    var arrRecentData = []
                    var index = 0
                    arrResentSearch.forEach(recentItem => {
                        if (recentItem.cat_id) {
                            OfflineHelper.getCategoryHierarchy(recentItem.cat_id, (category, categoryArr) => {
                                console.log(' -------------> getCategoryHierarchy ----=====', category, categoryArr);
                                recentItem['category'] = category;
                                arrRecentData[index] = recentItem
                                index++;
                                console.log(' -------------> getCategoryHierarchy index length ----=====', index, arrResentSearch.length);
                                if (index == arrResentSearch.length) {
                                    dispatch({
                                        type: ActionTypes.RECENT_SEARCH_RESPONSE,
                                        arrRecentSearch: arrRecentData,
                                    })
                                }
                            })
                        }
                    });
                })
            })
        }
    }
}

function addRecentSearch(param) {
    return dispatch => {
        if (Constants.isOnline) {
            mApi.addRecentSearch(param.title, param.catId, param.tagIds, param.isCatSearch).then(response => {
                if (response.ok) {
                    if (response.data.success) {
                        dispatch(getRecentSearch())
                    }
                }
            }).catch(err => {
                console.log('error: ', error);
            })
        }
        // else {
        // Helper.showAlert('No internet connection!');
        AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
            const userDetails = JSON.parse(result)
            const dateTime = Helper.getUTCTime()
            const deleteFavouritesQuery = `delete from recent_searches where (cat_id = ${param.catId} and title = "${param.title}" and tag_ids = "${param.tagIds}" and is_cat_search = ${param.isCatSearch} and user_id = ${userDetails.id})`
            SQLHelper.performTransactionWithQuery(Constants.dbRefrence, deleteFavouritesQuery, (data) => {
                const insertFavouritesQuery = `insert into recent_searches (cat_id, title, tag_ids, is_cat_search, user_id, updated_at, created_at) values (${param.catId}, "${param.title}", "${param.tagIds}", ${param.isCatSearch}, ${userDetails.id}, "${dateTime}", "${dateTime}")`
                SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertFavouritesQuery, (insertedRecentSearchId) => {
                    if (!Constants.isOnline) {
                        const insertOfflineFavouritesQuery = `insert into offline_insertions (table_name, row_insert_id) values ("recent_searches",${insertedRecentSearchId})`
                        SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertOfflineFavouritesQuery, (offlineInsertId) => {});
                        dispatch(getRecentSearch())
                    }
                });
            });
        });
        // }
    }
}

export { searchActions };

