import {
  NavigationContainer
} from '@react-navigation/native';
import {
  createNativeStackNavigator 
} from '@react-navigation/native-stack';
import React from 'react';
import { navigationRef } from './navigate';
import { NavigatorName, ScreenNames } from './screen';
import SignupScreen from "../../App/Container/SignupScreen";
import SignupDateScreen from "../../App/Container/SignupDateScreen";
import SignupGenderScreen from "../../App/Container/SignupGenderScreen";
import SignupCountryScreen from "../../App/Container/SignupCountryScreen";

const Stack = createNativeStackNavigator ();

const RegisterNav = ({...props}) => (
  console.log("regi nav props: ", props),
  <Stack.Navigator
    screenOptions={{
      cardOverlayEnabled: true,
      gestureEnabled: false,
    }}>
    <Stack.Screen
      name={"SignupScreen"}
      component={SignupScreen}
      initialParams={{ mainNav: props.navigation }}
      options={{
        gestureEnabled: false,
        headerShown: true,
        headerTransparent: true,
        headerTitle: () => {
          return <></>
        },
      }}
    />
    <Stack.Screen
      name={"SignupDateScreen"}
      component={SignupDateScreen}
      options={{
        gestureEnabled: false,
        headerShown: true,
        headerTransparent: true,
        headerTitle: () => {
          return <></>
        },
      }}
    />
    <Stack.Screen
      name={"SignupGenderScreen"}
      component={SignupGenderScreen}
      options={{
        gestureEnabled: false,
        headerShown: true,
        headerTransparent: true,
        headerTitle: () => {
          return <></>
        },
      }}
    />
    <Stack.Screen
      name={"SignupCountryScreen"}
      component={SignupCountryScreen}
      options={{
        gestureEnabled: false,
        headerShown: true,
        headerTransparent: true,
        headerTitle: () => {
          return <></>
        },
      }}
    />
  </Stack.Navigator>
);
export default RegisterNav;
