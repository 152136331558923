import { StyleSheet } from 'react-native';
import { ApplicationStyles, Metrics } from '../../Themes/';
export default StyleSheet.create({
    ...ApplicationStyles.screen, 
    logo: {
        width: Metrics.screenWidth / 2.5,
        height: Metrics.screenWidth / 2.5,
        resizeMode: 'contain',
        alignSelf: 'center',
      },   
})
