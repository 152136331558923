import React, { Component, Fragment } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  Image,
  ImageBackground,
  TouchableOpacity,
  TextInput,
  Modal,
  FlatList,
  Linking,
  ScrollView,
} from "react-native";

import ActionCreators from "../Redux/actions";
import { connect } from "react-redux";
import TimeZone from "react-native-timezone";
import moment from "moment-timezone";

import { Images, Colors, Metrics, Fonts } from "../Themes";
import styles from "./Styles/ActivityScreenStyles";
import { SafeAreaView } from "react-native";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import Icon from "react-native-vector-icons/FontAwesome";
import FooterNavComponents from "../Components/FooterNavComponents";
import Constants from "../Lib/Constants";
import { moveToListScreen } from "../Lib/Helper";
import { useIsFocused } from "@react-navigation/native";
class ActivityScreen extends BaseComponent {
  constructor() {
    super();
    this.state = {
      arrActivity: [],
      loading: true,
      isNotificationAllow: false,
      activityUnreadCount: 0,
      userDetail: null,
    };

    this.getUser = this.getUser.bind(this);
    this.getCurrentTimeZone = this.getCurrentTimeZone.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
    super.componentDidMount();
    this.getUser();
    // this.props.getActivityList(this.props.navigation);
    // this.props.getActivityUnreadCount();
    // this.props.setAllActivityAsRead();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused == false && this.props.isFocused == true) {
      this.getUser();
    }
    // if(this.props.isFocused){
    //   this.getUser();
    // }
    // if (prevProps.activityReducer != this.props.activityReducer) {
    //   if (
    //     prevProps.activityReducer.loading != this.props.activityReducer.loading
    //   ) {
    //     this.setState({loading: this.props.activityReducer.loading});
    //   }
    //   if (
    //     prevProps.activityReducer.arrActivity !=
    //     this.props.activityReducer.arrActivity
    //   ) {
    //     console.log(
    //       'actiity list >>> ',
    //       this.props.activityReducer.arrActivity,
    //     );
    //     this.setState({arrActivity: this.props.activityReducer.arrActivity});
    //   }

    //   if (
    //     prevProps.activityReducer.isNotificationAllow !=
    //     this.props.activityReducer.isNotificationAllow
    //   ) {
    //     console.log(
    //       'Notification response >>>>. ',
    //       this.props.activityReducer.isNotificationAllow,
    //     );
    //     this.setState(prevState => {
    //       return {
    //         isNotificationAllow: !prevState.isNotificationAllow,
    //       };
    //     });
    //   }
    // }
    // if (prevProps.activityUnreadCount != this.props.activityUnreadCount) {
    //   // alert(this.props.activityUnreadCount)
    //   this.setState({ activityUnreadCount: this.props.activityUnreadCount });
    // }
  }

  getUser = async () => {
    let user = await this.props.route.params.getUserDetails();
    let activities = await ActionCreators.activityActions.activityList(user);
    console.log("activities: ", activities.data);
    if (activities.status == 200) {
      this.setState({
        arrActivity: activities.data.data,
        userDetail: user,
        loading: false,
      });
    }
  };

  getCurrentTimeZone = async () => {
    const timezone = await TimeZone.getTimeZone();
    console.log("timezone: ", timezone);
    return timezone;
  };

  getTimeDetail(item) {
    //Fixed
    const start = new Date();
    var end = moment.tz(item.created_at, "UTC");
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    end = moment(end)
      .tz(timezone)
      .format(Constants.FULL_DATE_TIME_STAMP_FORMAT);
    let diffSec = moment(start).diff(end, "seconds");
    let diffMinute = moment(start).diff(end, "minutes");
    let diffHours = moment(start).diff(end, "hours");
    let diffDays = moment(start).diff(end, "days");
    let diffMonth = moment(start).diff(end, "months");
    let diffYrs = moment(start).diff(end, "years");

    if (diffSec < 60) {
      return diffSec + "s";
    } else if (diffMinute < 60) {
      return diffMinute + "m";
    } else if (diffHours < 24) {
      return diffHours + "h";
    } else if (diffMonth < 1) {
      return diffDays + "d";
    } else if (diffMonth < 12) {
      return diffMonth + "M";
    } else {
      return diffYrs + "Y";
    }
  }

  getNotifyIcon(item) {
    switch (item.type) {
      case "Price Entered":
        return Images.locationCircle;
        break;
      case "Price Changed":
        return Images.priceCircle;
        break;
      case "Price View":
        return Images.checkCircle;
        break;
      case "New Entry":
        return Images.addCircle;
        break;
      case "Allow Push Notification":
        return Images.notificationCircle;
        break;
      default:
        return Images.checkCircle;
        break;
    }
  }

  toggleNotification() {
    return new Promise((resolve) => {
      // FirebaseService.getNotificationToken().then(token => {
      //   FirebaseService.hasNotificationPermission().then(hasPermission => {
      //     const params = {
      //       isAllow: hasPermission ? 1 : 0,
      //       fcmToken: token,
      //     };
      //     const apiCall = Api.createSecure();
      //     apiCall
      //       .toggleNotification(params)
      //       .then(response => {
      //         resolve(true);
      //       })
      //       .catch(err => {
      //         resolve(true);
      //       });
      //   });
      // });
    });
  }

  renderActivityItem = (item, index) => {
    return (
      <TouchableOpacity
        style={styles.activitylistItem}
        onPress={async () => {
          if (item.type == "Allow Push Notification") {
            // FirebaseService.hasNotificationPermission().then(hasPermission => {
            //   if (hasPermission == 1) {
            //     this.toggleNotification().then(() => {
            //       this.props.getActivityList();
            //     });
            //   } else {
            //     Linking.openSettings();
            //   }
            // });
          } else {
            item.is_read = true;
            console.log("activity item: ", item);
            let setRead =
              await ActionCreators.activityActions.setActivityAsRead(
                item.id,
                this.state.userDetail
              );
            if (setRead.status == 200) {
              this.getUser();
            }
            this.props.navigation.navigate("SearchScreen", {
              isFromSearch: false,
              searchItem: item.entryKey.split("|")[0],
              searchTags: item.entryKey.split("|")[1],
              editPrice: true,
              specificListing: item.entryKeyId,
              enteredValues: {
                price: item.price,
                currencyCode: item.currency_code,
              },
            });
          }
        }}
      >
        <View style={styles.activityListLeft}>
          <Text style={[styles.smallText, styles.alignCenter]}>
            {this.getTimeDetail(item)}
          </Text>
          <Image
            source={this.getNotifyIcon(item)}
            style={styles.activityListIcon}
          />
          {!item.is_read ? <View style={styles.dot} /> : null}
        </View>
        <View style={styles.activityListBody}>
          <Text style={styles.normalText}>
            {item.text}{" "}
            <Text style={[styles.normalTextLight]}>{item.sub_text}</Text>
          </Text>
        </View>
        <View style={styles.activityListRight}>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  renderEmptyComponent = () => {
    return (
      <View style={[styles.notFound, { height: "100%" }]}>
        <Image source={Images.activity} style={styles.notFoundIcon} />
        <Text style={[styles.notFoundText, { width: "70%" }]}>
          There is no actiity for you to view at the moment
        </Text>
      </View>
    );
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.nevHeading}>Activity</Text>
            <Image source={Images.logo} style={styles.headerLogo} />
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.content}>
              {this.state.arrActivity.length > 0 ? (
                <FlatList
                  extraData={this.state}
                  data={this.state.arrActivity}
                  keyExtractor={(item, index) => index + ""}
                  renderItem={({ item, index }) =>
                    this.renderActivityItem(item, index)
                  }
                  // ListEmptyComponent={this.renderEmptyComponent()}
                />
              ) : this.state.loading ? null : (
                this.renderEmptyComponent()
              )}
            </View>
          </ScrollView>
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}
// export default ActivityScreen;

// mapStateToProps = state => {
//   // console.log('Favourite Screen', state);
//   return {
//     activityReducer: state.activityReducer,
//     activityUnreadCount: state.activityReducer.activityUnreadCount,
//   };
// };

// mapDispatchToProps = dispatch => {
//   return {
//     getActivityList: (navigation) => dispatch(ActionCreators.activityActions.activityList(navigation)),
//     setAllActivityAsRead: () => dispatch(ActionCreators.activityActions.setAllActivityAsRead()),
//     setActivityAsRead: (activityId) => dispatch(ActionCreators.activityActions.setActivityAsRead(activityId)),
//     getActivityUnreadCount: () => dispatch(ActionCreators.activityActions.getActivityUnreadCount())
//   };
// };

export default function (props) {
  const isFocused = useIsFocused();
  return <ActivityScreen {...props} isFocused={isFocused} />;
}
