import React, { Component, Fragment } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Images, Colors, Metrics, Fonts } from '../Themes';
import styles from "./Styles/OfflineBannerStyles"
import { connect } from "react-redux";
import Constants from '../Lib/Constants';
import * as Progress from 'react-native-progress';
import ActionCreators from "../Redux/actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Helper from '../Lib/Helper';

class DownloadingBannerTop extends Component {
    constructor() {
        super();
        this.state = {
            isVisible: false,
            totalWSCalls: Constants.NUMBEROFWS,
            completedWSCalls: 1,
            isOnline: null,
            isCallingWS: false,
            nextApiToCall: ''
        }
    }

    componentDidMount(){
        this.prepareForApiCall()
    }

    async prepareForApiCall(){
        var nextApiToCall = await AsyncStorage.getItem(Constants.LAST_API_CALLED)
        var apiProgress = await AsyncStorage.getItem(Constants.API_PROGRESS)
        if (nextApiToCall) {
            this.state.nextApiToCall = nextApiToCall
        }
        if (apiProgress) {
            this.state.completedWSCalls = parseInt(apiProgress)
        }
        this.props.onSyncStart()
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log('DownloadingBannerTop this.props',this.props);
        
        if (prevProps.isOnline !== this.props.isOnline) {
            this.setState({ isOnline: this.props.isOnline })
        }
        if (prevProps.isVisible != this.props.isVisible) {
            this.setState({ isVisible: this.props.isVisible })
            
            if (this.props.isVisible && !this.state.isCallingWS) {
                this.state.isCallingWS = true
                this.startCallingApi(true)
            }
        }
        if (prevProps.dictOfflineData && this.props.dictOfflineData && prevProps.dictOfflineData['tableName'] != this.props.dictOfflineData['tableName']) {
            this.parseResponse(this.props.dictOfflineData)
        }
    }

    startCallingApi(isInitial = false) {
        if (isInitial && this.state.nextApiToCall == '') {
            console.log('start 2',this.state.isOnline)
            this.callWS(Constants.SQL_TABLE_NAMES.COUNTRIES_1)
        }
        else if (this.state.nextApiToCall != '') {
            this.callWS(this.state.nextApiToCall)
        }
    }

    callWS(tableName){
        console.log('Called tableName api =========> ',tableName);
        if (this.props.isOnline && tableName != '') {
            this.props.getOfflineData(tableName)
        }
    }

    parseResponse(response) {
        // console.log('api =========> Response',response);
        const tableName = response['tableName']
        const wsResponse = response[tableName]
        switch (tableName) {
            case Constants.SQL_TABLE_NAMES.COUNTRIES_1:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.USERS_2)
                break;
            case Constants.SQL_TABLE_NAMES.USERS_2:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.CATEGORIES_3)
                break;
            case Constants.SQL_TABLE_NAMES.CATEGORIES_3:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.TAGS_4)
                break;
            case Constants.SQL_TABLE_NAMES.TAGS_4:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.ENTRY_TAGS_5)
                break;
            case Constants.SQL_TABLE_NAMES.ENTRY_TAGS_5:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.ACTIVITIES_6)
                break;
            case Constants.SQL_TABLE_NAMES.ACTIVITIES_6:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.ENTRIES_7)
                break;
            case Constants.SQL_TABLE_NAMES.ENTRIES_7:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.FAVOURITES_8)
                break;
            case Constants.SQL_TABLE_NAMES.FAVOURITES_8:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.ENTRY_MEDIA_9)
                break;
            case Constants.SQL_TABLE_NAMES.ENTRY_MEDIA_9:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.CURRENCYRATE_10)
                break;
            case Constants.SQL_TABLE_NAMES.CURRENCYRATE_10:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.RECENT_SEARCHES_11)
                break;
            case Constants.SQL_TABLE_NAMES.RECENT_SEARCHES_11:
                this.mangeDBForResponse(wsResponse, tableName, Constants.SQL_TABLE_NAMES.USER_VIEW_ENTRIES_12)
                break;
            case Constants.SQL_TABLE_NAMES.USER_VIEW_ENTRIES_12:
                this.mangeDBForResponse(wsResponse, tableName, '')
                break;        
            default:
                break;
        }
    }

    mangeDBForResponse(response, tableName, nextApiToCall) {

        // SQLHelper.insertUpdateTable(this.props.dbRefrence ,tableName,response[1])
        const responseLength = response.length
        if (responseLength > 0) {
            response.forEach(element => {
                SQLHelper.insertUpdateTable(this.props.dbRefrence ,tableName,element)
                if (element == response[responseLength - 1]) {
                    setTimeout(() => {
                        this.manageNextApi(nextApiToCall)
                    }, responseLength);
                }
            });
        }
        else{
            this.manageNextApi(nextApiToCall)
        }
        
    }

    manageNextApi(nextApiToCall){
        this.state.nextApiToCall = nextApiToCall
        const completedWSCalls = this.state.completedWSCalls + 1
        this.state.completedWSCalls = completedWSCalls
        if (nextApiToCall == '') {
            AsyncStorage.removeItem(Constants.LAST_API_CALLED)
            AsyncStorage.removeItem(Constants.API_PROGRESS)
            this.props.onSyncComplete()
        }
        else{
            AsyncStorage.setItem(Constants.LAST_API_CALLED, JSON.stringify(nextApiToCall))
            AsyncStorage.setItem(Constants.API_PROGRESS, JSON.stringify(completedWSCalls.toString()))
            this.callWS(nextApiToCall)
        }
    }
    
    render() {
        // console.log('Render Download banner',this.state);
        return (
            this.state.isVisible ?
            <View style={styles.SafeAreaDownloading}>
                    <TouchableOpacity style={styles.downloadItem}>
                        <Text style={styles.downloadItemText}>Downloading price information</Text>
                        <Image source={Images.arrowRight} style={styles.downloadArrow} />
                    </TouchableOpacity>
                    <Progress.Bar
                        progress={this.state.completedWSCalls / this.state.totalWSCalls}
                        width={Metrics.screenWidth}
                        height={5}
                        // indeterminate={true}
                        borderWidth={0}
                        borderRadius={0}
                        color="#74C8B0"
                        unfilledColor={'rgba(255,255,255,0.3)'}
                    />
                </View>
                : null
        )
    }
}


export default DownloadingBannerTop;