import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { Platform } from "react-native";
import Constants from "../Lib/Constants";
import moment from "moment";

const baseUrl = "https://app.tengah.com/api/v1/";

const create = (baseURL = baseUrl) => {
  /**
   * Step 1
   * Create and configure Axios api object
   */
  const api = axios.create({
    baseURL,
    headers: { "Cache-Control": "no-cache" },
    timeout: 10 * 1000, // 10 sec
  });

  api.interceptors.response.use(
    response => response,
    error => {
      console.error('Error response:', error);
      return Promise.reject(error);
    }
  );

  /**
   * Step 2
   * Define some functions that make api calls
   */
  const getCountries = () => api.get("countries");

  const checkEmail = (email = "", social_type = "", social_id = "") => {
    const data = new FormData();
    data.append("email", email);
    data.append("social_type", social_type);
    data.append("social_id", social_id);
    return api.post("check_email", data);
  };

  const signUp = (userDetail) => {
    const data = new FormData();
    Object.keys(userDetail).forEach(key => {
      data.append(key, userDetail[key]);
    });
    data.append("device_id", "123456");
    data.append("device_type", Platform.OS.toUpperCase());
    return api.post("signup", data);
  };

  const socialSignUpLogin = (userDetail) => {
    const data = new FormData();
    Object.keys(userDetail).forEach(key => {
      data.append(key, userDetail[key]);
    });
    data.append("device_id", "123456");
    data.append("device_type", Platform.OS.toUpperCase());
    return api.post("social_signup_login", data);
  };

  const login = (userDetail) => {
    const data = new FormData();
    Object.keys(userDetail).forEach(key => {
      data.append(key, userDetail[key]);
    });
    data.append("device_id", "123456");
    data.append("device_type", Platform.OS.toUpperCase());
    return api.post("login", data);
  };

  const forgotPassword = (email) => {
    const data = new FormData();
    data.append("email", email);
    return api.post("forgot_password", data);
  };

  const convertPrice = async (price, baseCurrencyCode, targetCurrencyCode) => {
    const data = new FormData();
    data.append("price", price);
    data.append("base_cur", baseCurrencyCode);
    data.append("target_cur", targetCurrencyCode);
    const response = await api.post("currency_convert", data);
    console.log("response from convert: ", response);
    return response;
  };

  /**
   * Step 3
   * Return back collection of functions
   */
  return {
    getCountries,
    checkEmail,
    signUp,
    socialSignUpLogin,
    login,
    forgotPassword,
    convertPrice,
  };
};

const createSecure = (baseURL = baseUrl) => {
  const dateTime = Date.now();
  const timestamp = Math.floor(dateTime / 1000);

  const api = axios.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      Connection: "close",
    },
    timeout: 300 * 1000, // 5 minutes
  });

  api.interceptors.request.use(async (config) => {
    const token = await AsyncStorage.getItem(Constants.KEY_TOKEN);
    console.log("<<< token >>>" + token);
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  });

  api.interceptors.response.use(
    response => response,
    error => {
      console.error('Error response:', error);
      return Promise.reject(error);
    }
  );

  /**
   * Step 2
   * Define some functions that make api calls
   */

  const updateProfile = (name, email, dob, gender) => {
    const data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("dob", dob);
    data.append("gender", gender);
    return api.post("updateme", data);
  };

  const changePassword = (oldPassword, newPassword) => {
    const data = new FormData();
    data.append("old_password", oldPassword);
    data.append("password", newPassword);
    data.append("password_confirmation", newPassword);
    return api.post("changepassword", data);
  };

  const logout = () => api.post("logout");

  const categories = (categoryId = "", keyword = "") => {
    const data = new FormData();
    data.append("id", categoryId);
    data.append("kwd", keyword);
    return api.post("categories", data);
  };

  const categoryTags = (categoryId, tagIds = "", keyword = "") => {
    const data = new FormData();
    data.append("cat_id", categoryId);
    data.append("tag_ids", tagIds);
    data.append("kwd", keyword);
    return api.post("category_tags", data);
  };

  const addTag = (tagName) => {
    const data = new FormData();
    data.append("name", tagName);
    return api.post("tag/add", data);
  };

  const popularItems = (categoryId = "") => {
    const data = new FormData();
    data.append("cat_id", categoryId);
    return api.post("popular", data);
  };

  const updateSearchLocation = (locationData, search_months) => {
    const data = new FormData();
    Object.keys(locationData).forEach(key => {
      data.append(`search_${key}`, locationData[key]);
    });
    data.append("search_months", search_months);
    if (locationData.radius) {
      data.append("search_radius", locationData.radius);
    }
    if (locationData.homeCountry) {
      data.append("country_name", locationData.homeCountry);
    }
    return api.post("user/preference/update", data);
  };

  const searchKeyword = (keyword, cat_id = undefined) => {
    const data = new FormData();
    data.append("kwd", keyword);
    if (cat_id) {
      data.append("cat_id", cat_id);
    }
    return api.post("search", data);
  };

  const getRecentSearches = () => api.post("recent_searches/list");

  const addRecentSearch = (title, catId, tagIds = "", isCatSearch) => {
    const data = new FormData();
    data.append("title", title);
    data.append("tag_ids", tagIds);
    data.append("cat_id", catId);
    data.append("is_cat_search", isCatSearch);
    return api.post("recent_searches/add", data);
  };

  const entryPrice = (catId, tagIds = "") => {
    const data = new FormData();
    data.append("cat_id", catId);
    data.append("tag_ids", tagIds);
    return api.post("entry/price", data);
  };

  const entryList = (catId, tagIds = "") => {
    const data = new FormData();
    data.append("cat_id", catId);
    data.append("tag_ids", tagIds);
    return api.post("entry/list", data);
  };

  const entryDetail = (entryId) => {
    const data = new FormData();
    data.append("id", entryId);
    return api.post("entry/detail", data);
  };

  const entryAdd = (param) => {
    const data = new FormData();
    Object.keys(param).forEach(key => {
      data.append(key, param[key]);
    });
    if (param.arrMedia && param.arrMedia.length > 0) {
      param.arrMedia.forEach((item, index) => {
        data.append(`mediaArr[${index}][type]`, "Image");
        data.append(`mediaArr[${index}][file]`, {
          uri: item.path,
          type: item.mime,
          name: timestamp + "." + item.mime.split("/")[1],
        });
      });
    }
    return api.post("entry/add", data);
  };

  const myEntries = () => api.post("user/entries");

  const favouriteList = () => api.post("favourites/list");

  const favouriteRemove = (favouriteId) => {
    const data = new FormData();
    data.append("id", favouriteId);
    return api.post("favourites/remove", data);
  };

  const favouriteAdd = (param) => {
    const data = new FormData();
    Object.keys(param).forEach(key => {
      data.append(key, param[key]);
    });
    return api.post("favourites/add", data);
  };

  const filterList = (catId, keyword) => {
    const data = new FormData();
    data.append("cat_id", catId);
    data.append("kwd", keyword);
    return api.post("entry/filter", data);
  };

  const activityList = () => api.post("user/activities");

  const toggleNotification = (params) => {
    const data = new FormData();
    data.append("push_notification", params.isAllow);
    data.append("device_token", params.fcmToken);
    data.append("device_type", Platform.OS.toUpperCase());
    return api.post("notification_allow", data);
  };

  const getOfflineTableData = (tbl_name, last_id) => {
    const data = new FormData();
    data.append("tbl_name", tbl_name);
    if (last_id) {
      data.append("last_id", last_id);
    }
    return api.post("offline/sync", data);
  };

  const updateUserOfflineCategory = (category_ids) => {
    const data = new FormData();
    data.append("category_ids", category_ids);
    return api.post("user/offline_category/store", data);
  };

  const getActivityUnreadCount = () => api.post("user/activity/unread_count");

  const setAllActivityAsRead = () => api.post("user/activity/read_all");

  const setActivityAsRead = (activityId) => {
    const data = new FormData();
    data.append("activity_id", activityId);
    return api.post("user/activity/read_status", data);
  };

  /**
   * Step 3
   * Return back collection of functions
   */
  return {
    updateProfile,
    changePassword,
    logout,
    categories,
    categoryTags,
    addTag,
    popularItems,
    updateSearchLocation,
    searchKeyword,
    getRecentSearches,
    addRecentSearch,
    entryPrice,
    entryList,
    entryDetail,
    entryAdd,
    myEntries,
    favouriteList,
    favouriteAdd,
    favouriteRemove,
    filterList,
    activityList,
    toggleNotification,
    getOfflineTableData,
    updateUserOfflineCategory,
    getActivityUnreadCount,
    setAllActivityAsRead,
    setActivityAsRead,
  };
};

export default { create, createSecure };
