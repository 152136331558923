import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Keyboard,
} from "react-native";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import ActionCreators from "../Redux/actions";
import { Images } from "../Themes";
import styles from "./Styles/SignupScreenStyles";
import * as Helper from "../Lib/Helper";
import Constants from "../Lib/Constants";
import { api } from "../../src/utils/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigatorName } from "../../src/routes/screen";
import * as Location from "expo-location";
import { apiRequests } from "../apiRequests";

class SignupScreen extends BaseComponent {
  constructor(props) {
    super(props);
    (this.state = {
      name: "",
      dob: "",
      gender: "",
      country: "",
      countryId: "",
      auth0Detials: "",
    }),
      (this.updateDetails = this.updateDetails.bind(this));
    this.completeSignup = this.completeSignup.bind(this);
    this.getAuth0Details = this.getAuth0Details.bind(this);
  }

  updateDetails(data) {
    this.setState(data);
    setTimeout(() => {
      console.log("updated State: ", this.state);
    }, 500);
  }

  async completeSignup() {
    let details = this.state;
    console.log("signup details: ", details);
    const data = {
      name: details.name,
      email: details.auth0Detials.email,
      auth0_id: details.auth0Detials.sub,
      dob: details.dob,
      gender: details.gender,
      country_id: details.countryId,
    };

    // data.append('device_id', '123456');
    // data.append('device_type', Platform.OS.toLocaleUpperCase());
    // data.append('device_token', "Expo");
    const createUser = await api("v1/api/request", "POST", {
      endpoint: "signup",
      type: "POST",
      data: data,
    });

    if (createUser.status == 200) {
      if (createUser.data.success) {
        //TODO go to home screen
        console.log("Navigate to home");
        AsyncStorage.setItem("user", JSON.stringify(createUser.data.data));
        // Helper.resetNavigationToScreen(
        //     this.props.navigation,
        //     'HomeStack',
        // );
        console.log(this.props);
        console.log("get user current location on signup screen");

        let setUserLocation = {};

        let userLocation = await Location.getForegroundPermissionsAsync();
        if (userLocation.status == "undetermined") {
          userLocation = await Location.requestForegroundPermissionsAsync();
          if (userLocation.granted) {
            setUserLocation = await Location.getCurrentPositionAsync();
          }
        } else if (userLocation.status == "granted" && userLocation.granted) {
          setUserLocation = await Location.getCurrentPositionAsync({
            accuracy: 2,
          });
          console.log("set user loc: ", setUserLocation);
        }

        let address = await Location.reverseGeocodeAsync({
          latitude: setUserLocation.coords.latitude,
          longitude: setUserLocation.coords.longitude,
        });

        let locationData = {
          country: address[0].country,
          latitude: setUserLocation.coords.latitude,
          location: `${address[0].city}, ${address[0].region}`,
          longitude: setUserLocation.coords.longitude,
        };

        let updateLocation = await apiRequests.updateLocation(locationData);

        if (updateLocation.status == 200) {
          if (updateLocation.data.success) {
            console.log(updateLocation.data);
          }
        }

        this.props.route.params.mainNav.pop();
        this.props.route.params.mainNav.navigate(NavigatorName.HomeNavigator);
      }
    } else {
      Helper.showAlert(createUser.data.message);
    }

    console.log("Create Response: ", createUser);
    console.log("create USer ", createUser.data);
  }

  async getAuth0Details() {
    let resp = await api("v1/api/getAuth0Details", "GET", null);

    console.log("resp: ", resp.data);

    if (resp.status == 200) {
      if (resp.data.success) {
        this.setState({
          auth0Detials: resp.data.data,
        });
      }
    }

    // if(resp.status == 200){
    //   let result = resp.data;
    //   console.log("AUTH0 DETAILS>>>>>>>>")
    //   console.log(result);
    //   ReactAppboy.changeUser(result.data[0].sub);
    //   ReactAppboy.setFirstName(params.firstName);
    //   ReactAppboy.setLastName(params.lastName);
    //   ReactAppboy.setPhoneNumber(params.phone);
    //   ReactAppboy.setGender(params.gender);
    //   ReactAppboy.setEmail(result.data[0].email);
    //   setLoading(false);
    // } else {
    //   if(resp == "expired"){
    //     navigation.navigate("Splash");
    //   }
    //   setLoading(false);
    // }
  }

  componentDidMount() {
    super.componentDidMount();

    this.setState({
      name: this.state.name,
    });

    this.getAuth0Details();
    this.props.navigation.setOptions({
      headerRight: () => {
        return (
          <TouchableOpacity
            activeOpacity={1}
            style={styles.rightIcon}
            onPress={() => {
              this.onPressNext();
            }}
          >
            <Text style={styles.headerText}>Next</Text>
            <Image source={Images.arrowRight} style={[styles.arrow]} />
          </TouchableOpacity>
        );
      },
    });
  }

  onPressNext = () => {
    Keyboard.dismiss();
    const name = this.state.name;
    if (name.trim() == "") {
      Helper.showAlert(Constants.ERROR_MESSAGES.NAME);
    } else if (!Helper.validateName(name)) {
      Helper.showAlert(Constants.ERROR_MESSAGES.VALID_NAME);
    } else {
      console.log("current state: ", this.state);
      this.props.navigation.navigate("SignupDateScreen", {
        state: this.state,
        setSignupDetails: this.updateDetails,
        completeSignup: this.completeSignup,
      });
    }
  };

  // componentDidUpdate(prevProps, prevState) {
  //     if(prevProps.userDetail){
  //         if (prevProps.userDetail.name != this.props.userDetail.name) {
  //             this.setState({ name: this.props.userDetail.name });
  //         }
  //     }
  // }

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.mainContainer}>
          <View style={styles.content}>
            <View /* style={styles.frm}*/>
              <Text style={[styles.h1, styles.marginBottom, { marginTop: 40 }]}>
                Create a username
              </Text>
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="Username"
                placeholderTextColor="rgba(255, 255, 255, 0.7)"
                style={[
                  styles.TextInput,
                  {
                    color: "white",
                    fontSize: 24,
                    marginTop: 10,
                    marginBottom: 5,
                  },
                ]}
                value={this.state.name}
                onChangeText={(name) => {
                  this.updateDetails({ name: name });
                }}
                returnKeyType="next"
                onSubmitEditing={(event) => this.onPressNext()}
              />
              <Text style={[styles.msg]}>
                This name will be displayed on your Tengah profile.
              </Text>
            </View>
          </View>
        </SafeAreaView>
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> User Detail Name', state);
//     return {
//         userDetail: state.userReducer.userDetail,
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         setName: (name) => dispatch(ActionCreators.userActions.setName(name)),
//     }
// }

export default SignupScreen;
