import { StyleSheet } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'

export default StyleSheet.create({
  header: {
  },
  logo: {
    width: Metrics.screenWidth / 3,
    height: Metrics.screenWidth / 9,
    resizeMode: 'contain'
  },
  menu: {
    width: Metrics.screenWidth / 12,
    height: Metrics.screenWidth / 12,
    resizeMode: 'contain'
  },
  helpIcon: {
    width: Metrics.screenWidth / 20,
    height: Metrics.screenWidth / 20,
    resizeMode: 'contain',
  },
  helpTouch: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  rightNav: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  rightIcon: {
    paddingHorizontal: 20,
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  leftIcon: {
    height: '100%',
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center'
  },
  arrow: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    tintColor:'#fff'
  },
  headerText: {
    fontSize: 16,
    color: Colors.white,
    fontFamily: "robotoBase",
    paddingHorizontal: 10
  },
})
