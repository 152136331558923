import React from "react";
import {
  FlatList,
  Image,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  StyleSheet,
  Pressable,
  ActivityIndicator,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import ImagePickerComponent from "../Components/ImagePickerComponent";
// import ImagePicker from "react-native-image-crop-picker";
import LoadingComponent from "../Components/LoadingComponent";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import Api from "../Services/Api";
import { Images, Metrics, Colors } from "../Themes";
import styles from "./Styles/EnterPriceScreenStyles";
import { StackActions, NavigationActions } from "react-navigation";
import { api } from "../../src/utils/api";
import RNPickerSelect from "react-native-picker-select";
import * as ImagePicker from "expo-image-picker";
import moment from "moment";

const categories = require("../constants/categories.json");
var timerId = "";

class MainAddPrice extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.route.params;

    this.state = {
      priceDetail: "",
      showTagAddedPopup: false,
      userDetail: {},
      loading: true,
      imagePickerVisible: false,
      searchPrice: "",
      homePrice: "",
      description: "",
      arrMedia: [],
      popUpVisible: false,
      popupDialogMessage: "",
      isOnline: Constants.isOnline,
      search_country: "",
      country: "",
      category: "",
      selectedLocation: "", // search_latitude, search_longitude, search_country_id, search_location,
      isLocationModified: false, //True if location modified
      categoryInputDisable: false,
      tagInputDisable: false,
      categories: [],
      tags: {
        tag: "",
        tagsArray: [],
      },
      addLoading: false,
    };
    this.getUserDetails = this.getUserDetails.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.removeFromImgArr = this.removeFromImgArr.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getUserDetails();
    if (this.state.showTagAddedPopup) {
      this.setState(
        { popUpVisible: true, popupDialogMessage: Constants.TAG_ADDED },
        () =>
          setTimeout(() => {
            this.setState({ popUpVisible: false, popupDialogMessage: "" });
          }, Constants.TIME_FOR_POP_UPS)
      );
    }
    let selectCategories = [];
    for (let i = 0; i < categories.values.length; i++) {
      const element = categories.values[i];
      selectCategories.push({
        label: element.name,
        value: element.name,
      });
    }
    this.setState({
      categories: selectCategories,
    });
  }

  componentDidUpdate() {
    let selectCategories = [];
    for (let i = 0; i < categories.values.length; i++) {
      const element = categories.values[i];
      selectCategories.push({
        label: element.name,
        value: element.name,
      });
    }
    if (
      (this.props.route.params.keyword &&
        this.props.route.params.keyword !== "") ||
      (this.props.route.params.tags && this.props.route.params.tags !== "")
    ) {
      this.setState({
        categoryInputDisable: true,
        categories: selectCategories,
        category: this.props.route.params.keyword,
        tags: {
          ...this.state.tags,
          tagsArray:
            this.props.route.params.tags !== ""
              ? this.props.route.params.tags.split(",")
              : [],
        },
      });
      this.props.navigation.setParams({
        keyword: "",
        tags: "",
      });
    }
  }

  updateCategoryState = (state) => {
    console.log("input state: ", state);
    this.setState({
      category: state,
    });
  };

  updateTagState = (state) => {
    console.log("input state: ", state);
    let updateStateVal = state.tag;
    updateStateVal = updateStateVal.replace(/[^a-zA-Z0-9\- ]/g, "");
    state.tag = updateStateVal;

    if (state.tagsArray.length >= 5) {
      this.setState({
        tagInputDisable: true,
      });
    } else {
      this.setState({
        tagInputDisable: false,
      });
    }

    this.setState({
      tags: state,
    });
  };

  getUserDetails = async () => {
    let user = await this.props.route.params.getUserDetails();
    this.setState({
      userDetail: user,
      search_country: user.search_country,
      country: user.country,
      loading: false,
    });
  };

  updateLocation = async (locationData, status) => {
    console.log("Update user location");
    console.log(locationData);
    console.log(status);

    let location = {
      search_country: locationData.country,
      search_latitude: locationData.latitude,
      search_longitude: locationData.longitude,
      search_location: locationData.location,
      search_country: locationData.country,
    };

    let country = await api("v1/api/request", "POST", {
      endpoint: "country",
      type: "POST",
      data: {
        country: locationData.country,
      },
    });

    if (country.status == 200) {
      location.currency_code = country.data.data.currency_code;
    }
    this.setState({
      isLocationModified: status,
      selectedLocation: location,
    });

    setTimeout(() => {
      this.convertPrice();
    }, 2000);
  };

  convertPrice = async () => {
    if (
      this.state.selectedLocation ||
      this.state.country.currency_code !==
        this.state.search_country.currency_code
    ) {
      let data = {};

      let baseCur =
        this.state.selectedLocation !== ""
          ? this.state.selectedLocation.currency_code
          : this.state.search_country.currency_code;
      data.price = this.state.searchPrice;
      data.base_cur = baseCur;
      data.target_cur = this.state.country.currency_code;

      let convertedPrice = await api("v1/api/request", "POST", {
        endpoint: "currency_convert",
        type: "POST",
        data: data,
      });

      if (convertedPrice.status == 200) {
        console.log("his.state.searchPrice: ", this.state.searchPrice);
        console.log("convertedPrice: ", convertedPrice.data);
        this.setState({
          homePrice: convertedPrice.data.data.converted_price,
        });
      }
    } else {
      this.setState({ homePrice: this.state.searchPrice });
    }
  };

  onPressDone = async () => {
    const locationData = this.state.selectedLocation
      ? this.state.selectedLocation
      : this.state.userDetail;

    this.setState({
      addLoading: true,
    });

    var images = [];
    for (let am = 0; am < this.state.arrMedia.length; am++) {
      const element = this.state.arrMedia[am];
      images.push({
        type: element.type,
        uri: element.uri,
        name:
          element.name || `image-${moment().format("YYYY-MM-DD-HH-mm-ss")}.jpg`,
      });
    }

    const data = {
      price: this.state.searchPrice,
      categories: this.state.category,
      latitude: locationData.search_latitude,
      longitude: locationData.search_longitude,
      location: locationData.search_location,
      country:
        typeof locationData.search_country == "string"
          ? locationData.search_country
          : locationData.search_country.name,
      tags: this.state.tags.tagsArray.toString(),
      description: this.state.description,
      user_id: this.state.userDetail.id,
      arrMedia: images,
    };

    Constants.hideTellUsWhatYouPaid = true;
    let addEntry = await api(
      "v1/api/request",
      "POST",
      {
        endpoint: "entry/new",
        type: "POST",
        data: data,
      },
      true
    );
    console.log("entry status: ", addEntry);
    if (addEntry.status == 200) {
      console.log("add entry to db response: ", addEntry.data);
      if (addEntry.data.success) {
        // this.props.route.params.searchKeyword && this.props.route.params.searchKeyword();
        this.setState(
          { popUpVisible: true, popupDialogMessage: Constants.PRICE_ADDED },
          () =>
            setTimeout(() => {
              this.setState({ popUpVisible: false, popupDialogMessage: "" });
              if (this.props.route.params.isFromSearch) {
                this.props.route.params.searchAgain(data.tags, data.categories);
                this.props.navigation.goBack();
              } else {
                this.props.navigation.navigate("SearchScreen", {
                  searchItem: data.categories,
                  searchTags: data.tags,
                });
              }

              this.setState({
                categoryInputDisable: false,
                tagInputDisable: false,
                category: "",
                tags: {
                  tag: "",
                  tagsArray: [],
                },
                searchPrice: "",
                homePrice: "",
                description: "",
                arrMedia: [],
              });
            }, Constants.TIME_FOR_POP_UPS)
        );
      }
    }
    this.setState({
      addLoading: false,
    });
    //this.props.addEntry(param,this.props.navigation)
  };

  openGallery = async () => {
    // this.setImagePicker(false);
    // setTimeout(() => {
    //   ImagePicker.openPicker({
    //     cropping: false,
    //   }).then((image) => {
    //     // console.log(image);
    //     this.onImageSelect(image);
    //     // this.props.onSelectedImage(image)
    //   });
    // }, 500);
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: false,
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      this.onImageSelect(result.assets[0]);
      this.setImagePicker(false);
    }
  };

  openCamera = async () => {
    // setTimeout(() => {
    //   ImagePicker.openCamera({
    //     cropping: false,
    //   }).then((image) => {
    //     // console.log(image);
    //     this.onImageSelect(image);
    //     // this.props.onSelectedImage(image)
    //   });
    // }, 500);
    var permission = await ImagePicker.getCameraPermissionsAsync();
    console.log("permissions for camera: ", permission);
    if (permission.granted == false && permission.canAskAgain == true) {
      permission = await ImagePicker.requestCameraPermissionsAsync();
    }

    if (permission.granted) {
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: false,
        quality: 1,
      });

      console.log(result);

      if (!result.canceled) {
        this.onImageSelect(result.assets[0]);
        this.setImagePicker(false);
      }
    }

    console.log("permissions for camera again: ", permission);
  };

  onPressSelectCategory = () => {
    this.props.navigation.push("AddPriceScreen", { isEditing: true });
  };

  onPressSelectTags = () => {
    const { cat_id, category, parentName, entryCount } = this.state.priceDetail;
    this.props.navigation.push("ProductListScreen", {
      catId: cat_id,
      title: category,
      selectedName: parentName,
      isFromAddPrice: true,
      entryCount: entryCount,
      isEditing: true,
    });
  };

  onPressSelectLocation = () => {
    this.props.navigation.push("SearchCityScreen", {
      isMainAdd: true,
      updateLocation: this.updateLocation,
    });
  };

  removeFromImgArr = (index) => {
    var theArray = this.state.arrMedia;

    if (index > -1) { // only splice array when item is found
      theArray.splice(index, 1); // 2nd parameter means remove one item only
    }

    this.setState({
      arrMedia: theArray
    })

  }

  render() {
    const locationData = this.state.isLocationModified
      ? this.state.selectedLocation
      : this.state.userDetail;
    if (this.state.loading) {
      return <LoadingComponent />;
    } else {
      return (
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          {this.state.addLoading && (
            <View style={styles.absoluteView}>
              <View>
                <ActivityIndicator color={"white"} size={"large"} />
              </View>
            </View>
          )}
          <SafeAreaView style={styles.container}>
            <View style={[styles.header, { paddingLeft: 0, paddingRight: 0 }]}>
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.rightIcon}
                  onPress={() => this.handleBackButtonClick()}
                >
                  <Image source={Images.plus} style={styles.Modalclose} />
                </TouchableOpacity>
              </View>
              <View style={styles.headerBody}>
                <Text style={styles.headerTitle}>
                  {this.state.isFromTellUs
                    ? "Tell us what you paid"
                    : "How much did you pay?"}
                </Text>
              </View>
              {/* <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.rightIconWithText}
                  onPress={() => this.onPressDone()}
                >
                  <Text style={styles.headerText}>Done</Text>
                  <Image source={Images.arrowRight} style={styles.arrow} />
                </TouchableOpacity>
              </View> */}
            </View>
            <KeyboardAvoidingView
              style={styles.container}
              keyboardVerticalOffset={Platform.OS == "android" ? 15 : 0}
              behavior={Platform.OS == "android" ? "height" : "padding"}
            >
              <ScrollView contentContainerStyle={styles.ScrollView}>
                <View style={[styles.content]}>
                  <View style={styles.list}>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Categories*</Text>
                        <RNPickerSelect
                          placeholder={{
                            label: "Select Category",
                            value: "",
                            fontSize: (Metrics.screenWidth * 24) / 414,
                            fontFamily: "semiBold",
                          }}
                          placeholderTextColor="rgb(255,255,255)"
                          items={this.state.categories}
                          onValueChange={(value, index) =>
                            this.updateCategoryState(value)
                          }
                          style={{ ...pickerSelectStyles }}
                          value={this.state.category}
                          useNativeAndroidPickerStyle={false} //android only
                          hideIcon={false}
                          disabled={this.state.categoryInputDisable}
                        />
                      </View>
                    </View>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Tags*</Text>
                        {/* <TagInput
                          updateState={this.updateTagState}
                          tags={this.state.tags}
                          label="Press , to add a tag"
                          labelStyle={{ color: "#fff" }}
                          tagStyle={styles.tag}
                          tagsViewStyle={styles.tagView}
                          tagTextStyle={styles.tagText}
                          inputStyle={styles.inputText}
                          inputContainerStyle={styles.inputStyle}
                          disabled={this.state.tagInputDisable}
                          disabledInputStyle={styles.disabledInputStyle}
                          autoCorrect={false}
                          keysForTag={","}
                        /> */}
                      </View>
                    </View>
                    <TouchableOpacity
                      style={styles.listItem}
                      onPress={() => this.onPressSelectLocation()}
                    >
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Location*</Text>
                        <Text style={styles.listItemText}>
                          {locationData.search_location}
                        </Text>
                      </View>
                      <View style={styles.listItemRight}>
                        <Image
                          source={Images.arrowRight}
                          style={styles.listItemArrow}
                        />
                      </View>
                    </TouchableOpacity>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Price*</Text>
                        <View style={styles._row}>
                          <TextInput
                            underlineColorAndroid="transparent"
                            placeholder="0.00"
                            placeholderTextColor="#fff"
                            style={styles.listInputIDR}
                            keyboardType="numeric"
                            value={this.state.searchPrice}
                            onChangeText={(searchPrice) =>
                              this.setState({ searchPrice }, () => {
                                clearTimeout(timerId);
                                timerId = setTimeout(
                                  this.convertPrice.bind(this),
                                  200
                                );
                              })
                            }
                            onSubmitEditing={(event) => this.convertPrice()}
                          />
                          <Text style={styles.smallText}>
                            {this.state.selectedLocation
                              ? this.state.selectedLocation.currency_code
                              : this.state.search_country.currency_code}
                          </Text>
                        </View>
                        <View style={styles._row}>
                          <TextInput
                            underlineColorAndroid="transparent"
                            placeholder={`${this.state.country.currency_symbol}0.00`} //Nochange
                            placeholderTextColor="#fff"
                            style={styles.listInputAUD}
                            editable={false}
                            value={this.state.homePrice}
                          />
                          <Text style={styles.smallText}>
                            {this.state.country.currency_code}
                          </Text>
                          {/* Nochange */}
                        </View>
                      </View>
                    </View>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Text style={styles.normalText}>Description</Text>
                        <TextInput
                          underlineColorAndroid="transparent"
                          placeholder="Tell us more about your purchase"
                          placeholderTextColor="#fff"
                          style={styles.listInput}
                          value={this.state.description}
                          onChangeText={(description) =>
                            this.setState({ description })
                          }
                        />
                      </View>
                    </View>

                    <TouchableOpacity
                      style={styles.listItem}
                      onPress={() => {
                        this.setImagePicker(true);
                      }}
                    >
                      <View style={[styles.listBody, styles.imageAddSection]}>
                        <Text style={styles.normalText}>Image</Text>
                        {this.state.arrMedia.length > 0 ? (
                          <FlatList
                            data={this.state.arrMedia}
                            style={{ marginTop: 10 }}
                            horizontal
                            extraData={this.state}
                            keyExtractor={(item, index) => index + ""}
                            renderItem={({ item, index }) => {
                              return (
                                <View style={styles.imageContainer}>
                                  <View style={styles.imagePlaceholder}>
                                    <Image
                                      source={{ uri: item.uri }}
                                      style={styles.uploadedImage}
                                    />
                                    
                                  </View>
                                    <Pressable onPress={() => {this.removeFromImgArr(index)}}>
                                      <Image
                                        source={Images.close}
                                        style={styles.removeImage}
                                      />
                                    </Pressable>
                                </View>
                              );
                            }}
                          />
                        ) : (
                          <View style={styles.imageUpload}>
                            <View style={styles.imagePlaceholder}>
                              <Image
                                source={Images.camera}
                                style={styles.placeholderImg}
                              />
                            </View>
                            <Text style={styles.normalText}>
                              Tap to add image
                            </Text>
                          </View>
                        )}
                      </View>
                      <View style={styles.listItemRight}>
                        <Image
                          source={Images.arrowRight}
                          style={styles.listItemArrow}
                        />
                      </View>
                    </TouchableOpacity>
                    <View style={styles.listItem}>
                      <View style={styles.listBody}>
                        <Pressable
                          style={[styles.button, styles.fixedAddPrice]}
                          onPress={() => {
                            this.onPressDone();
                          }}
                        >
                          <Text style={styles.buttonText}>Done</Text>
                          <Image
                            source={Images.arrowRight}
                            style={styles.arrow}
                          />
                        </Pressable>
                      </View>
                    </View>
                  </View>
                </View>
              </ScrollView>
            </KeyboardAvoidingView>
          </SafeAreaView>

          <ImagePickerComponent
            visible={this.state.imagePickerVisible}
            onClose={() => this.setImagePicker(false)}
            onSelectedImage={(image) => this.onImageSelect(image)}
            openGallery={() => this.openGallery()}
            openCamera={() => this.openCamera()}
          />

          <PopupDialogComponent
            visible={this.state.popUpVisible}
            message={this.state.popupDialogMessage}
          />

          {this.state.loading ? <LoadingComponent /> : null}
        </ImageBackground>
      );
    }
  }

  onBackSelectedImages = (arrImages) => {
    console.log("onBackSelectedImages");

    this.setState({ arrMedia: arrImages });
  };

  setImagePicker = (visible) => {
    this.setState({ imagePickerVisible: visible });
  };

  onImageSelect = (image) => {
    console.log("image", image);
    this.state.arrMedia.push(image);
    this.setState({});
  };
}

export default MainAddPrice;

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    marginTop: 20,
    backgroundColor: Colors.transparent,
    fontFamily: "robotoBold",
    fontSize: (Metrics.screenWidth * 16) / 414,
    color: "#fff",
  },
  inputAndroid: {
    marginTop: 20,
    backgroundColor: Colors.transparent,
    fontFamily: "robotoBold",
    fontSize: (Metrics.screenWidth * 18) / 414,
    color: "#fff",
  },
});
