import * as Helper from "../../Lib/Helper";
import Api from "../../Services/Api";
import ActionTypes from "./types";
import { entryActions } from "./Entry";
import Constants from "../../Lib/Constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import { api } from "../../../src/utils/api";

const mApi = Api.createSecure();

const favouriteActions = {
  favouriteList,
  favouriteAdd,
  favouriteRemove,
};

async function favouriteList(user) {
  console.log("data for fav: ", {
    user_id: user.id,
    latitude: user.search_latitude,
    longitude: user.search_longitude,
    distanceInMetres: user.search_radius * 1000,
  });
  let favs = await api("v1/api/request", "POST", {
    endpoint: "favourites/list",
    type: "POST",
    data: {
      user_id: user.id,
      latitude: user.search_latitude,
      longitude: user.search_longitude,
      distanceInMetres: user.search_radius * 1000,
      search_months: user.search_months,
    },
  });
  return favs;
  // return dispatch => {
  //     if (Constants.isOnline) {
  //         dispatch({
  //             type: ActionTypes.FAVOURITE_LIST_LOADING,
  //             loading: true,
  //         })

  //         mApi.favouriteList().then(response => {
  //             if (response.ok) {
  //                 if (response.data.success) {
  //                     dispatch({
  //                         type: ActionTypes.FAVOURITE_LIST,
  //                         arrFavourite: response.data.data,
  //                     })
  //                 } else {
  //                     Helper.showAlert(response.data.message,navigation,response.data.status)
  //                 }
  //             } else {
  //                 Helper.showAlert(response.problem,navigation)
  //             }
  //             dispatch({
  //                 type: ActionTypes.FAVOURITE_LIST_LOADING,
  //                 loading: false,
  //             })
  //         }).catch(error => {
  //             console.log('error:', error);
  //             dispatch({
  //                 type: ActionTypes.FAVOURITE_LIST_LOADING,
  //                 loading: false,
  //             })
  //         })
  //     } else {
  //         AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
  //             const userDetails = JSON.parse(result)
  //             SQLHelper.selectTable(Constants.dbRefrence,Constants.SQL_TABLE_NAMES.FAVOURITES_8,`user_id = ${userDetails.id}`,(arrFavourites) => {
  //                 console.log(Constants.SQL_TABLE_NAMES.FAVOURITES_8 + 'entries ======>',result);
  //                 // dispatch({
  //                 //     type: ActionTypes.FAVOURITE_LIST,
  //                 //     arrFavourite: arrFavourites,
  //                 // })
  //                 var dataMainResponse = []
  //                 arrFavourites.forEach(favourite => {
  //                     const catId = favourite.cat_id
  //                     const tagIds = favourite.tag_ids
  //                     OfflineHelper.getUserEntriesByDistance(async (arrEntryId) => {
  //                         const user = await AsyncStorage.getItem(Constants.KEY_USER_DETAIL)
  //                         const userDetails = JSON.parse(user)
  //                         const user_currency_code = userDetails.country.currency_code
  //                         const user_currency_symbol = userDetails.country.currency_symbol
  //                         const user_search_currency_code = userDetails.search_country.currency_code
  //                         const user_search_currency_symbol = userDetails.search_country.currency_symbol
  //                         const monthsAgoDate = moment(new Date()).subtract(userDetails.search_months, 'months').format(Constants.FULL_DATE_TIME_STAMP_FORMAT)
  //                         // alert(monthsAgoDate)
  //                         var queryEntries = `SELECT entries.*,categories.unit_measure,countries.currency,countries.currency_code,countries.currency_symbol from entries LEFT JOIN countries ON countries.id = entries.country_id LEFT JOIN categories ON categories.id = entries.cat_id where entries.cat_id = ${catId} and date(entries.created_at) > ${monthsAgoDate} and entries.id in (${arrEntryId})`
  //                         if (tagIds != '') {
  //                             var tag_ids = tagIds.split(',');
  //                             tag_ids.sort((a, b) => a - b);
  //                             const sortedTagIds = tag_ids.join(',')
  //                             // append  `where('tag_ids',implode(",",$tag_ids)` exact match to requested tags
  //                             queryEntries += ` and entries.tag_ids LIKE "${sortedTagIds}"`
  //                         }
  //                         queryEntries += ` order by price asc`
  //                         SQLHelper.performTransactionWithQuery(Constants.dbRefrence, queryEntries, (finalentryArr) => {
  //                             console.log('queryEntries :', finalentryArr);
  //                             const totalEntry = finalentryArr.length
  //                             if (totalEntry > 0) {
  //                                 favourite['cat_id'] = catId;
  //                                 favourite['main_tag'] = ''
  //                                 favourite['tag_ids'] = ''
  //                                 OfflineHelper.getCategoryHierarchy(catId, (category, categoryArr) => {
  //                                     favourite['category'] = category;
  //                                     favourite['categoryArr'] = categoryArr;

  //                                     const firstEntry = finalentryArr[0];
  //                                     const lastEntry = finalentryArr[finalentryArr.length - 1];
  //                                     favourite['unit_measure'] = firstEntry.unit_measure;
  //                                     var avrageSearchPrice = ''
  //                                     var avrageHomePrice = ''

  //                                     const validateAndPass = () => {
  //                                         console.log('validateAndPass validateAndPass ----- -__> ');

  //                                         if (avrageSearchPrice != '' && avrageHomePrice != '') {
  //                                             console.log(' *__* *__* *__* validateAndPass ----- *__* ');
  //                                             // console.log('validateAndPass dictData ', dictData);

  //                                             favourite['search_price'] = user_search_currency_symbol + ' ' + avrageSearchPrice + ' ' + user_search_currency_code
  //                                             favourite['home_price'] = user_currency_symbol  + ' ' + avrageHomePrice + ' ' + user_currency_code

  //                                             // if (favourite['main_tag'] == '') {
  //                                             //     favourite['main_tag'] = categoryArr[0].name;
  //                                             // }

  //                                             favourite['is_cat_search'] = tagIds != '' ? 0 : 1;
  //                                             if (arrFavourites[arrFavourites.length - 1] == favourite) {
  //                                                 console.log('if (arrFavourites[arrFavourites.length - 1] ===== favourite)',arrFavourites);
  //                                                 dispatch({
  //                                                     type: ActionTypes.FAVOURITE_LIST,
  //                                                     arrFavourite: arrFavourites,
  //                                                 })
  //                                             }
  //                                             else{
  //                                                 console.log('if (arrFavourites[arrFavourites.length - 1] != favourite)');
  //                                             }

  //                                         }
  //                                     }

  //                                     var tempPrice = 0;
  //                                     var eIdArr = [];
  //                                     var entryTagIds = '';

  //                                     finalentryArr.forEach(entry => {
  //                                         eIdArr.push(entry.id);
  //                                         console.log('forEach finalentryArr -------->', entry);
  //                                         tempPrice += entry.price;
  //                                         entryTagIds += `,${entry.tag_ids}`
  //                                     });

  //                                     console.log('forEach entryTagIds -------->', entryTagIds);
  //                                     if (entryTagIds != '') {
  //                                         entryTagIds = entryTagIds.substring(1);
  //                                     }
  //                                     console.log('forEach removed first char entryTagIds -------->', entryTagIds);

  //                                     const avgPrice = tempPrice / totalEntry;
  //                                     OfflineHelper.convertPrice(avgPrice, firstEntry.currency_code, userDetails.search_country.currency_code, (newPrice) => {
  //                                         avrageSearchPrice = newPrice
  //                                         validateAndPass()
  //                                     })

  //                                     OfflineHelper.convertPrice(avgPrice, firstEntry.currency_code, userDetails.country.currency_code, (newPrice) => {
  //                                         avrageHomePrice = newPrice
  //                                         validateAndPass()
  //                                     })

  //                                     // OfflineHelper.convertPrice(firstEntry.price, firstEntry.currency_code, userDetails.search_country.currency_code, (newPrice) => {
  //                                     //     lowSearchPrice = newPrice
  //                                     //     validateAndPass()
  //                                     // })

  //                                     // OfflineHelper.convertPrice(firstEntry.price, firstEntry.currency_code, userDetails.country.currency_code, (newPrice) => {
  //                                     //     lowHomePrice = newPrice
  //                                     //     validateAndPass()
  //                                     // })

  //                                     // OfflineHelper.convertPrice(lastEntry.price, lastEntry.currency_code, userDetails.search_country.currency_code, (newPrice) => {
  //                                     //     highSearchPrice = newPrice
  //                                     //     validateAndPass()
  //                                     // })

  //                                     // OfflineHelper.convertPrice(lastEntry.price, lastEntry.currency_code, userDetails.country.currency_code, (newPrice) => {
  //                                     //     highHomePrice = newPrice
  //                                     //     validateAndPass()
  //                                     // })

  //                                 });
  //                             }
  //                         });
  //                     })
  //                 });
  //             })

  //         })

  //     }
  // }
}

async function favouriteAdd(param) {
  let addFav = await api("v1/api/request", "POST", {
    endpoint: "favourites/add",
    type: "POST",
    data: param,
  });
  return addFav;
  // return dispatch => {
  //     if (Constants.isOnline) {
  //         dispatch({
  //             type: ActionTypes.FAVOURITE_ADD_LOADING,
  //             loading: true,
  //         })

  //         mApi.favouriteAdd(param).then(response => {
  //             if (response.ok) {
  //                 if (response.data.success) {
  //                     dispatch({
  //                         type: ActionTypes.FAVOURITE_ADD,
  //                         is_favourite: response.data.success,
  //                     })
  //                     dispatch(favouriteList())
  //                     dispatch(entryActions.entryPrice(param.catId, param.tagIds, navigation))

  //                 } else {
  //                     Helper.showAlert(response.data.message,navigation,response.data.status)
  //                 }
  //             } else {
  //                 Helper.showAlert(response.problem,navigation)
  //             }
  //             dispatch({
  //                 type: ActionTypes.FAVOURITE_ADD_LOADING,
  //                 loading: false,
  //             })
  //         }).catch(error => {
  //             console.log('error:', error);
  //             dispatch({
  //                 type: ActionTypes.FAVOURITE_ADD_LOADING,
  //                 loading: false,
  //             })
  //         })
  //     }
  //     // else {
  //         const title =  param.title;
  //         const tag_ids = param.tagIds;
  //         const other_tags = param.otherTags;
  //         const cat_id = param.catId;
  //         const category = param.category;
  //         const home_price = param.homePrice;
  //         const search_price = param.searchPrice;
  //         const is_cat_search = param.isCatSearch;
  //         const dateTime = Helper.getUTCTime()
  //         AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
  //             const userDetails = JSON.parse(result)
  //             const deleteFavouritesQuery = `delete from favourites where (cat_id = ${cat_id} and category = "${category}" and title = "${title}" and tag_ids = "${tag_ids}" and other_tags = "${other_tags}" and home_price = "${home_price}" and search_price = "${search_price}" and is_cat_search = ${is_cat_search} and user_id = ${userDetails.id})`
  //             SQLHelper.performTransactionWithQuery(Constants.dbRefrence, deleteFavouritesQuery,(data) => {
  //                 const insertFavouritesQuery = `insert into favourites (cat_id, category, title, tag_ids, other_tags, home_price, search_price, is_cat_search, user_id, updated_at, created_at) values (${cat_id}, "${category}", "${title}", "${tag_ids}", "${other_tags}", "${home_price}", "${search_price}", ${is_cat_search}, ${userDetails.id}, "${dateTime}", "${dateTime}")`
  //                 SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertFavouritesQuery, (insertedFavouriteId) => {
  //                     if (!Constants.isOnline) {
  //                         const insertOfflineFavouritesQuery = `insert into offline_insertions (table_name, row_insert_id) values ("favourites",${insertedFavouriteId})`
  //                         SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertOfflineFavouritesQuery, (offlineInsertId) => {});
  //                         dispatch({
  //                             type: ActionTypes.FAVOURITE_ADD,
  //                             is_favourite: response.data.success,
  //                         })
  //                     }
  //                     dispatch(favouriteList())
  //                     dispatch(entryActions.entryPrice(param.catId, param.tagIds, navigation))
  //                 })
  //             })
  //         })
  //     // }
  // }
}

async function favouriteRemove(favid) {
  let removeFav = await api("v1/api/request", "POST", {
    endpoint: "favourites/remove",
    type: "POST",
    data: {
      id: favid,
    },
  });
  return removeFav;
}

export { favouriteActions };
