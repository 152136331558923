import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  Image,
  View,
  TouchableOpacity,
  Switch,
  KeyboardAvoidingView,
  ImageBackground,
  ScrollView,
  Platform
} from "react-native";
import styles from "./Styles/TooltipScreenStyles";
import { copilot, walkthroughable, CopilotStep } from "react-native-copilot";
import FooterNavComponents from "../Components/FooterNavComponents";
import { SafeAreaView } from "react-native";
import { Images, Colors, Metrics, Fonts } from "../Themes";
import TooltipComponents from "../Components/TooltipComponents";
import stepNumberComponent from "../Components/stepNumberComponent";
import Constants from "../Lib/Constants";

const WalkthroughableText = walkthroughable(Text);
const WalkthroughableImage = walkthroughable(Image);

const circleSvgPath = ({ position, canvasSize }) =>
  `M0,0H${canvasSize.x}V${canvasSize.y}H0V0ZM${position.x._value},${position.y._value}`;

class App extends Component {
  state = {
    secondStepActive: true,
  };

  componentDidMount() {
    this.props.copilotEvents.on("stepChange", this.handleStepChange);
    this.props.start();
  }

  handleStepChange = (step) => {
    console.log(`Current step is: ${step.name}`);
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.nevHeading}>Activity</Text>
            <Image source={Images.logo} style={styles.headerLogo} />
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.content}></View>
          </ScrollView>
        </SafeAreaView>
        <FooterNavComponents
          property={this.props}
          isHomeTooltipShown={true}
          unreadCount={Constants.activityUnreadCount}
        />
      </ImageBackground>
    );
  }
}

export default copilot({
  tooltipStyle: styles.tooltipStyle,
  animated: false,
  overlay: "svg",
  // svgMaskPath: circleSvgPath,
  byPassAlignment: true,
  marginTopExtra: Platform.OS === "ios" ? 0 : 8,
  tooltipComponent: TooltipComponents,
  stepNumberComponent: stepNumberComponent,
  backdropColor: "rgba(0,0,0,0.30)",
  verticalOffset: Platform.OS === "ios" ? 0 : 25,
})(App);
