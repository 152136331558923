import ActionTypes from './types'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from '../../Lib/Constants';
import Api from '../../Services/Api'
import * as Helper from '../../Lib/Helper';

const userActions = {
    //set details while regostering
    setName, setEmail, setDOB, setGender, setCountry, setPassword, setSocialType, setSocialId, setDeviceToken,
    //userDetail in JSON format
    setUserDetail, getUserDetail,
    //API
    updateOfflineSyncCategory
}

function setName(name) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_NAME,
            name: name
        })
    }
}
function setEmail(email) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_EMAIL,
            email: email
        })
    }
}
function setDOB(dob) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_DOB,
            dob: dob
        })
    }
}
function setGender(gender) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_GENDER,
            gender: gender
        })
    }
}
function setCountry(countryName, countryId) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_COUNTRY,
            countryName: countryName,
            countryId: countryId
        })
    }
}
function setPassword(password) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_PASSWORD,
            password: password
        })
    }
}
function setSocialType(socialType) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_SOCIAL_TYPE,
            socialType: socialType
        })
    }
}
function setSocialId(socialId) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_SOCIAL_ID,
            socialId: socialId
        })
    }
}
function setDeviceToken(deviceToken) {
    return dispatch => {
        dispatch({
            type: ActionTypes.USER_DEVICE_TOKEN,
            deviceToken: deviceToken
        })
    }
}

function setUserDetail(userDetail) {
    console.log('set user call');
    
    return dispatch => {
        try {
            AsyncStorage.setItem(Constants.KEY_USER_DETAIL, JSON.stringify(userDetail)),
            AsyncStorage.setItem(Constants.KEY_SEARCH_MONTHS, JSON.stringify(userDetail.search_months)),
            AsyncStorage.setItem(Constants.KEY_USER_ID, JSON.stringify(userDetail.id))
            dispatch(getUserDetail())
        } catch (error) {
            console.log('AsyncStorage error: ', error);
        }

    }
}

function getUserDetail() {

    return dispatch => {

        AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
            if (result) {
                dispatch({
                    type: ActionTypes.USER_DETAIL,
                    userDetail: result
                })
            } else {

                dispatch({
                    type: ActionTypes.USER_DETAIL,
                    userDetail: ''
                })
            }
        })

    }

}

function updateOfflineSyncCategory(categoryIds) {
    return dispatch => {
        if (Constants.isOnline) {
            dispatch({
                type: ActionTypes.USER_UPDATE_OFFLINECATEGORY_LOADING,
                loading: true
            })
            const mApi = Api.createSecure()
            mApi.updateUserOfflineCategory(categoryIds).then(response => {
                if (response.ok) {
                    if (response.data.success) {
                        setTimeout(() => {
                            dispatch({
                                type: ActionTypes.USER_OFFLINECATEGORY_UPDATED,
                                categoryUpdated: true
                            })
                        }, 300);
                    } else {
                        Helper.showAlert(response.data.message)
                    }
                } else {
                    Helper.showAlert(response.problem)
                }
                dispatch({
                    type: ActionTypes.USER_UPDATE_OFFLINECATEGORY_LOADING,
                    loading: false
                })
            }).catch(error => {
                console.log('error', error);
                dispatch({
                    type: ActionTypes.USER_UPDATE_OFFLINECATEGORY_LOADING,
                    loading: false
                })
            })
        } 

        AsyncStorage.getItem(Constants.KEY_USER_DETAIL, (error, result) => {
            const user = JSON.parse(result)
            var deleteQuery = `delete from user_offline_categories where user_id = ${user.id}`
            if (categoryIds == '') {
                
            }
            else{
                
            }
        });
    }

}

export { userActions };

