import React from "react";
import { StyleSheet, Image, View, Text, Pressable } from "react-native";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import AsyncStorage from "@react-native-async-storage/async-storage";

//Screens
import AccountScreen from "../Container/AccountScreen";
import ChangePasswordScreen from '../Container/ChangePasswordScreen';
import EditAccountScreen from '../Container/EditAccountScreen';
import ForgotPasswordScreen from '../Container/ForgotPasswordScreen';
import YourEntriesScreen from '../Container/YourEntriesScreen';
import PreferencesScreen from '../Container/PreferencesScreen';
import OfflineSettingsScreen from '../Container/OfflineSettingsScreen';
import TermsAndConditionsScreen from '../Container/TermsAndConditionsScreen';
import PrivacyPolicyScreen from '../Container/PrivacyPolicyScreen';
import SearchLocationScreen from "../Container/SearchLocationScreen";
import SearchCityScreen from "../Container/SearchCityScreen";
import SearchRadiusScreen from "../Container/SearchRadiusScreen";
import HomeCurrencyScreen from "../Container/HomeCurrencyScreen";


const Navigation = createNativeStackNavigator();

const getUserDetails = async () => {
    let user = await AsyncStorage.getItem("user");
    return JSON.parse(user);
}

class AccountStack extends React.Component {
  render(){      
      let fsProps = this.props;
      return (
          <Navigation.Navigator>
            <Navigation.Group>
              <Navigation.Screen
                name={"AccountScreen"}
                component={AccountScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"ChangePasswordScreen"}
                component={ChangePasswordScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"EditAccountScreen"}
                component={EditAccountScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"ForgotPasswordScreen"}
                component={ForgotPasswordScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"YourEntriesScreen"}
                component={YourEntriesScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"PreferencesScreen"}
                component={PreferencesScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"OfflineSettingsScreen"}
                component={OfflineSettingsScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"TermsAndConditionsScreen"}
                component={TermsAndConditionsScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"PrivacyPolicyScreen"}
                component={PrivacyPolicyScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"SearchLocationScreen"}
                component={SearchLocationScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"SearchCityScreen"}
                component={SearchCityScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"SearchRadiusScreen"}
                component={SearchRadiusScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
              <Navigation.Screen
                name={"HomeCurrencyScreen"}
                component={HomeCurrencyScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
            </Navigation.Group>
          </Navigation.Navigator>
        );
  }
  
}

export default AccountStack;