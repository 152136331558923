import Constants from '../Lib/Constants';
import Api from '../Services/Api';

//uploading offline data


export function entryAdd_offline(offlineInsertId,tableFields) {
    return new Promise(function(resolve, reject) {
        if (Constants.isOnline) {  
            console.log('offlineInsertDetail paramsData tableFields',tableFields);
            var param = {}

            param['price'] = tableFields.price;
            param['catId'] = tableFields.cat_id;
            param['latitude'] = tableFields.latitude;
            param['longitude'] = tableFields.longitude;
            param['countryId'] = tableFields.country_id;
            param['tagIds'] = tableFields.tag_ids;
            param['location'] = tableFields.location;
            param['description'] = tableFields.description;

            const mApi = Api.createSecure()
            const updateSyncFieldQuery = `update offline_insertions set isSynced = 1 where id = ${offlineInsertId} `
            const dictResponse = {}
            dictResponse['offlineInsertId'] = offlineInsertId
            dictResponse['success'] = false
        }
    })
}

export function categoryAdd_offline(offlineInsertId, tableFields) {
    return new Promise(function (resolve, reject) {
        if (Constants.isOnline) {
            console.log('offlineInsertDetail paramsData tableFields', tableFields);
            const mApi = Api.createSecure()
            const updateSyncFieldQuery = `update offline_insertions set isSynced = 1 where id = ${offlineInsertId} `
            const dictResponse = {}
            dictResponse['offlineInsertId'] = offlineInsertId
            dictResponse['success'] = false
        }
        else{
            resolve(dictResponse)
        }
    });
}

export function favouriteAdd_offline(offlineInsertId,tableFields)
{
    return new Promise(function (resolve, reject) {
        if (Constants.isOnline) {
            console.log('offlineInsertDetail paramsData tableFields', tableFields);
            var param = {}
            param['title'] = tableFields.title;
            param['catId'] = tableFields.cat_id;
            param['tagIds'] = tableFields.tag_ids;
            param['otherTags'] = tableFields.other_tags;
            param['category'] = tableFields.category;
            param['homePrice'] = tableFields.home_price;
            param['searchPrice'] = tableFields.search_price;
            param['isCatSearch'] = tableFields.is_cat_search;

            const mApi = Api.createSecure()
            const updateSyncFieldQuery = `update offline_insertions set isSynced = 1 where id = ${offlineInsertId} `
            const dictResponse = {}
            dictResponse['offlineInsertId'] = offlineInsertId
            dictResponse['success'] = false
            // SQLHelper.performTransactionWithQuery(Constants.dbRefrence, updateSyncFieldQuery, (updated) => { })
            // mApi.favouriteAdd(param).then(response => {
            //     if (response.ok) {
            //         if (response.data.success) {
            //             dictResponse['success'] = true
            //         }
            //     }
            //     resolve(dictResponse)
            // }).catch(error => {
            //     resolve(dictResponse)
            //     console.log('error', error);
            // })
        }
        else{
            resolve(dictResponse)
        }
    });
}


export function addRecentSearch_offline(offlineInsertId,tableFields)
{
    return new Promise(function (resolve, reject) {
        if (Constants.isOnline) {
            console.log('offlineInsertDetail paramsData tableFields', tableFields);
            var param = {}

            param['title'] = tableFields.title;
            param['catId'] = tableFields.cat_id;
            param['tagIds'] = tableFields.tag_ids;
            param['isCatSearch'] = tableFields.is_cat_search;

            const mApi = Api.createSecure()
            const updateSyncFieldQuery = `update offline_insertions set isSynced = 1 where id = ${offlineInsertId} `
            const dictResponse = {}
            dictResponse['offlineInsertId'] = offlineInsertId
            dictResponse['success'] = false
            // SQLHelper.performTransactionWithQuery(Constants.dbRefrence, updateSyncFieldQuery, (updated) => { })
            // mApi.addRecentSearch(param.title, param.catId, param.tagIds, param.isCatSearch).then(response => {
            //     if (response.ok) {
            //         if (response.data.success) {
            //             dictResponse['success'] = true
            //         }
            //     }
            //     resolve(dictResponse)
            // }).catch(error => {
            //     resolve(dictResponse)
            //     console.log('error', error);
            // })
        }
        else{
            resolve(dictResponse)
        }
    });
}