import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  TextInput
} from 'react-native';
import ModalSelector from 'react-native-modal-selector'
import { SafeAreaView } from 'react-native';
import {connect} from 'react-redux';
import BaseComponent from '../Components/BaseComponent';
import LoadingComponent from '../Components/LoadingComponent';
import Constants from '../Lib/Constants';
import * as Helper from '../Lib/Helper';
import ActionCreators from '../Redux/actions';
import Api from '../Services/Api';
import {Colors, Fonts, Images, Metrics} from '../Themes';
import styles from './Styles/SignupScreenStyles';
import { countryActions } from "../Redux/actions/Country";
import { StorageOperation } from '../storage/asyncStorage';

class SignupCountryScreen extends BaseComponent {
  static navigationOptions = ({ navigation }) => ({
    headerRight: () => <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => navigation.state.params.onNext()}>
        <Text style={styles.headerText}>Complete Sign Up</Text>
        <Image source={Images.arrowRight} style={[styles.arrow]} />
    </TouchableOpacity>
  });

  constructor(props) {
    super(props);
    this.state = {
      arrCountries: [],
      countryName: this.props.state?.countryName,
      countryId: this.props.state?.countryId,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    var getCountries = countryActions.getCountries();
    console.log(getCountries);

    this.setState({
      arrCountries: getCountries
    })

    this.props.navigation.setOptions({
      headerRight: () => {
      return(
          <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => {this.onPressNext()}}>
              <Text style={styles.headerText}>Complete Sign Up</Text>
              <Image source={Images.arrowRight} style={[styles.arrow]} />
          </TouchableOpacity>
      )
      },
      headerLeft: () => {
          return (
              <TouchableOpacity 
                  activeOpacity={1} 
                  style={[styles.leftIcon, {paddingLeft: 10}]} 
                  onPress={() => { 
                      this.props.navigation.navigate('SignupGenderScreen')
                  }}>
                  <Image source={Images.arrowLeft} style={[styles.arrow]} />
              </TouchableOpacity>
          )
      }
    })
  }

  onPressNext = () => {
    const country = this.state.countryId;
    console.log(country);
    if (country == '') {
      Helper.showAlert(Constants.ERROR_MESSAGES.COUNTRY);
    } else {
      this.props.route.params.completeSignup();
    }
  };

  handleSelectedCountry = (value, index) => {
    console.log("val ind: ", value, index)
    // const data = this.state.arrCountries.find(item => value == item.value);
    // if (data) {
    // }
    this.setState({
      countryName: value
    })
    this.props.route.params.setSignupDetails({country: value, countryId: index});
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}>
        <SafeAreaView
          style={styles.mainContainer}>
          <View style={styles.content}>
            <View style={styles.frm}>
              <Text style={[styles.h1, styles.marginBottom, {
                marginTop:30
              }]}>
                What country do you live in?
              </Text>
              <ModalSelector
                data={this.state.arrCountries}
                initValue="Select a country"
                cancelButtonAccessibilityLabel={'Close'}
                onChange={(option)=>{ this.state.countryId != option.key
                  ? this.handleSelectedCountry(option.label, option.key)
                  : null}}>
                <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="Select a country"
                    placeholderTextColor="rgba(255, 255, 255, 0.7)"
                    style={styles.TextInput}
                    value={this.state.countryName}
                    />
              </ModalSelector>
            </View>
          </View>
        </SafeAreaView>
        {this.props.loading || this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//   // console.log('>>>>> User Detail Country', state);
//   return {
//     userDetail: state.userReducer.userDetail,
//     arrCountries: state.countryReducer.arrCountries,
//     loading: state.countryReducer.loading,
//   };
// };

// mapDispatchToProps = dispatch => {
//   return {
//     setCountry: (countryName, countryId) =>
//       dispatch(ActionCreators.userActions.setCountry(countryName, countryId)),
//     setUser: userDetail =>
//       dispatch(ActionCreators.userActions.setUserDetail(userDetail)),
//     getCountries: () => dispatch(ActionCreators.countryActions.getCountries()),
//   };
// };
export default (SignupCountryScreen);

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    backgroundColor: Colors.transparent,
    fontSize: (Metrics.screenWidth * 24) / 414,
    fontFamily: "semiBold",
    color: '#fff',
  },
  inputAndroid: {
    backgroundColor: Colors.transparent,
    fontSize: (Metrics.screenWidth * 24) / 414,
    fontFamily: "semiBold",
    color: '#fff',
  },
});
