import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,
    currencyHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20,
        borderBottomWidth: 1,
        borderColor: 'rgba(242,242,242,0.2)'
    },
    currencyIcon: {
        width: (Metrics.screenWidth * 25) / 414,
        height: (Metrics.screenWidth * 25) / 414,
        resizeMode: 'contain',
    },
    currencyBody: {
        flex: 1,
        paddingLeft: 15,
        paddingRight: 15,
    },
    gpsIcon: {
        width: (Metrics.screenWidth * 30) / 414,
        height: (Metrics.screenWidth * 30) / 414,
        resizeMode: 'contain',
        marginLeft: 10
    },
    listItem: {
        flexDirection: 'row-reverse',
        alignItems: 'center',
        paddingTop: (Metrics.screenWidth * 15) / 414,
        paddingBottom: (Metrics.screenWidth * 15) / 414,
        borderBottomWidth: 1,
        borderColor: 'rgba(255,255,255,0.3)',
        paddingLeft: 0,
        paddingRight: 0,
    },
    locationContent: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 0,
        paddingBottom: 5
    },
    locationArrow: {
        height: 12,
        width: 12,
        resizeMode: 'contain'
    }
})
