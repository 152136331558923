import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import {
  Image,
  ImageBackground,
  Keyboard,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { SafeAreaView } from 'react-native';
import {connect} from 'react-redux';
import BaseComponent from '../Components/BaseComponent';
import LoadingComponent from '../Components/LoadingComponent';
import Constants from '../Lib/Constants';
import * as Helper from '../Lib/Helper';
import ActionCreators from '../Redux/actions';
import Api from '../Services/Api';
import {Images} from '../Themes';
import styles from './Styles/SignupScreenStyles';
import { StorageOperation } from '../storage/asyncStorage';

class LoginScreen extends BaseComponent {
  static navigationOptions = ({navigation}) => ({
    headerLeft: () => (
      <TouchableOpacity
        style={styles.leftIcon}
        onPress={() => {
          Keyboard.dismiss();
          navigation.dispatch(navigation.goBack());
        }}>
        <Image source={Images.close} style={styles.arrow} />
      </TouchableOpacity>
    ),

    headerRight: () => (
      <TouchableOpacity
        activeOpacity={1}
        style={styles.rightIcon}
        onPress={() => navigation.state.params.onLogin()}>
        <Text style={styles.headerText}>Log in</Text>
        <Image source={Images.arrowRight} style={[styles.arrow]} />
      </TouchableOpacity>
    ),
  });

  constructor(props) {
    super(props);
    this.state = {
      email: __DEV__ ? 'delvin@mailinator.com' : '',
      password: __DEV__ ? '12345678' : '',
      loading: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.props.navigation.setParams({
      onLogin: this.onPressLogin.bind(this),
    });
  }

  callLoginApi(userDetail) {
    const mApi = Api.create();
    mApi
      .login(userDetail)
      .then(response => {
        if (response.ok) {
          if (response.data.success) {
            try {
              StorageOperation.setData([
                [Constants.KEY_IS_LOGIN, "1"],
                [Constants.KEY_TOKEN, response.data.data.token],
              ]).then(() => {
                this.props.setUser(response.data.data);
                Helper.resetNavigationToScreen(
                  this.props.navigation,
                  'HomeStack',
                );
              });
            } catch (error) {
              console.log('AsyncStorage error: ', error);
            }
          } else {
            Helper.showAlert(response.data.message);
          }
        } else {
          Helper.showAlert(response.problem);
        }

        this.setState({loading: false});
      })
      .catch(err => {
        console.log('error: ' + err);
        this.setState({loading: false});
      });
  }

  onPressLogin = () => {
    Keyboard.dismiss();
    const email = this.state.email;
    const password = this.state.password;
    if (email.trim() == '') {
      Helper.showAlert(Constants.ERROR_MESSAGES.EMAIL);
    } else if (!Helper.validateEmail(email)) {
      Helper.showAlert(Constants.ERROR_MESSAGES.VALID_EMAIL);
    } else if (password.trim() == '') {
      Helper.showAlert(Constants.ERROR_MESSAGES.PASSWORD);
    } else if (!Helper.validatePassword(password)) {
      Helper.showAlert(Constants.ERROR_MESSAGES.VALID_PASSWORD);
    } else {
      this.setState({loading: true});
      const userDetail = {email: email, password: password};
      // FirebaseService.getNotificationToken().then(token => {
      //   FirebaseService.hasNotificationPermission().then(hasPermission => {
      //     userDetail.deviceToken = token;
      //     userDetail.isAllowNotification = hasPermission ? 1 : 0;

      //     this.callLoginApi(userDetail);
      //   });
      // });
    }
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}>
        <SafeAreaView
          style={styles.mainContainer}>
          <View style={styles.content}>
            <View style={styles.frm}>
              <Text style={[styles.h1, styles.marginBottom, {marginTop:30}]}>Welcome back</Text>
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="Enter your email"
                placeholderTextColor="rgba(255, 255, 255, 0.7)"
                style={[styles.TextInput, {marginBottom:10}]}
                keyboardType="email-address"
                value={this.state.email}
                onChangeText={email => this.setState({email})}
                returnKeyType="next"
                onSubmitEditing={event => this._password.focus()}
              />
              <TextInput
                underlineColorAndroid="transparent"
                placeholder="Enter your password"
                placeholderTextColor="rgba(255, 255, 255, 0.7)"
                style={[styles.TextInput, {marginBottom:10}]}
                secureTextEntry
                value={this.state.password}
                onChangeText={password => this.setState({password})}
                returnKeyType="go"
                ref={ref => (this._password = ref)}
                onSubmitEditing={event => this.onPressLogin()}
              />
              <TouchableOpacity
                onPress={() =>
                  this.props.navigation.navigate('ForgotPasswordScreen')
                }>
                <Text style={styles.msg}>
                  Tap here to recover your password
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

export default LoginScreen;
