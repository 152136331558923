import React from "react";
import { ImageBackground, StyleSheet, Text, View, TouchableOpacity, Image, TextInput } from 'react-native';
// import RNPickerSelect from 'react-native-picker-select';
import ModalSelector from 'react-native-modal-selector'
import { SafeAreaView } from 'react-native';
import BaseComponent from "../Components/BaseComponent";
import { Colors, Fonts, Images, Metrics } from "../Themes";
import styles from "./Styles/SignupScreenStyles";
import { connect } from "react-redux";
import ActionCreators from "../Redux/actions";
import * as Helper from '../Lib/Helper'
import Constants from "../Lib/Constants";

class SignupGenderScreen extends BaseComponent {

    static navigationOptions = ({ navigation }) => ({
        headerRight: () => <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => navigation.state.params.onNext()}>
            <Text style={styles.headerText}>Next</Text>
            <Image source={Images.arrowRight} style={[styles.arrow]} />
        </TouchableOpacity>
    });

    constructor(props) {
        super(props);
        this.state = {
            gender: "",
            arrGender: [
                {
                    key: 'Female',
                    label: 'Female',
                },
                {
                    key: 'Male',
                    label: 'Male',
                },
                // {
                //     key: 'Non binary',
                //     label: 'Other',
                // },
            ],
        }
    }

    componentDidMount() {
        super.componentDidMount()

        this.props.navigation.setOptions({
            headerRight: () => {
            return(
                <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => {this.onPressNext()}}>
                    <Text style={styles.headerText}>Next</Text>
                    <Image source={Images.arrowRight} style={[styles.arrow]} />
                </TouchableOpacity>
            )
            },
            headerLeft: () => {
                return (
                    <TouchableOpacity 
                        activeOpacity={1} 
                        style={[styles.leftIcon, {paddingLeft: 10}]} 
                        onPress={() => { 
                            this.props.navigation.navigate('SignupDateScreen')
                        }}>
                        <Image source={Images.arrowLeft} style={[styles.arrow]} />
                    </TouchableOpacity>
                )
            }
        })
    }

    onPressNext = () => {
        const gender = this.state.gender

        if (gender == '') {
            Helper.showAlert(Constants.ERROR_MESSAGES.GENDER)
        } else {
            console.log(this.props)
            this.props.navigation.navigate('SignupCountryScreen', {
                setSignupDetails:this.props.route.params.setSignupDetails,
                completeSignup:this.props.route.params.completeSignup
            })
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.userDetail.gender != this.props.userDetail.gender) {
    //         this.setState({ gender: this.props.userDetail.gender })
    //     }
    // }

    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.mainContainer} >
                    <View style={styles.content}>
                        <View style={styles.frm}>
                            <Text style={[styles.h1, styles.marginBottom, {
                              marginTop:30
                            }]}>What do you identify as?</Text>
                            <ModalSelector
                              data={this.state.arrGender}
                              initValue="Choose a gender"
                              cancelButtonAccessibilityLabel={'Close'}
                              onChange={(option)=>{ 
                                console.log(option);
                                this.setState({
                                    gender: option.label
                                })
                                this.props.route.params.setSignupDetails({gender: option.label})
                                }}>
                              <TextInput
                                  underlineColorAndroid="transparent"
                                  placeholder="Choose a gender"
                                  placeholderTextColor="rgba(255, 255, 255, 0.7)"
                                  style={styles.TextInput}
                                  value={this.state.gender}
                                  />
                            </ModalSelector>
                        </View>
                    </View>
                </SafeAreaView>
            </ImageBackground>
        )
    }
}

// mapStateToProps = state => {
//     // console.log('>>>>> User Detail Gender', state);
//     return {
//         userDetail: state.userReducer.userDetail
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         setGender: (gender) => dispatch(ActionCreators.userActions.setGender(gender))
//     }
// }
export default (SignupGenderScreen)

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        backgroundColor: Colors.transparent,
        fontSize: (Metrics.screenWidth * 24) / 414,
        fontFamily: "semiBold",
        color: '#fff'
    },
    inputAndroid: {
        backgroundColor: Colors.transparent,
        fontSize: (Metrics.screenWidth * 24) / 414,
        fontFamily: "semiBold",
        color: '#fff'

    },
});
