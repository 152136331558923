import AsyncStorage from "@react-native-async-storage/async-storage";
import { api } from "../../../src/utils/api";
import Constants from "../../Lib/Constants";
import * as Helper from "../../Lib/Helper";
import Api from "../../Services/Api";
import ActionTypes from "./types";
import { userActions } from "./User";

const searchLocationActions = {
  setSearchLocation,
  setSearchMonths,
};

function setSearchLocation(locationData) {
  return async (dispatch) => {
    if (Constants.isOnline) {
      dispatch({
        type: ActionTypes.SEARCH_LOCATION,
        isSearchLocationUpdated: false,
      });
      dispatch({
        type: ActionTypes.SEARCH_LOCATION_LOADING,
        loading: true,
      });
      const mApi = Api.createSecure();
      var userDetails = await AsyncStorage.getItem(Constants.KEY_USER_DETAIL);
      var search_months = JSON.parse(userDetails).search_months;
      mApi
        .updateSearchLocation(locationData, search_months ? search_months : 12)
        .then((response) => {
          if (response.ok) {
            if (response.data.success) {
              try {
                AsyncStorage.setItem(
                  Constants.KEY_FIRST_TIME_LOCATION,
                  JSON.stringify(1)
                );

                dispatch(userActions.setUserDetail(response.data.data));

                dispatch({
                  type: ActionTypes.SEARCH_LOCATION,
                  isSearchLocationUpdated: true,
                });
              } catch (error) {
                console.log("AsyncStorage error: ", error);
              }
            } else {
              Helper.showAlert(response.data.message);
            }
          } else {
            Helper.showAlert(response.problem);
          }
          dispatch({
            type: ActionTypes.SEARCH_LOCATION_LOADING,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error: ", error);
          dispatch({
            type: ActionTypes.SEARCH_LOCATION_LOADING,
            loading: false,
          });
        });
    } else {
      // Helper.showAlert('No internet connection!');
    }
  };
}

async function setSearchMonths(locationData, search_months) {
  // return async dispatch => {
  //     if (Constants.isOnline) {
  //         dispatch({
  //             type: ActionTypes.SEARCH_MONTH,
  //             isSearchLocationUpdated: false,
  //         })
  //         dispatch({
  //             type: ActionTypes.SEARCH_MONTH_LOADING,
  //             loading: true,
  //         })
  //         const mApi = Api.createSecure()
  //         mApi.updateSearchLocation(locationData,search_months).then(response => {
  //             if (response.ok) {
  //                 if (response.data.success) {
  //                     try {
  //                         dispatch(userActions.setUserDetail(response.data.data))
  //                     } catch (error) {
  //                         console.log('AsyncStorage error: ', error);

  //                     }
  //                 } else {
  //                     Helper.showAlert(response.data.messages)
  //                 }
  //             } else {
  //                 Helper.showAlert(response.problem)
  //             }
  //             dispatch({
  //                 type: ActionTypes.SEARCH_MONTH_LOADING,
  //                 loading: false,
  //             })
  //         }).catch(error => {
  //             console.log('error: ', error);
  //             dispatch({
  //                 type: ActionTypes.SEARCH_MONTH_LOADING,
  //                 loading: false,
  //             })
  //         })
  //     } else {
  //         console.log('Saved offline');
  //         var userDetails = await AsyncStorage.getItem(Constants.KEY_USER_DETAIL)
  //         var jsonData = JSON.parse(userDetails)
  //         jsonData.search_months = search_months
  //         dispatch(userActions.setUserDetail(jsonData))
  //         // Helper.showAlert('No internet connection!');
  //     }

  // }

  let data = {
    search_location: locationData.location,
    search_latitude: locationData.latitude,
    search_longitude: locationData.longitude,
    search_country_name: locationData.country,
    short_name: locationData.short_name,
    user_id: locationData.user_id,
    search_months: search_months,
  };

  if (locationData.radius) {
    data["search_radius"] = locationData.radius;
  }

  if (locationData.homeCountry) {
    data["country_name"] = locationData.homeCountry;
  }

  const searchResp = await api("v1/api/request", "POST", {
    endpoint: "user/preference/update",
    type: "POST",
    data: data,
  });

  return searchResp.data;
}

export { searchLocationActions, setSearchMonths };
