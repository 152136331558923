import NetInfo from '@react-native-community/netinfo';
import Api from '../../Services/Api';
import ActionTypes from './types';
import Constants from "../../Lib/Constants";

const mApi = Api.createSecure()

const offlineStateActions = {
    addOfflineStateListner
}

//Getting network reachability to check internet connection
function addOfflineStateListner() {
    return (dispatch, getState) => {
        NetInfo.addEventListener(state => {
            console.log('Connection type', state.type);
            console.log('Is connected?', state.isConnected);
            console.log("Is isInternetReachable?", state.isInternetReachable);
            const isOnline = state.isConnected && state.isInternetReachable
            if (state.isInternetReachable != null) {
                Constants.isOnline = isOnline
                return dispatch({
                    type: ActionTypes.OFFLINE_STATE,
                    isOnline: isOnline,
                });
            }

        });
    };
}

export { offlineStateActions };

