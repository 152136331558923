import React, { Component, Fragment } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Images, Colors, Metrics, Fonts } from '../Themes';
import NetInfo from "@react-native-community/netinfo";
import styles from "./Styles/OfflineBannerStyles"
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "../Lib/Constants";
import ActionCreators from "../Redux/actions";
import { connect } from "react-redux";

class OfflineBannerBottom extends Component {
    constructor() {
        super();
        this.state = {
            isOfflineEnabled: null,
            isOnline: null,
            isVisible: true,
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOnline !== this.props.isOnline) {
            this.setState({ isOnline: this.props.isOnline })
        }
        if (prevState.isOfflineEnabled !== this.props.isOfflineEnabled) {
            this.setState({ isOfflineEnabled: this.props.isOfflineEnabled })
        }
    }

    componentDidMount(){
        // this._unsubscribe = NetInfo.addEventListener(state => {            
        //     console.log("Connection type", state.type);
        //     console.log("Is connected?", state.isConnected);
        //     console.log("Is isInternetReachable?", state.isInternetReachable);
        //     const isOnline = state.isConnected && state.isInternetReachable
        //     if (state.isInternetReachable != null) {
        //         console.log("Is isOnline?", isOnline);
        //         this.props.onOnlineStatusChange(isOnline)
        //     }
        //   });
    }

    componentWillUnmount() {
        // this._unsubscribe()
    }
   
    onPressClose = () => {
        this.setState({
            isVisible:false
        })
    }

    onToggleOfflineSync = () => {
        var isOn = 1
        if (this.state.isOfflineEnabled) {
            isOn = 0
        }
        AsyncStorage.setItem(Constants.OFFLINE_SYNC,JSON.stringify(isOn))
        // this.setState({
        //     isOfflineEnabled: !this.state.isOfflineEnabled
        // });
        this.props.onToggleOfflineSync ? this.props.onToggleOfflineSync() : null
    }

    render() {
        // console.log('OfflineBannerBottom',this.state);
        
        if (this.state.isOnline == null || this.state.isOfflineEnabled == null ) {
            return(null)
        }
        if (!this.state.isOnline && this.state.isVisible) {
            return (
                <View style={styles.offlineMode}>
                    <View style={styles.offlineModeHeader}>
                        <Image source={Images.offline} style={styles.offlineIcon} />
                        <Text style={styles.offlineModeTitle}>No connection!</Text>
                    </View>
                    <View style={styles.offlineModeContent}>
                        <Text style={styles.offlineModeText}>Enable offline mode and we’ll download price information next time you have connection.</Text>
                    </View>
                    <View style={styles.offlineModeBottom}>
                        <TouchableOpacity
                            onPress={this.onToggleOfflineSync}
                            style={[styles.switchBtn, this.state.isOfflineEnabled ? styles.switchOn : null]}>

                            <Image source={Images.marker} style={[styles.switchIcon, this.state.isOfflineEnabled ? styles.switchIconOn : null]} />
                            <Text style={styles.switchText}>{this.state.isOfflineEnabled ?'ON':'OFF'}</Text>
                        </ TouchableOpacity>
                        <Text style={styles.emLabel}>Settings</Text>
                    </View>
                    <TouchableOpacity style={styles.closeOfflineMode} onPress={() => this.onPressClose()}>
                        <Image source={Images.close} style={styles.closeOfflineModeIcon} />
                    </TouchableOpacity>
                </View>
        )
        }
        return(null)
    }
}


export default (OfflineBannerBottom)