import React, { createRef } from "react";
import {
  Image,
  ImageBackground,
  Keyboard,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  SafeAreaView,
  Pressable,
  ScrollView,
  StyleSheet,
  Platform,
  ActivityIndicator,
} from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import SearchComponent from "../Components/SearchComponent";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import { Images, Metrics, Colors } from "../Themes";
import styles from "./Styles/SearchScreenStyles";
import Constants from "../Lib/Constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import { favouriteActions } from "../Redux/actions/Favourite";
import { api } from "../../src/utils/api";
import RNPickerSelect from "react-native-picker-select";
import Api from "../Services/Api";

var timerId = "";
class SearchScreen extends BaseComponent {
  constructor(props) {
    super(props);
    this.inputRef = createRef();
    this.state = {
      loading: true,
      selectedTab: 2,
      keyword: "",
      isLoading: false,
      arrPopular: [],
      arrRecent: [],
      arrTags: [],
      filteredArrTags: [],
      arrCategories: [],
      searchTags: "",
      searchResult: {},
      selectedCategory: "",
      searchCleared: false,
      specificListing: null,
      popUpVisible: false,
      isSearchFavourite: false,
      isFavRemovedVisible: false,
      popupDialogMessage: "",
      userDetail: null,
      entryCount: 0,
      filterValue: "",
      tagSortArr: [
        {
          label: "Alphabetical",
          value: "Alphabetical",
        },
        {
          label: "Most Popular",
          value: "Most Popular",
        },
      ],
      tagSort: "",
      homePrice: 0,
      pricesLoading: false,
      enteredValues: null,
    };
    timerId = setTimeout(this.searchKeyword.bind(this), Constants.SEARCH_TIMER);
    this.searchKeyword = this.searchKeyword.bind(this);
    this.selectSearchTag = this.selectSearchTag.bind(this);
    this.onPressNavigateToEnterPrice =
      this.onPressNavigateToEnterPrice.bind(this);
    this.onPressEditPrice = this.onPressEditPrice.bind(this);
    this.renderTags = this.renderTags.bind(this);
    this.getAllTags = this.getAllTags.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.favouriteSearch = this.favouriteSearch.bind(this);
    this.checkIsFav = this.checkIsFav.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.getEntryCount = this.getEntryCount.bind(this);
    this.filterSearchTags = this.filterSearchTags.bind(this);
    this.sortSearchTags = this.sortSearchTags.bind(this);
    this.setSearchTags = this.setSearchTags.bind(this);
    this.searchAgain = this.searchAgain.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    ActionCreators.searchActions.getRecentSearch();
    ActionCreators.popularItemsActions.getPopularItem();

    if (this.props.route.params.enteredValues) {
      this.setState({
        enteredValues: this.props.route.params.enteredValues,
      });
    }

    // console.log("params: ", this.props.route.params);

    if (this.props.route.params.searchItem) {
      this.setState({
        keyword: this.props.route.params.searchItem,
        searchTags: this.props.route.params.searchTags
          ? this.props.route.params.searchTags
          : "",
        specificListing: this.props.route.params.specificListing
          ? this.props.route.params.specificListing
          : null,
      });
      this.getAllTags(this.props.route.params.searchItem);
    } else {
      if (this.inputRef) {
        let parentThis = this;
        setTimeout(function () {
          // console.log("i would focus the input here and now");
          if (parentThis.inputRef.current) {
            parentThis.inputRef.current.focus();
          }
        }, 800);
      }
      this.setState({
        loading: false,
      });
    }
  }

  componentDidUpdate() {
    this.getUserDetails();
    if (this.props.route.params.searchTags) {
      if (this.state.searchTags == "" && this.state.searchResult) {
        this.setSearchTags(this.props.route.params.searchTags);
        let tags = [];
        for (let ft = 0; ft < this.state.filteredArrTags.length; ft++) {
          const element = this.state.filteredArrTags[ft];
          console.log("element: ", element);
          // this.setSearchTags(element, this.state.searchTags);
          // if (
          //   indexOf(this.props.route.params.searchTags.split(","), element) > -1
          // ) {
          //   this.setSearchTags(element);
          // }
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      userDetail: null,
    });
  }

  showPopup = (visible, popUpMessage) => {
    this.setState(
      { popUpVisible: visible, popupDialogMessage: popUpMessage },
      () =>
        setTimeout(() => {
          this.setState({ popUpVisible: false, popupDialogMessage: "" });
        }, Constants.TIME_FOR_POP_UPS)
    );
  };

  async getUserDetails() {
    if (this.state.userDetail == null) {
      let user = await this.props.route.params.getUserDetails();
      this.setState({
        userDetail: user,
      });
      this.getEntryCount(user);
    }
  }

  async getEntryCount(user, searchTags = false, kw = false) {
    const entryCount = await api("v1/api/request", "POST", {
      endpoint: "entry/list-count",
      type: "POST",
      data: {
        kwd: kw ? kw : this.state.keyword,
        latitude: user.search_latitude,
        longitude: user.search_longitude,
        distanceInMetres: user.search_radius * 1000,
        search_months: user.search_months,
        tags: searchTags ? searchTags : "",
      },
    });
    if (entryCount.data.success) {
      this.setState({
        entryCount: entryCount.data.data.entryCount,
      });
    }
  }

  async getAllTags(keyword, category) {
    if (keyword.length > 0) {
      let user = await this.props.route.params.getUserDetails();
      let resp = await ActionCreators.searchActions.getAllTags(
        keyword,
        user.search_latitude,
        user.search_longitude,
        user.search_radius * 1000,
        user.search_months
      );
      if (resp.status == 200) {
        const inputString = keyword;
        const regex = /(?<=\D)\s/; // Positive lookbehind for a non-digit character

        const resultArray = inputString.split(regex);

        // Filter and map to get an array of matching tags
        const matchingTagsArray = resultArray.filter((part) => {
          return resp.data.data.tags.includes(part);
        });

        let tagState = {
          arrTags: resp.data.data.tags ? resp.data.data.tags : [],
          filteredArrTags: resp.data.data.tags ? resp.data.data.tags : [],
        };

        // console.log("category:", category);
        // console.log("searchtags:", this.state.searchTags);

        let searchTags = this.state.searchTags;

        if (category !== false) {
          if (matchingTagsArray.length > 0) {
            let tagsToAdd = matchingTagsArray.filter(
              (tag) => !searchTags.includes(tag)
            );

            if (tagsToAdd.includes(category) && tagsToAdd.length > 1) {
              searchTags = searchTags.concat(tagsToAdd);
            } else {
              console.log("matchingTags exist: ", tagsToAdd);
              searchTags = searchTags.concat(tagsToAdd);
            }
          }
        } else {
          searchTags = searchTags.concat(
            matchingTagsArray.filter((tag) => !searchTags.includes(tag))
          );
        }

        // console.log("set state seach tags: ", searchTags.toString());

        tagState.searchTags = searchTags.toString();

        this.setState(tagState);
        return tagState.filteredArrTags;
      }
      this.checkIsFav(user);
    }
  }

  removeDuplicatesFromArray(mainArray, arrayToRemove) {
    return mainArray.filter((item) => !arrayToRemove.includes(item));
  }

  onTabChange = (selectedTab) => {
    this.setState({
      selectedTab,
      pricesLoading: false,
    });
  };

  setSearchTags = (tags) => {
    console.log("set search item: ", tags);
    // let searchTags = [];
    // let tags = this.state.searchTags;
    // let tagsArr = tags.split(",");

    // console.log("tagsArray: ", tagsArr);

    // if (tagsArr.indexOf(item) == -1) {
    //   searchTags.push(item);
    // }

    // tags = tags + searchTags.toString();
    this.setState({
      searchTags: tags,
    });
  };

  searchAgain = (tags, keyword) => {
    this.setSearchTags(tags);
    this.searchKeyword(keyword);
    this.getEntryCount(this.state.userDetail, tags, keyword);
  };

  searchKeyword = async (
    kw = false,
    initialSearch = true,
    searchTagInSearch = false
  ) => {
    // console.log("kw", kw);
    let kword = "";
    if (kw) {
      kword = kw;
    } else {
      if (this.state.keyword.trim() == "") {
        // Helper.showAlert('Please enter product or category')
        return;
      } else {
        this.setState({
          loading: true,
        });
      }
      kword = this.state.keyword;
      // this.setState({
      //   searchTags: "",
      // });
    }

    // console.log("this.state.fitlertedArrTags: ", this.state.filteredArrTags);

    // for (let fat = 0; fat < this.state.filteredArrTags.length; fat++) {
    //   const element = this.state.filteredArrTags[fat];
    //   let lowerCaseName = element.toLowerCase();
    //   let lowerCaseKeyword = kword.toLowerCase();

    //   if (
    //     lowerCaseKeyword.split(" ").includes(lowerCaseName) ||
    //     lowerCaseKeyword.includes(lowerCaseName)
    //   ) {
    //     this.setSearchTags(element);
    //   }
    // }

    let user = await this.props.route.params.getUserDetails();
    let resp = await ActionCreators.searchActions.search(
      kword,
      user.search_latitude,
      user.search_longitude,
      user.search_radius * 1000,
      this.state.searchTags,
      user.search_months
    );
    if (resp.status == 200) {
      let results = resp.data.data;
      console.log("result: ", results);
      let allTags = "";
      if (initialSearch == true) {
        allTags = await this.getAllTags(
          kword,
          results?.categories[0]?.categories
        );
      } else {
        allTags = this.state.arrTags;
      }

      let searchTags = resp.data.data.tags;
      console.log("allTags on search: ", allTags);
      console.log("search tags: ", searchTags);

      if (searchTags.length < allTags.length) {
        this.setState({
          filteredArrTags: searchTags,
        });
      } else if (searchTags.length == allTags.length) {
        this.setState({
          filteredArrTags: allTags,
        });
      }

      // for (let at = 0; at < allTags.length; at++) {
      //   const element = allTags[at];
      //   let lowerCaseName = element.toLowerCase();
      //   let lowerCaseKeyword = kword.toLowerCase();

      //   if (
      //     lowerCaseKeyword.split(" ").includes(lowerCaseName) ||
      //     lowerCaseKeyword.includes(lowerCaseName)
      //   ) {
      //     this.setSearchTags(element);
      //   }
      // }

      // Split kword into individual phrases
      const phrases = kword.toLowerCase().split(" ");

      // Initialize an array to store matched phrases
      const matchedPhrases = [];

      // Loop through each phrase
      for (let i = 0; i < phrases.length; i++) {
        // Create multi-word phrases starting from the current phrase
        for (let j = i; j < phrases.length; j++) {
          const multiWordPhrase = phrases.slice(i, j + 1).join(" ");

          // Check if the multi-word phrase exists in any tag
          const phraseMatched = allTags.some(
            (tag) => tag.toLowerCase() === multiWordPhrase
          );

          // If the multi-word phrase is matched in any tag, add it to matchedPhrases
          if (phraseMatched) {
            matchedPhrases.push(multiWordPhrase);
          }
        }
      }

      // If there are matched phrases, set them as the search tags
      if (matchedPhrases.length > 0) {
        this.setSearchTags(matchedPhrases.toString());
      }

      let stateObj = {
        searchResult: results.result,
        selectedCategory: results.categories,
        loading: false,
      };

      console.log("searchTagInSearch: ", searchTagInSearch);

      if (searchTagInSearch !== false) {
        stateObj.searchTags = searchTagInSearch;
        this.setSearchTags(searchTagInSearch);
      }

      this.getEntryCount(this.state.userDetail, this.state.searchTags, kw);

      this.setState(stateObj);
      if (this.state.pricesLoading == false) {
        this.setState({
          pricesLoading: false,
        });
      }
    }
  };

  clearSearch = () => {
    Keyboard.dismiss();
    this.setState({
      keyword: "",
      arrTags: [],
      arrCategories: [],
      searchResult: "",
      loading: false,
      searchCleared: true,
    });
  };

  selectSearchTag = (tag) => {
    console.log("update search tag: ", tag);
    let searchTags = "";
    let keyword = "";

    console.log("this.state.searchTags: ", this.state.searchTags);

    if (this.state.searchTags !== "") {
      let checkSearchTags = this.state.searchTags.split(",");
      if (checkSearchTags.indexOf(tag) > -1) {
        for (let cs = 0; cs < checkSearchTags.length; cs++) {
          const element = checkSearchTags[cs];
          if (element == tag) {
            checkSearchTags.splice(cs, 1);
            cs = checkSearchTags.length;
            // var kw = this.state.keyword;
            // keyword = kw.replace(element.trim(), "").trim();
          }
        }

        searchTags = checkSearchTags.toString();
      } else {
        checkSearchTags.push(tag);
        searchTags = checkSearchTags.toString();
        // let kw = this.state.keyword.toLowerCase();
        // if (!kw.includes(tag.toLowerCase())) {
        //   keyword = kw + " " + tag;
        // } else {
        //   keyword = kw.replace(tag.toLowerCase().trim(), "").trim();
        // }
      }
    } else {
      searchTags = tag;
      // let kw = this.state.keyword.toLowerCase();
      // if (!kw.includes(tag.toLowerCase())) {
      //   keyword = kw + " " + tag;
      // } else {
      //   keyword = kw;
      // }
    }

    this.getEntryCount(this.state.userDetail, searchTags);

    this.setState({
      searchTags: searchTags,
      keyword: this.state.keyword,
    });
    setTimeout(() => {
      this.searchKeyword(this.state.keyword, false, searchTags);
    }, 500);
  };

  sortSearchTags(value) {
    this.state.tagSort != value ? this.setState({ tagSort: value }) : null;
    switch (value) {
      case "Alphabetical":
        var newArr = this.state.filteredArrTags;
        newArr = newArr.sort();
        this.setState({
          filteredArrTags: newArr,
        });
        break;
      case "Most Popular":
        this.state.filteredArrTags;
        break;
      case "Default":
        this.getAllTags(this.state.keyword);
        break;
      default:
        this.setState({
          filteredArrTags: this.state.arrTags,
        });
        break;
    }
  }

  onPressNavigateToEnterPrice() {
    let tags = this.state.searchTags;
    const inputString = this.state.keyword;
    const regex = /(?<=\D)\s/; // Positive lookbehind for a non-digit character

    const resultArray = inputString
      .split(regex)
      .filter((part) => !tags.includes(part));
    if (!this.props.route.params.searchItem) {
      console.log("no set search item");
      if (tags.length > 0) {
        if (resultArray.length > 0) {
          console.log("resultarray: ", resultArray);
          tags = tags + `, ${resultArray.toString()}`;
        }
      } else {
        if (resultArray.length > 0) {
          tags = resultArray.toString();
        }
      }
    } else {
      if (this.state.searchCleared) {
        if (tags.length > 0) {
          tags = tags + `, ${resultArray.toString()}`;
        } else {
          tags = `${resultArray.toString()}`;
        }
      }
    }

    this.props.navigation.navigate("MainAddPrice", {
      isFromTellUs: false,
      isFromSearch: true,
      searchAgain: this.searchAgain,
      keyword:
        this.state.selectedCategory.length > 1
          ? "ALL"
          : this.state.selectedCategory.length > 0
          ? this.state.selectedCategory[0].categories
          : this.state.keyword,
      tags: tags,
    });
  }

  onPressEditPrice() {
    console.log(this.state.searchResult);
    let priceDetails = {
      ...this.state.searchResult,
      categories: this.state.keyword,
      tags: this.state.searchTags,
    };

    let tags = this.state.searchTags;
    const inputString = this.state.keyword;
    const regex = /(?<=\D)\s/; // Positive lookbehind for a non-digit character

    const resultArray = inputString
      .split(regex)
      .filter((part) => !tags.includes(part));
    if (!this.props.route.params.searchItem) {
      console.log("no set search item");
      if (tags.length > 0) {
        if (resultArray.length > 0) {
          tags = tags + `, ${resultArray.toString()}`;
        }
      } else {
        if (resultArray.length > 0) {
          tags = resultArray.toString();
        }
      }
    } else {
      if (this.state.searchCleared) {
        if (tags.length > 0) {
          tags = tags + `, ${resultArray.toString()}`;
        } else {
          tags = `${resultArray.toString()}`;
        }
      }
    }

    this.props.navigation.navigate("EnterPriceScreen", {
      priceDetail: {
        categories: this.state.keyword,
        tags: tags,
      },
      editPrice: true,
      specificListing: this.state.specificListing,
      showPopup: this.showPopup.bind(this),
      keyword:
        this.state.selectedCategory.length > 1
          ? "ALL"
          : this.state.selectedCategory.length > 0
          ? this.state.selectedCategory[0].categories
          : this.state.keyword,
      searchKeyword: this.searchKeyword,
      enteredValues: this.state.enteredValues,
    });
  }

  async favouriteSearch(addFav) {
    let category =
      this.state.selectedCategory.length > 0
        ? this.state.selectedCategory[0].categories
        : this.state.keyword;
    let user = await this.props.route.params.getUserDetails();
    const entryKey = `${category}|${this.state.searchTags}`;
    const param = {
      user_id: user.id,
      categories: category,
      tags: this.state.searchTags,
      entryKey: entryKey,
    };

    let fav = await ActionCreators.favouriteActions.favouriteAdd(param);
    console.log("fav", fav.data);
    if (fav.status == 200) {
      this.checkIsFav(user);
      this.setState(
        {
          popUpVisible: true,
          popupDialogMessage: addFav
            ? Constants.ADDED_FAV_POP_UP
            : Constants.REMOVED_FAV_POP_UP,
        },
        () => {
          setTimeout(() => {
            this.setState({ popUpVisible: false, popupDialogMessage: "" });
          }, Constants.TIME_FOR_POP_UPS);
        }
      );
    }
  }

  filterSearchTags(data) {
    var temp = this.state.arrTags;
    if (data !== "") {
      temp = this.state.filteredArrTags.filter((item) => item.includes(data));
    }
    this.setState({
      filterValue: data,
      filteredArrTags: temp,
    });
  }

  async checkIsFav(user) {
    const checkFav = await api("v1/api/request", "POST", {
      endpoint: "favourites/check",
      type: "POST",
      data: {
        userId: user.id,
        categories:
          this.state.selectedCategory.length > 0
            ? this.state.selectedCategory[0].categories
            : this.state.keyword,
        tags: this.state.searchTags,
      },
    });

    if (checkFav.status == 200) {
      const isFav = checkFav.data.data.isFavourite;
      this.setState({
        isSearchFavourite: isFav,
      });
    }
  }

  convertPrice = async (price, searchCountry, homeCountry) => {
    const mApi = Api.create();
    mApi
      .convertPrice(price, searchCountry, homeCountry)
      .then((response) => {
        if (response.ok) {
          if (response.data.success) {
            const price = response.data.data.converted_price;
            // if (this.state.pricesLoading == true) {
            var parentState = this;
            parentState.setState({
              homePrice: price,
            });
            //   setTimeout(function () {
            //     parentState.setState({
            //       homePrice: price,
            //       pricesLoading: false,
            //     });
            //   }, 1000);
            // }
          } else {
            Helper.showAlert(
              response.data.message,
              this.props.navigation,
              response.data.status
            );
          }
        } else {
          Helper.showAlert(response.problem, this.props.navigation);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  render() {
    const _this = this;
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        {this.state.loading ? <LoadingComponent /> : null}
        <SafeAreaView style={styles.container}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: Platform.OS == "android" ? 25 : 0,
            }}
          >
            <Pressable
              style={styles.topLeftArrow}
              onPress={() => this.props.navigation.goBack()}
            >
              <Image
                source={Images.arrowLeft}
                style={[styles.arrow, styles.largerLeft]}
              />
            </Pressable>
            <View style={{ flexDirection: "row" }}>
              <Pressable
                style={styles.topRight}
                onPress={() =>
                  this.favouriteSearch(
                    this.state.isSearchFavourite ? false : true
                  )
                }
              >
                <Image
                  source={
                    this.state.isSearchFavourite
                      ? Images.favourites_filled
                      : Images.favourites
                  }
                  style={[styles.arrow, styles.largerLeft]}
                />
              </Pressable>
              {this.props.route.params?.editPrice && (
                <Pressable
                  style={styles.topRight}
                  onPress={() => {
                    this.onPressEditPrice();
                  }}
                >
                  <Text style={{ color: "white" }}>Edit Price</Text>
                </Pressable>
              )}
            </View>
          </View>
          <View style={[styles.searchHeader]}>
            <Image source={Images.search} style={styles.searchIcon} />
            <TextInput
              ref={this.inputRef}
              underlineColorAndroid="transparent"
              placeholder="Search for anything..."
              placeholderTextColor="rgba(255, 255, 255, 0.7)"
              style={styles.searchInput}
              value={this.state.keyword}
              onChangeText={(keyword) => {
                this.setState({ keyword }, () => {
                  clearTimeout(timerId);
                  // if (this.state.keyword.length >= 2) {
                  timerId = setTimeout(
                    this.searchKeyword.bind(this),
                    Constants.SEARCH_TIMER
                  );
                  // }
                });
                if (keyword == "") {
                  this.setState({
                    loading: false,
                    searchCleared: true,
                  });
                } else {
                  this.setState({
                    loading: true,
                  });
                }
              }}
              returnKeyType="search"
              onSubmitEditing={() => this.searchKeyword()}
            />
            <TouchableOpacity
              style={styles.searchBtn}
              onPress={() =>
                this.state.keyword != ""
                  ? this.clearSearch()
                  : this.handleBackButtonClick()
              }
            >
              <Text style={styles.searchBtnText}>Cancel</Text>
            </TouchableOpacity>
          </View>
          <ScrollView
            bounces={false}
            showsVerticalScrollIndicator={true}
            persistentScrollbar={true}
          >
            {this.state.keyword.trim() != "" ? (
              <View style={styles.container}>
                <View>
                  {this.state.searchResult.averagePrice ? (
                    <View style={[styles.tabContainer]}>
                      {this.state.searchTags !== "" && (
                        <>
                          <View style={styles.tabNav}>
                            <TouchableOpacity
                              style={[
                                styles.tabNavItem,
                                styles.tabNavItemfull,
                                this.state.selectedTab == 1
                                  ? styles.tabNavItemselected
                                  : null,
                              ]}
                              onPress={() => this.onTabChange(1)}
                            >
                              <Text
                                style={[
                                  styles.tabNavItemText,
                                  this.state.selectedTab == 1
                                    ? styles.selected
                                    : null,
                                ]}
                              >
                                Low
                              </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.tabNavItem,
                                styles.tabNavItemfull,
                                this.state.selectedTab == 2
                                  ? styles.tabNavItemselected
                                  : null,
                              ]}
                              onPress={() => this.onTabChange(2)}
                            >
                              <Text
                                style={[
                                  styles.tabNavItemText,
                                  this.state.selectedTab == 2
                                    ? styles.selected
                                    : null,
                                ]}
                              >
                                Average
                              </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                              style={[
                                styles.tabNavItem,
                                styles.tabNavItemfull,
                                this.state.selectedTab == 3
                                  ? styles.tabNavItemselected
                                  : null,
                              ]}
                              onPress={() => this.onTabChange(3)}
                            >
                              <Text
                                style={[
                                  styles.tabNavItemText,
                                  this.state.selectedTab == 3
                                    ? styles.selected
                                    : null,
                                ]}
                              >
                                High
                              </Text>
                            </TouchableOpacity>
                          </View>
                          {this.renderPriceTab(
                            this.state.selectedTab,
                            this.convertPrice,
                            this.state.pricesLoading
                          )}
                        </>
                      )}

                      {this.renderTags("Add tags to refine your search")}
                      <View style={styles.allPricesSection}>
                        <Text style={styles.allPriceHeading}>
                          All prices shown are from the last{" "}
                          {this.state.userDetail.search_months} months
                        </Text>
                        <View style={styles.flexRow}>
                          <TouchableOpacity
                            style={styles.locationContent}
                            onPress={() => {
                              this.props.navigation.navigate(
                                "SearchLocationScreen",
                                {
                                  getUserDetails: this.getUserDetails,
                                  isFromSearch: true,
                                  searchKeyword: this.searchKeyword,
                                  keyword: this.state.keyword,
                                }
                              );
                            }}
                          >
                            <Image
                              source={Images.location}
                              style={styles.location}
                            />
                            <Text style={styles.locationText}>
                              {this.state.userDetail.search_location}
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={styles.flexRow}>
                          <TouchableOpacity
                            style={styles.entriesRow}
                            onPress={() =>
                              this.props.navigation.navigate(
                                "EntryListingScreen",
                                {
                                  category: this.state.keyword,
                                  tags: this.state.searchTags,
                                  lat: this.state.userDetail.search_latitude,
                                  lng: this.state.userDetail.search_longitude,
                                  search_months:
                                    this.state.userDetail.search_months,
                                  distance:
                                    this.state.userDetail.search_radius * 1000,
                                }
                              )
                            }
                          >
                            <Image
                              source={Images.userEntries}
                              style={styles.location}
                            />
                            <Text style={styles.locationText}>
                              View all price entries ({this.state.entryCount})
                            </Text>
                          </TouchableOpacity>
                        </View>
                        {this.state.searchResult.averagePrice && (
                          <Pressable
                            style={[styles.button, styles.fixedAddPrice]}
                            onPress={() => {
                              if (this.state.searchTags !== "") {
                                this.onPressNavigateToEnterPrice();
                              } else {
                                alert(
                                  "Please select a tag before adding a price!"
                                );
                              }
                            }}
                          >
                            <Text style={styles.buttonText}>
                              Tell us what you paid
                            </Text>
                            <Image
                              source={Images.arrowRight}
                              style={styles.arrow}
                            />
                          </Pressable>
                        )}
                      </View>
                    </View>
                  ) : (
                    <View>
                      <View style={styles.noResultFoundContainer}>
                        <Text style={styles.noResultText}>
                          There are no prices matching your search!
                        </Text>
                        <View>
                          <Pressable
                            style={[styles.button]}
                            onPress={() => this.onPressNavigateToEnterPrice()}
                          >
                            <Text style={styles.buttonText}>Add a price</Text>
                            <Image
                              source={Images.arrowRight}
                              style={styles.arrow}
                            />
                          </Pressable>
                        </View>
                      </View>
                      {this.renderTags("Refine selected tags", true)}
                    </View>
                  )}
                </View>
              </View>
            ) : (
              <View style={styles.content}>{this.renderEmptyComponent()}</View>
            )}
          </ScrollView>
          <PopupDialogComponent
            visible={this.state.popUpVisible}
            message={this.state.popupDialogMessage}
            isRemoveType={this.state.isFavRemovedVisible}
          />
        </SafeAreaView>
      </ImageBackground>
    );
  }

  renderPriceTab(selectedTab, convert, isLoading) {
    var obj = 0;
    switch (selectedTab) {
      case 1:
        obj = this.state.searchResult.minPrice;
        convert(
          obj,
          this.state.userDetail.search_country.currency_code,
          this.state.userDetail.country.currency_code
        );
        break;
      case 2:
        obj = this.state.searchResult.averagePrice;
        convert(
          obj,
          this.state.userDetail.search_country.currency_code,
          this.state.userDetail.country.currency_code
        );
        break;
      case 3:
        obj = this.state.searchResult.maxPrice;
        convert(
          obj,
          this.state.userDetail.search_country.currency_code,
          this.state.userDetail.country.currency_code
        );
        break;
      default:
        obj = 0;
        break;
    }

    return (
      <View style={styles.tabContent}>
        <View style={styles.ListingPriceRow}>
          <Text style={styles.largeSizeFont}>
            {this.state.searchResult.currency_code}
            {obj.toFixed(2)}
          </Text>
        </View>
        <View style={styles.homeListingPrice}>
          <Text style={styles.mediumSizeFont}>
            {isLoading ? (
              <View style={{ flex: 1, minHeight: 52 }}>
                <ActivityIndicator color={"#FFF"} size={"small"} />
              </View>
            ) : (
              <>
                {this.state.userDetail.country.currency_code}
                {this.state.userDetail.country.currency_symbol}
                {this.state.homePrice}
              </>
            )}
          </Text>
        </View>
      </View>
    );
  }

  renderTags = (message, empty = false) => {
    const _this = this;

    if (_this.state.arrTags.length > 0) {
      return (
        <View style={styles.tagsParent}>
          <View style={styles.tagsContainer}>
            <Text style={styles.heading5}>{message}</Text>
            <View style={[styles.flexRow, styles.tagFilterRow]}>
              <TextInput
                placeholder="Search for a tag"
                style={styles.filterTagInput}
                placeholderTextColor={"white"}
                onChangeText={(data) => this.filterSearchTags(data)}
                value={this.state.filterValue}
              />
              <TouchableOpacity>
                <RNPickerSelect
                  placeholder={{
                    label: "Default",
                    value: "Default",
                    fontSize: (Metrics.screenWidth * 24) / 414,
                    fontFamily: "semiBold",
                  }}
                  value={this.state.tagSort}
                  items={this.state.tagSortArr}
                  onValueChange={(value, index) => this.sortSearchTags(value)}
                  style={{ ...pickerSelectStyles }}
                  useNativeAndroidPickerStyle={false} //android only
                  hideIcon={false}
                  Icon={() => (
                    <Image
                      source={Images.filterIcon}
                      style={styles.filterIcon}
                    />
                  )}
                />
              </TouchableOpacity>
            </View>
            <View style={styles.seachTags}>
              {this.state.filteredArrTags.map(function (item) {
                let isActive = false;
                isActive =
                  _this.state.searchTags
                    .toLowerCase()
                    .split(",")
                    .indexOf(item.toLowerCase()) > -1
                    ? true
                    : false;
                return (
                  <Pressable
                    onPress={() => _this.selectSearchTag(item)}
                    style={[
                      styles.singleTag,
                      isActive ? styles.activeSingleTag : "",
                      empty ? styles.tagEmpty : "",
                    ]}
                  >
                    <Text
                      style={[
                        styles.singleTagText,
                        isActive ? styles.activeSingleTagText : "",
                        empty ? styles.tagEmpty : "",
                      ]}
                    >
                      {item}
                    </Text>
                  </Pressable>
                );
                // } else {
                //     return null
                // }
              })}
            </View>
            {
                this.state.filteredArrTags.length === 0 && this.state.filterValue.length > 0 && (
                  <Text style={[styles.heading5, {textAlign: "center", fontSize: 16}]}>This tag does not exist. Please add a price below to be the first to add this tag.</Text>
                )
              }
          </View>
        </View>
      );
    } else {
      return null;
    }
  };

  renderEmptyComponent = () => {
    return this.state.loading ? null : (
      <View style={styles.notFound}>
        <Image source={Images.search} style={styles.notFoundIcon} />
        <Text style={styles.notFoundTitle}>Search Tengah</Text>
        <Text style={styles.notFoundText}>
          Find the average price for anything
        </Text>
      </View>
    );
  };

  renderItemRecent = (item, index) => {
    return (
      <TouchableOpacity
        key={index + ""}
        style={styles.listItem}
        onPress={() =>
          Helper.moveToListScreen(
            this.props.navigation,
            item.cat_id,
            item.tag_ids,
            this.searchKeyword,
            this.state.keyword
          )
        }
      >
        <View style={styles.listBody}>
          <Text style={styles.smallText}>{item.category}</Text>
          <Text style={styles.listItemBigText}>{item.title}</Text>
          {/* <Text style={styles.normalText}>{item.other_tags}</Text> */}
        </View>
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entries_count}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  renderItemPopular = (item, index) => {
    return (
      <TouchableOpacity
        key={index + ""}
        style={styles.listItem}
        onPress={() =>
          Helper.moveToListScreen(
            this.props.navigation,
            item.cat_id,
            item.tag_ids
          )
        }
      >
        <View style={styles.listBody}>
          <Text style={styles.smallText}>{item.category}</Text>
          <Text style={styles.listItemBigText}>{item.main_tag}</Text>
          <Text style={styles.normalText}>{item.other_tags}</Text>
        </View>
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entries_count}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };
}

export default SearchScreen;

const pickerSelectStyles = StyleSheet.create({
  inputIOSContainer: {
    position: "relative",
    marginLeft: 28,
    marginBottom: 25,
  },
  placeholder: {
    color: "transparent",
  },
  inputIOS: {
    position: "absolute",
    backgroundColor: Colors.transparent,
    color: "transparent",
  },
  inputAndroidContainer: {
    position: "relative",
    marginLeft: 28,
    marginBottom: 25,
  },
  inputAndroid: {
    position: "absolute",
    backgroundColor: Colors.transparent,
    color: "transparent",
  },
});
