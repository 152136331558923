import React from "react";
import {
  Image,
  ImageBackground,
  Modal,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  ScrollView,
} from "react-native";
import { SafeAreaView } from "react-native";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import { Images } from "../Themes";
import styles from "./Styles/EntryDetailsScreenStyles";
// import MapView, { Marker, Circle } from "react-native-maps";
import * as Helper from "../Lib/Helper";
import MyMap from "../Components/myMap";

class EntryDetailsScreen extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.navigation.state.params;
    this.state = {
      entryId: param && param.entryId ? param.entryId : "",
      imageZoom: false,
      loading: false,
      entryDetail: "",
      radius: 50, //meters
      currentItem: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.props.getEntryDetail(this.state.entryId, this.props.navigation);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.entryReducer != this.props.entryReducer) {
      if (
        prevProps.entryReducer.detailLoading !=
        this.props.entryReducer.detailLoading
      ) {
        this.setState({ loading: this.props.entryReducer.detailLoading });
      }
      if (
        prevProps.entryReducer.entryDetail !=
        this.props.entryReducer.entryDetail
      ) {
        this.setState({ entryDetail: this.props.entryReducer.entryDetail });
      }
    }
  }

  setImageZoomVisible = (visible, item) => {
    this.setState({ imageZoom: visible });
    if (item) {
      this.state.currentItem = item;
    }
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        {this.state.entryDetail != "" ? (
          <SafeAreaView style={styles.container}>
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <TouchableOpacity
                  style={styles.rightIcon}
                  onPress={() => this.handleBackButtonClick()}
                >
                  <Image source={Images.arrowLeft} style={styles.arrow} />
                </TouchableOpacity>
              </View>
              <View style={styles.headerBody}>
                <Text style={styles.headerTitle}>
                  {this.state.entryDetail.user_name}
                </Text>
              </View>
              <View style={styles.headerRight}>
                <TouchableOpacity activeOpacity={1} style={styles.rightIcon} />
              </View>
            </View>
            <ScrollView contentContainerStyle={styles.ScrollView}>
              <View style={styles.ListingTopHeader}>
                <View style={styles.ListingPriceRow}>
                  <Text style={styles.smallText}>
                    {this.state.entryDetail.search_currency}
                  </Text>
                  <Text style={styles.largeSizeFont}>
                    {this.state.entryDetail.search_price}
                  </Text>
                </View>
                {this.state.entryDetail.search_currency ==
                this.state.entryDetail.home_currency_code ? null : (
                  <View style={styles.ListingPriceRow}>
                    <Text style={styles.smallText}>
                      {this.state.entryDetail.home_currency_code}
                    </Text>
                    <Text style={styles.mediumSizeFont}>
                      {this.state.entryDetail.home_currency_symbol +
                        this.state.entryDetail.home_price}
                    </Text>
                  </View>
                )}
              </View>
              <View>
                {/* <Image source={Images.map} style={styles.mapImage} />
                            <TouchableOpacity style={styles.radiusCircle}></TouchableOpacity> */}
                <MyMap
                  entryDetail={this.state.entryDetail}
                  radius={this.state.radius}
                />
              </View>

              <View style={[styles.content, { paddingBottom: 50 }]}>
                <Text style={[styles.thirtheenStyleLight, styles.italic]}>
                  {this.state.entryDetail.created_at}
                </Text>
                <Text style={[styles.pre]}>
                  {this.state.entryDetail.description}
                </Text>

                <FlatList
                  data={this.state.entryDetail.media}
                  horizontal
                  keyExtractor={(item, index) => index + ""}
                  extraData={this.state}
                  renderItem={({ item, index }) => {
                    console.log("Render image item", item);
                    return (
                      <TouchableOpacity
                        style={styles.imagePreview}
                        onPress={() => this.setImageZoomVisible(true, item)}
                      >
                        <Image
                          source={
                            item.url
                              ? { uri: item.url }
                              : Images.placeholderImage
                          }
                          style={styles.uploadedImage}
                        />
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            </ScrollView>
          </SafeAreaView>
        ) : null}
        <Modal
          animationType="fade"
          transparent={true}
          visible={this.state.imageZoom}
          onRequestClose={() => this.setImageZoomVisible(false)}
        >
          <SafeAreaView style={styles.imageZoomModal}>
            <View style={styles.header}>
              <View style={styles.headerLeft}>
                <TouchableOpacity
                  style={styles.rightIcon}
                  onPress={() => {
                    this.setImageZoomVisible(false);
                  }}
                >
                  <Image source={Images.plus} style={styles.Modalclose} />
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.fullImage}>
              <Image
                source={
                  this.state.currentItem.url
                    ? { uri: this.state.currentItem.url }
                    : Images.placeholderImage
                }
                style={styles.zoomImage}
              />
            </View>
          </SafeAreaView>
        </Modal>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}


export default EntryDetailsScreen;
