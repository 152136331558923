import moment from "moment";
import React, { Component } from "react";
import {
  Image,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import Icon from "react-native-vector-icons/FontAwesome";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import Api from "../Services/Api";
import { Images, Metrics } from "../Themes";
import styles from "./Styles/LaunchScreenStyles";


/* auth0 imports */
import { authConfig } from "../constants/authSettings";
import * as AuthSession from "expo-auth-session";
import toQueryString from "to-querystring";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StorageOperation } from "../storage/asyncStorage";

const mApi = Api.create();

const redirectUrl = AuthSession.makeRedirectUri({ useProxy: false });
const goToAuth0 = async (hint) => {
  console.log("redirect url", redirectUrl);
  let authUrl =
    `${authConfig.AUTHDOMAIN}/authorize?` +
    toQueryString({
      client_id: authConfig.CLIENTID,
      response_type: "token code",
      scope:
        "openid profile email client_credentials offline_access read:current_user update:users update:current_user_identities create:current_user_metadata update:current_user_metadata delete:current_user_metadata create:current_user_device_credentials delete:current_user_device_credentials",
      redirect_uri: redirectUrl,
      //redirect_uri: redirectUrl,
      audience: authConfig.AUDIENCE,
      prompt: "login",
      screen_hint: hint,
    });

  console.log("authurl: ", authUrl);

  const result = await AuthSession.startAsync({ authUrl: authUrl });
  if (result.params) {
    const response = result.params;
    console.log("result", result);
    if (!result.errorCode) {
      await AsyncStorage.setItem("token", result.params.access_token);
      await AsyncStorage.setItem("code", result.params.code);
      let codeResult = await getRefreshToken();
      if (codeResult) {
        console.log(response.access_token);
        getUser(response.access_token);
      } else {
        setLoading(false);
      }
    }
  } else {
    console.log("Failed Login");
    console.log(result);
  }
};

class LaunchScreen extends Component {
  // static navigationOptions = ({ navigation }) => ({
  //     header: null,
  // });

  constructor(props) {
    super(props);
    this.state = { loading: false };
  }
  render() {
    return (
      <ImageBackground
        source={Images.background}
        style={styles.ImageBackground}
      >
        <LinearGradient
          colors={[
            "rgba(26,136,201,0.3)",
            "rgba(62,168,206,0.3)",
            "rgba(101,200,202,0.3)",
            "rgba(107,200,190,0.3)",
            "rgba(116,200,175,0.3)",
          ]}
          locations={[0, 27.23, 49.76, 75.6, 100]}
          style={styles.LinearGradient}
        >
          <SafeAreaView style={styles.mainContainer}>
            <View style={styles.content}>
              <View style={[styles.topLaunch, styles.SpaceLaunch]}>
                <Image source={Images.logo} style={styles.logo} />
              </View>
              <View style={[styles.middleLaunch, styles.SpaceLaunch]}>
                <Text style={styles.h1}>Travel with Tengah</Text>
                <Text style={styles.h5}>
                  Tengah offers complete price transparency when it comes to
                  travelling. Find out what other travellers have paid, and
                  never get ripped off again!
                </Text>
              </View>
              <View style={[styles.bottomLaunch, styles.SpaceLaunch]}>
                {/*<TouchableOpacity
                  style={styles.socialButton}
                  onPress={() => this.onPressFacebook()}>
                  <Icon
                    name="facebook"
                    size={(Metrics.screenWidth * 26) / 414}
                    color="#fff"
                  />
                  <Text style={styles.socialButtonText}>
                    Login
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.socialButton}
                  onPress={() => this.onPressGoogle()}>
                  <Icon
                    name="google-plus"
                    size={(Metrics.screenWidth * 26) / 414}
                    color="#fff"
                  />
                  <Text style={styles.socialButtonText}>
                    Sign Up
                  </Text>
                </TouchableOpacity>
                {Platform.OS == 'ios' ? (
                  <TouchableOpacity
                    style={styles.socialButton}
                    onPress={() => this.onPressAppleLogin()}>
                    <Icon
                      name="apple"
                      size={(Metrics.screenWidth * 26) / 414}
                      color="#fff"
                    />
                    <Text style={styles.socialButtonText}>
                      Sign up with Apple
                    </Text>
                  </TouchableOpacity>
                ) : null} */}
                <TouchableOpacity
                  style={styles.socialButton}
                  onPress={() => this.goToAuth0("signup")}
                >
                  <Icon
                    name="envelope"
                    size={(Metrics.screenWidth * 26) / 414}
                    color="#fff"
                  />
                  <Text style={styles.socialButtonText}>
                    Sign up with email
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[styles.socialButton, styles.loginButton]}
                  onPress={() => this.goToAuth0("login")}
                >
                  <Text style={styles.socialButtonText}>Login</Text>
                </TouchableOpacity>
              </View>
            </View>
          </SafeAreaView>
        </LinearGradient>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }

  moveToSignUpScreen = (socialType) => {
    this.props.setSocialType(socialType);
    this.props.navigation.navigate("SignupScreen");
  };

  onPressEmail = () => {
    this.props.setSocialId("");
    this.props.setName("");
    this.props.setEmail("");
    this.props.setDOB("");
    this.props.setGender("");

    this.moveToSignUpScreen(Constants.SOCIAL_TYPES.NORMAL);
  };

  /**
   * Facebook login
   */
  onPressFacebook = () => {
    const _this = this;
    // Attempt a login using the Facebook login dialog asking for default permissions.
  };

  /**
   * Facebook complete
   */

  /**
   * Google
   */
  onPressGoogle = async () => {};

  checkIfAlreadyRegister = (socialData, socialType) => {
    // console.log('checkIfAlreadyRegister', JSON.stringify(socialData));

    if (socialData.email != "" || socialData.socialId != "") {
      this.setState({ loading: true });
      mApi
        .checkEmail(socialData.email, socialType, socialData.socialId)
        .then((response) => {
          if (response.ok) {
            if (response.data.success) {
              let responseData = response.data.data;
              const date = moment(responseData.dob).format(
                Constants.DATE_FORMAT_DMY
              );
              responseData.dob = date;
              console.log("responseData", responseData);
              this.socialLogin(socialData, socialType, responseData);
            } else {
              this.props.setSocialId(socialData.socialId);
              this.props.setName(socialData.name);
              this.props.setEmail(socialData.email);
              this.props.setDOB(socialData.dob);
              this.props.setGender(socialData.gender);

              this.moveToSignUpScreen(socialType);
            }
          } else {
            Helper.showAlert(response.problem);
          }
          this.setState({ loading: false });
        })
        .catch((err) => {
          console.log("error: ", err);
          this.setState({ loading: false });
        });
    }
  };

  socialLogin = (socialData, socialType, responseData) => {
    console.log(
      'socialData.dob && socialData.dob !="" ',
      socialData.dob != "" ? socialData.dob : responseData.dob
    );
    this.setState({ loading: true });

    // FirebaseService.getNotificationToken().then(token => {
    //   FirebaseService.hasNotificationPermission().then(hasPermission => {
    //     const userDetail = {
    //       name: socialData.name != '' ? socialData.name : responseData.name,
    //       email: (socialData.email != ''
    //         ? socialData.email
    //         : responseData.email),
    //       dob: socialData.dob != '' ? socialData.dob : responseData.dob,
    //       gender:
    //         socialData.gender != '' ? socialData.gender : responseData.gender,
    //       countryId: responseData.country_id,
    //       socialId: socialData.socialId,
    //       socialType: socialType,
    //       deviceToken: token,
    //       isAllowNotification: hasPermission ? 1 : 0,
    //     };
    //     console.log('Social signup',userDetail);
    //     mApi
    //       .socialSignUpLogin(userDetail)
    //       .then(response => {
    //         if (response.ok) {
    //           if (response.data.success) {
    //             try {
    //               StorageOperation.setData([
    //                 [Constants.KEY_IS_LOGIN, "1"],
    //                 [Constants.KEY_TOKEN, response.data.data.token],
    //               ]).then(() => {
    //                 this.props.setUser(response.data.data);
    //                 // this.toggleNotification();
    //                 Helper.resetNavigationToScreen(this.props.navigation, 'HomeStack');
    //               });
    //             } catch (error) {
    //               console.log('AsyncStorage error: ', error);
    //             }
    //           } else {
    //             Helper.showAlert(response.data.message);
    //           }
    //         } else {
    //           Helper.showAlert(response.problem);
    //         }

    //         this.setState({loading: false});
    //       })
    //       .catch(err => {
    //         console.log('error: ' + err);
    //         this.setState({loading: false});
    //       });
    //   });
    // });
  };
}

const toggleNotification = () => {
  console.log(">>>>> toggleNotification");
  // FirebaseService.getNotificationToken().then(token => {
  //   console.log('>>>>> toggleNotification',token);
  //   FirebaseService.hasNotificationPermission().then(hasPermission => {
  //     console.log('>>>>> toggleNotification',hasPermission);
  //     const params = {
  //       isAllow: hasPermission ? 1 : 0,
  //       fcmToken: token,
  //     };
  //     const apiCall = Api.createSecure();
  //     apiCall
  //       .toggleNotification(params)
  //       .then(response => {
  //         this.setState({loading: false}, () => {
  //           Helper.resetNavigationToScreen(this.props.navigation, 'HomeStack');
  //         });
  //       })
  //       .catch(err => {
  //         this.setState({loading: false}, () => {
  //           Helper.resetNavigationToScreen(this.props.navigation, 'HomeStack');
  //         });
  //       });
  //   });
  // });
};

export default LaunchScreen;
