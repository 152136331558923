import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors } from '../../Themes/'

export default StyleSheet.create({
    ...ApplicationStyles.screen,
    modalDialog: {
        flex: 1,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: Colors.white
    },
    close: {
        width: 12,
        height: 12,
        resizeMode: 'contain',
        tintColor: '#fff',
        right:10
    }
})
