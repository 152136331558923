import React, { Component } from "react";
import {
  Alert,
  Image,
  ImageBackground,
  Modal,
  Platform,
  Text,
  SafeAreaView,
  TouchableOpacity,
  View,
  Linking,
  ScrollView,
} from "react-native";

// import firebase services

import { CopilotStep, walkthroughable } from "react-native-copilot";
import { Images } from "../Themes";
import styles from "./Styles/FooterNavComponentsStyles";
import { SafeAreaInsetsContext } from "react-native-safe-area-context";

const WalkthroughableText = walkthroughable(Text);
const WalkthroughableImage = walkthroughable(Image);
const WalkthroughableTouchableOpacity = walkthroughable(TouchableOpacity);

class FooterNavComponents extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      addProductVisible: false,
      loading: false,
      pushNotificationsVisible: false,
      isHomeTooltipShown: null,
      activityUnreadCount: 0,
    };
  }

  componentDidMount() {
    // if (Platform.OS === 'ios') {
    // this.props.getActivityUnreadCount();
    // }
    // else{
    //   setTimeout(() => {
    //     this.props.getActivityUnreadCount();
    //   }, 3000);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props);
    if (prevState.isHomeTooltipShown != this.props.isHomeTooltipShown) {
      // console.log(this.props.isHomeTooltipShown);
      // this.setState({ isHomeTooltipShown: this.props.isHomeTooltipShown })
    }

    // if (prevProps.activityUnreadCount != this.props.activityUnreadCount) {
    //   this.setState({ activityUnreadCount: this.props.activityUnreadCount });
    // }
  }

  ShowHideComponent = () => {
    if (this.state.show == true) {
      this.setState({ show: false });
    } else {
      this.setState({ show: true });
    }
  };

  setaddProductVisible = (visible) => {
    this.setState({ addProductVisible: visible });
  };

  setpushNotificationsVisible = (visible) => {
    this.setState({ pushNotificationsVisible: visible });
  };

  toggleNotification() {
    return new Promise((resolve) => {
      // FirebaseService.getNotificationToken().then(token => {
      //   FirebaseService.hasNotificationPermission().then(hasPermission => {
      //     const params = {
      //       isAllow: hasPermission ? 1 : 0,
      //       fcmToken: token,
      //     };
      //     const apiCall = Api.createSecure();
      //     apiCall
      //       .toggleNotification(params)
      //       .then(response => {
      //         resolve(true);
      //       })
      //       .catch(err => {
      //         resolve(true);
      //       });
      //   });
      // });
    });
  }

  onNavigate = () => {
    this.setState({ pushNotificationsVisible: false }, () =>
      setTimeout(() => {
        this.props.property.navigation.navigate("ActivityScreen");
      }, 200)
    );
  };

  onPressActityTab = (routeName) => {
    if (this.props.property.navigation.state.routeName != "ActivityScreen") {
      // FirebaseService.hasNotificationPermission().then(hasPermission => {
      //   console.log('hasPermission >>> ', hasPermission);
      //   this.setState({
      //     pushNotificationsVisible: hasPermission == 1 ? false : true,
      //   });
      //   if (hasPermission == 1) {
      //     // this.setState({ activityUnreadCount: 0 });
      //     setTimeout(() => {
      //       this.props.property.navigation.navigate('ActivityScreen');
      //     }, 200);
      //   } else {
      //     this.toggleNotification();
      //   }
      // });
    }
  };

  onPressAllowNotification = async () => {
    // FirebaseService.hasNotificationPermission().then(hasPermission => {
    //   if (hasPermission == 1) {
    //     this.toggleNotification().then(() => {
    //       this.setState({
    //         pushNotificationsVisible: hasPermission == 1 ? false : true,
    //       });
    //       setTimeout(() => {
    //         this.props.property.navigation.navigate('ActivityScreen');
    //       }, 200);
    //     });
    //   } else {
    //     Linking.openSettings();
    //   }
    // });
  };

  // onScreenClick(screen) {
  //     this.props.AccountScreen(screen)
  // }

  _renderCopilot = (order, title, disc, tabIcon, routeName) => {
    const opacityStyle = {
      opacity:
        // this.props.property.navigation.state.routeName == routeName ? 1 : 0.5,
        1,
    };

    return (
      <CopilotStep order={order} name={title} text={disc}>
        <WalkthroughableTouchableOpacity
          style={[styles.tabStyle]}
          onPress={() => {
            routeName == "ActivityScreen"
              ? this.onPressActityTab(routeName)
              : this.props.property.navigation.navigate(routeName);
          }}
        >
          <View>
            <Image source={tabIcon} style={[styles.tabIcon, opacityStyle]} />
            {routeName == "ActivityScreen" &&
            this.props.activityUnreadCount > 0 ? (
              <View style={styles.badgeStyle} />
            ) : null}
          </View>
          <Text style={[styles.footerNavText, opacityStyle]}>{title}</Text>
        </WalkthroughableTouchableOpacity>
      </CopilotStep>
    );
  };

  renderWithCopilot = () => {
    return (
      <View style={styles.footerNav}>
        {this._renderCopilot(
          1,
          "Search",
          "Search for items, products and services by browsing through the catagories, or check out the popular searches in your area.",
          Images.searchTab,
          "HomeScreen"
        )}

        {this._renderCopilot(
          2,
          "Favorites",
          "Save all your favourite search items in one place and easily keep track of how much they cost from location to location.",
          Images.favouritesTab,
          "FavouritesScreen"
        )}

        <CopilotStep
          order={3}
          name="Sharing is Caring"
          text="Return the favor and add your travel prices to Tengah. "
        >
          <WalkthroughableTouchableOpacity
            style={styles.footerNavAdd}
            onPress={() =>
              this.props.property.navigation.navigate("AddPriceScreen")
            }
          >
            <Image source={Images.plusSquare} style={styles.Addplus} />
          </WalkthroughableTouchableOpacity>
        </CopilotStep>

        {this._renderCopilot(
          4,
          "Activity",
          "Keep up to date with the most recent price activity in your area.",
          Images.activityTab,
          "ActivityScreen"
        )}

        {/* {this._renderCopilot(
          5,
          'Account',
          'View and update your account settings, personal preferences and recent activity.',
          Images.userTab,
          'AccountScreen',
        )} */}
      </View>
    );
  };

  render() {
    return (
      <SafeAreaInsetsContext.Consumer>
        {(insets) => (
          <View
            style={[
              styles.SafeAreaViewFooter,
              { height: insets.bottom > 0 ? 50 + insets.bottom : 64 },
            ]}
          >
            {this.renderWithCopilot()}
            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.pushNotificationsVisible}
              onRequestClose={() => {
                // Alert.alert('Modal has been closed.');
              }}
            >
              <ImageBackground
                source={Images.grediantBackground}
                style={styles.ImageBackground}
              >
                <SafeAreaView style={styles.container}>
                  <View
                    style={[styles.header, { paddingLeft: 0, paddingRight: 0 }]}
                  >
                    <View style={styles.headerRight}>
                      <TouchableOpacity
                        style={styles.rightIcon}
                        onPress={() => {
                          this.setpushNotificationsVisible(
                            !this.state.pushNotificationsVisible
                          );
                        }}
                      >
                        <Image source={Images.plus} style={styles.Modalclose} />
                      </TouchableOpacity>
                    </View>
                    <View style={styles.headerBody}>
                      <Text style={styles.headerTitle}>Push Notifications</Text>
                    </View>
                    <View style={styles.headerRight}>
                      <TouchableOpacity
                        style={styles.rightIconWithText}
                        onPress={() => this.onNavigate()}
                      >
                        <Text style={styles.headerText}>Activity</Text>
                        <Image
                          source={Images.arrowRight}
                          style={styles.arrow}
                        />
                      </TouchableOpacity>
                    </View>
                  </View>
                  <ScrollView contentContainerStyle={styles.ScrollView}>
                    <View style={[styles.content]}>
                      <Image
                        source={Images.pushNotifications1}
                        style={styles.pushNotifications}
                      />
                      <Image
                        source={Images.pushNotifications2}
                        style={styles.pushNotifications}
                      />
                      <Image
                        source={Images.pushNotifications1}
                        style={[styles.pushNotifications, { marginBottom: 30 }]}
                      />
                      <Text style={styles.heading1}>
                        Allow us to send you notifications
                      </Text>
                      <Text style={styles.pre}>
                        We will send you notifications to alert you when you
                        when there are price changes in your area for products
                        you are interested in.
                      </Text>
                      <TouchableOpacity
                        style={[styles.button, styles.buttonLeft]}
                        onPress={() => this.onPressAllowNotification()}
                      >
                        <Text style={styles.buttonText}>
                          Allow Push Notifications
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </ScrollView>
                </SafeAreaView>
              </ImageBackground>
            </Modal>
          </View>
        )}
      </SafeAreaInsetsContext.Consumer>
    );
  }
}

export default FooterNavComponents;
