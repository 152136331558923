import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,
    rightIcon: {
        paddingHorizontal: 10,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    ListingTopHeader:{
        justifyContent:"center",
        alignItems:'center',
        padding:15,
    },
    ListingPriceArea:{
        backgroundColor:'rgba(255,255,255,0.2)',
        padding:15
    },
    largeSizeFont:{
        fontSize: (Metrics.screenWidth * 48) / 414,
        color: '#fff',
        textAlign: 'center',
        fontFamily: "semiBold",
        lineHeight:60
    },
    mediumSizeFont:{
        fontSize: (Metrics.screenWidth * 36) / 414,
        color: '#fff',
        textAlign: 'center',
        fontFamily: "base",
    },
    ListingPriceRow:{
        flexDirection:'row',
        alignItems:'flex-end',
        justifyContent:'center',
        paddingVertical:0
    },
    smallText: {
        fontSize: (Metrics.screenWidth * 12) / 414,
        fontFamily: "robotoLight",
        color: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: (Metrics.screenWidth * 15) / 414,
        marginHorizontal:10
    },
    italic: {
        fontFamily: "robotoLightItalic",
    },
    span: {
        fontSize: (Metrics.screenWidth * 13) / 414,
        fontFamily: "robotoLightItalic",
        color: '#fff',
        textAlign:'center'
    },
    ListingPriceFooter:{
        padding:20
    },
    priceEntriesBtn:{
        flexDirection:'row',
        alignItems:'center',
        height: (Metrics.screenWidth * 80) / 414,
        marginTop:40,
    },
    userEntriesImg:{
        width: (Metrics.screenWidth * 30) / 414,
        height: (Metrics.screenWidth * 30) / 414,
        tintColor: '#fff',
        resizeMode: 'contain'
    },
    userEntriesBody:{
        flex:1,
        paddingLeft:20,
        paddingRight:20
    },
    priceEntriesBtnText:{
        fontSize: (Metrics.screenWidth * 18) / 414,
        fontFamily: "robotoBlack",
        color: '#fff',
        marginVertical: 2
    },
    priceEntriesText: {
        fontSize: (Metrics.screenWidth * 16) / 414,
        fontFamily: "robotoBase",
        color: '#fff',
        marginVertical:2
    },
    ScrollView: {
        flexGrow: 1,
    },
    currencyIcon:{
        width: (Metrics.screenWidth * 25) / 414,
        height: 60,
        tintColor: '#fff',
        resizeMode: 'contain',
        justifyContent: 'center',
    },
})
