import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import {
  Image,
  ImageBackground,
  Modal,
  Share,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  SafeAreaView,
  ScrollView,
  Platform
} from "react-native";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import stepNumberComponent from "../Components/stepNumberComponent";
import TooltipComponents from "../Components/TooltipComponents";
import Constants from "../Lib/Constants";
import ActionCreators from "../Redux/actions";
import { Images } from "../Themes";
import styles from "./Styles/ListingScreenStyles";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import NoPriceModalComponent from "../Components/NoPriceModalComponent";
import { api } from "../../src/utils/api";
import * as Helper from "../Lib/Helper";
import { favouriteActions } from "../Redux/actions/Favourite";

const WalkthroughableTouchableOpacity = walkthroughable(TouchableOpacity);
const WalkthroughableView = walkthroughable(View);
const WalkthroughableImage = walkthroughable(Image);

var timerId = "";

class ListingScreen extends BaseComponent {
  state = {
    secondStepActive: true,
  };

  constructor(props) {
    super(props);
    const param = this.props.route.params;
    this.state = {
      catId: param && param.catId ? param.catId : "",
      tagIds: param && param.tagIds ? param.tagIds : "",
      isFromSearch: param && param.isFromSearch ? param.isFromSearch : false,
      isFromAddPrice:
        param && param.isFromAddPrice ? param.isFromAddPrice : false,
      showPriceAddedPopup:
        param && param.showPriceAddedPopup ? param.showPriceAddedPopup : false,
      isFromProductListing:
        param && param.isFromProductListing
          ? param.isFromProductListing
          : false,

      userDetail: {},
      selectedTab: 2,
      loading: false,
      favLoading: false,
      isFav: false,
      favId: null,

      priceDetails: this.props.route.params.item
        ? this.props.route.params.item
        : "",
      prevPriceDetails: null,
      priceEntriesVisible: false,
      entryListResponse: "",

      filterVisible: false,
      filterLoading: false,
      arrFilter: param && param.arrTags ? param.arrTags : [],
      arrSelectedFilterData: this.props.route.params.item
        ? this.props.route.params.item
        : [],
      selectedTagIds: "",
      filterKeyword: "",
      optionModal: false,
      isFilter: false,

      //popup
      popUpVisible: false,
      isFavRemovedVisible: false,
      popupDialogMessage: "",
      noPriceModalVisible: true,
      hideTellUsWhatYouPaid: Constants.hideTellUsWhatYouPaid,
    };
    this.setUserDetails = this.setUserDetails.bind(this);
    this.getListingDetails = this.getListingDetails.bind(this);
    this.retrieveNewResults = this.retrieveNewResults.bind(this);
    this.checkIsFav = this.checkIsFav.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    // this.props.getEntryPriceDetails(this.state.catId, this.state.tagIds, this.props.navigation);
    this.setUserDetails();
    console.log("list screen params: ", this.props.route.params);
    this.props.copilotEvents.on("stepChange", (currentStep) => {
      if (currentStep.order == 4) {
        try {
          AsyncStorage.setItem(Constants.PRICE_TOOLTIP, JSON.stringify(1));
        } catch (error) {
          console.log("AsyncStorage Error: ", error);
        }
      }
    });

    if (this.state.showPriceAddedPopup) {
      this.showPopup(true, Constants.PRICE_ADDED);
    }
    setTimeout(() => {
      Constants.hideTellUsWhatYouPaid = false;
    }, 3000);
  }

  componentWillUnmount() {
    // Don't forget to disable event handlers to prevent errors
    console.log("page left: ", true);
    this.props.copilotEvents.off("stepChange");
  }

  setUserDetails = async () => {
    let user = await this.props.route.params.getUserDetails();
    this.setState({
      userDetail: user,
    });
    this.checkIsFav(this.props.route.params.item.entryKey, user);
  };

  getListingDetails = async () => {
    console.log("Retrieve new results for this listing");
    this.retrieveNewResults();
  };

  checkIsFav = async (entryKey, user) => {
    console.log("entry key on click: ", entryKey);
    console.log("user on click: ", user);
    let favs = await favouriteActions.favouriteList(user);

    if (favs.status == 200) {
      console.log("favs: ", favs.data.data);
      let removeFav = true;

      for (let fi = 0; fi < favs.data.data.length; fi++) {
        const element = favs.data.data[fi];
        if (element.entryKey == entryKey) {
          removeFav = false;
          this.setState({
            isFav: true,
            favId: element.id,
          });
        }
      }

      console.log("remove fav:?", removeFav);

      if (removeFav) {
        this.setState({
          isFav: false,
          favId: null,
        });
      }
    }
  };

  retrieveNewResults = async () => {
    this.setState({
      loading: true,
    });
    console.log("Retrieve data again");
    const getUserDetails = await this.props.route.params.getUserDetails();
    const resp = await api("v1/api/request", "POST", {
      endpoint: "search",
      type: "POST",
      data: {
        latitude: getUserDetails.search_latitude,
        longitude: getUserDetails.search_longitude,
        distanceInMetres: getUserDetails.search_radius * 1000,
        kwd: this.state.priceDetails.entryKey,
      },
    });

    this.setState({
      userDetail: getUserDetails,
      priceDetails: resp.data.data[0],
      loading: false,
    });

    // if(resp.data.data.length == 0){
    //     Helper.updateSearchLocation(
    //         "This product does not have any pricing details. Update your search or location selected.",
    //         this.props.navigation,
    //         this.getListingDetails
    //     )
    // }
  };

  setOptionModalVisible = (visible) => {
    this.setState({ optionModal: visible });
  };

  startTooltip = async (isForcefullyShow = false) => {
    this.setState({ optionModal: false });
    const isPriceTooltipShown = await AsyncStorage.getItem(
      Constants.PRICE_TOOLTIP
    );

    if (isPriceTooltipShown != "1" || isForcefullyShow) {
      this.props.copilotEvents.on("stop", this.handleStepStop);
      if (Platform.OS == "ios") {
        this.props.start();
      } else {
        this.props.start(false, this._scrollView);
      }
    }
  };
  handleStepStop = (stop) => {
    AsyncStorage.setItem(Constants.PRICE_TOOLTIP, JSON.stringify(1));
    this.props.copilotEvents.off("stop");
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("screen updated: ", prevState.priceDetails);
    if (prevState.priceDetails) {
      if (this.state.prevPriceDetails == null) {
        this.setState({
          prevPriceDetails: prevState.priceDetails,
        });
      }
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.userDetail != this.props.userDetail) {
  //         this.setState({ userDetail: this.props.userDetail })
  //     }
  //     if (prevProps.entryReducer != this.props.entryReducer) {
  //         if (prevProps.entryReducer.entryPriceDetail != this.props.entryReducer.entryPriceDetail) {
  //             // let tagIds = this.props.entryReducer.entryPriceDetail.tag_ids
  //             this.setState({ priceDetails: this.props.entryReducer.entryPriceDetail }, () => {
  //                 this.startTooltip()
  //                 this.checkForRecentSearchAdd()
  //                 setTimeout(() => {
  //                     this.props.getEntryList(this.state.catId, this.state.tagIds, this.props.navigation)
  //                     if ((!prevState.arrTags || prevState.arrTags.length == 0) && prevState.arrSelectedFilterData.length == 0 ) {
  //                         this.props.getFilterList(this.state.catId)
  //                     }
  //                 }, 300);
  //             })
  //             if (this.state.isFilter) {
  //                 this.setState({ popUpVisible: true, popupDialogMessage: Constants.PRICE_FILTER }, () => {
  //                     setTimeout(() => {
  //                         this.setState({ popUpVisible: false, popupDialogMessage: '', isFilter: false })
  //                     }, Constants.TIME_FOR_POP_UPS);
  //                 });
  //             }
  //         }
  //         if (prevProps.entryReducer.priceLoading != this.props.entryReducer.priceLoading) {
  //             this.setState({ loading: this.props.entryReducer.priceLoading })
  //         }
  //         if (prevProps.entryReducer.entryList != this.props.entryReducer.entryList) {
  //             this.setState({ entryListResponse: this.props.entryReducer.entryList })
  //         }
  //     }

  //     if (prevProps.favouriteReducer != this.props.favouriteReducer) {
  //         if (prevProps.favouriteReducer.addLoading != this.props.favouriteReducer.addLoading) {
  //             this.setState({ favLoading: this.props.favouriteReducer.addLoading })
  //         }
  //         if (prevProps.favouriteReducer.removeLoading != this.props.favouriteReducer.removeLoading) {
  //             this.setState({ favLoading: this.props.favouriteReducer.removeLoading })
  //         }
  //         if (prevProps.favouriteReducer.is_favourite != this.props.favouriteReducer.is_favourite) {
  //             if (this.props.favouriteReducer.is_favourite) {
  //                 this.setState({ popUpVisible: true, popupDialogMessage: Constants.ADDED_FAV_POP_UP }, () => {
  //                     setTimeout(() => {
  //                         this.setState({ popUpVisible: false, popupDialogMessage: '' })
  //                     }, Constants.TIME_FOR_POP_UPS);
  //                 });
  //             }
  //         }
  //         if (prevProps.favouriteReducer.remove_favourite != this.props.favouriteReducer.remove_favourite) {
  //             if (this.props.favouriteReducer.remove_favourite) {
  //                 this.setState({ isFavRemovedVisible: true, popUpVisible: true, popupDialogMessage: Constants.REMOVED_FAV_POP_UP }, () => {
  //                     setTimeout(() => {
  //                         this.setState({ isFavRemovedVisible: false, popUpVisible: false, popupDialogMessage: '' })
  //                     }, Constants.TIME_FOR_POP_UPS);
  //                 });
  //             }
  //         }
  //     }

  //     if (prevProps.filterReducer != this.props.filterReducer) {
  //         if (prevProps.filterReducer.loading != this.props.filterReducer.loading) {
  //             this.setState({ filterLoading: this.props.filterReducer.loading })
  //         }
  //         if (prevProps.filterReducer.arrFilter != this.props.filterReducer.arrFilter) {
  //             // if (this.state.arrSelectedTags.length == 0) {
  //                 this.setState({ arrFilter: this.props.filterReducer.arrFilter })
  //             // }
  //         }
  //     }

  //     // if (prevProps.searchReducer != this.props.searchReducer) {
  //     //     if (prevProps.searchReducer.searchLoading != this.props.searchReducer.searchLoading) {
  //     //         this.setState({ filterLoading: this.props.searchReducer.searchLoading })
  //     //     }
  //     //     if (prevProps.searchReducer.searchResponse != this.props.searchReducer.searchResponse) {
  //     //         if (this.props.searchReducer.searchResponse != '' && prevProps.searchReducer.searchResponse.tags != this.props.searchReducer.searchResponse.tags) {
  //     //             const arrTags = this.props.searchReducer.searchResponse.tags
  //     //             if (arrTags.length == 0) {
  //     //                 this.setState({ arrFilter: [] })
  //     //             } else {
  //     //                 var arr = []
  //     //                 arrTags.map((element, index) => {
  //     //                     const item = {
  //     //                         "tag_id": element.tag_ids,
  //     //                         "tag_name": element.main_tag,
  //     //                         "tag_count": element.entries_count
  //     //                     }
  //     //                     arr.push(item)
  //     //                 })
  //     //                 this.setState({ arrFilter: arr })
  //     //             }
  //     //         }
  //     //     }
  //     // }
  //     if (prevProps.searchLocationReducer != this.props.searchLocationReducer) {
  //         if (prevProps.searchLocationReducer.isSearchLocationUpdated != this.props.searchLocationReducer.isSearchLocationUpdated) {
  //             if (this.props.searchLocationReducer.isSearchLocationUpdated) {
  //                 //call all apis
  //                 this.props.getEntryPriceDetails(this.state.catId, this.state.tagIds, this.props.navigation)
  //             }
  //         }
  //     }
  //     if (prevProps.categoryReducer != this.props.categoryReducer) {
  //         if (prevProps.categoryReducer.tagLoading != this.props.categoryReducer.tagLoading) {
  //             this.setState({ filterLoading: this.props.categoryReducer.tagLoading })
  //         }
  //         if (prevProps.categoryReducer.arrCategoryTag != this.props.categoryReducer.arrCategoryTag) {
  //             this.setState({
  //                 arrFilter: this.props.categoryReducer.arrCategoryTag,
  //             })
  //         }
  //     }
  // }

  checkForRecentSearchAdd = () => {
    //UPDATE
    if (this.state.isFromSearch) {
      const param = {
        title: this.state.priceDetails.main_tag,
        catId: this.state.catId,
        tagIds: this.state.tagIds,
        isCatSearch: this.state.priceDetails.is_cat_search,
      };
      this.props.addToRecentSearch(param);
      this.state.isFromSearch = false;
    }
  };

  onPressFavourite = async () => {
    if (this.state.priceDetails != "") {
      const priceDetail = this.state.priceDetails;
      if (this.state.isFav) {
        if (this.state.favId !== null) {
          let unfav = await ActionCreators.favouriteActions.favouriteRemove(
            this.state.favId
          );
          if (unfav.status == 200) {
            this.checkIsFav(priceDetail.entryKey, this.state.userDetail);
            this.setState(
              {
                popUpVisible: true,
                popupDialogMessage: Constants.REMOVED_FAV_POP_UP,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    popUpVisible: false,
                    popupDialogMessage: "",
                  });
                }, Constants.TIME_FOR_POP_UPS);
              }
            );
          }
        }
      } else {
        const param = {
          user_id: this.state.userDetail.id,
          categories: priceDetail.categories,
          tags: priceDetail.tags,
          entryKey: priceDetail.entryKey,
        };
        // this.props.addFavourite(param)
        let fav = await ActionCreators.favouriteActions.favouriteAdd(param);
        if (fav.status == 200) {
          this.checkIsFav(priceDetail.entryKey, this.state.userDetail);
          this.setState(
            {
              popUpVisible: true,
              popupDialogMessage: Constants.ADDED_FAV_POP_UP,
            },
            () => {
              setTimeout(() => {
                this.setState({ popUpVisible: false, popupDialogMessage: "" });
              }, Constants.TIME_FOR_POP_UPS);
            }
          );
        }
      }
    }
  };

  onPressNavigateToEnterPrice(isNoPrice = false) {
    if (isNoPrice) {
      console.log("No Price Nav");
      this.props.navigation.navigate("EnterPriceScreen", {
        isFromTellUs: true,
        priceDetail: this.state.prevPriceDetails,
        showPopup: this.showPopup.bind(this),
        getListingDetails: this.getListingDetails,
        searchKeyword: this.props.route.params.searchKeyword,
        keyword: this.props.route.params.keyword,
      });
    } else {
      console.log("this.state.priceDetails", this.state.priceDetails);
      // if (this.state.priceDetails && this.state.priceDetails.length > 0 ) {
      this.props.navigation.navigate("EnterPriceScreen", {
        isFromTellUs: true,
        priceDetail: this.state.priceDetails,
        showPopup: this.showPopup.bind(this),
        getListingDetails: this.getListingDetails,
        searchKeyword: this.props.route.params.searchKeyword,
        keyword: this.props.route.params.keyword,
      });
      // }
    }
  }

  renderPriceTab(selectedTab) {
    console.log(selectedTab);
    return null;
    // var obj = ''
    // switch (selectedTab) {
    //     case 1:
    //         obj = this.state.priceDetails.minPrice
    //         break;
    //     case 2:
    //         obj = this.state.priceDetails.averagePrice
    //         break;
    //     case 3:
    //         obj = this.state.priceDetails.maxPrice
    //         break;
    //     default:
    //         obj = ''
    //         break;
    // }
    // return (
    //     this.state.priceDetails == '' ?
    //         null :
    //         // <View style={styles.tabContent}>
    //         //     <View style={styles.ListingPriceRow}>
    //         //         <Image source={Images.location} style={styles.currencyIcon} />
    //         //         <Text style={styles.smallText}>{obj.search_currency}</Text>
    //         //         <Text style={styles.largeSizeFont}>{obj.search_symbol} {obj.search_price}</Text>
    //         //         <Text style={[styles.smallText, styles.italic]}>{this.state.priceDetails.unit_measure}</Text>
    //         //     </View>
    //         //     {obj.search_currency == obj.home_currency_code ? null :
    //         //         <View style={styles.ListingPriceRow}>
    //         //             <Image source={Images.home_currency} style={styles.currencyIcon} />
    //         //             <Text style={styles.smallText}>{obj.home_currency_code}</Text>
    //         //             <Text style={styles.mediumSizeFont}>{obj.home_currency_symbol} {obj.home_price}</Text>
    //         //         </View>
    //         //     }
    //         // </View>
    //         <View style={styles.tabContent}>
    //              <View style={styles.ListingPriceRow}>
    //                 <Text style={styles.largeSizeFont}>{this.state.priceDetails.currency_code}{obj.toFixed(2)}</Text>
    //             </View>
    //         </View>
    // )
  }

  renderPriceHeader() {
    // console.log('this.state.arrSelectedFilterData',this.state.arrSelectedFilterData);
    // if (!this.state.priceDetails) return(<Text style={[styles.smallText, { marginBottom: 0, marginTop: 5 }]}> </Text>)
    // if (this.state.arrSelectedFilterData.length > 0) {
    //     // console.log('if (this.state.arrSelectedFilterData.length > 0) {');
    //     return (this.renderDefaultTitle())
    // } else if (this.state.isFromProductListing && this.state.priceDetails.categoryArr) {
    //     // console.log('else if (this.state.isFromProductListing && this.state.priceDetails.categoryArr) {');
    //     const categoryArr = this.state.priceDetails.categoryArr
    //     return (
    //         <>
    //             <Text style={[styles.smallText, { marginBottom: 0 }]}>{categoryArr.length > 0 ? this.state.priceDetails.categoryArr[0]: this.state.priceDetails.category}</Text>
    //             <Text style={styles.nevHeading}>{categoryArr.length > 1 ? this.state.priceDetails.categoryArr[1]: ''}</Text>
    //         </>
    //     )
    // }
    // else if (this.state.priceDetails && this.state.priceDetails.category){
    //     try {
    //         const lastOccuranceIndex = this.state.priceDetails.category.lastIndexOf(" - ")
    //         const otherTags = this.state.priceDetails.category.substring(lastOccuranceIndex + 3);
    //         const category = this.state.priceDetails.category.substring(0, lastOccuranceIndex);
    //         return (
    //           <>
    //             <Text style={[styles.smallText, { marginBottom: 0 }]}>{category}</Text>
    //             <Text style={[styles.normalText, { marginBottom: 0, marginTop: 5 }]}>{this.state.priceDetails.main_tag}</Text>
    //           </>
    //         )
    //       } catch (error) {
    //         console.log('error', error);
    //         return (this.renderDefaultTitle())
    //       }
    // }
    // else{
    //     console.log('else if(this.state.priceDetails.other_tags){');
    //     return (this.renderDefaultTitle())
    // }
    return null;
  }

  renderDefaultTitle = () => {
    return (
      <>
        {this.state.priceDetails.categories ? (
          <Text style={[styles.smallText, { marginBottom: 0 }]}>
            {this.state.priceDetails.categories.split(",").join(", ")}
          </Text>
        ) : null}
        <Text style={styles.nevHeading}>
          {this.state.priceDetails.tags.split(",").join(", ")}
        </Text>
      </>
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          <LoadingComponent />
        </ImageBackground>
      );
    } else {
      return (
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          <SafeAreaView style={styles.container}>
            <View style={styles.header}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.handleBackButtonClick()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
              <View style={styles.headerRight}>
                <TouchableOpacity
                  style={styles.rightIcon}
                  onPress={() => this.onPressFavourite()}
                >
                  <CopilotStep
                    order={4}
                    name="Favorites"
                    text="Love this search and want to access it as quick as possible? Save it to your favorites!"
                  >
                    <WalkthroughableImage
                      source={
                        this.state.isFav == true
                          ? Images.favourites_filled
                          : Images.favourites
                      }
                      style={styles.headerIcon}
                    />
                  </CopilotStep>
                </TouchableOpacity>

                {/* <TouchableOpacity style={styles.rightIcon} onPress={() => this.setOptionModalVisible(true)}>
                                    <Image source={Images.more} style={styles.headerIcon} />
                                </TouchableOpacity> */}
              </View>
            </View>
            <ScrollView
              contentContainerStyle={[
                styles.ScrollView,
                { paddingBottom: 100 },
              ]}
              ref={(ref) => (this._scrollView = ref)}
            >
              {/* <CopilotStep
                                order={1}
                                name='Categories'
                                text='This list of words outline the product you are viewing prices for. The top two rows and categories adn the bottom are tags.'> */}
              <View style={styles.ListingTopHeader}>
                {this.renderPriceHeader()}
              </View>
              {/* </CopilotStep> */}
              <CopilotStep
                order={2}
                name="Average Price"
                text="Here you can view the average, low and high prices of your searched item. Prices are shown in both local currency and your home currency."
              >
                <WalkthroughableView
                  style={[styles.ListingPriceArea, { padding: 0 }]}
                >
                  <View style={styles.tabContainer}>
                    <View style={styles.tabNav}>
                      <TouchableOpacity
                        style={[
                          styles.tabNavItem,
                          styles.tabNavItemfull,
                          this.state.selectedTab == 1
                            ? styles.tabNavItemselected
                            : null,
                        ]}
                        onPress={() => this.onTabChange(1)}
                      >
                        <Text
                          style={[
                            styles.tabNavItemText,
                            this.state.selectedTab == 1
                              ? styles.selected
                              : null,
                          ]}
                        >
                          Low
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.tabNavItem,
                          styles.tabNavItemfull,
                          this.state.selectedTab == 2
                            ? styles.tabNavItemselected
                            : null,
                        ]}
                        onPress={() => this.onTabChange(2)}
                      >
                        <Text
                          style={[
                            styles.tabNavItemText,
                            this.state.selectedTab == 2
                              ? styles.selected
                              : null,
                          ]}
                        >
                          Average
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.tabNavItem,
                          styles.tabNavItemfull,
                          this.state.selectedTab == 3
                            ? styles.tabNavItemselected
                            : null,
                        ]}
                        onPress={() => this.onTabChange(3)}
                      >
                        <Text
                          style={[
                            styles.tabNavItemText,
                            this.state.selectedTab == 3
                              ? styles.selected
                              : null,
                          ]}
                        >
                          High
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {this.state.priceDetails !== undefined &&
                      this.renderPriceTab(this.state.selectedTab)}
                  </View>
                </WalkthroughableView>
              </CopilotStep>
              <View style={styles.ListingPriceFooter}>
                <Text style={styles.span}>
                  All prices shown are from the last{" "}
                  {this.state.userDetail.search_months} months
                </Text>
                <View style={styles.buttonGroup}>
                  <CopilotStep
                    order={3}
                    name="Filters"
                    text="Refine your search by adding or removing item specifics."
                  >
                    <WalkthroughableTouchableOpacity
                      style={styles.filterButton}
                      onPress={() => this.setfilterVisible(true)}
                    >
                      <Image
                        source={Images.filter}
                        style={styles.filterButtonIcon}
                      />
                      <Text style={styles.filterButtonText}>
                        {"FILTER".toUpperCase()}
                      </Text>
                    </WalkthroughableTouchableOpacity>
                  </CopilotStep>
                  <TouchableOpacity
                    style={styles.filterButton}
                    onPress={() => this.onPressShare()}
                  >
                    <Image
                      source={Images.share}
                      style={styles.filterButtonIcon}
                    />
                    <Text style={styles.filterButtonText}>
                      {"Share".toUpperCase()}
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  style={styles.locationContent}
                  onPress={() =>
                    this.props.navigation.navigate("SearchLocationScreen", {
                      isListingScreen: true,
                      getListingDetails: () => this.getListingDetails(),
                    })
                  }
                >
                  <Image source={Images.location} style={styles.location} />
                  <Text style={styles.locationText}>
                    {this.state.userDetail.search_location}
                  </Text>
                </TouchableOpacity>
                {this.state.priceDetails !== undefined && (
                  <CopilotStep
                    order={6}
                    name="Community"
                    text="See what other travelers think about their purchase and check out their snaps."
                  >
                    <WalkthroughableTouchableOpacity
                      style={styles.priceEntriesBtn}
                      onPress={() => this.setPriceEntriesVisible(true)}
                    >
                      <Image
                        source={Images.userEntries}
                        style={styles.userEntriesImg}
                      />
                      <View style={styles.userEntriesBody}>
                        <Text style={styles.priceEntriesBtnText}>
                          View price entries (
                          {this.state.priceDetails.entryCount})
                        </Text>
                        <Text style={styles.priceEntriesText}>
                          User entries with photos and comments
                        </Text>
                      </View>
                      <Image source={Images.arrowRight} style={styles.arrow} />
                    </WalkthroughableTouchableOpacity>
                  </CopilotStep>
                )}
              </View>
            </ScrollView>
            {/* {this.state.hideTellUsWhatYouPaid ? null : */}
            <CopilotStep
              order={5}
              name="Share"
              text="Did you purchase the same item? Let us know what you paid and help your fellow travellers."
            >
              <WalkthroughableTouchableOpacity
                style={[
                  styles.button,
                  {
                    alignSelf: "flex-end",
                    margin: 20,
                    marginBottom: 40,
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  },
                ]}
                onPress={() => this.onPressNavigateToEnterPrice()}
              >
                <Text style={styles.buttonText}>Tell us what you paid</Text>
                <Image source={Images.arrowRight} style={styles.arrow} />
              </WalkthroughableTouchableOpacity>
            </CopilotStep>
            {/* } */}
          </SafeAreaView>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.filterVisible}
            onRequestClose={() => this.setfilterVisible(false)}
          >
            <ImageBackground
              source={Images.grediantBackground}
              style={styles.ImageBackground}
            >
              <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                  <View style={styles.headerRight}>
                    <TouchableOpacity
                      style={styles.rightIcon}
                      onPress={() => this.setfilterVisible(false)}
                    >
                      <Image source={Images.plus} style={styles.Modalclose} />
                    </TouchableOpacity>
                  </View>
                  <View style={styles.headerBody}>
                    <Text style={styles.headerTitle}>Filter</Text>
                  </View>
                  <View style={styles.headerRight}>
                    <TouchableOpacity
                      style={styles.rightIconWithText}
                      onPress={() => {
                        console.log(
                          "arrSelectedFilterData: ",
                          this.state.arrSelectedFilterData
                        );
                        const tagIds = this.state.arrSelectedFilterData.map(
                          (value) => value.tag_id
                        );
                        this.setfilterVisible(false);
                        this.setState({ isFilter: true }, () =>
                          this.props.getEntryPriceDetails(
                            this.state.catId,
                            tagIds.join(","),
                            this.props.navigation
                          )
                        );
                      }}
                    >
                      <Text style={styles.headerText}>Done</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.searchBox}>
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="Search for a tag"
                    placeholderTextColor="rgba(255, 255, 255, 1)"
                    style={styles.searchFrm}
                    value={this.state.filterKeyword}
                    onChangeText={(filterKeyword) => {
                      this.setState({ filterKeyword }, () => {
                        clearTimeout(timerId);
                        timerId = setTimeout(
                          this.searchKeyword.bind(this),
                          Constants.SEARCH_TIMER
                        );
                      });
                    }}
                    returnKeyType="search"
                    onSubmitEditing={() => this.searchKeyword()}
                  />
                </View>
                <View styles={[styles.container, { paddingBottom: 100 }]}>
                  <ScrollView contentContainerStyle={styles.ScrollView}>
                    <FlatList
                      data={this.state.arrSelectedFilterData}
                      extraData={this.state}
                      key="selectedFilterData"
                      keyExtractor={(item, index) => index + ""}
                      renderItem={({ item, index }) => {
                        return (
                          <View
                            style={[
                              styles.listItem,
                              styles.listItemMarginHorizontal,
                            ]}
                          >
                            <View style={styles.listBody}>
                              <Text style={styles.listItemText}>
                                {item.tag_name}
                              </Text>
                            </View>
                            <TouchableOpacity
                              style={styles.listItemRight}
                              onPress={() => {
                                this.state.arrSelectedFilterData.splice(
                                  index,
                                  1
                                );
                                const tagIds =
                                  this.state.arrSelectedFilterData.map(
                                    (value) => value.tag_id
                                  );
                                this.setState(
                                  { selectedTagIds: tagIds.join(",") },
                                  () => {
                                    this.searchKeyword();
                                  }
                                );
                              }}
                            >
                              <Image
                                source={Images.addCircle}
                                style={[
                                  styles.listItemArrow,
                                  { transform: [{ rotate: "45deg" }] },
                                ]}
                              />
                            </TouchableOpacity>
                          </View>
                        );
                      }}
                    />
                    <View style={[styles.categoriesList]}>
                      <FlatList
                        data={this.state.arrFilter}
                        extraData={this.state}
                        key="filterList"
                        keyExtractor={(item, index) => index + ""}
                        renderItem={({ item, index }) => {
                          return (
                            <TouchableOpacity
                              style={[
                                styles.listItem,
                                styles.listItemMarginHorizontal,
                              ]}
                              onPress={() => {
                                this.state.arrSelectedFilterData.push(item);
                                const tagIds =
                                  this.state.arrSelectedFilterData.map(
                                    (value) => value.tag_id
                                  );
                                this.setState(
                                  { selectedTagIds: tagIds.join(",") },
                                  () => {
                                    this.searchKeyword();
                                  }
                                );
                              }}
                            >
                              <View style={styles.listBody}>
                                <Text style={styles.listItemText}>
                                  {item.tag_name}
                                </Text>
                              </View>
                              <View style={styles.listItemRight}>
                                <Text style={styles.countText}>
                                  {item.tag_count}
                                </Text>
                                <Image
                                  source={Images.arrowRight}
                                  style={styles.listItemArrow}
                                />
                              </View>
                            </TouchableOpacity>
                          );
                        }}
                      />
                    </View>
                  </ScrollView>
                </View>
              </SafeAreaView>
              {this.state.filterLoading ? <LoadingComponent /> : null}
            </ImageBackground>
          </Modal>

          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.priceEntriesVisible}
            onRequestClose={() => this.setPriceEntriesVisible(false)}
          >
            <ImageBackground
              source={Images.grediantBackground}
              style={styles.ImageBackground}
            >
              <SafeAreaView style={styles.container}>
                <View style={styles.header}>
                  <View style={styles.headerRight}>
                    <TouchableOpacity
                      style={styles.rightIcon}
                      onPress={() => this.setPriceEntriesVisible(false)}
                    >
                      <Image source={Images.plus} style={styles.Modalclose} />
                    </TouchableOpacity>
                  </View>
                  <View style={styles.headerBody}>
                    <Text style={styles.headerTitle}>
                      Price Entries (
                      {this.state.entryListResponse.entries_count})
                    </Text>
                  </View>
                  <View style={styles.headerRight}>
                    <TouchableOpacity
                      activeOpacity={1}
                      style={styles.rightIcon}
                    ></TouchableOpacity>
                  </View>
                </View>
                <View style={styles.content}>
                  <FlatList
                    data={this.state.entryListResponse.entries}
                    renderItem={({ item, index }) => {
                      return (
                        <TouchableOpacity
                          style={styles.listItem}
                          onPress={() => {
                            this.setPriceEntriesVisible(false);
                            this.props.navigation.navigate(
                              "EntryDetailsScreen",
                              { entryId: item.id }
                            );
                          }}
                        >
                          <View style={styles.listBody}>
                            <View style={styles.listBodyInline}>
                              <Text style={styles.listItemBigNormalText}>
                                {item.price}
                              </Text>
                              <Text style={[styles.thirtheenStyle, styles.sub]}>
                                {item.currency}
                              </Text>
                            </View>
                            <Text style={styles.normalText}>
                              {item.user_name}
                            </Text>
                          </View>
                          <View style={styles.listItemRight}>
                            {item.photo_cnt > 0 ? (
                              <View style={styles.listClickView}>
                                <Image
                                  source={Images.camera}
                                  style={styles.listClickIcon}
                                />
                              </View>
                            ) : null}
                            {item.description == "" ? null : (
                              <View style={styles.listClickView}>
                                <Image
                                  source={Images.comment}
                                  style={styles.listClickIcon}
                                />
                              </View>
                            )}
                            <Image
                              source={Images.arrowRight}
                              style={styles.listItemArrow}
                            />
                          </View>
                        </TouchableOpacity>
                      );
                    }}
                    keyExtractor={(item, index) => item.id + ""}
                    key={"entryList"}
                    showsVerticalScrollIndicator={false}
                    extraData={this.state}
                  />
                </View>
              </SafeAreaView>
            </ImageBackground>
          </Modal>

          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.optionModal}
            onRequestClose={() => this.setOptionModalVisible(false)}
          >
            <View style={styles.dropModal}>
              <TouchableOpacity
                style={styles.modalbodyClose}
                onPress={() => this.setOptionModalVisible(false)}
              ></TouchableOpacity>
              <SafeAreaView style={styles.dropdown}>
                <TouchableOpacity
                  style={styles.optionItem}
                  onPress={() => this.startTooltip(true)}
                >
                  <Text style={styles.optionText}>Intro Tooltips</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.optionItem}
                  onPress={() => this.setOptionModalVisible(false)}
                >
                  <Text style={[styles.optionText, styles.colorRed]}>
                    Cancel
                  </Text>
                </TouchableOpacity>
              </SafeAreaView>
            </View>
          </Modal>

          <PopupDialogComponent
            visible={this.state.popUpVisible}
            message={this.state.popupDialogMessage}
            isRemoveType={this.state.isFavRemovedVisible}
          />

          {!this.state.loading &&
          !this.state.popUpVisible &&
          !this.state.isFavRemovedVisible &&
          this.state.priceDetails == undefined ? (
            <NoPriceModalComponent
              visible={this.state.noPriceModalVisible}
              onPressGoBack={() =>
                this.setState({ noPriceModalVisible: false }, () => {
                  this.props.navigation.goBack();
                  this.props.navigation.goBack();
                })
              }
              onPressEnterPrice={() =>
                this.setState({ noPriceModalVisible: false }, () => {
                  this.props.navigation.goBack();
                  this.props.navigation.goBack();
                  this.onPressNavigateToEnterPrice(true);
                })
              }
            />
          ) : null}
          {this.state.loading || this.state.favLoading ? (
            <LoadingComponent />
          ) : null}
        </ImageBackground>
      );
    }
  }
  showPopup = (visible, popUpMessage) => {
    this.setState(
      { popUpVisible: visible, popupDialogMessage: popUpMessage },
      () =>
        setTimeout(() => {
          this.setState({ popUpVisible: false, popupDialogMessage: "" });
        }, Constants.TIME_FOR_POP_UPS)
    );
  };

  searchKeyword = () => {
    console.log("searchKeyword ------__>", this.state.arrSelectedFilterData);
    if (this.state.arrSelectedFilterData.length == 0) {
      this.props.getFilterList(this.state.catId, this.state.filterKeyword);
    } else {
      // this.props.search(this.state.filterKeyword)
      this.props.getCategoryTagsList(
        this.state.catId,
        this.state.selectedTagIds,
        this.state.filterKeyword,
        this.props.navigation
      );
    }
  };

  onTabChange = (selectedTab) => {
    this.setState({ selectedTab });
  };

  setfilterVisible = (visible) => {
    if (!visible) {
      // this.state.arrSelectedFilterData = []
      this.state.filterKeyword = "";

      this.props.getFilterList(this.state.catId);
    }
    this.setState({ filterVisible: visible });
  };
  setPriceEntriesVisible = (visible) => {
    this.setState({ priceEntriesVisible: visible });
  };

  onPressShare = async () => {
    var link =
      Constants.SHARE_DOMAIN + "detail/" + this.state.priceDetails.cat_id;
    if (this.state.tagIds) {
      link = link + "/" + this.state.tagIds;
    }

    const message =
      "Hey, check out the average price for " +
      this.state.priceDetails.tags.split(",").join(", ") +
      " on Tengah: " +
      link;

    Share.share({
      title: "Share Listing",
      message: message,
    });
  };
}

const ListObject = copilot({
  tooltipStyle: styles.tooltipStyle,
  animated: false,
  overlay: "svg",
  // svgMaskPath: circleSvgPath,
  byPassAlignment: true,
  marginTopExtra: Platform.OS === "ios" ? 0 : 8,
  tooltipComponent: TooltipComponents,
  stepNumberComponent: stepNumberComponent,
  backdropColor: "rgba(0,0,0,0.30)",
  verticalOffset: Platform.OS === "ios" ? 0 : 25,
})(ListingScreen);

Object.assign(ListObject, {
  navigationOptions: ListingScreen.navigationOptions,
});

export default ListObject;
