import * as Helper from "../../Lib/Helper";
import Api from "../../Services/Api";
import ActionTypes from "./types";
import NetInfo from "@react-native-community/netinfo";
import Constants from "../../Lib/Constants";
import { api } from "../../../src/utils/api";

const popularItemsActions = {
  getPopularItem,
};

// function getPopularItem(categoryId = '') {
//     return dispatch => {

//         if (Constants.isOnline) {
//             dispatch({
//                 type: ActionTypes.POPULAR_ITEMS_LOADING,
//                 loading: true,
//             })
//              const mApi = Api.createSecure()
//              mApi.popularItems(categoryId).then(response => {
//                 if (response.ok) {
//                     if (response.data.success) {
//                         console.log('Online Data for popular list:', response);
//                         if (categoryId == '') {
//                             dispatch({
//                                 type: ActionTypes.POPULAR_ITEMS,
//                                 arrPopular: response.data.data,
//                             })
//                         } else {
//                             dispatch({
//                                 type: ActionTypes.FILTER_POPULAR_ITEMS,
//                                 arrFilterPopular: response.data.data,
//                             })
//                         }

//                     } else {
//                         Helper.showAlert(response.data.message)
//                     }
//                 } else {
//                     Helper.showAlert(response.problem)
//                 }
//                 dispatch({
//                     type: ActionTypes.POPULAR_ITEMS_LOADING,
//                     loading: false,
//                 })
//             }).catch(error => {
//                 console.log('error:', error);
//                 dispatch({
//                     type: ActionTypes.POPULAR_ITEMS_LOADING,
//                     loading: false,
//                 })
//             })
//         } else {
//             OfflineHelper.getUserEntriesByDistance((arrEntryId) => {
//                 // console.log('offlineResponse arrEntryId --------->',arrEntryId);
//                 var query = 'select count(*) as entries_count, GROUP_CONCAT(tag_id) as tag_ids, entry_id from entry_tags where '
//                 var data = []
//                 if (categoryId != '') {
//                     query += 'exists (select * from entries where entry_tags.entry_id = entries.id and cat_id = '+ categoryId +') and '
//                 }
//                 query += 'entry_id in (' + arrEntryId + ') group by `entry_id` order by `entries_count` desc'

//                 // console.log('Offline Query for popular list: --------->',query);
//                 SQLHelper.performTransactionWithQuery(Constants.dbRefrence, query,(tags) => {
//                     // console.log('performTransactionWithQuery results --------->',tags);
//                     if (tags) {
//                         var index = 0;
//                         var eids = [];
//                         var etids = {};
//                         tags.forEach(tag => {
//                             eids.push(tag.entry_id)
//                             etids[tag.entry_id] = tag.tag_ids
//                         });
//                         // console.log('performTransactionWithQuery eids --------->',eids);
//                         // console.log('performTransactionWithQuery etids --------->',etids);

//                         var queryEntry = 'Select *, count(*) as entries_count from entries where id in ( '+ eids +') group by tag_ids, cat_id order by `entries_count` desc limit 20'
//                         SQLHelper.performTransactionWithQuery(Constants.dbRefrence, queryEntry,(entries) => {
//                             if(entries)
//                             {
//                                 // console.log('performTransactionWithQuery entries --------->', entries);
//                                 entries.forEach(entry => {
//                                     var dictData = {}
//                                     dictData['entry_id'] = entry.id;
//                                     dictData['cat_id'] = entry.cat_id;
//                                     dictData['entries_count'] = entry.entries_count;
//                                     dictData['tag_ids'] = entry.tag_ids;

//                                     if (entry.cat_id) {
//                                         OfflineHelper.getCategoryHierarchy(entry.cat_id,(category, categoryArr)=>{
//                                             dictData['category'] = category;
//                                             dictData['categoryArr'] = categoryArr;
//                                             data[index] = dictData
//                                         })
//                                     }
//                                     tagIdArr = entry.tag_ids.split(",");
//                                     // console.log('--------> Tag logic 1-------->',tagIdArr);
//                                     if (tagIdArr.length > 0) {
//                                         // console.log('--------> Tag logic 2--------> tagIdArr.length ',tagIdArr.length);
//                                         if (tagIdArr.length == 1) {
//                                             const condition = 'id = '+ tagIdArr[0]
//                                             // console.log('--------> Tag logic 3--------> tagIdArr[0] ',tagIdArr[0]);
//                                             SQLHelper.selectTable(Constants.dbRefrence,Constants.SQL_TABLE_NAMES.TAGS_4,condition,(mainTagData) => {
//                                                 // console.log('--------> Tag logic 4--------> mainTagData ',mainTagData);
//                                                 if (mainTagData) {
//                                                     dictData['main_tag'] = mainTagData[0].name;
//                                                     dictData['other_tags'] = "";
//                                                     data[index] = dictData
//                                                 }
//                                             })
//                                         }
//                                         else {
//                                             // console.log('--------> Tag logic 5--------> tagIdArr.length ',tagIdArr.length);
//                                             var main_tag = '';
//                                             var other_tagArr = [];
//                                             tagIdArr.forEach(tagId => {
//                                                 const condition = 'id = ' + tagId
//                                                 SQLHelper.selectTable(Constants.dbRefrence, Constants.SQL_TABLE_NAMES.TAGS_4, condition, (mainTagData) => {
//                                                     // console.log('--------> Tag logic 6--------> mainTagData ',mainTagData);
//                                                     if (etids[entry.id] && main_tag == '' && mainTagData)
//                                                     {
//                                                         if (etids[entry.id].split(",").includes(tagId)){
//                                                             main_tag = mainTagData[0].name;
//                                                             dictData['main_tag'] = main_tag;
//                                                             data[index] = dictData
//                                                             if (other_tagArr.length > main_tag) {
//                                                                 other_tagArr.splice(main_tag,1)
//                                                             }
//                                                         }
//                                                     }
//                                                     else{
//                                                         other_tagArr.push(mainTagData[0].name);
//                                                         // console.log('--------> Data for popular other_tagArr logic 7--------> other_tagArr ',other_tagArr);
//                                                         dictData['other_tags'] = other_tagArr.join(',');
//                                                         data[index] = dictData
//                                                     }
//                                                 })
//                                             });

//                                         }
//                                     }
//                                     else{
//                                         data[index] = dictData
//                                     }
//                                     data[index] = dictData
//                                     index++
//                                 });
//                                 // setTimeout(() => {
//                                     console.log('Offline Data for popular --------->',data);
//                                 // }, 3000);
//                                 if (categoryId == '') {
//                                     dispatch({
//                                         type: ActionTypes.POPULAR_ITEMS,
//                                         arrPopular: data,
//                                     })
//                                 } else {
//                                     dispatch({
//                                         type: ActionTypes.FILTER_POPULAR_ITEMS,
//                                         arrFilterPopular: data,
//                                     })
//                                 }
//                             }
//                         })
//                     }

//                 })
//             })
//             // alert('You are offline')
//         }
//     }
// }

async function getPopularItem(lat, lng, distance, search_months) {
  console.log("Parant get popular");
  // console.log(lat)
  // console.log(lng)
  // console.log(distance)
  // console.log(range)
  const popularItems = await api("v1/api/request", "POST", {
    endpoint: "popular",
    type: "POST",
    data: {
      latitude: lat,
      longitude: lng,
      distanceInMetres: distance,
      search_months: search_months,
    },
  });
  return popularItems.data.data;
}

export { popularItemsActions };
