import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Keyboard,
  SafeAreaView,
  FlatList,
  ScrollView,
} from "react-native";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import stepNumberComponent from "../Components/stepNumberComponent";
import NoEntriesComponent from "../Components/NoEntriesComponent";
import NoSearchResult from "../Components/NoSearchResult";
import TooltipComponents from "../Components/TooltipComponents";
import Constants from "../Lib/Constants";
import { Images } from "../Themes";
import styles from "./Styles/ProductScreenStyles";
import ActionCreators from "../Redux/actions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import LoadingComponent from "../Components/LoadingComponent";
import * as Helper from "../Lib/Helper";
import SearchComponent from "../Components/SearchComponent";

const WalkthroughableTouchableOpacity = walkthroughable(TouchableOpacity);

var timerId = "";

class ProductScreen extends BaseComponent {
  state = {
    secondStepActive: true,
  };

  constructor(props) {
    super(props);

    const param = this.props.route.params;

    this.state = {
      catId: "",
      isFromAddPrice:
        param && param.isFromAddPrice ? param.isFromAddPrice : false,
      isEditing: param && param.isEditing ? param.isEditing : false,
      selectedTab: 1,

      loading: false,
      popularloading: false,
      arrFilterPopular: [],
      arrFilterCategory: [],
      arrOriginalCategory: [],
      catName: "",
      searchText: "",
      arrTags: [],
      arrCategories: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getData();
    // this.props.copilotEvents.on('stepChange', (currentStep) => {
    //     if (currentStep.order == 1) {
    //         try {
    //             AsyncStorage.setItem(Constants.PRODUCT_TOOLTIP, "1")
    //         } catch (error) {
    //             console.log('AsyncStorage Error: ', error);
    //         }
    //     }
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.popularItemsReducer != this.props.popularItemsReducer) {
    //     if (prevProps.popularItemsReducer.loading != this.props.popularItemsReducer.loading) {
    //         this.setState({ popularloading: this.props.popularItemsReducer.loading })
    //     }
    //     if ((prevProps.popularItemsReducer.arrFilterPopular != this.props.popularItemsReducer.arrFilterPopular)) {
    //         this.setState({ arrFilterPopular: this.props.popularItemsReducer.arrFilterPopular })
    //     }
    // }
    // if (prevProps.categoryReducer != this.props.categoryReducer) {
    //     if (prevProps.categoryReducer.loading != this.props.categoryReducer.loading) {
    //         this.setState({ loading: this.props.categoryReducer.loading })
    //     }
    //     if (prevProps.categoryReducer.arrFilterCat != this.props.categoryReducer.arrFilterCat) {
    //         if (this.state.arrOriginalCategory.length == 0) {
    //             this.state.arrOriginalCategory = this.props.categoryReducer.arrFilterCat
    //         }
    //         this.setState({
    //             arrFilterCategory: this.props.categoryReducer.arrFilterCat,
    //             catName: this.props.categoryReducer.arrFilterCat.length > 0 ? this.props.categoryReducer.arrFilterCat[0].name : ''
    //         // }, () => this.startTooltip()) disbled tooltip as view price button is removed
    //         })
    //     }
    // }
    // if (prevProps.searchReducer != this.props.searchReducer) {
    //     if (prevProps.searchReducer.searchLoading != this.props.searchReducer.searchLoading) {
    //         this.setState({ loading: this.props.searchReducer.searchLoading })
    //     }
    //     if (prevProps.searchReducer.searchResponse != this.props.searchReducer.searchResponse) {
    //         if (this.props.searchReducer.searchResponse != '') {
    //             this.setState({
    //                 arrTags: this.props.searchReducer.searchResponse.tags,
    //                 arrCategories: this.props.searchReducer.searchResponse.categories
    //             })
    //         }
    //     }
    // }
  }

  componentWillUnmount() {
    // Don't forget to disable event handlers to prevent errors
    this.props.copilotEvents.off("stepChange");
  }

  startTooltip = async () => {
    const isProductTooltipShown = await AsyncStorage.getItem(
      Constants.PRODUCT_TOOLTIP
    );

    if (isProductTooltipShown != "1") {
      this.props.copilotEvents.on("stop", this.handleStepStop);
      setTimeout(() => {
        this.props.start();
      }, 300);
    }
  };

  handleStepStop = (stop) => {
    this.props.copilotEvents.off("stop");
  };

  getData = () => {
    // this.props.getCategoryList(this.state.catId,'',this.props.navigation)
    // this.props.getPopularItemList(this.state.catId)
  };

  onTabChange = (selectedTab) => {
    this.setState({ selectedTab });
  };

  searchKeyword = () => {
    // this.props.getCategoryList(this.state.catId, this.state.searchText,this.props.navigation)

    if (this.state.searchText.trim() == "") {
      // Helper.showAlert('Please enter product or category')
      return;
    }
    this.props.search(this.state.searchText, this.state.catId);
  };

  clearSearch = () => {
    Keyboard.dismiss();
    this.setState({
      searchText: "",
      arrFilterCategory: this.state.arrOriginalCategory,
    });
  };

  onPressCategoryItem = (item) => {
    const title = this.state.catName.trim() + Constants.DOT + item.name;
    if (this.state.isEditing) {
      this.props.setCategory(item.id, title, item.name, item.entries_count);
      this.props.navigation.navigate("EnterPriceScreen");
    } else {
      this.props.navigation.navigate("ProductListScreen", {
        catId: item.id,
        title: title,
        selectedName: item.name,
        isFromAddPrice: this.state.isFromAddPrice,
        entryCount: item.entries_count,
        isEditing: this.state.isEditing,
      });
    }
  };

  renderCategoryItem = (item, index) => {
    return (
      <TouchableOpacity
        style={styles.listItem}
        onPress={() => this.onPressCategoryItem(item)}
      >
        <View style={styles.listBody}>
          <Text style={styles.listItemText}>{item.name}</Text>
        </View>
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entries_count}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  renderPopularItemTitle = (item) => {
    if (item.other_tags) {
      return (
        <View style={styles.listBody}>
          <Text style={styles.smallText}>{item.category}</Text>
          <Text style={styles.listItemBigText}>{item.main_tag}</Text>
          <Text style={styles.normalText}>{item.other_tags}</Text>
        </View>
      );
    } else {
      const lastOccuranceIndex = item.category.lastIndexOf(" - ");
      const otherTags = item.category.substring(lastOccuranceIndex + 3);
      const category = item.category.substring(0, lastOccuranceIndex);
      return (
        <View style={styles.listBody}>
          <Text style={styles.smallText}>{category}</Text>
          <Text style={styles.listItemBigText}>{otherTags}</Text>
          <Text style={styles.normalText}>{item.main_tag}</Text>
        </View>
      );
    }
  };

  renderPopularItem = (item, index) => {
    return (
      <TouchableOpacity
        style={styles.listItem}
        onPress={() =>
          Helper.moveToListScreen(
            this.props.navigation,
            item.cat_id,
            item.tag_ids
          )
        }
      >
        {this.renderPopularItemTitle(item)}
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entries_count}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  renderEmptyComponent = (title) => {
    return this.state.loading ? null : this.state.searchText == "" ? (
      <NoEntriesComponent navigation={this.props.navigation} />
    ) : (
      <NoSearchResult
        containerStyle={{ paddingTop: 100 }}
        title={`No categories match your search!`}
        onPressClearSearch={() => this.clearSearch()}
      />
    );
  };

  renderCategory() {
    return (
      <FlatList
        data={
          this.state.arrFilterCategory.length > 0
            ? this.state.arrFilterCategory[0].child
            : []
        }
        extraData={this.state}
        keyExtractor={(item, index) => index + ""}
        renderItem={({ item, index }) => this.renderCategoryItem(item, index)}
        ListEmptyComponent={this.renderEmptyComponent}
        key={"category1"}
      />
    );
  }

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <TouchableOpacity
              style={styles.rightIcon}
              onPress={() => this.handleBackButtonClick()}
            >
              <Image source={Images.arrowLeft} style={styles.arrow} />
            </TouchableOpacity>
            <Text style={[styles.nevHeading, { paddingLeft: 0 }]}>
              {this.state.catName}
            </Text>
            <TouchableOpacity
              style={styles.rightIcon}
              onPress={() => console.log("Stop")}
            >
              {/* <Text style={styles.headerText}>Done</Text>
                            <Image source={Images.arrowRight} style={styles.arrow} /> */}
            </TouchableOpacity>
          </View>
          <View style={styles.search}>
            <View style={styles.searchInputContainer}>
              <Image source={Images.search} style={styles.searchIcon} />
              <TextInput
                underlineColorAndroid="transparent"
                placeholder={`Search ${this.state.catName.toLowerCase()} for a product or category`}
                placeholderTextColor="rgba(255, 255, 255, 1)"
                style={styles.searchInput}
                value={this.state.searchText}
                onChangeText={(searchText) => {
                  this.setState({ searchText }, () => {
                    clearTimeout(timerId);
                    // if (this.state.keyword.length >= 2) {
                    timerId = setTimeout(
                      this.searchKeyword.bind(this),
                      Constants.SEARCH_TIMER
                    );
                    // }
                  });
                }}
                returnKeyType="search"
                onSubmitEditing={() => this.searchKeyword()}
              />
            </View>
          </View>
          <ScrollView
            contentContainerStyle={[styles.ScrollView, { paddingBottom: 80 }]}
          >
            {this.state.searchText.trim() != "" ? (
              <View style={styles.content}>
                <SearchComponent
                  arrTags={this.state.arrTags}
                  arrCategories={this.state.arrCategories}
                  // onPressClearSearch={this.clearSearch}
                  navigation={this.props.navigation}
                />
              </View>
            ) : (
              <View style={styles.content}>
                {this.state.isEditing ? (
                  <View style={styles.tabContainer}>
                    {this.renderCategory()}
                  </View>
                ) : (
                  <View style={styles.tabContainer}>
                    <View style={styles.tabNav}>
                      <TouchableOpacity
                        style={[
                          styles.tabNavItem,
                          styles.tabNavItemfull,
                          this.state.selectedTab == 1
                            ? styles.tabNavItemselected
                            : null,
                        ]}
                        onPress={() => this.onTabChange(1)}
                      >
                        <Text
                          style={[
                            styles.tabNavItemText,
                            this.state.selectedTab == 1
                              ? styles.selected
                              : null,
                          ]}
                        >
                          Sub-Categories
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.tabNavItem,
                          styles.tabNavItemfull,
                          this.state.selectedTab == 2
                            ? styles.tabNavItemselected
                            : null,
                        ]}
                        onPress={() => this.onTabChange(2)}
                      >
                        <Text
                          style={[
                            styles.tabNavItemText,
                            this.state.selectedTab == 2
                              ? styles.selected
                              : null,
                          ]}
                        >
                          Popular
                        </Text>
                      </TouchableOpacity>
                    </View>
                    {this.state.selectedTab == 1 ? (
                      // Categories
                      this.renderCategory()
                    ) : (
                      // Popular
                      <FlatList
                        data={this.state.arrFilterPopular}
                        extraData={this.state}
                        keyExtractor={(item, index) => index + ""}
                        renderItem={({ item, index }) =>
                          this.renderPopularItem(item, index)
                        }
                        ListEmptyComponent={
                          this.state.loading ? null : (
                            <NoEntriesComponent
                              navigation={this.props.navigation}
                            />
                          )
                        }
                        key={"popular1"}
                      />
                    )}
                  </View>
                )}
              </View>
            )}
          </ScrollView>
          {/* {this.state.isFromAddPrice || this.state.loading?
                        null :
                        <CopilotStep
                            order={1}
                            name='View prices button'
                            text='Click the view prices button to view the average price of products and services at the current depth that you are searching at.'>
                            <WalkthroughableTouchableOpacity style={[styles.button, { position:'absolute' ,alignSelf: 'flex-end', margin: 20, marginBottom: 40, marginTop: 20, bottom: 0, right: 0 }]}
                                onPress={() => Helper.moveToListScreen(this.props.navigation, this.state.catId)}>
                                <Text style={styles.buttonText}>View prices</Text>
                                <Image source={Images.arrowRight} style={styles.arrow} />
                            </WalkthroughableTouchableOpacity>
                        </CopilotStep>
                    } */}
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}


const ProductObject = ProductScreen;

Object.assign(ProductObject, {
  navigationOptions: ProductScreen.navigationOptions,
});

export default ProductObject;
