import {Dimensions, StyleSheet} from 'react-native';
import {ApplicationStyles} from '../../../App/Themes';

export const styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
  },
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
    resizeMode: "cover"
  },
  mainContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  signup: {
    backgroundColor: "#fff",
    marginBottom: 44,
    height: 51,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    text: {
      fontSize: 16,
      color: "#000000",
    },
  },
  btnWapper: {
    width: '100%',
  },
  profile: {
    textAlign: 'center',
    color: "#faf6ef",
    fontSize: 14,
    marginBottom: 25,
  },
  mainContainer:{
    flex:1,
    paddingTop: 15,
    width: "100%",
    paddingLeft: Dimensions.get("window").width / 6,
    paddingRight: Dimensions.get("window").width / 6,
    paddingBottom: 50,
    flexGrow: 1,
  },
  content:{
      flex:1,
      padding:20,
      flexDirection:'column',
      justifyContent:'flex-start',
  },
  signupFooter:{
      paddingBottom:20
  },
  SignInButtons:{
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  }, 
  landingContent:{
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 30
  },
  SpaceLaunch:{
    marginBottom: 80
  },
  loginButton: {
    marginTop: 25
  }
});
