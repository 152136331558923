import React, { Component } from "react";
import {
  Modal,
  View,
  TouchableOpacity,
  Image,
  ImageBackground,
  ScrollView,
  SafeAreaView,
} from "react-native";
import styles from "./Styles/LinkPopUpComponentStyles";
import WebView from "react-native-webview";
import { Images } from "../Themes";

export default class LinkPopUpComponent extends Component {
  static defaultProps = {
    visible: false,
    url: "",
    onClose: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
      url: this.props.url,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.setState({
        visible: this.props.visible,
        url: this.props.url,
      });
    }
  }

  render() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.visible}
        onRequestClose={() => this.props.onClose()}
      >
        <ImageBackground
          source={Images.grediantBackground}
          style={styles.ImageBackground}
        >
          <SafeAreaView style={styles.container}>
            <View style={[styles.header, { justifyContent: "flex-end" }]}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.props.onClose()}
              >
                <Image source={Images.plus} style={styles.Modalclose} />
              </TouchableOpacity>
            </View>
            <ScrollView contentContainerStyle={styles.ScrollView}>
              <WebView
                source={{ uri: this.state.url }}
                style={{
                  backgroundColor: "transparent",
                  padding: 20,
                  width: "100%",
                  height: "100%",
                }}
              />
            </ScrollView>
          </SafeAreaView>
        </ImageBackground>
      </Modal>
    );
  }
}
