const types = {
  GET_LOGIN_SUCCESS: 'GET_LOGIN_SUCCESS',
  GET_ADD_ITEM_SUCCESS: 'GET_ADD_ITEM_SUCCESS',

  COUNTRIES: 'COUNTRIES',
  COUNTRIES_LOADING: 'COUNTRIES_LOADING',

  SEARCH_LOCATION: 'SEARCH_LOCATION',
  SEARCH_LOCATION_LOADING: 'SEARCH_LOCATION_LOADING',
  SEARCH_MONTH: 'SEARCH_MONTH',
  SEARCH_MONTH_LOADING: 'SEARCH_LOCATION_LOADING',

  CATEGORY_LIST: 'CATEGORY_LIST',
  CATEGORY_LOADING: 'CATEGORY_LOADING',
  FILTER_CATEGORY_LIST: 'FILTER_CATEGORY_LIST',
  CATEGORY_TAG_LIST: 'CATEGORY_TAG_LIST',
  CATEGORY_TAG_LOADING: 'CATEGORY_TAG_LOADING',
  ADD_TAG_LOADING: 'ADD_TAG_LOADING',
  ADDED_TAG: 'ADDED_TAG',
  OFFLINEDATA_DOWNLOADING: 'OFFLINEDATA_DOWNLOADING',
  OFFLINEDATA_RESPONSE: 'OFFLINEDATA_RESPONSE',
  OFFLINE_STATE: 'OFFLINE_STATE',

  POPULAR_ITEMS: 'POPULAR_ITEMS',
  POPULAR_ITEMS_LOADING: 'POPULAR_ITEMS_LOADING',
  FILTER_POPULAR_ITEMS: 'FILTER_POPULAR_ITEMS',

  ENTRY_PRICE_LOADING: 'ENTRY_PRICE_LOADING',
  ENTRY_PRICE_DETAIL: 'ENTRY_PRICE_DETAIL',
  ENTRY_LIST_LOADING: 'ENTRY_LIST_LOADING',
  ENTRY_LIST: 'ENTRY_LIST',
  ENTRY_DETAIL_LOADING: 'ENTRY_DETAIL_LOADING',
  ENTRY_DETAIL: 'ENTRY_DETAIL',
  ENTRY_ADD_LOADING: 'ENTRY_ADD_LOADING',
  ENTRY_ADD: 'ENTRY_ADD',
  MY_ENTRIES_LOADING: 'MY_ENTRIES_LOADING',
  MY_ENTRIES: 'MY_ENTRIES',

  SEARCH_LOADING: 'SEARCH_LOADING',
  SEARCH_RESPONSE: 'SEARCH_RESPONSE',
  RECENT_SEARCH_LOADING: 'RECENT_SEARCH_LOADING',
  RECENT_SEARCH_RESPONSE: 'RECENT_SEARCH_RESPONSE',

  FAVOURITE_ADD_LOADING: 'FAVOURITE_ADD_LOADING',
  FAVOURITE_REMOVE_LOADING: 'FAVOURITE_REMOVE_LOADING',
  FAVOURITE_ADD: 'FAVOURITE_ADD',
  FAVOURITE_REMOVE: 'FAVOURITE_REMOVE',
  FAVOURITE_LIST_LOADING: 'FAVOURITE_LIST_LOADING',
  FAVOURITE_LIST: 'FAVOURITE_LIST',

  FILTER_LOADING: 'FILTER_LOADING',
  FILTER_LIST: 'FILTER_LIST',

  ACTIVITY_LOADING: 'ACTIVITY_LOADING',
  ACTIVITY_LIST: 'ACTIVITY_LIST',
  ACTIVITY_UNREAD_COUNT: 'ACTIVITY_UNREAD_COUNT',
  TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',

  USER_DETAIL: 'USER_DETAIL',
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
  USER_DOB: 'USER_DOB',
  USER_GENDER: 'USER_GENDER',
  USER_COUNTRY: 'USER_COUNTRY',
  USER_PASSWORD: 'USER_PASSWORD',
  USER_SOCIAL_TYPE: 'USER_SOCIAL_TYPE',
  USER_SOCIAL_ID: 'USER_SOCIAL_ID',
  USER_DEVICE_TOKEN: 'USER_DEVICE_TOKEN',
  USER_UPDATE_OFFLINECATEGORY: 'USER_UPDATE_OFFLINECATEGORY',
  USER_UPDATE_OFFLINECATEGORY_LOADING: 'USER_UPDATE_OFFLINECATEGORY_LOADING',
  USER_OFFLINECATEGORY_UPDATED: 'USER_OFFLINECATEGORY_UPDATED',
  ENTRY_CATEGORY: 'ENTRY_CATEGORY',
  ENTRY_TAGS: 'ENTRY_TAGS',
  ENTRY_LOCATION: 'ENTRY_LOCATION',
};

export default types;
