import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'

export default StyleSheet.create({
    ...ApplicationStyles.screen,
    container: {
        flex: 1,
    },

    tooltipStyle:{
        backgroundColor: Colors.transparent,
        borderRadius: 0,
        paddingTop: 0,
        paddingLeft:0,
        paddingRight: 0,
        paddingBottom:0,
        marginBottom:Metrics.screenHeight / 5,
        right:0,
        left:0,        
        width:'90%',  
        marginLeft:'5%',
        marginRight:'5%',      
        alignSelf:'center'
    },
})
