import { StyleSheet, Platform } from "react-native";
import { Metrics, ApplicationStyles, Colors, Fonts } from "../../Themes/";

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  absoluteView: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the last value (0.5) for transparency
    justifyContent: "center",
    alignItems: "center",
    zIndex: 11111111111111,
  },
  removeImage: {
    width: 5,
    height: 5,
    padding: 10,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    position: "absolute",
    bottom: 0,
    right: -5,
    borderRadius: 100,
    zIndex: 11111111111
  },
  imageContainer:{
    position: "relative",
    overflow: "visible",
    marginRight: 10
  },
  imageAddSection: {
    justifyContent: "center"
  }
});
