import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { TouchableOpacity, View, Image } from "react-native";
import AppNavigation from "../../../App/Navigation/AppNavigation";
import HomePage from "../../../screens/home";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Images } from "../../../App/Themes";
import HomeStack from "../../../App/Navigation/homeStack";
import FavouriteStack from "../../../App/Navigation/favouriteStack";
import AddPriceStack from "../../../App/Navigation/addPriceStack";
import ActivityStack from "../../../App/Navigation/activityStack";
import AccountStack from "../../../App/Navigation/accountStack";
import FooterNavComponentsStyles from "../../../App/Components/Styles/FooterNavComponentsStyles";

const Tab = createBottomTabNavigator();

const AppTabNav = React.memo((props) => {
  const navigation = props.navigation;
  const params = props.route.params;
  const insets = useSafeAreaInsets();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: {
          backgroundColor: "#66BDB0",
          borderTopColor: "transparent",
        },
        tabBarActiveTintColor: "white",
        tabBarInactiveTintColor: "#FFFFFF80",
      }}
    >
      <Tab.Screen
        name="Search"
        options={{
          tabBarLabel: "Search",
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <Image
              style={[
                {
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                },
                !focused && { opacity: 0.5 },
              ]}
              source={Images.searchTab}
            />
          ),
          gestureEnabled: true,
        }}
        component={HomeStack}
      />
      <Tab.Screen
        name="Favourites"
        options={{
          tabBarLabel: "Favorites",
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <Image
              style={[
                {
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                },
                !focused && { opacity: 0.5 },
              ]}
              source={Images.favouritesTab}
            />
          ),
          gestureEnabled: true,
        }}
        component={FavouriteStack}
      />
      <Tab.Screen
        name="AddPrice"
        options={{
          tabBarLabel: "",
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <Image
              style={FooterNavComponentsStyles.Addplus}
              source={Images.plusSquare}
            />
          ),
          gestureEnabled: true,
        }}
        component={AddPriceStack}
      />
      <Tab.Screen
        name="Activity"
        options={{
          tabBarLabel: "Activity",
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <Image
              style={[
                {
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                },
                !focused && { opacity: 0.5 },
              ]}
              source={Images.activityTab}
            />
          ),
          gestureEnabled: true,
        }}
        component={ActivityStack}
      />
      <Tab.Screen
        name="Account"
        options={{
          tabBarLabel: "Account",
          headerShown: false,
          tabBarIcon: ({ color, size, focused }) => (
            <Image
              style={[
                {
                  width: 25,
                  height: 25,
                  resizeMode: "contain",
                },
                !focused && { opacity: 0.5 },
              ]}
              source={Images.userTab}
            />
          ),
          gestureEnabled: true,
        }}
        component={AccountStack}
      />
    </Tab.Navigator>
  );
  // }
});

export default AppTabNav;
