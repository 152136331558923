import React from 'react'
import { FlatList, Image, ImageBackground, SafeAreaView, ScrollView, Text, TextInput, TouchableOpacity, View, Keyboard } from 'react-native'
import { connect } from 'react-redux'
import BaseComponent from '../Components/BaseComponent'
import SearchComponent from "../Components/SearchComponent";
import * as Helper from '../Lib/Helper'
import { Images } from '../Themes'
import styles from './Styles/AddPriceScreenStyles'
import Constants from '../Lib/Constants'
import ActionCreators from '../Redux/actions'
import LoadingComponent from '../Components/LoadingComponent'
import { popularItemsActions } from '../Redux/actions/PopularItems';

var timerId = ''

class AddPriceScreen extends BaseComponent {

    constructor(props) {
        super(props)
        
        const param = this.props.route.params;

        this.state = {
            selectedTab: 1,
            isEditing: param && param.isEditing ? param.isEditing : false,
            arrCategory: [],
            arrPopular: [],
            loading: false,
            searchText: '',
            arrSearchTags: [],
            arrSearchCategories: []
        }
        this.getPopularItem = this.getPopularItem.bind(this);
    }

    componentDidMount(){
        // this.getPopularItem()
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.popularItemsReducer != this.props.popularItemsReducer) {
        //     if ((prevProps.popularItemsReducer.arrPopular != this.props.popularItemsReducer.arrPopular) || (this.state.arrPopular != this.props.popularItemsReducer.arrPopular)) {
        //         this.setState({ arrPopular: this.props.popularItemsReducer.arrPopular })
        //     }
        // }
        // if (prevProps.categoryReducer != this.props.categoryReducer) {

        //     if (prevProps.categoryReducer.loading != this.props.categoryReducer.loading) {
        //         this.setState({ loading: this.props.categoryReducer.loading })
        //     }
        //     if (this.state.searchText == '') {
        //         if ((prevProps.categoryReducer.arrCategory != this.props.categoryReducer.arrCategory) || (this.state.arrCategory != this.props.categoryReducer.arrCategory)) {
        //             this.setState({ arrCategory: this.props.categoryReducer.arrCategory })
        //         }
        //     } else {
        //         if ((prevProps.categoryReducer.arrFilterCat != this.props.categoryReducer.arrFilterCat) || (this.state.arrCategory != this.props.categoryReducer.arrFilterCat)) {
        //             this.setState({ arrCategory: this.props.categoryReducer.arrFilterCat, })
        //         }
        //     }
        // }

        // if (prevProps.searchReducer != this.props.searchReducer) {
        //     if (prevProps.searchReducer.recentLoading != this.props.searchReducer.recentLoading) {
        //         this.setState({ loading: this.props.searchReducer.recentLoading })
        //     }
        //     if (prevProps.searchReducer.arrRecentSearch != this.props.searchReducer.arrRecentSearch) {
        //         this.setState({ arrRecent: this.props.searchReducer.arrRecentSearch })
        //     }

        //     if (prevProps.searchReducer.searchLoading != this.props.searchReducer.searchLoading) {
        //         this.setState({ loading: this.props.searchReducer.searchLoading })
        //     }
        //     if (prevProps.searchReducer.searchResponse != this.props.searchReducer.searchResponse) {
        //         if (this.props.searchReducer.searchResponse != '') {
        //             this.setState({
        //                 arrSearchTags: this.props.searchReducer.searchResponse.tags,
        //                 arrSearchCategories: this.props.searchReducer.searchResponse.categories
        //             })

        //         }
        //     }
        // }
    }

    getPopularItem = async () => {
        console.log('Calling api getPopularItem');
        let popular = await popularItemsActions.getPopularItem();
        console.log("popular: ", popular);
        this.setState({
            arrPopular: popular,
            isAsyncTaskComplete: true
        })
    }

    setSelectedTab = (selectedTab) => {
        this.setState({ selectedTab })
    }

    searchKeyword = () => {
        // this.props.getCategoryList(this.state.searchText,this.props.navigation) Disabled For CR
        if (this.state.searchText.trim() == '') {
            return
        }
        this.props.search(this.state.searchText)
    }

    onPressSearchItem = ({ cat_id, tag_ids, category, main_tag, entries_count }) => {
        const priceDetail = {
            cat_id,
            tag_ids,
            category,
            main_tag,
            entries_count,
            parentName:''
        }
        // const priceDetail = {
        //     cat_id: this.state.catId,
        //     tag_ids: tagIds.join(','),
        //     category: this.state.title,
        //     main_tag: tagNames.join(Constants.DOT),
        //     parentName: this.state.parentName,
        //     entryCount: this.state.entryCount
        // }

        this.props.navigation.navigate('EnterPriceScreen', { priceDetail: priceDetail, showTagAddedPopup: false })
    }


    moveToEnterPriceScreen = (item) => {
        const tagIds = item.tag_ids
        const tagNames = item.main_tag
        const priceDetail = {
            cat_id: item.cat_id,
            tag_ids: tagIds,
            category: item.category,
            main_tag: tagNames
        }
        this.props.navigation.navigate('EnterPriceScreen', { priceDetail: priceDetail, showTagAddedPopup: false })
    }

    renderItemCategories = (item, index) => {
        return (
            <TouchableOpacity style={styles.gridItem} onPress={() => {
                Keyboard.dismiss()
                if (this.state.isEditing) {
                    this.props.navigation.push('ProductScreen', { catId: item.id, isFromAddPrice: true, isEditing: this.state.isEditing })
                }
                else{
                    this.props.navigation.navigate('ProductScreen', { catId: item.id, isFromAddPrice: true })
                }
            }}>
                <Text style={styles.gridItemText}>{item.name}</Text>
                <Image source={{ uri: item.image }} style={styles.gridItemIcon} />
            </TouchableOpacity>
        )
    }

    renderItemPopular = (item, index) => {
        console.log("Popular ittttttttttmmmmmmmmmmm ----->>>> ",item);
        return (
            <TouchableOpacity style={styles.listItem} onPress={() => this.moveToEnterPriceScreen(item)}>
                <View style={styles.listBody}>
                    <Text style={styles.smallText}>{item.category}</Text>
                    <Text style={styles.listItemBigText}>{item.main_tag}</Text>
                    <Text style={styles.normalText}>{item.other_tags}</Text>
                </View>
                <View style={styles.listItemRight}>
                    <Text style={styles.countText}>{item.entries_count}</Text>
                    <Image source={Images.arrowRight} style={styles.listItemArrow} />
                </View>
            </TouchableOpacity>
        )
    }

    renderCategoryList(){
        return(
            <FlatList
                data={this.state.arrCategory}
                renderItem={({ item, index }) => this.renderItemCategories(item, index)}
                keyExtractor={(item, index) => index + ''}
                extraData={this.state}
                key={'category'}
                numColumns={2} />
        )
    }

    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.container}>
                    <View style={[styles.header, { paddingLeft: 0, paddingRight: 0, }]}>
                        <View style={styles.headerRight}>
                            <TouchableOpacity style={styles.rightIcon} onPress={() => this.handleBackButtonClick()}>
                                <Image source={Images.plus} style={styles.Modalclose} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.headerBody}>
                            <Text style={styles.headerTitle}>How much did you pay?</Text>
                        </View>
                        <View style={styles.headerRight}>
                            <TouchableOpacity style={styles.rightIcon} activeOpacity={1}>
                                {/* <Text style={styles.headerText}>Next</Text>
                                <Image source={Images.arrowRight} style={styles.arrow} /> */}
                            </TouchableOpacity>
                        </View>
                    </View>
                     <View style={styles.search}>
                    <View style={styles.searchInputContainer}>
                            <Image source={Images.search} style={styles.searchIcon} />
                            <TextInput
                                underlineColorAndroid="transparent"
                                placeholder="Search for product or category"
                                placeholderTextColor="rgba(255, 255, 255, 1)"
                                style={styles.searchInput}
                                value={this.state.searchText}
                                onChangeText={(searchText) => {
                                    {
                                        this.setState({ searchText }, () => {
                                            clearTimeout(timerId);
                                            timerId = setTimeout(this.searchKeyword.bind(this), Constants.SEARCH_TIMER);
                                        })
                                    }
                                }}
                                returnKeyType='search'
                                onSubmitEditing={() => this.searchKeyword()}
                            />
                        </View>
                    </View>
                    <ScrollView contentContainerStyle={styles.ScrollView}>
                        
                        <View style={styles.content}>
                            {this.state.searchText.trim() != '' ?
                                <SearchComponent
                                    arrTags={this.state.arrSearchTags}
                                    arrCategories={this.state.arrSearchCategories}
                                    // onPressClearSearch={this.clearSearch}
                                    onPress={(item) => this.onPressSearchItem(item)}
                                    navigation={this.props.navigation} /> :
                                this.state.isEditing ?
                                    <View style={styles.tabContainer}>
                                        {this.renderCategoryList()}
                                    </View>
                                    :
                                    <View style={styles.tabContainer}>
                                        <View style={styles.tabNav}>
                                            <TouchableOpacity style={[styles.tabNavItem, this.state.selectedTab == 1 ? styles.tabNavItemselected : null]} onPress={() => this.setSelectedTab(1)}>
                                                <Text style={[styles.tabNavItemText, this.state.selectedTab == 1 ? styles.selected : null]}>Categories</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={[styles.tabNavItem, this.state.selectedTab == 2 ? styles.tabNavItemselected : null]} onPress={() => this.setSelectedTab(2)}>
                                                <Text style={[styles.tabNavItemText, this.state.selectedTab == 2 ? styles.selected : null]}>Popular</Text>
                                            </TouchableOpacity>
                                        </View>
                                        {this.state.selectedTab == 1 ?
                                            // Categories
                                            this.renderCategoryList()
                                            :
                                            // Popular
                                            <FlatList
                                                data={this.state.arrPopular}
                                                renderItem={({ item, index }) => this.renderItemPopular(item, index)}
                                                keyExtractor={(item, index) => index + ''}
                                                key={'popular'}
                                                extraData={this.state} />
                                        }
                                    </View>
                            }
                        </View>
                    </ScrollView>
                </SafeAreaView>
                {this.state.loading ? <LoadingComponent /> : null}
            </ImageBackground>
        )
    }
}

export default (AddPriceScreen)