import React from "react";
import { StyleSheet, Image, View, Text, Pressable } from "react-native";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import AsyncStorage from "@react-native-async-storage/async-storage";

//Screens
import MainAddPrice from '../Container/MainAddPrice';
import SearchCityScreen from '../Container/SearchCityScreen';

const Navigation = createNativeStackNavigator();

const getUserDetails = async () => {
    console.log("get user from async");
    let user = await AsyncStorage.getItem("user");
    return JSON.parse(user);
}

class AddPriceStack extends React.Component {
  render(){      
      let fsProps = this.props;
      return (
          <Navigation.Navigator>
            <Navigation.Group>
              <Navigation.Screen
                name={"MainAddPrice"}
                component={MainAddPrice}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              /> 
              <Navigation.Screen
                name={"SearchCityScreen"}
                component={SearchCityScreen}
                initialParams={{
                    getUserDetails: getUserDetails
                }}
                options={({...props}) => (
                  {
                    animationEnabled: false,
                    transitionConfig: () => ({
                      transitionSpec: {
                        duration: 0,
                        timing: 0,
                      },
                    }),
                    headerTitle: "",
                    headerShown: false,
                    headerTitleAlign: "center",
                })}
              />
            </Navigation.Group>
          </Navigation.Navigator>
        );
  }
  
}

export default AddPriceStack;