import { Alert, Keyboard, Platform } from "react-native";
import Geocoder from "react-native-geocoding";
import Geolocation from "react-native-geolocation-service";
import { NavigationActions, StackActions } from "react-navigation";
import Constants from "../Lib/Constants";
import { Metrics } from "../Themes";
import moment from "moment";
import Api from "../Services/Api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

export function validateEmail(email) {
  var rex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return rex.test(email);
}

export function validateName(name) {
  // var rex = /^[a-zA-Z]*$/     //only characters without space
  var rex = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
  return rex.test(name);
}

export function validatePassword(password) {
  const strPassword = `${password}`;
  return strPassword.length < 8 ? false : true;
}

export function closeCB() {
  console.log("Database CLOSED");
}

export function successCB(tx, results) {
  // console.log('successCB successCB successCB' + results);
}

export function errorCB(err) {
  console.log("errorCB errorCB errorCB Error: " + (err.message || err));
  return false;
}

export function getLocalTimefromUTC(time) {
  var utcDate = moment.utc(time, "YYYY-MM-DD HH:mm:ss").toDate();
  const localDate = moment(utcDate).local().format("YYYY-MM-DD HH:mm:ss"); //converted in to local time

  return localDate;
}

export function getUTCTime(time) {
  var utcDate = moment.utc().format("YYYY-MM-DD HH:mm:ss");
  return utcDate;
}

export function getTodaysDate() {
  var dateStamp = moment(new Date()).format(Constants.DATE_STAMP_FORMAT);
  return dateStamp;
}

export function resetNavigationToScreen(navigation, screenName, params = {}) {
  const resetAction = StackActions.reset({
    index: 0,
    actions: [
      NavigationActions.navigate({
        routeName: screenName,
        params: params,
      }),
    ],
  });
  navigation.dispatch(resetAction);
}

export function showAlert(message, navigation, status = 0) {
  console.log("message", message);
  console.log("status", status);
  if (status === 401 && navigation) {
    console.log("Error");
    AsyncStorage.multiRemove([
      Constants.KEY_IS_LOGIN,
      Constants.KEY_TOKEN,
      Constants.KEY_USER_ID,
      Constants.KEY_USER_DETAIL,
      Constants.OFFLINE_SYNC,
      Constants.LAST_OFFLINE_DATA_DOWNLOAD_DATE,
      Constants.OFFLINE_SYNC_DIFFRENCE_DAYS,
      Constants.LAST_API_CALLED,
      Constants.API_PROGRESS,
      Constants.IS_CURRENT_LOCATION,
    ]);
    const resetAction = StackActions.reset({
      index: 0,
      actions: [
        NavigationActions.navigate({
          routeName: "AuthStack",
        }),
      ],
    });
    navigation.dispatch(resetAction);
  }
  setTimeout(() => {
    Alert.alert(Constants.ALERT_ERROR, message);
  }, 100);
}

export function showSignleActionAlert(message, btnFirstName = "OK") {
  return new Promise((resolve) => {
    Alert.alert(Constants.APP_NAME, message, [
      {
        text: btnFirstName,
        onPress: () => {
          resolve(true);
        },
      },
    ]);
  });
}

export async function getCurrentLocationData(fn) {
  var locationData = { latitude: "", longitude: "", location: "", country: "" };
  // this.setState({ loading: true })
  const IP_GEOLOCATION_API_URL = 'https://ipapi.co/json/';

  const getIpLocation = async () => {
    try {
      const response = await axios.get(IP_GEOLOCATION_API_URL);
      const data = response.data;

      if (data && data.latitude && data.longitude) {
        return {
          coords: {
            latitude: data.latitude,
            longitude: data.longitude,
          },
        };
      } else {
        throw new Error("IP Geolocation failed");
      }
    } catch (error) {
      console.error("IP Geolocation error: ", error);
      return null;
    }
  };

  let info = await getIpLocation();
      if (info && info.coords) {
        const lat = info.coords.latitude;
        const lng = info.coords.longitude;

        locationData = { ...locationData, latitude: lat, longitude: lng };

        Geocoder.init(Constants.GOOGLE_API_KEY);
        Geocoder.from(lat, lng)
          .then((res) => {
            // console.log("Geocoder response", JSON.stringify(res));
            if (res && res.status === "OK") {
              if (res.results && res.results.length > 0) {
                var shortName = "";
                var location = "";
                var locationpt1 = "";
                var locationpt2 = "";
                var index = 0;

                for (let lc = 0; lc < res.results.length; lc++) {
                  const element = res.results[lc];
                  if (element.types.includes("country")) {
                    shortName = element.short_name;
                    index = lc;
                  }

                  if (element.types.includes("administrative_area_level_1")) {
                    locationpt2 = element.address_components[0].short_name;
                  }

                  if (element.types.includes("administrative_area_level_2")) {
                    locationpt1 = element.address_components[0].short_name;
                  }
                }

                location = locationpt1 + ", " + locationpt2;

                const result = res.results[index];

                locationData = {
                  ...locationData,
                  short_name: shortName,
                  location: location,
                };

                if (result.address_components) {
                  locationData = getLocationDataFromAddressComponent(
                    result,
                    locationData
                  );
                  fn(true, locationData);
                }
              } else {
                fn(false, locationData);
              }
            } else {
              fn(false, locationData);
            }
            // this.setState({ loading: false })
          })
          .catch((error) => {
            console.log("Geocoder Error: ", error);
            // this.setState({ loading: false })
            fn(false, locationData);
          });
      }
}

export function getLocationDataFromAddressComponent(result, locationData) {
  result.address_components.map((item, index) => {
    if (item.types.includes("locality")) {
      const savedLocation = locationData.location;
      locationData = {
        ...locationData,
        location:
          savedLocation != ""
            ? item.long_name + ", " + savedLocation
            : item.long_name,
      };
    }
    if (item.types.includes("administrative_area_level_1")) {
      const saveLocation = locationData.location;
      locationData = {
        ...locationData,
        location:
          saveLocation != ""
            ? saveLocation + ", " + item.long_name
            : item.long_name,
      };
    }
    if (item.types.includes("country")) {
      locationData = {
        ...locationData,
        country: item.long_name,
        short_name: item.short_name,
      };
    }
  });
  return locationData;
}
export function moveToListScreen(
  navigation,
  catId,
  tagIds = "",
  searchKeyword,
  keyword
) {
  Keyboard.dismiss();
  navigation.navigate("ListingScreen", {
    catId,
    tagIds,
    searchKeyword: searchKeyword,
    keyword: keyword,
  });
}

export function getLatLongDelta(radius) {
  //convert radius in km
  // Define the const outside the class
  // const { width, height } = Dimensions.get('window');
  const width = Metrics.screenWidth;
  const height = (Metrics.screenHeight * 420) / 983;

  const ASPECT_RATIO = width / height;
  const LATITUDE_DELTA = 0.5;
  const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;

  // Use the below code to zoom to particular location with radius.
  return {
    latitudeDelta: LATITUDE_DELTA * Number(radius / 1000 / 15),
    longitudeDelta: LONGITUDE_DELTA * Number(radius / 1000 / 15),
  };
}

export function checkPermission(permission, fn) {
  // console.log(permission);
  check(permission)
    .then((result) => {
      checkPermissionResult(result, permission, fn);
    })
    .catch((error) => console.log("Permission Error: ", error));
}

function checkPermissionResult(result, permission, fn) {
  // console.log(result);

  switch (
    result
    // case RESULTS.UNAVAILABLE:
    //     if (Platform.OS == 'ios') {
    //         requestPermission(permission, fn)
    //     }
    //     else{
    //         fn(false)
    //         showAlert('This feature is not available on this device')
    //     }
    //     break;

    // case RESULTS.DENIED:
    //     requestPermission(permission, fn)
    //     break;

    // case RESULTS.GRANTED:
    //     fn(true)
    //     break;

    // case RESULTS.BLOCKED:
    //     fn(false)
    //     // if (permission == PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE || permission == PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE || permission == PERMISSIONS.IOS.PHOTO_LIBRARY) {
    //     //     openSetting(Constants.MESSAGE_ACCESS_STORAGE)
    //     // } else if (permission == PERMISSIONS.ANDROID.CAMERA || permission == PERMISSIONS.IOS.CAMERA) {
    //     //     openSetting(Constants.MESSAGE_ACCESS_CAMERA)
    //     // }
    //     break;

    // default:
    //     fn(false)
    //     break;
  ) {
  }
}

export function requestPermission(permission, fn) {
  request(permission)
    .then((result) => {
      console.log("Permission result: Helper", JSON.stringify(result));
      fn(result);
    })
    .catch((error) => console.log("Permission Error: ", error));
}

export function openSetting(message) {
  Alert.alert(Constants.APP_NAME, message, [
    {
      text: "Cancel",
      onPress: () => console.log("permission denied"),
      style: "cancel",
    },
    {
      text: "Open Settings",
      onPress: () => openSettings(),
    },
  ]);
}

export function updateSearchLocation(message, navigation, getListingDetails) {
  Alert.alert("No Data Found", message, [
    {
      text: "Update Search",
      onPress: () => navigation.goBack(),
      style: "cancel",
    },
    {
      text: "Update Location",
      onPress: () =>
        navigation.navigate("SearchLocationScreen", {
          isListingScreen: true,
          getListingDetails: () => getListingDetails(),
        }),
    },
  ]);
}

export function getRadiansFrom(degrees) {
  return degrees * (Math.PI / 180);
}

export function covertKmToMiles(distanceInKM) {
  const mile = distanceInKM / 1.609344;
  return mile;
}

export function number_format(
  number,
  decimals,
  dec_point = ".",
  thousands_point = ","
) {
  if (number == null || !isFinite(number)) {
    throw new TypeError("number is not valid");
  }

  if (!decimals) {
    var len = number.toString().split(".").length;
    decimals = len > 1 ? len : 0;
  }

  number = parseFloat(number).toFixed(decimals);

  number = number.replace(".", dec_point);

  var splitNum = number.split(dec_point);
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_point);
  number = splitNum.join(dec_point);

  return number;
}

export function covertArrayToDictionary(array, keyToMakeDictionary) {
  var newDictionary = {};
  array.forEach((element) => {
    if (element[keyToMakeDictionary]) {
      newDictionary[element[keyToMakeDictionary]] = element;
    }
  });
  console.log("Helper.covertArrayToDictionary(arrTagDetails)", newDictionary);
  return newDictionary;
}

export function sortArrayOfDict(array, key, isAscending = true) {
  array.sort((a, b) => {
    const bandA = a[key].toUpperCase();
    const bandB = b[key].toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = isAscending ? 1 : -1;
    } else if (bandA < bandB) {
      comparison = isAscending ? -1 : 1;
    }
    return comparison;
  });
  return array;
}

//uploading offline data

// export function entryAdd_offline(offlineInsertId,tableFields) {
//     return new Promise(function(resolve, reject) {
//         if (Constants.isOnline) {
//             const mApi = Api.createSecure()
//             const updateSyncFieldQuery = `update offline_insertions set isSynced = 1 where id = ${offlineInsertId} `
//             SQLHelper.performTransactionWithQuery(Constants.dbRefrence, updateSyncFieldQuery, (updated) => {})
//             mApi.entryAdd(param).then(response => {
//                 if (response.ok) {
//                     if (response.data.success) {
//                         resolve(true)
//                     }
//                 }
//                 resolve(false)
//             }).catch(error => {
//                 resolve(false)
//                 console.log('error', error);
//             })
//         }
//     })
// }

export function favouriteAdd_offline(offlineInsertId, tableFields) {
  if (Constants.isOnline) {
    mApi
      .favouriteAdd(param)
      .then((response) => {
        if (response.ok) {
          if (response.data.success) {
            resolve(true);
          }
        }
        resolve(false);
      })
      .catch((err) => {
        resolve(false);
        console.log("error: ", error);
      });
  }
}

export function addRecentSearch_offline(offlineInsertId, tableFields) {
  if (Constants.isOnline) {
    const mApi = Api.createSecure();
    mApi
      .addRecentSearch(
        param.title,
        param.catId,
        param.tagIds,
        param.isCatSearch
      )
      .then((response) => {
        if (response.ok) {
          if (response.data.success) {
            resolve(true);
          }
        }
        resolve(false);
      })
      .catch((err) => {
        resolve(false);
        console.log("error: ", error);
      });
  }
}

// export function openUrl(url) {
//     Linking.canOpenURL(url)
//         .then((supported) => {
//             if (!supported) {
//                 console.log("Can't handle url: " + url);
//             } else {
//                 Linking.openURL(url);
//             }
//         }).catch((err) => console.error('An error occurred', err));
// }
