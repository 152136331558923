import React from 'react';
import { Image, Keyboard, Text, TouchableOpacity } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import DrawerComponent from '../Components/DrawerComponent';
import AccountScreen from '../Container/AccountScreen';
import ActivityScreen from '../Container/ActivityScreen';
import ChangePasswordScreen from '../Container/ChangePasswordScreen';
import CityScreen from '../Container/CityScreen';
import EditAccountScreen from '../Container/EditAccountScreen';
import EntryDetailsScreen from '../Container/EntryDetailsScreen';
import FavouritesScreen from '../Container/FavouritesScreen';
import ForgotPasswordScreen from '../Container/ForgotPasswordScreen';
import HomeCurrencyScreen from '../Container/HomeCurrencyScreen';
import HomeScreen from '../Container/HomeScreen';
import LaunchScreen from '../Container/LaunchScreen';
import ListingScreen from '../Container/ListingScreen';
import LoginScreen from '../Container/LoginScreen';
import OfflineSettingsScreen from '../Container/OfflineSettingsScreen';
import PreferencesScreen from '../Container/PreferencesScreen';
import ProductListScreen from '../Container/ProductListScreen';
import ProductScreen from '../Container/ProductScreen';
import SearchCityScreen from '../Container/SearchCityScreen';
import SearchLocationScreen from '../Container/SearchLocationScreen';
import SearchRadiusScreen from '../Container/SearchRadiusScreen';
import SearchScreen from '../Container/SearchScreen';
import SignupCountryScreen from '../Container/SignupCountryScreen';
import SignupCreatePasswordScreen from '../Container/SignupCreatePasswordScreen';
import SignupDateScreen from '../Container/SignupDateScreen';
import SignupEmailScreen from '../Container/SignupEmailScreen';
import SignupGenderScreen from '../Container/SignupGenderScreen';
import SignupScreen from '../Container/SignupScreen';
import SplashScreen from '../Container/SplashScreen';
import TooltipScreen from '../Container/TooltipScreen';
import YourEntriesScreen from '../Container/YourEntriesScreen';
import { Images } from '../Themes';
import styles from './Styles/NavigationStyles';
import EnterPriceScreen from '../Container/EnterPriceScreen';
import AddImageScreen from '../Container/AddImageScreen';
import AddPriceScreen from '../Container/AddPriceScreen';
import TermsAndConditionsScreen from '../Container/TermsAndConditionsScreen';
import PrivacyPolicyScreen from '../Container/PrivacyPolicyScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const getUserDetails = async () => {
  let user = await AsyncStorage.getItem('user');
  return JSON.parse(user);
};

const HomeStack = () => (
  <Stack.Navigator
    initialRouteName="HomeScreen"
    screenOptions={{
      headerStyle: {
        backgroundColor: '#fff',
        shadowOpacity: 0,
        shadowOffset: { height: 0 },
        elevation: 0,
        borderBottomWidth: 0,
      },
      headerTintColor: '#000',
      headerTransparent: false,
      headerLeft: () => <TouchableOpacity activeOpacity={1} style={styles.rightIcon} />,
      headerRight: () => (
        <TouchableOpacity style={styles.leftIcon} activeOpacity={1}>
          <Image source={Images.menu} style={styles.menu} />
        </TouchableOpacity>
      ),
      headerTitle: () => null,
    }}
  >
    <Stack.Screen name="HomeScreen" component={HomeScreen} />
    <Stack.Screen name="ProductScreen" component={ProductScreen} />
    <Stack.Screen name="ProductListScreen" component={ProductListScreen} />
    <Stack.Screen name="ListingScreen" component={ListingScreen} />
    <Stack.Screen name="SearchScreen" component={SearchScreen} />
    <Stack.Screen name="FavouritesScreen" component={FavouritesScreen} />
    <Stack.Screen name="ActivityScreen" component={ActivityScreen} />
    <Stack.Screen name="AccountScreen" component={AccountScreen} />
    <Stack.Screen name="EditAccountScreen" component={EditAccountScreen} />
    <Stack.Screen name="ChangePasswordScreen" component={ChangePasswordScreen} />
    <Stack.Screen name="YourEntriesScreen" component={YourEntriesScreen} />
    <Stack.Screen name="PreferencesScreen" component={PreferencesScreen} />
    <Stack.Screen name="SearchLocationScreen" component={SearchLocationScreen} />
    <Stack.Screen name="SearchRadiusScreen" component={SearchRadiusScreen} />
    <Stack.Screen name="HomeCurrencyScreen" component={HomeCurrencyScreen} />
    <Stack.Screen name="OfflineSettingsScreen" component={OfflineSettingsScreen} />
    <Stack.Screen name="TermsAndConditionsScreen" component={TermsAndConditionsScreen} />
    <Stack.Screen name="PrivacyPolicyScreen" component={PrivacyPolicyScreen} />
    <Stack.Screen name="EntryDetailsScreen" component={EntryDetailsScreen} />
    <Stack.Screen name="CityScreen" component={CityScreen} options={{ gestureEnabled: false }} />
    <Stack.Screen name="SearchCityScreen" component={SearchCityScreen} />
    <Stack.Screen name="TooltipScreen" component={TooltipScreen} />
    <Stack.Screen name="EnterPriceScreen" component={EnterPriceScreen} />
    <Stack.Screen name="AddImageScreen" component={AddImageScreen} />
    <Stack.Screen name="AddPriceScreen" component={AddPriceScreen} />
  </Stack.Navigator>
);

const DrawerStack = () => (
  <Drawer.Navigator
    drawerContent={(props) => <DrawerComponent {...props} />}
    drawerPosition="left"
    drawerStyle={{ width: '100%', backgroundColor: '#fff' }}
  >
    <Drawer.Screen name="HomeStack" component={HomeStack} />
  </Drawer.Navigator>
);

const AuthStack = () => (
  <Stack.Navigator
    initialRouteName="LaunchScreen"
    screenOptions={{
      headerStyle: {
        backgroundColor: 'transparent',
        shadowOpacity: 0,
        shadowOffset: { height: 0 },
        elevation: 0,
        borderBottomWidth: 0,
      },
      headerTintColor: '#000',
      headerTransparent: true,
      headerLeft: ({ navigation }) => (
        <TouchableOpacity
          style={styles.leftIcon}
          onPress={() => {
            Keyboard.dismiss();
            navigation.goBack();
          }}
        >
          <Image source={Images.arrowLeft} style={styles.arrow} />
        </TouchableOpacity>
      ),
      headerRight: () => (
        <TouchableOpacity activeOpacity={1} style={styles.rightIcon}>
          <Text style={styles.headerText}>Next</Text>
          <Image source={Images.arrowRight} style={[styles.arrow]} />
        </TouchableOpacity>
      ),
      headerTitle: () => null,
    }}
  >
    <Stack.Screen name="LaunchScreen" component={LaunchScreen} options={{ headerShown: false }} />
    <Stack.Screen name="LoginScreen" component={LoginScreen} />
    <Stack.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
    <Stack.Screen name="SignupScreen" component={SignupScreen} />
    <Stack.Screen name="SignupEmailScreen" component={SignupEmailScreen} />
    <Stack.Screen name="SignupDateScreen" component={SignupDateScreen} />
    <Stack.Screen name="SignupGenderScreen" component={SignupGenderScreen} />
    <Stack.Screen name="SignupCountryScreen" component={SignupCountryScreen} />
    <Stack.Screen name="SignupCreatePasswordScreen" component={SignupCreatePasswordScreen} />
  </Stack.Navigator>
);

const SplashStack = () => (
  <Stack.Navigator initialRouteName="SplashScreen">
    <Stack.Screen name="SplashScreen" component={SplashScreen} options={{ headerShown: false }} />
  </Stack.Navigator>
);

const AppNavigation = () => (
  <NavigationContainer>
    <Stack.Navigator initialRouteName="HomeStack" screenOptions={{ headerShown: false }}>
      {/* Uncomment below lines if you want to include these stacks */}
      {/* <Stack.Screen name="SplashStack" component={SplashStack} /> */}
      {/* <Stack.Screen name="AuthStack" component={AuthStack} /> */}
      <Stack.Screen name="DrawerStack" component={DrawerStack} />
    </Stack.Navigator>
  </NavigationContainer>
);

export default AppNavigation;
