/**
 * @format
 */

import { registerRootComponent } from "expo";
import React from "react";
import {
  SafeAreaView,
  StatusBar,
  StyleSheet,
  View,
  Text,
  TextInput,
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
} from "react-native";

import RootNavigation from "./src/routes";
import { useFonts } from "expo-font";
import { LogBox } from "react-native";
import { Images, ApplicationStyles, Metrics } from "./App/Themes";

const App = () => {
  const fonts = {
    base: require("./assets/fonts/Oswald-Regular.ttf"),
    bold: require("./assets/fonts/Oswald-Bold.ttf"),
    medium: require("./assets/fonts/Oswald-Medium.ttf"),
    semiBold: require("./assets/fonts/Oswald-SemiBold.ttf"),
    light: require("./assets/fonts/Oswald-Light.ttf"),
    robotoBase: require("./assets/fonts/Roboto-Regular.ttf"),
    robotoBold: require("./assets/fonts/Roboto-Bold.ttf"),
    robotoMedium: require("./assets/fonts/Roboto-Medium.ttf"),
    robotoLight: require("./assets/fonts/Roboto-Light.ttf"),
    robotoBlack: require("./assets/fonts/Roboto-Black.ttf"),
    robotoLightItalic: require("./assets/fonts/Roboto-LightItalic.ttf"),
    osBase: require("./assets/fonts/OpenSans-Regular.ttf"),
    osBold: require("./assets/fonts/OpenSans-Bold.ttf"),
    osSemiBold: require("./assets/fonts/OpenSans-SemiBold.ttf"),
    osLight: require("./assets/fonts/OpenSans-Italic.ttf"),
  };

  const [loaded] = useFonts(fonts);
  LogBox.ignoreLogs(["Warning: ..."]);
  if (!loaded) {
    return (
      // <ImageBackground
      //   source={Images.splashBackground}
      //   style={[styles.ImageBackground, {justifyContent: 'center'}]}>
      // </ImageBackground>
      null
    );
  }

  return (
    <View style={styles.container}>
      <StatusBar
        translucent
        barStyle="dark-content"
        backgroundColor={"transparent"}
      />
      <ImageBackground
        source={Images.splashBackground}
        style={[styles.container, { justifyContent: "center" }]}
      >
        {Platform.OS === "android" ? (
          <SafeAreaView style={{ flex: 1 }}>
            <RootNavigation />
          </SafeAreaView>
        ) : (
          <RootNavigation />
        )}
      </ImageBackground>
    </View>
  );
};

registerRootComponent(App);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  ...ApplicationStyles.screen,
  logo: {
    width: Metrics.screenWidth / 2.5,
    height: Metrics.screenWidth / 2.5,
    resizeMode: "contain",
    alignSelf: "center",
  },
});
