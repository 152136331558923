// leave off @2x/@3x
const Images = {
  background: require("../Images/BG.png"),
  grediantBackground: require("../Images/grediant-bg.jpg"),
  logo: require("../Images/Logo.png"),
  close: require("../Images/close.png"),
  arrowLeft: require("../Images/arrow-left.png"),
  arrowRight: require("../Images/arrow-right.png"),
  arrowBottom: require("../Images/arrow-bottom.png"),
  arrowTop: require("../Images/arrow-top.png"),
  visibilityOn: require("../Images/visibility-on.png"),
  visibilityOff: require("../Images/visibility-off.png"),
  search: require("../Images/search.png"),
  search_radius: require("../Images/search_radius.png"),
  location: require("../Images/location.png"),
  beverages: require("../Images/beverages.png"),
  health: require("../Images/health.png"),
  shopping: require("../Images/fashion.png"),
  food: require("../Images/food.png"),
  accommodation: require("../Images/accommodation.png"),
  transport: require("../Images/transport.png"),
  tours: require("../Images/tours.png"),
  souvenirs: require("../Images/souvenirs.png"),
  travel: require("../Images/travel.png"),
  services: require("../Images/services.png"),
  favourites: require("../Images/favourites.png"),
  activity: require("../Images/activity.png"),
  user: require("../Images/user.png"),
  plus: require("../Images/plus.png"),
  star: require("../Images/star.png"),
  more: require("../Images/more.png"),
  filter: require("../Images/filter.png"),
  share: require("../Images/share.png"),
  userEntries: require("../Images/user-entries.png"),
  camera: require("../Images/camera.png"),
  comment: require("../Images/comment.png"),
  checkCircle: require("../Images/check-circle.png"),
  notificationCircle: require("../Images/notification-circle.png"),
  addCircle: require("../Images/add-circle.png"),
  priceCircle: require("../Images/price-circle.png"),
  locationCircle: require("../Images/location-circle.png"),
  fillStar: require("../Images/fillStar.png"),
  favourites_filled: require("../Images/favourites_filled.png"),
  pushNotifications1: require("../Images/pushNotifications1.png"),
  pushNotifications2: require("../Images/pushNotifications2.png"),
  email: require("../Images/email.png"),
  aud: require("../Images/aud.png"),
  home_currency: require("../Images/homeCurrency.png"),
  currency: require("../Images/currency.png"),
  map: require("../Images/map.jpg"),
  marker: require("../Images/marker.png"),
  gps: require("../Images/gps.png"),
  image: require("../Images/image.png"),
  video: require("../Images/video.png"),
  placeholderImage: require("../Images/placeholder.jpg"),
  splashBackground: require("../Images/splash-background.jpg"),
  searchTab: require("../Images/search.png"),
  favouritesTab: require("../Images/favourites.png"),
  activityTab: require("../Images/activity.png"),
  userTab: require("../Images/user.png"),
  plusCircle: require("../Images/plus-circle.png"),
  plusSquare: require("../Images/plus-square.png"),
  offline: require("../Images/offline.png"),
  calender: require("../Images/calendar-icon.png"),
  filterIcon: require("../Images/filter-icon.png"),
};

export default Images;
