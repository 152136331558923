import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
  ScrollView,
} from "react-native";
import { SafeAreaView } from "react-native";
import BaseComponent from "../Components/BaseComponent";
import { Fonts, Images, Metrics, Colors } from "../Themes";
import styles from "./Styles/AccountScreenStyles";
import { connect } from "react-redux";
import ActionCreators from "../Redux/actions";
import Constants from "../Lib/Constants";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import RNPickerSelect from "react-native-picker-select";
import LoadingComponent from "../Components/LoadingComponent";
import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";

class PreferencesScreen extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: null,
      popUpVisible: false,
      popUpMessage: "",
      search_months: "",
      loading: true,
      arrMoths: [
        {
          label: "3 months",
          value: 3,
        },
        {
          label: "6 months",
          value: 6,
        },
        {
          label: "12 months",
          value: 12,
        },
      ],
    };
    this.getUserDetail = this.getUserDetail.bind(this);
  }

  componentDidMount() {
    this.getUserDetail();
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      this.setState({
        loading: false,
        userDetail: userdetails,
        search_months: userdetails.search_months,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused == false && this.props.isFocused == true) {
      this.setState({
        loading: true,
      });
      this.getUserDetail();
    }
  }

  showPopup = (visible, popUpMessage) => {
    console.log("show popup on prederences");
    // let __this = this;
    // this.setState({ popUpVisible: visible, popUpMessage: popUpMessage }, () => {
    //   setTimeout(() => {
    //     console.log("set timeout for hiding popup");
    //     __this.setState({ popUpVisible: false, popUpMessage: "" });
    //   }, Constants.TIME_FOR_POP_UPS);
    // });
  };

  async updateSearchMonth() {
    if (this.state.userDetail.search_months != this.state.search_months) {
      var locationData = {};
      locationData["latitude"] = this.state.userDetail.search_latitude;
      locationData["location"] = this.state.userDetail.search_location;
      locationData["longitude"] = this.state.userDetail.search_longitude;
      locationData["country"] = this.state.userDetail.search_country.name;
      locationData["user_id"] = this.state.userDetail.id;
      locationData["short_name"] =
        this.state.userDetail.search_country.iso_code_2;
      if (!this.state.loading) {
        const resp = await ActionCreators.searchLocationActions.setSearchMonths(
          locationData,
          this.state.search_months
        );
        console.log("update search month: ", resp);
        await AsyncStorage.setItem("user", JSON.stringify(resp.data));
        // this.props.setSearchMonths(locationData, this.state.search_months)
      }
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.handleBackButtonClick()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Preferences</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                activeOpacity={1}
                style={styles.rightIcon}
              ></TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.content}>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("SearchLocationScreen", {
                    isFromPreference: true,
                    showPopup: this.showPopup.bind(this),
                  })
                }
              >
                <View style={styles.accountItemLeft}>
                  <Image
                    source={Images.location}
                    style={styles.accountItemIcon}
                  />
                </View>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Search location</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetail.search_location}
                  </Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("SearchRadiusScreen", {
                    isFromPreference: true,
                    showPopup: this.showPopup.bind(this),
                  })
                }
              >
                <View style={styles.accountItemLeft}>
                  <Image
                    source={Images.search_radius}
                    style={styles.accountItemIcon}
                  />
                </View>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Search radius</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetail.search_radius + "km"}
                  </Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("HomeCurrencyScreen", {
                    isFromPreference: true,
                    showPopup: this.showPopup.bind(this),
                  })
                }
              >
                <View style={styles.accountItemLeft}>
                  <Image
                    source={Images.home_currency}
                    style={styles.accountItemIcon}
                  />
                </View>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Home currency</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetail.country.currency_code}
                  </Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={styles.accountItem} activeOpacity={1}>
                <View style={styles.accountItemLeft}>
                  <Image
                    source={Images.currency}
                    style={styles.accountItemIcon}
                  />
                </View>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Location currency</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetail.search_country.currency_code}
                  </Text>
                </View>
                {/* <View style={styles.accountItemRight}>
                                    <Image source={Images.arrowRight} style={styles.listItemArrow} />
                                </View> */}
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountItem}
                activeOpacity={1}
                onPress={() => {
                  /*//TODO: */
                }}
              >
                <View style={styles.accountItemLeft}>
                  <Image
                    source={Images.calender}
                    style={styles.accountItemIcon}
                  />
                </View>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Search date range</Text>
                  <RNPickerSelect
                    placeholder={{}}
                    placeholderTextColor="rgba(255,255,255,0.7)"
                    items={this.state.arrMoths}
                    onValueChange={(value) =>
                      this.setState({ search_months: value }, () => {
                        if (Platform.OS === "android" || Platform.OS == "web") {
                          this.updateSearchMonth();
                        }
                      })
                    }
                    style={{ ...pickerSelectStyles }}
                    value={this.state.search_months}
                    useNativeAndroidPickerStyle={false} //android only
                    hideIcon={false}
                    onDonePress={() => this.updateSearchMonth()}
                    onClose={() => {
                      if (Platform.OS === "android") {
                        this.updateSearchMonth();
                      }
                    }}
                  />
                  {/* <Text style={styles.heading5}></Text> */}
                </View>
                {/* <View style={styles.accountItemRight}>
                                    <Image source={Images.arrowRight} style={styles.listItemArrow} />
                                </View> */}
              </TouchableOpacity>
            </View>
          </ScrollView>
        </SafeAreaView>
        <PopupDialogComponent
          visible={this.state.popUpVisible}
          message={this.state.popUpMessage}
        />
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> Preferences Screen', state);
//     return {
//         userDetail: typeof state.userReducer.userDetail == 'object' ? state.userReducer.userDetail : JSON.parse(state.userReducer.userDetail),
//         searchLocationReducer : state.searchLocationReducer,
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         getUserDetail: () => dispatch(ActionCreators.userActions.getUserDetail()),
//         setSearchMonths: (locationData, search_months) => dispatch(ActionCreators.searchLocationActions.setSearchMonths(locationData, search_months)),
//     }
// }

export default function (props) {
  const isFocused = useIsFocused();
  return <PreferencesScreen {...props} isFocused={isFocused} />;
}

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    backgroundColor: Colors.transparent,
    fontFamily: "robotoBold",
    fontSize: (Metrics.screenWidth * 18) / 414,
    color: "#fff",
  },
  inputAndroid: {
    // height: (Metrics.screenWidth * 40) / 414,
    backgroundColor: Colors.transparent,
    fontFamily: "robotoBold",
    fontSize: (Metrics.screenWidth * 18) / 414,
    color: "#fff",
    paddingTop: 0,
  },
});
