import React, { Component, Fragment } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  Image,
  ImageBackground,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from "react-native";
import { Images, Colors, Metrics, Fonts } from "../Themes";
import styles from "./Styles/OfflineSettingsScreenStyles";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import ActionCreators from "../Redux/actions";
import LoadingComponent from "../Components/LoadingComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "../Lib/Constants";

class OfflineSettingsScreen extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      arrCategory: [],
      userDetail: "",
      enabledCategoriesIds: [],
      isOfflineSettingsUpdated: false,
      originalActivationKey: "",
      modifiedActivationKey: "",
    };
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.userDetail != this.props.userDetail) {
  //         if (this.props.userDetail.offline_categories.length > 0) {
  //             var arrEnabledCategoriesIds = this.props.userDetail.offline_categories.map((item) => {
  //                 return item.category_id
  //             })
  //             arrEnabledCategoriesIds.sort((a, b) => a - b);
  //             let originalActivationKey = arrEnabledCategoriesIds.join(',')
  //             this.setState({ userDetail: this.props.userDetail, enabledCategoriesIds: arrEnabledCategoriesIds, originalActivationKey, modifiedActivationKey: originalActivationKey})
  //         }
  //         else{
  //             this.setState({ userDetail: this.props.userDetail})
  //         }
  //         // console.log('userDetail',this.props.userDetail);
  //     }
  //     if (prevProps.categoryReducer != this.props.categoryReducer) {
  //         if (prevProps.categoryReducer.loading != this.props.categoryReducer.loading) {
  //             this.setState({ loading: this.props.categoryReducer.loading })
  //         }
  //         if ((prevProps.categoryReducer.arrCategory != this.props.categoryReducer.arrCategory) || (this.state.arrCategory != this.props.categoryReducer.arrCategory)) {
  //             var arrCategory = this.props.categoryReducer.arrCategory.map((item) => {
  //                 if (this.state.enabledCategoriesIds.includes(item.id)) {
  //                     item.isSelected = true
  //                 }
  //                 return item
  //             })
  //             this.setState({ arrCategory: arrCategory })
  //         }
  //     }
  //     if (prevProps.loading != this.props.loading) {
  //         this.setState({ loading: this.props.loading })
  //     }
  //     if (prevProps.isOfflineSettingsUpdated != this.props.isOfflineSettingsUpdated) {
  //         console.log('this.props.navigation',this.props);
  //         this.props.navigation.popToTop()
  //     }
  // }

  componentDidMount() {
    setTimeout(() => {
      this.getCategories();
    }, 1000);
    this._timeoutID = undefined;
  }

  getCategories = () => {
    this.props.getCategoryList(this.props.navigation);
  };

  _onPressBtn = (item) => {
    if (item.isSelected) {
      item.isSelected = false;
    } else {
      item.isSelected = true;
    }
    // if (typeof this._timeoutID === 'number') {
    //     clearTimeout(this._timeoutID)
    // }
    // this.setState({},() => {
    //     this._timeoutID = setTimeout(() => {
    //         this.updateOfflineSyncCategory()
    //     }, 1000);
    // });

    var offline_categories = [];
    var categoryIds = "";
    var arrSelectedCategory = [];
    this.state.arrCategory.forEach((category) => {
      if (category.isSelected) {
        var dictEnabledCategory = {};
        dictEnabledCategory["category_id"] = category.id;
        dictEnabledCategory["name"] = category.name;
        offline_categories.push(dictEnabledCategory);
        arrSelectedCategory.push(category.id);
      }
    });
    arrSelectedCategory.sort((a, b) => a - b);
    categoryIds = arrSelectedCategory.join(",");
    this.state.modifiedActivationKey = categoryIds;
    this.setState({});
  };

  updateOfflineSyncCategory = () => {
    var offline_categories = [];
    var categoryIds = "";
    var arrSelectedCategory = [];
    this.state.arrCategory.forEach((category) => {
      if (category.isSelected) {
        var dictEnabledCategory = {};
        dictEnabledCategory["category_id"] = category.id;
        dictEnabledCategory["name"] = category.name;
        offline_categories.push(dictEnabledCategory);
        arrSelectedCategory.push(category.id);
      }
    });
    arrSelectedCategory.sort((a, b) => a - b);
    categoryIds = arrSelectedCategory.join(",");
    if (arrSelectedCategory.length > 0) {
      AsyncStorage.setItem(Constants.OFFLINE_SYNC, JSON.stringify(1));
    }
    if (categoryIds != this.state.originalActivationKey) {
      this.state.userDetail.offline_categories = offline_categories;
      this.props.setUser(this.state.userDetail);
      this.state.modifiedActivationKey = categoryIds;
      this.props.updateOfflineSyncCategory(`${categoryIds}`);
    } else {
      // this.props.navigation.goBack(null);
    }
  };

  renderItemCategories(item) {
    const likedStyles = item.isSelected ? styles.switchOn : null;
    const switchIconStyles = item.isSelected ? styles.switchIconOn : null;
    return (
      <TouchableOpacity style={styles.listItem}>
        <View style={styles.listBody}>
          <Text style={styles.heading5}>{item.name}</Text>
          {/* <Text style={[styles.thirtheenStyleLight, styles.italic]}>MB</Text> */}
        </View>
        <TouchableOpacity
          onPress={() => this._onPressBtn(item)}
          style={[styles.switchBtn, likedStyles]}
        >
          <Image
            source={Images.marker}
            style={[styles.switchIcon, switchIconStyles]}
          />
          <Text style={styles.switchText}>
            {item.isSelected ? "ON" : "OFF"}
          </Text>
        </TouchableOpacity>
      </TouchableOpacity>
    );
  }

  render() {
    const canSave =
      this.state.originalActivationKey != this.state.modifiedActivationKey;
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.mainContainer}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.props.navigation.navigate("AccountScreen")}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Offline settings</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                activeOpacity={1}
                style={canSave ? styles.rightIconWithText : styles.rightIcon}
                onPress={() => this.updateOfflineSyncCategory()}
              >
                <Text style={styles.headerText}>{canSave ? "Save " : ""}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.content}>
              <Text style={styles.pre}>
                Select categories of products you want to sync prices for so
                that you can use Tengah when you’re travelling without data!
              </Text>
              <Text style={styles.pre}>
                Information will be synced for the location you have set in your
                preferences.
              </Text>
              <View style={styles.list}>
                <FlatList
                  data={this.state.arrCategory}
                  renderItem={({ item, index }) =>
                    this.renderItemCategories(item, index)
                  }
                  keyExtractor={(item, index) => index + ""}
                  extraData={this.state}
                  key={"category"}
                />
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> Home Screen', state);
//     return {
//         userDetail: typeof state.userReducer.userDetail == 'object' ? state.userReducer.userDetail : JSON.parse(state.userReducer.userDetail),
//         categoryReducer: state.categoryReducer,
//         forceUpdateOfflineData: state.offlineStateReducer.forceUpdate,
//         loading: state.userReducer.loading,
//         isOfflineSettingsUpdated: state.userReducer.forceUpdate,
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         getUserDetail: () => dispatch(ActionCreators.userActions.getUserDetail()),
//         updateOfflineSyncCategory: (categoryIds) => dispatch(ActionCreators.userActions.updateOfflineSyncCategory(categoryIds)),
//         setUser: (userDetail) => dispatch(ActionCreators.userActions.setUserDetail(userDetail)),
//         getCategoryList: (navigation) => dispatch(ActionCreators.categoryActions.getCategories('','',navigation)),
//     }
// }

export default OfflineSettingsScreen;
