import React, { Component } from "react";
import {
  Image,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import { Images } from "../Themes";
import { copilot, CopilotStep, walkthroughable } from "react-native-copilot";
import stepNumberComponent from "../Components/stepNumberComponent";
import TooltipComponents from "../Components/TooltipComponents";
import styles from "./Styles/ProductScreenStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";

const WalkthroughableTouchableOpacity = walkthroughable(TouchableOpacity);

var timerId = "";

class ProductListScreen extends Component {
  constructor(props) {
    super(props);
    const param = this.props.navigation.state.params;
    this.state = {
      catId: param && param.catId ? param.catId : "",
      title: param && param.title ? param.title : "",
      entryCount: param && param.entryCount ? param.entryCount : "",
      parentName: param && param.selectedName ? param.selectedName : "",
      isFromAddPrice:
        param && param.isFromAddPrice ? param.isFromAddPrice : false,
      isEditing: param && param.isEditing ? param.isEditing : false,

      tagIds: "",
      searchText: "",
      loading: false,

      arrCategoryTag: [],
      arrSelectedTags: [],

      enterPriceVisible: false,
      imagePicker: false,
      is_redirect: false,
    };

    this.getData();
  }

  componentDidMount() {
    this.props.copilotEvents.on("stepChange", (currentStep) => {
      if (currentStep.order == 1) {
        try {
          AsyncStorage.setItem(
            Constants.PRODUCT_LIST_TOOLTIP,
            JSON.stringify(1)
          );
        } catch (error) {
          console.log("AsyncStorage Error: ", error);
        }
      }
    });
  }

  getData = () => {
    this.props.getCategoryTagsList(
      this.state.catId,
      this.state.tagIds,
      this.state.searchText,
      this.props.navigation
    );
  };

  startTooltip = async () => {
    const isProductListTooltipShown = await AsyncStorage.getItem(
      Constants.PRODUCT_LIST_TOOLTIP
    );
    if (isProductListTooltipShown != "1") {
      this.props.copilotEvents.on("stop", this.handleStepStop);
      if (
        !this.state.isFromAddPrice &&
        this.state.entryCount > 0 &&
        (this.state.is_redirect || this.state.arrSelectedTags == 0)
      ) {
        setTimeout(() => {
          this.props.start();
        }, 300);
      }
    }
  };

  handleStepStop = (stop) => {
    this.props.copilotEvents.off("stop");
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryReducer != this.props.categoryReducer) {
      if (
        prevProps.categoryReducer.tagLoading !=
        this.props.categoryReducer.tagLoading
      ) {
        this.setState({
          loading: this.props.categoryReducer.tagLoading,
          is_redirect: this.props.categoryReducer.is_redirect,
        });
      }
      if (
        prevProps.categoryReducer.arrCategoryTag !=
        this.props.categoryReducer.arrCategoryTag
      ) {
        this.setState(
          {
            arrCategoryTag: this.props.categoryReducer.arrCategoryTag,
          },
          () => {
            console.log(
              "this.props.categoryReducer",
              this.props.categoryReducer
            );
            console.log("this.state.entryCount", this.state.entryCount);
            if (
              this.props.categoryReducer.arrCategoryTag.length == 0 &&
              this.state.entryCount > 0
            ) {
              if (this.state.isFromAddPrice) {
                // this.moveToEnterPriceScreen()
              } else {
                this.moveToListScreen(
                  this.props.navigation,
                  this.state.catId,
                  this.state.tagIds
                );
              }
            }
            this.startTooltip();
          }
        );
      }
      if (
        prevProps.categoryReducer.addTagLoading !=
        this.props.categoryReducer.addTagLoading
      ) {
        this.setState({ loading: this.props.categoryReducer.addTagLoading });
      }
      if (
        prevProps.categoryReducer.addedTag !=
        this.props.categoryReducer.addedTag
      ) {
        const tag = this.props.categoryReducer.addedTag;
        if (tag != "") {
          const obj = {
            tag_id: tag.id,
            tag_name: tag.name,
            tag_count: 0,
          };
          this.state.arrSelectedTags.push(obj);
          this.setState({ searchText: "" });
        }
      }
    }
  }
  searchKeyword = () => {
    this.getData();
  };

  moveToListScreen = (navigation, catId, tagIds) => {
    navigation.navigate("ListingScreen", {
      catId,
      tagIds,
      arrTags: this.state.arrCategoryTag,
      arrSelectedTags: this.state.arrSelectedTags,
      isFromProductListing: true,
    });
  };

  moveToEnterPriceScreen = (showTagAddedPopup = false) => {
    const tagIds = this.state.arrSelectedTags.map((value) => value.tag_id);
    const tagNames = this.state.arrSelectedTags.map((value) => value.tag_name);

    const priceDetail = {
      cat_id: this.state.catId,
      tag_ids: tagIds.join(","),
      category: this.state.title,
      main_tag: tagNames.join(Constants.DOT),
      parentName: this.state.parentName,
      entryCount: this.state.entryCount,
    };
    if (this.state.isEditing) {
      this.props.setTags(tagIds, priceDetail.main_tag);
      this.props.navigation.navigate("EnterPriceScreen");
    } else {
      this.props.navigation.navigate("EnterPriceScreen", {
        priceDetail: priceDetail,
        showTagAddedPopup: showTagAddedPopup,
      });
    }
  };

  renderTags = (item, index) => {
    return (
      <TouchableOpacity
        style={[styles.listItem, styles.listItemMarginHorizontal]}
        onPress={() => {
          this.state.arrSelectedTags.push(item);
          const tagIds = this.state.arrSelectedTags.map(
            (value) => value.tag_id
          );
          this.setState({ tagIds: tagIds.join(",") }, () => {
            this.getData();
          });
        }}
      >
        <View style={styles.listBody}>
          <Text style={styles.listItemText}>{item.tag_name}</Text>
        </View>
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.tag_count}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  renderSelectedTags = (item, index) => {
    return (
      <View style={[styles.listItem, styles.listItemMarginHorizontal]}>
        <View style={styles.listBody}>
          <Text style={styles.listItemText}>{item.tag_name}</Text>
        </View>
        <TouchableOpacity
          style={styles.listItemRight}
          onPress={() => {
            this.state.arrSelectedTags.splice(
              index,
              this.state.arrSelectedTags.length
            );
            const tagIds = this.state.arrSelectedTags.map(
              (value) => value.tag_id
            );
            this.setState({ tagIds: tagIds.join(",") }, () => {
              this.getData();
            });
          }}
        >
          <Image
            source={Images.addCircle}
            style={[styles.listItemArrow, { transform: [{ rotate: "45deg" }] }]}
          />
        </TouchableOpacity>
      </View>
    );
  };

  renderEmptyComponent = () => {
    return (
      <View style={styles.listItemMarginHorizontal}>
        <TouchableOpacity
          style={styles.listItem}
          onPress={() =>
            this.props.addTag(this.state.searchText, this.props.navigation)
          }
        >
          <View style={styles.listBody}>
            <Text style={styles.listItemText}>{this.state.searchText}</Text>
            <Text style={[styles.listItemText, styles.normalText]}>
              Add to system
            </Text>
          </View>
          <View style={styles.listItemRight}>
            <Text style={styles.countText}>{0}</Text>
            <Image source={Images.arrowRight} style={styles.listItemArrow} />
          </View>
        </TouchableOpacity>
        <Text
          style={[styles.smallText, { paddingTop: 20 }]}
        >{`There is no tag in our system for “${this.state.searchText}”, would you like to add it as an additional tag?\nPlease double check your spelling.`}</Text>
      </View>
    );
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <TouchableOpacity
              style={styles.rightIcon}
              onPress={() => this.props.navigation.navigate("ProductScreen")}
            >
              <Image source={Images.arrowLeft} style={styles.arrow} />
            </TouchableOpacity>
            <Text
              style={[
                styles.nevHeading,
                styles.nevHeadingMedium,
                { paddingLeft: 0 },
              ]}
            >
              {this.state.title}
            </Text>
            <TouchableOpacity
              style={styles.rightIcon}
              onPress={() => {
                if (this.state.isFromAddPrice) {
                  if (this.state.tagIds != "") {
                    this.moveToEnterPriceScreen();
                  } else if (this.state.arrSelectedTags.length > 0) {
                    this.moveToEnterPriceScreen();
                  }
                } else if (this.state.entryCount > 0) {
                  //Gunjan
                  // if (this.state.arrSelectedTags.length > 0 && this.props.categoryReducer.is_redirect) {
                  if (this.state.arrSelectedTags.length > 0) {
                    this.moveToListScreen(
                      this.props.navigation,
                      this.state.catId,
                      this.state.tagIds
                    );
                  } else if (this.state.arrSelectedTags.length == 0) {
                    this.moveToListScreen(
                      this.props.navigation,
                      this.state.catId,
                      this.state.tagIds
                    );
                  }
                } else if (this.state.arrSelectedTags.length > 0) {
                  this.moveToEnterPriceScreen();
                }
              }}
            >
              {!this.state.isFromAddPrice &&
              this.state.entryCount == 0 ? null : (this.state.entryCount > 0 &&
                  this.state.is_redirect) ||
                this.state.arrSelectedTags.length != 0 ? (
                <>
                  <Text style={styles.headerText}>Done</Text>
                  <Image source={Images.arrowRight} style={styles.arrow} />
                </>
              ) : null}
            </TouchableOpacity>
          </View>

          <View style={styles.search}>
            <View style={styles.searchInputContainer}>
              <Image source={Images.search} style={styles.searchIcon} />
              <TextInput
                underlineColorAndroid="transparent"
                placeholder={`Search ${this.state.parentName.toLowerCase()} for a product or tag`}
                placeholderTextColor="rgba(255, 255, 255, 1)"
                style={styles.searchInput}
                value={this.state.searchText}
                onChangeText={(searchText) => {
                  {
                    this.setState({ searchText }, () => {
                      clearTimeout(timerId);
                      // if (this.state.keyword.length >= 2) {
                      timerId = setTimeout(
                        this.searchKeyword.bind(this),
                        Constants.SEARCH_TIMER
                      );
                      // }
                    });
                  }
                }}
                returnKeyType="search"
                onSubmitEditing={() => this.searchKeyword()}
              />
            </View>
          </View>

          <ScrollView
            contentContainerStyle={[styles.ScrollView, { paddingBottom: 80 }]}
          >
            <View>
              <FlatList
                data={this.state.arrSelectedTags}
                extraData={this.state}
                key="selectedTags"
                keyExtractor={(item, index) => index + ""}
                renderItem={({ item, index }) =>
                  this.renderSelectedTags(item, index)
                }
              />
              <View style={[styles.categoriesList]}>
                <FlatList
                  data={this.state.arrCategoryTag}
                  keyExtractor={(item, index) => index + ""}
                  key="tags"
                  renderItem={({ item, index }) => this.renderTags(item, index)}
                  ListEmptyComponent={
                    this.state.arrCategoryTag.length == 0 &&
                    this.state.searchText != "" &&
                    this.state.isFromAddPrice
                      ? this.renderEmptyComponent
                      : null
                  }
                  extraData={this.state}
                />
              </View>
            </View>
            {!this.state.loading &&
            this.state.arrCategoryTag.length == 0 &&
            this.state.arrSelectedTags.length == 0 ? (
              <View style={{ justifyContent: "center", flex: 1 }}>
                <View style={styles.notFound}>
                  <Text style={styles.notFoundText}>
                    There are no entries here!
                  </Text>
                  <TouchableOpacity
                    style={[styles.button, styles.buttonAddPrice]}
                    onPress={() => this.props.navigation.goBack()}
                  >
                    <Text style={styles.buttonText}>Go Back</Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}
          </ScrollView>

          {this.state.isFromAddPrice ? null : this.state.entryCount > 0 &&
            (this.state.is_redirect || this.state.arrSelectedTags == 0) ? (
            <CopilotStep
              order={1}
              name="View Prices"
              text="View prices for your current search, or choose filters for a more accurate price."
            >
              <WalkthroughableTouchableOpacity
                style={[
                  styles.button,
                  {
                    position: "absolute",
                    alignSelf: "flex-end",
                    margin: 20,
                    marginBottom: 40,
                    marginTop: 20,
                    bottom: 0,
                    right: 0,
                  },
                ]}
                onPress={() => {
                  if (
                    this.state.arrSelectedTags > 0 &&
                    this.state.is_redirect
                  ) {
                    this.moveToListScreen(
                      this.props.navigation,
                      this.state.catId,
                      this.state.tagIds
                    );
                  } else if (this.state.arrSelectedTags == 0) {
                    this.moveToListScreen(
                      this.props.navigation,
                      this.state.catId,
                      this.state.tagIds
                    );
                  }
                }}
              >
                <Text style={styles.buttonText}>View prices</Text>
                <Image source={Images.arrowRight} style={styles.arrow} />
              </WalkthroughableTouchableOpacity>
            </CopilotStep>
          ) : null}
        </SafeAreaView>

        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }

  setEnterPriceVisible = (visible) => {
    this.setState({ enterPriceVisible: visible });
  };

  onNavigate = () => {
    this.setState({ enterPriceVisible: false }, () =>
      this.props.navigation.navigate("ListingScreen", { isFromListing: true })
    );
  };

  setImagePicker = (visible) => {
    this.setState({ imagePicker: visible });
  };
}


const ProductListObject = (ProductListScreen);

Object.assign(ProductListObject, {
  navigationOptions: ProductListScreen.navigationOptions,
});

export default ProductListObject;
