import * as Helper from '../../Lib/Helper';
import Api from '../../Services/Api';
import ActionTypes from './types';
import Constants from '../../Lib/Constants';

const mApi = Api.createSecure()

const filterActions = {
    filterList
}

function filterList(catId, keyword) {
    return dispatch => {
        if (Constants.isOnline) {
            dispatch({
                type: ActionTypes.FILTER_LOADING,
                loading: true,
            })

            mApi.filterList(catId, keyword).then(response => {
                if (response.ok) {
                    if (response.data.success) {
                        dispatch({
                            type: ActionTypes.FILTER_LIST,
                            arrFilter: response.data.data,
                        })
                    } else {
                        Helper.showAlert(response.data.message)
                    }
                } else {
                    Helper.showAlert(response.problem)
                }
                dispatch({
                    type: ActionTypes.FILTER_LOADING,
                    loading: false,
                })
            }).catch(error => {
                console.log('error:', error);
                dispatch({
                    type: ActionTypes.FILTER_LOADING,
                    loading: false,
                })
            })
        } else {
            OfflineHelper.getUserEntriesByDistance((arrEntryId) => {
                
                const queryToFetch = `select count(*) as tag_count, tag_id from entry_tags where entry_id in (${arrEntryId}) and exists (select * from entries where entry_tags.entry_id = entries.id and cat_id = ${catId}) group by tag_id`
                SQLHelper.performTransactionWithQuery(Constants.dbRefrence, queryToFetch, (responseData) => { 
                    console.log('queryToFetch responseData',responseData);
                    var data = [];
                    var responseCount = 0;
                    responseData.forEach(element => {
                        console.log('queryToFetch element',element);
                        SQLHelper.selectTable(Constants.dbRefrence, Constants.SQL_TABLE_NAMES.TAGS_4,`id = ${element.tag_id}`,(arrTagDetails) =>{
                            if (arrTagDetails.length > 0) {
                                var dictData = {}
                                dictData['tag_id'] = element.tag_id
                                dictData['tag_name'] = arrTagDetails[0].name
                                dictData['tag_count'] = element.tag_count
                                data[responseCount] = dictData
                            }
                            console.log('queryToFetch responseCount responseData.length',responseCount ,responseData.length);                   
                            responseCount++;     
                            if (responseCount == (responseData.length - 1)) {
                                console.log('queryToFetch response',data);
                                var sortOrder = {
                                    tag_count:"desc",tag_name:"asc"
                                };
                                // const sortedData = MultiKeySort(data,sortOrder)
                                const sortedData = data;
                                //Alternet LessCode
                                // data.sort(function (a, b) {
                                //     return b.tag_count - a.tag_count || a.tag_name.localeCompare(b.tag_name) ;
                                // });
                                console.log('queryToFetch sortedData response',sortedData);
                                
                                dispatch({
                                    type: ActionTypes.FILTER_LIST,
                                    arrFilter: sortedData,
                                })
                            }        
                        })
                    });
                });
            })            
        }

    }
}


export { filterActions };

