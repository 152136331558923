import React, {useState, useEffect} from 'react';
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  StatusBar,
  Pressable,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { api } from '../../src/utils/api';
import { NavigatorName, ScreenNames } from '../../src/routes/screen';
import * as WebBrowser from 'expo-web-browser';
import secrets from "../../secrets.json";

export default function HomePage({...props}) {

  logout = async () => {
    let removeToken = await AsyncStorage.removeItem("token");
    let removerefreshToken = await AsyncStorage.removeItem("refreshToken");

    const domain = secrets.APIDOMAIN

    let result = await WebBrowser.openBrowserAsync(
      secrets.auth0Domain +
        "/v2/logout?client_id=" +
        secrets.auth0ClientId +
        "&returnTo="+domain+"v1/api/logout"
    );

    console.log(result);

    if (removeToken == null) {
      props.navigation.navigate(ScreenNames.Onboarding);
    }
  }

  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <Text>Hello</Text>
      <Pressable onPress={() => logout()}>
        <Text>Logout</Text>
      </Pressable>
    </View>
  );
}
