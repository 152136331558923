import React, { Component } from "react";
import {
  Modal,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  Image,
} from "react-native";
import * as ImagePicker from 'expo-image-picker';
import * as Helper from "../Lib/Helper";
import { Images } from "../Themes";
import styles from "./Styles/ImagePickerComponentStyles";

export default class ImagePickerComponent extends Component {
  static defaultProps = {
    visible: false,
    onClose: () => {},
    onSelectedImage: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.visible != this.props.visible) {
      this.setState({ visible: this.props.visible });
    }
  }

  onClose = () => {
    this.props.onClose();
  };

  checkGalleryPermission = () => {
    if (Platform.OS == "android") {
    } else if (Platform.OS == "ios") {
    }
  };

  checkCameraPermission = () => {
    if (Platform.OS == "android") {
    } else if (Platform.OS == "ios") {
    }
  };

  openGallery = async () => {
    if (this.props.openGallery) {
      this.props.openGallery();
    } else {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      if (!result.canceled) {
        this.onClose();
        this.props.onSelectedImage(image);
      }
    }
  };

  openCamera = async () => {
    if (this.props.openCamera) {
      this.props.openCamera();
    } else {
      let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      });
  
      if (!result.canceled) {
        this.onClose();
        this.props.onSelectedImage(image);
      }
    }
  };

  render() {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={this.state.visible}
        onRequestClose={() => this.onClose()}
      >
        <View style={styles.imagePickerModal}>
          <TouchableOpacity
            style={styles.bodyClose}
            onPress={() => this.onClose()}
          >
            <SafeAreaView style={styles.imagePickerStyle}>
              <TouchableOpacity
                style={styles.imagePickerOption}
                onPress={() => this.props.openGallery()}
              >
                <Image
                  source={Images.image}
                  style={styles.imagePickerOptionIcon}
                />
                <Text style={styles.imagePickerOptionText}>
                  Choose from device
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.imagePickerOption, styles.listBorderNone]}
                onPress={() => this.props.openCamera()}
              >
                <Image
                  source={Images.video}
                  style={styles.imagePickerOptionIcon}
                />
                <Text style={styles.imagePickerOptionText}>Take a photo</Text>
              </TouchableOpacity>
            </SafeAreaView>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }
}
