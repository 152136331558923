import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,    
    content: {
        flex:1,
        padding: 20,
        justifyContent:'space-between',
    },
    SpaceLaunch:{
        paddingTop:Metrics.screenWidth / 30,
        paddingBottom: Metrics.screenWidth / 30
    },   
})
