import React, { Component } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './Styles/LinkPopUpComponentStyles'


export default class NoSearchResult extends Component {
    render() {
        return (
            <View style={[styles.notFound,this.props.containerStyle]}>
                <Text style={styles.notFoundText}>{this.props.title}</Text>
                <TouchableOpacity style={[styles.button, styles.buttonAddPrice]} onPress={() => this.props.onPressClearSearch()}>
                    <Text style={styles.buttonText}>Clear search</Text>
                </TouchableOpacity>
            </View>
        )
    }
}