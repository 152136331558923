export const StackName = {
  Home: 'HomeStack',
};

export const NavigatorName = {
  LoginNavigator: 'LoginNavigator',
  HomeNavigator: 'HomeNavigator',
  RegisterNavigator: 'RegisterNavigator',
};

export const ScreenNames = {
  Login: 'Login',
  Splash: 'Splash', 
  RegisterProfile: "RegisterProfile",
  Home: "Home"
};
