import React, { Component, Fragment } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import BaseComponent from '../Components/BaseComponent';
import { Images } from '../Themes';
import Styles from "./Styles/DrawerComponentStyles";


class DrawerComponent extends Component {

    render() {
        return (
            <View style={Styles.drawer}>
                
                            <Text style={Styles.navItemText}>Home</Text>
                      
            </View>
        );
    }
}

export default DrawerComponent;
