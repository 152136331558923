import React, { Component } from "react";
import { ActivityIndicator, View, Modal, Image, Text } from "react-native";
import { Colors, Images } from "../Themes";
import styles from "./Styles/PopupDialogComponentStyles";

export default class PopupDialogComponent extends Component {
  static defaultProps = {
    visible: false,
    isRemoveType: false,
    message: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible,
      isRemoveType: this.props.isRemoveType,
      message: this.props.message,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps != this.props) {
      this.setState({
        visible: this.props.visible,
        isRemoveType: this.props.isRemoveType,
        message: this.props.message,
      });
    }
  }

  render() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.visible}
      >
        <View style={styles.modalDialog}>
          <View style={styles.dialog}>
            <Image
              style={[
                styles.dialogIcon,
                this.state.isRemoveType ? styles.cancel : null,
              ]}
              source={
                this.state.isRemoveType ? Images.addCircle : Images.checkCircle
              }
            />
            <Text style={styles.dialogText}>{this.state.message}</Text>
          </View>
        </View>
      </Modal>
    );
  }
}
