import React, { Component } from "react";
import { ActivityIndicator, View } from "react-native";
import { Colors } from "../Themes";

export default class LoadingComponent extends Component {
  static defaultProps = { visible: false };
  render() {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(100,100,100,0.5)",
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <ActivityIndicator size="large" color={Colors.white} />
      </View>
    );
  }
}
