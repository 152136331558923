import React, { Component, Fragment } from "react";
import {
  Image,
  Modal,
  ScrollView,
  Platform,
  ImageBackground,
  Text,
  TextInput,
  TouchableHighlight,
  Alert,
  TouchableOpacity,
  View,
  ActivityIndicator,
  KeyboardAvoidingView,
  StyleSheet,
} from "react-native";
import { Images, Colors, Metrics, Fonts } from "../Themes";

class CustomMarker extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Image
        style={styles.image}
        source={this.props.pressed ? Images.marker : Images.marker}
        resizeMode="contain"
      />
    );
  }
}

export default CustomMarker;

const styles = StyleSheet.create({
  image: {
    height: (Metrics.screenWidth * 80) / 414,
    width: (Metrics.screenWidth * 80) / 414,
  },
});
