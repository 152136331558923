// import * as Navigation from './navigation'
import {userActions} from './User';
import {countryActions} from './Country';
import {searchLocationActions} from './SearchLocation';
import {popularItemsActions} from './PopularItems';
import {entryActions} from './Entry';
import {searchActions} from './Search';
import {favouriteActions} from './Favourite';
import {filterActions} from './Filter';
import {activityActions} from './Activity';
import {categoryActions} from './Category';
import {offlineDataActions} from './OfflineData';
import {offlineStateActions} from './OfflineState';
import { enterPriceActions } from './EnterPrice';

const ActionCreators = {
  userActions,
  countryActions,
  searchLocationActions,
  popularItemsActions,
  entryActions,
  searchActions,
  favouriteActions,
  filterActions,
  activityActions,
  categoryActions,
  offlineDataActions,
  offlineStateActions,
  enterPriceActions
};

export default ActionCreators;
