import * as Helper from '../../Lib/Helper';
import Api from '../../Services/Api';
import ActionTypes from './types';
import Constants from '../../Lib/Constants';
import { api } from '../../../src/utils/api';

const mApi = Api.createSecure()

const categoryActions = {
    getCategories, getCategoryTags, addTag
}

// function getCategories(catId = '', keyword = '',navigation) {
//     return dispatch => {

//         dispatch({
//             type: ActionTypes.FILTER_CATEGORY_LIST,
//             arrFilterCat: [],
//         })
//         if (Constants.isOnline) {
//             dispatch({
//                 type: ActionTypes.CATEGORY_LOADING,
//                 loading: true,
//             })
//             mApi.categories(catId, keyword).then(response => {

//                 if (response.ok) {
//                     if (response.data.success) {
//                         if (catId == '' && keyword == '') {
//                             console.log('1 Online Data for CATEGORIES_3 list:', response.data.data);
//                             // setTimeout(() => {
//                             dispatch({
//                                 type: ActionTypes.CATEGORY_LIST,
//                                 arrCategory: response.data.data,
//                             })
//                             // }, 300);

//                         } else {
//                             console.log('2 Online Data for CATEGORIES_3 list:', response.data.data);
//                             // setTimeout(() => {
//                             dispatch({
//                                 type: ActionTypes.FILTER_CATEGORY_LIST,
//                                 arrFilterCat: response.data.data,
//                             })
//                             // }, 300);
//                         }
//                     } else {
//                         Helper.showAlert(response.data.message,navigation)
//                     }
//                 } else {
//                     Helper.showAlert(response.problem,navigation)
//                 }
//                 dispatch({
//                     type: ActionTypes.CATEGORY_LOADING,
//                     loading: false,
//                 })
//             }).catch(error => {
//                 console.log('error:', error);
//                 dispatch({
//                     type: ActionTypes.CATEGORY_LOADING,
//                     loading: false,
//                 })
//             })
//         } else {

//             OfflineHelper.getUserEntriesByDistance((arrEntryId) => {
//                 var parentIdCondition = (catId == '' ? 'type="Primary"' : `parent_id = ${catId}`)
//                 var keywordCondition = (keyword == '' ? '' : `name like "${keyword}%" and `)
//                 var selectCategoriesQuery = `select categories.*, (select count(*) from entries where categories.id = entries.cat_id and entries.id in (${arrEntryId})) as entries_count, (select count(*) from categories as rn_reserver_0 where categories.id = rn_reserver_0.parent_id) as child_count from categories where ${keywordCondition}${parentIdCondition} and is_active = 1 order by entries_count desc`
//                 SQLHelper.performTransactionWithQuery(Constants.dbRefrence, selectCategoriesQuery, (responseData) => {
//                     if (catId == '' && keyword == '') {
//                         console.log('Offline Data for CATEGORIES_3 list:', responseData);
//                         dispatch({
//                             type: ActionTypes.CATEGORY_LIST,
//                             arrCategory: responseData,
//                         });
//                     } else if (catId != '') {
//                         SQLHelper.selectTable(Constants.dbRefrence, Constants.SQL_TABLE_NAMES.CATEGORIES_3, `id=${catId}`, (categorieDetails) => {
//                             var arrData = categorieDetails
//                             arrData[0]['child'] = responseData
//                             console.log('Offline Data for CATEGORIES_3 dictData:', arrData);
//                             dispatch({
//                                 type: ActionTypes.FILTER_CATEGORY_LIST,
//                                 arrFilterCat: arrData,
//                             });
//                         })

//                     }
//                     else {
//                         console.log('Offline Data for CATEGORIES_3 list:', responseData);
//                         dispatch({
//                             type: ActionTypes.FILTER_CATEGORY_LIST,
//                             arrFilterCat: responseData,
//                         });
//                     }
//                 });
//             });
//         }
//     }
// }

async function getCategories(lat, lng, distance) {

    const categories  = await api("v1/api/request", "POST", {
        endpoint: "categories",
        type: "POST",
        data: {
            latitude: lat,
            longitude: lng,
            distanceInMetres: distance

        }
    });

    return categories.data.data;

    // mApi.categories(catId, keyword).then(response => {

    //     if (response.ok) {
    //         if (response.data.success) {
    //             if (catId == '' && keyword == '') {
    //                 console.log('1 Online Data for CATEGORIES_3 list:', response.data.data);
    //                 // setTimeout(() => {
    //                 dispatch({
    //                     type: ActionTypes.CATEGORY_LIST,
    //                     arrCategory: response.data.data,
    //                 })
    //                 // }, 300);

    //             } else {
    //                 console.log('2 Online Data for CATEGORIES_3 list:', response.data.data);
    //                 // setTimeout(() => {
    //                 dispatch({
    //                     type: ActionTypes.FILTER_CATEGORY_LIST,
    //                     arrFilterCat: response.data.data,
    //                 })
    //                 // }, 300);
    //             }
    //         } else {
    //             Helper.showAlert(response.data.message,navigation)
    //         }
    //     } else {
    //         Helper.showAlert(response.problem,navigation)
    //     }
    //     dispatch({
    //         type: ActionTypes.CATEGORY_LOADING,
    //         loading: false,
    //     })
    // })
}

function getCategoryTags(catId, tagIds = '', keyword = '',navigation) {
    return dispatch => {

        if (Constants.isOnline) {
            dispatch({
                type: ActionTypes.CATEGORY_TAG_LOADING,
                loading: true,
            })
            mApi.categoryTags(catId, tagIds, keyword).then(response => {
                dispatch({
                    type: ActionTypes.CATEGORY_TAG_LOADING,
                    loading: false,
                })
                if (response.ok) {
                    if (response.data.success) {
                        console.log('Online getCategoryTags ---->>>', response.data.data);

                        dispatch({
                            type: ActionTypes.CATEGORY_TAG_LIST,
                            arrCategoryTag: response.data.data,
                            is_redirect: response.data.is_redirect,
                        })
                        // if (catId == '') {
                        //     dispatch({
                        //         type: ActionTypes.CATEGORY_TAG_LIST,
                        //         arrCategory: response.data.data,
                        //     })
                        // } else {
                        //     dispatch({
                        //         type: ActionTypes.FILTER_CATEGORY_LIST,
                        //         arrFilterCat: response.data.data,
                        //     })
                        // }
                    } else {
                        Helper.showAlert(response.data.message,navigation,response.data.status)
                    }
                } else {
                    Helper.showAlert(response.problem,navigation)
                }
            }).catch(error => {
                console.log('error:', error);
                dispatch({
                    type: ActionTypes.CATEGORY_TAG_LOADING,
                    loading: false,
                })
            })
        } else {
            // alert(tagIds)
            OfflineHelper.getUserEntriesByDistance((arrEntryId) => {
                var tagArr = [];
                var properTagCount = 1;
                var sortOrder = {
                    tag_count: "desc", tag_name: "asc"
                };
                if (tagIds == '') {
                    const entryTagQuery = `select count(*) as tag_count, tag_id, (select name from tags where id = tag_id) as tag_name from entry_tags where entry_id in (${arrEntryId}) and exists (select * from entries where entry_tags.entry_id = entries.id and cat_id = ${catId}) group by tag_id order by tag_count desc`
                    SQLHelper.performTransactionWithQuery(Constants.dbRefrence, entryTagQuery, (responseData) => {
                        // console.log('Offline entry_tags ---->>>', responseData);
                        // Alternet LessCode
                        // responseData.sort(function (a, b) {
                        //     return b.tag_count - a.tag_count || a.tag_name.localeCompare(b.tag_name);
                        // });
                        // const sortedData = MultiKeySort(responseData, sortOrder)
                        const sortedData = responseData;
                        dispatch({
                            type: ActionTypes.CATEGORY_TAG_LIST,
                            arrCategoryTag: sortedData,
                            is_redirect: properTagCount
                        });
                    })
                }
                else {
                    var tag_ids = tagIds.split(',');
                    tag_ids.sort((a, b) => a - b);
                    // console.log('Offline entry_tags ---->>> tag_ids', tag_ids);
                    var tag_idStr = tag_ids.join(',');
                    const queryPoparTagCount = `select count(*) as aggregate from entries where cat_id = ${catId} and id in (${arrEntryId}) and tag_ids = "${tag_idStr}"`
                    var queryFinalEntryIds = `select * from entries where cat_id = ${catId} and id in (${arrEntryId})`
                    SQLHelper.performTransactionWithQuery(Constants.dbRefrence, queryPoparTagCount, (tagCountResponse) => {
                        properTagCount = tagCountResponse[0].aggregate
                        // console.log('---->>> Offline entry_tags tagCountResponse ---->>>', properTagCount);
                    })
                    tag_ids.forEach(finalTagId => {
                        // append  `and FIND_IN_SET('68',tag_ids)`
                        queryFinalEntryIds += `and (tag_ids LIKE "${finalTagId}" OR tag_ids LIKE "%,${finalTagId},%" OR tag_ids LIKE "${finalTagId},%" OR tag_ids LIKE "%,${finalTagId}")`
                    });
                    SQLHelper.performTransactionWithQuery(Constants.dbRefrence, queryFinalEntryIds, (finalentryIdArr) => {
                        // console.log('---->>> Offline entry_tags finalentryIdArr ---->>>', finalentryIdArr);  
                        if (finalentryIdArr) {
                            // console.log('---->>> Offline entry_tags finalentryIdArr inside if ---->>>');
                            var temp = [];
                            var tempTagIds = [];
                            var entryTagIds = "";
                            finalentryIdArr.forEach(finalentry => {
                                temp.push(finalentry.id);
                                tempTagIds.push(finalentry.tag_ids)
                            });
                            entryTagIds = tempTagIds.join(',')
                            // console.log('---->>> Offline entry_tags tempTagIds ---->>>', tempTagIds);         
                            // console.log('---->>> Offline entry_tags str entryTagIds ---->>>', entryTagIds);      
                            if (entryTagIds != "") {
                                var uniqueTempTagIds = [...new Set(entryTagIds.split(','))];
                                // console.log('---->>> Offline entry_tags unique tempTagIds ---->>>', uniqueTempTagIds);     
                                var cnt = 0
                                uniqueTempTagIds.forEach(tempTagId => {
                                    if (!tag_ids.includes(tempTagId)) {
                                        var remainingTagCountQuery = `select count(*) as aggregate from entries where cat_id = ${catId} and id in (${arrEntryId}) `
                                        tag_ids.forEach(finalentryId => {
                                            // append  `and FIND_IN_SET(value,tag_ids)`
                                            remainingTagCountQuery += `and (tag_ids LIKE "${finalentryId}" OR tag_ids LIKE "%,${finalentryId},%" OR tag_ids LIKE "${finalentryId},%" OR tag_ids LIKE "%,${finalentryId}")`
                                        });
                                        remainingTagCountQuery += `and (tag_ids LIKE "${tempTagId}" OR tag_ids LIKE "%,${tempTagId},%" OR tag_ids LIKE "${tempTagId},%" OR tag_ids LIKE "%,${tempTagId}")`

                                        SQLHelper.performTransactionWithQuery(Constants.dbRefrence, remainingTagCountQuery, (aggregateTagCount) => {
                                            // console.log('---->>> Offline entry_tags remainingTagCountQuery ---->>>', aggregateTagCount);           
                                            if (aggregateTagCount) {
                                                const tagCount = aggregateTagCount[0].aggregate
                                                var keywordCondition = ''
                                                if (keyword != '' ) {
                                                    keywordCondition = ` and ( `
                                                    var keywords = keyword.split(' ');
                                                    keywords.forEach(sepratedKeyword => {
                                                        keywordCondition += `name like "${sepratedKeyword}%" `
                                                    });
                                                    keywordCondition += ` or name like "${keyword}% )" `
                                                }
                                                const originalTagQuery = `select * from tags where id = ${tempTagId}${keywordCondition} limit 1`
                                                SQLHelper.performTransactionWithQuery(Constants.dbRefrence, originalTagQuery, (originalTag) => {
                                                    if (originalTag) {
                                                        // console.log('---->>> Offline entry_tags originalTagQuery ---->>>', originalTag[0]);         
                                                        var dictData = {};
                                                        dictData["tag_id"] = originalTag[0].id;
                                                        dictData["tag_name"] = originalTag[0].name;
                                                        dictData["tag_count"] = tagCount;
                                                        tagArr.push(dictData)
                                                        cnt++;
                                                        // console.log(`---->>> Offline entry_tags Final Data ---->>>`,cnt,tempTagIds.length); 
                                                        if (cnt == uniqueTempTagIds.length) {
                                                            // const sortedData = MultiKeySort(tagArr, sortOrder)
                                                            const sortedData = tagArr;
                                                            // console.log(`---->>> Offline entry_tags Final sortedData Data ---->>>`,sortedData);
                                                            dispatch({
                                                                type: ActionTypes.CATEGORY_TAG_LIST,
                                                                arrCategoryTag: sortedData,
                                                                is_redirect: properTagCount >= 1
                                                            });
                                                        }
                                                    }
                                                })
                                            }
                                        })
                                    }
                                    else {
                                        // console.log(`---->>> Offline entry_tags Final else else else ---->>>`);                                           
                                        cnt++;
                                        if (cnt == uniqueTempTagIds.length) {
                                            // const sortedData = MultiKeySort(tagArr, sortOrder)
                                            const sortedData = tagArr;
                                            console.log(`---->>> Offline entry_tags Final else sortedData Data ---->>>`, sortedData);
                                            dispatch({
                                                type: ActionTypes.CATEGORY_TAG_LIST,
                                                arrCategoryTag: sortedData,
                                                is_redirect: properTagCount >= 1
                                            });
                                        }
                                    }
                                });

                            }
                        }
                    })
                }
            })
        }
    }
}

function addTag(tagName,navigation) {
    return dispatch => {
        const dateTime = Helper.getUTCTime()
        const insertQuery = `insert into tags (name, is_active, updated_at, created_at) values ("${tagName}", 1 , "${dateTime}", "${dateTime}")`
        if (Constants.isOnline) {
            dispatch({
                type: ActionTypes.ADD_TAG_LOADING,
                loading: true,
            })

            mApi.addTag(tagName).then(response => {
                if (response.ok) {
                    if (response.data.success) {
                        // if (response.data.data.length > 0) {
                        //     dispatch({
                        //         type: ActionTypes.ADDED_TAG,
                        //         addedTag: response.data.data[0],
                        //     })
                        // }
                        if (response.data.data && response.data.data.id) {
                            SQLHelper.performTransactionWithQuery(Constants.dbRefrence, insertQuery, (arrTag) => {
                                console.log('Got callback insert ------>',arrTag);
                             });
                            dispatch({
                                type: ActionTypes.ADDED_TAG,
                                addedTag: response.data.data,
                            })
                        }
                    } else {
                        Helper.showAlert(response.data.message, navigation, response.data.status)
                    }
                } else {
                    Helper.showAlert(response.problem,navigation)
                }
                dispatch({
                    type: ActionTypes.ADD_TAG_LOADING,
                    loading: false,
                })
            }).catch(error => {
                console.log('error:', error);
                dispatch({
                    type: ActionTypes.ADD_TAG_LOADING,
                    loading: false,
                })
            })
        } else {
            const selectTagQuery = `select * from tags where name = "${tagName}" limit 1`
            SQLHelper.performTransactionWithQuery(Constants.dbRefrence, selectTagQuery, (arrTag) => {
                console.log('Got callback select ------>',arrTag.length);
                if (arrTag.length == 1) {
                    dispatch({
                        type: ActionTypes.ADDED_TAG,
                        addedTag: arrTag[0],
                    })
                } else {
                    console.log('Not found callback select  ------>',arrTag);
                    SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertQuery, (insertedTagId) => {
                        console.log('Got callback insert ------>',response);
                        const insertOfflineTagQuery = `insert into offline_insertions (table_name, row_insert_id) values ("tags",${insertedTagId})`
                        SQLHelper.performInsertGetIdQuery(Constants.dbRefrence, insertOfflineTagQuery, (offlineInsertId) => {});
                        SQLHelper.performTransactionWithQuery(Constants.dbRefrence, selectTagQuery, (arrAddedTag) => {
                            console.log('Got callback select 22 ------>',arrAddedTag.length);
                            if (arrAddedTag.length == 1) {
                                dispatch({
                                    type: ActionTypes.ADDED_TAG,
                                    addedTag: arrAddedTag[0],
                                })
                            }
                        });
                    });
                }
                
            });
        }
    }
}

export { categoryActions };

