import React, { Component, Fragment } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  Image,
  ImageBackground,
  TouchableOpacity,
  FlatList,
  Modal,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { Images, Colors, Metrics, Fonts } from "../Themes";
import styles from "./Styles/YourEntriesScreenStyles";
import { SafeAreaView } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import BaseComponent from "../Components/BaseComponent";
import ActionCreators from "../Redux/actions";
import { connect } from "react-redux";
import LoadingComponent from "../Components/LoadingComponent";
import { moveToListScreen } from "../Lib/Helper";
import { api } from "../../src/utils/api";

class EntryListingScreen extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      arrEntries: [],
      params: this.props.route.params,
      entryOpen: false,
      imgLoading: false,
    };
    this.getEntries = this.getEntries.bind(this);
    this.renderPhotos = this.renderPhotos.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getEntries();
  }

  async getEntries() {
    let data = {
      latitude: this.state.params.lat,
      longitude: this.state.params.lng,
      distanceInMetres: this.state.params.distance,
      search_months: this.state.params.search_months,
      kwd: this.state.params.category,
      tags: this.state.params.tags,
    };

    const entries = await api("v1/api/request", "POST", {
      endpoint: "entry/list",
      type: "POST",
      data: data,
    });

    if ((entries.status = 200)) {
      this.setState({
        arrEntries: entries.data.data,
        loading: false,
      });
    }
  }

  onPressEntryItem = (i) => {
    if (this.state.entryOpen == i) {
      this.setState({
        entryOpen: false,
      });
    } else {
      this.setState({
        entryOpen: i,
      });
    }
  };

  renderItemTitle = (item) => {
    return (
      <View style={styles.listBody}>
        <Text style={styles.smallText}>{item.categories}</Text>
        <Text style={styles.listItemBigText}>
          {item.tags.replace(/,/g, ", ")}
        </Text>
      </View>
    );
  };

  renderPhotos = (photos, parentThis) => {
    let array = JSON.parse(photos);
    if (array === null || array === undefined) {
      return null;
    } else if (Array.isArray(array)) {
      return array.map(function (photo) {
        return (
          <>
            {parentThis.state.imgLoading && (
              <ActivityIndicator color={"#FFF"} />
            )}
            <Image
              source={{ uri: photo }}
              style={styles.itemImage}
              onLoadStart={() =>
                parentThis.setState({
                  imgLoading: true,
                })
              }
              onLoadEnd={() =>
                parentThis.setState({
                  imgLoading: false,
                })
              }
            />
          </>
        );
      });
    } else {
      // Handle the case where 'array' is neither null nor undefined but not an array
      return null; // Or handle the error appropriately
    }
  };

  renderEntryItem(item, index) {
    let parentThis = this;
    let photos = JSON.parse(item.photos);
    return (
      <TouchableOpacity
        style={styles.listRow}
        onPress={() => {
          if (photos !== null || item.description !== null) {
            this.onPressEntryItem(index);
          }
        }}
      >
        <View style={[styles.listItem, styles.listBorderNone]}>
          {this.renderItemTitle(item)}
          {(photos !== null || item.description !== null) &&
            (this.state.entryOpen !== false ? (
              <View style={styles.listItemRight}>
                <Image source={Images.arrowTop} style={styles.listItemArrow} />
              </View>
            ) : (
              <View style={styles.listItemRight}>
                <Image
                  source={Images.arrowBottom}
                  style={styles.listItemArrow}
                />
              </View>
            ))}
        </View>
        <View style={styles.listRowBottom}>
          <View style={[styles.textTag, styles.flex]}>
            <Text style={styles.thirtheenStyle}>
              {item.currency_code} {item.price}{" "}
            </Text>
          </View>
          <Text style={[styles.thirtheenStyleLight, styles.italic]}>
            {item.created_at}
          </Text>
        </View>
        {this.state.entryOpen !== false && this.state.entryOpen == index && (
          <View style={styles.photoDescSection}>
            <View style={styles.decriptionSection}>
              <Text style={styles.thirtheenStyle}>{item.description}</Text>
            </View>
            <View>{parentThis.renderPhotos(item.photos, parentThis)}</View>
          </View>
        )}
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.props.navigation.goBack()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Entries</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity style={styles.rightIcon}>
                <Text style={styles.headerText}> </Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={[styles.content, { paddingTop: 0 }]}>
              <FlatList
                data={this.state.arrEntries}
                renderItem={({ item, index }) =>
                  this.renderEntryItem(item, index)
                }
                keyExtractor={(item, index) => index + ""}
                key={"tags"}
                extraData={this.state}
              />
            </View>
          </ScrollView>
        </SafeAreaView>

        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

export default EntryListingScreen;
