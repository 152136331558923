import {StyleSheet, Platform} from 'react-native';
import {Metrics, ApplicationStyles, Colors, Fonts} from '../../Themes/';

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  SafeAreaViewFooter: {
    height:50,
    // flex: 0.15,
    backgroundColor: '#66BDB0',
    //marginTop: (Metrics.screenWidth * 50) / 828,
  },
  footerNav: {
    flexDirection: 'row',
    width: '100%',
    paddingTop:2
  },
  tabStyle: {
    flex: 1,
    height: (Metrics.screenWidth * 70) / 414,
    backgroundColor: Colors.transparent,
    flexDirection: 'column',
    borderBottomWidth: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerNavText: {
    fontSize: (Metrics.screenWidth * 13) / 414,
    color: '#fff',
    textAlign: 'center',
    marginTop: 5,
    fontFamily: "robotoMedium",
  },
  tabIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  footerNavIcon: {
    width: (Metrics.screenWidth * 18) / 414,
    height: (Metrics.screenWidth * 18) / 414,
    resizeMode: 'contain',
    marginBottom: 6,
    alignSelf: 'center',
  },
  footerNavAdd: {
    flex: 1,
    backgroundColor: Colors.transparent,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: (Metrics.screenWidth * 65) / 414,
    height: (Metrics.screenWidth * 65) / 414,
  },
  Addplus: {
    width: (Metrics.screenWidth * 42) / 414,
    height: (Metrics.screenWidth * 42) / 414,
    resizeMode: 'contain',
    borderRadius:10,
    marginTop:15
  },
  tooltipStyleInner:{
    flex:1,
  },
  tooltipStyleContent: {
    backgroundColor: '#fff',
    borderRadius: 6,
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  tooltipTitle: {
    fontFamily: "base",
    fontSize: (Metrics.screenWidth * 22) / 414,
    lineHeight: (Metrics.screenWidth * 36) / 414,
    color: Colors.black,
    marginBottom: 5,
  },
  tooltipText: {
    fontFamily: "robotoBase",
    fontSize: (Metrics.screenWidth * 16) / 414,
    lineHeight: (Metrics.screenWidth * 24) / 414,
    color: Colors.black,
  },

  badgeStyle: {
    width: 8,
    aspectRatio: 1,
    borderRadius: 4,
    backgroundColor: '#FFBE45',
    position: 'absolute',
    alignSelf: 'flex-end',
  },
  tooltipMainContainerStyle: {
    width: (Metrics.screenWidth - 40),
    minHeight: 150,
    alignSelf:'center'
  }
});
