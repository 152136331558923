import React, { Component } from "react";
import { Image, ImageBackground } from "react-native";
import * as Helper from "../Lib/Helper";
import { Images } from "../Themes";
import styles from "./Styles/SplashScreenStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Constants from "../Lib/Constants";

import Api from "../Services/Api";
import { StorageOperation } from "../storage/asyncStorage";

export default class SplashScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.checkLogin();
    // FirebaseService.firebasePushSetup();
  }

  checkLogin = async () => {
    StorageOperation.getData([Constants.KEY_IS_LOGIN]).then((value) => {
      const isLogin = value[0][1];
      if (isLogin == "1") {
        this.toggleNotification();
      } else {
        Helper.resetNavigationToScreen(this.props.navigation, "AuthStack");
      }
    });
  };

  toggleNotification = () => {
    // FirebaseService.getNotificationToken().then(token => {
    //   FirebaseService.hasNotificationPermission().then(hasPermission => {
    //     const params = {
    //       isAllow: hasPermission ? 1 : 0,
    //       fcmToken: token,
    //     };
    //     const apiCall = Api.createSecure();
    //     apiCall
    //       .toggleNotification(params)
    //       .then(response => {
    //         console.log('response.ok',response.ok);
    //         console.log('response.data.success',response.data.success);
    //         if (response.ok && !response.data.success) {
    //           console.log('response.ok && !response.data.success',!response.data.success);
    //           Helper.showAlert(response.data.message, this.props.navigation, response.data.status)
    //         }
    //         else{
    Helper.resetNavigationToScreen(this.props.navigation, "HomeStack");
    //         }
    //       })
    //       .catch(err => {
    //         console.log('Error',err);
    //         Helper.resetNavigationToScreen(this.props.navigation, 'HomeStack');
    //       });
    //   });
    // });
  };

  render() {
    return (
      <ImageBackground
        source={Images.splashBackground}
        style={[styles.ImageBackground, { justifyContent: "center" }]}
      >
        <Image style={styles.logo} source={Images.logo} />
      </ImageBackground>
    );
  }
}
