import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors } from '../../Themes/'

export default StyleSheet.create({
    ...ApplicationStyles.screen,
    modalView:{        
      minWidth: 180,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 25,
      paddingBottom: 25,
      backgroundColor: 'white',
      borderRadius: 6,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
    textBlackLeftAlign: {
        color: 'black',
        textAlign: 'left',
    },    
})
