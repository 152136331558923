import React from "react";
import {
  Alert,
  Image,
  ScrollView,
  ImageBackground,
  Keyboard,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  SafeAreaView,
} from "react-native";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import Api from "../Services/Api";
import { Images, Metrics } from "../Themes";
import styles from "./Styles/AccountScreenStyles";

class ChangePasswordScreen extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      password: "",
      isCurrentPassVisible: false,
      isPassVisible: false,
      loading: false,
    };
  }

  onPressSave = () => {
    Keyboard.dismiss();
    if (Constants.isOnline) {
      if (this.state.currentPassword.trim() == "") {
        Helper.showAlert("Please enter current password");
      } else if (this.state.password.trim() == "") {
        Helper.showAlert(Constants.ERROR_MESSAGES.PASSWORD);
      } else if (!Helper.validatePassword(this.state.password)) {
        Helper.showAlert(Constants.ERROR_MESSAGES.VALID_PASSWORD);
      } else {
        this.setState({ loading: true });
        const mApi = Api.createSecure();
        mApi
          .changePassword(this.state.currentPassword, this.state.password)
          .then((response) => {
            if (response.ok) {
              if (response.data.success) {
                Alert.alert(
                  Constants.APP_NAME,
                  "Your password has been updated successfully.",
                  [
                    {
                      text: "Ok",
                      onPress: () => this.handleBackButtonClick(),
                    },
                  ],
                  { cancelable: false }
                );
              } else {
                Helper.showAlert(
                  response.data.message,
                  this.props.navigation,
                  response.data.status
                );
              }
            } else {
              Helper.showAlert(response.problem, this.props.navigation);
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log("error: ", error);
            this.setState({ loading: false });
          });
      }
    } else {
      // Helper.showAlert(Constants.ERROR_MESSAGES.NO_INTERNET)
    }
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.props.navigation.navigate("AccountScreen")}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Change password</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                style={styles.rightIconWithText}
                onPress={() => this.onPressSave()}
              >
                <Text style={styles.headerText}>Save</Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.accountList}>
              <View style={styles.accountItem}>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Current Password</Text>
                  <View style={styles.passwordRow}>
                    <TextInput
                      underlineColorAndroid="transparent"
                      placeholder="Current Password"
                      placeholderTextColor="rgba(255,255,255,0.7)"
                      value={this.state.currentPassword}
                      style={styles.inputField}
                      secureTextEntry={!this.state.isCurrentPassVisible}
                      onChangeText={(currentPassword) =>
                        this.setState({ currentPassword })
                      }
                      returnKeyType="next"
                      onSubmitEditing={(event) => this._password.focus()}
                    />
                    <TouchableOpacity
                      style={styles.visibilityTouch}
                      onPress={() =>
                        this.setState({
                          isCurrentPassVisible:
                            !this.state.isCurrentPassVisible,
                        })
                      }
                    >
                      <Image
                        source={
                          this.state.isCurrentPassVisible
                            ? Images.visibilityOn
                            : Images.visibilityOff
                        }
                        style={styles.visibility}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <View style={styles.accountItem}>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Password</Text>
                  <View style={styles.passwordRow}>
                    <TextInput
                      underlineColorAndroid="transparent"
                      placeholder="Password"
                      placeholderTextColor="rgba(255,255,255,0.7)"
                      value={this.state.password}
                      style={styles.inputField}
                      onChangeText={(password) => this.setState({ password })}
                      returnKeyType="go"
                      ref={(ref) => (this._password = ref)}
                      secureTextEntry={!this.state.isPassVisible}
                      onSubmitEditing={(event) => this.onPressSave()}
                    />
                    <TouchableOpacity
                      style={styles.visibilityTouch}
                      onPress={() =>
                        this.setState({
                          isPassVisible: !this.state.isPassVisible,
                        })
                      }
                    >
                      <Image
                        source={
                          this.state.isPassVisible
                            ? Images.visibilityOn
                            : Images.visibilityOff
                        }
                        style={styles.visibility}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
              <Text style={[styles.msg, { marginTop: 10 }]}>
                Use at least 8 characters
              </Text>
            </View>
          </ScrollView>
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}
export default ChangePasswordScreen;
