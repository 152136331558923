import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/native";
import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
  ScrollView,
} from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import { Images } from "../Themes";
import styles from "./Styles/AccountScreenStyles";

class AccountScreen extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      logoutLoading: false,
      userDetails: null,
      loading: true,
      activityUnreadCount: Constants.activityUnreadCount,
    };
    this.getUserDetail = this.getUserDetail.bind(this);
  }

  componentDidMount() {
    ActionCreators.activityActions.getActivityUnreadCount();
    this.getUserDetail();
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      this.setState({
        loading: false,
        userDetails: userdetails,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused == false && this.props.isFocused == true) {
      this.setState({
        loading: true,
      });
      this.getUserDetail();
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.userDetail != this.props.userDetail) {
  //         this.setState({ userDetail: this.props.userDetail })
  //     }
  //     if (prevProps.activityUnreadCount != this.props.activityUnreadCount) {
  //         // alert(this.props.activityUnreadCount)
  //         this.setState({ activityUnreadCount: this.props.activityUnreadCount });
  //     }
  // }

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }

    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.nevHeading}>Account</Text>
            <Image source={Images.logo} style={styles.headerLogo} />
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.profileArea}>
              <View style={[styles.profileRow, styles.profileRowFull]}>
                <Image source={Images.location} style={styles.profileIcon} />
                <View style={styles.profileBody}>
                  <Text style={styles.smallText}>Search location</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetails.search_location}
                  </Text>
                </View>
              </View>
              <View style={[styles.profileRow]}>
                <Image
                  source={Images.home_currency}
                  style={styles.profileIcon}
                />
                <View style={styles.profileBody}>
                  <Text style={styles.smallText}>Home currency</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetails.country.currency_code}
                  </Text>
                </View>
              </View>
              <View style={[styles.profileRow, styles.profileRowFull]}>
                <Image source={Images.email} style={styles.profileIcon} />
                <View style={styles.profileBody}>
                  <Text style={styles.smallText}>Email</Text>
                  <Text style={styles.heading5}>
                    {this.state.userDetails.email}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.accountList}>
              {/* <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("EditAccountScreen")
                }
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>Edit account details</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity> */}
              {this.state.userDetails.social_type === "Normal" ? (
                <TouchableOpacity
                  style={styles.accountItem}
                  onPress={() =>
                    this.props.navigation.navigate("ChangePasswordScreen")
                  }
                >
                  <View style={styles.accountItemBody}>
                    <Text style={styles.heading5}>Change password</Text>
                  </View>
                  <View style={styles.accountItemRight}>
                    <Image
                      source={Images.arrowRight}
                      style={styles.listItemArrow}
                    />
                  </View>
                </TouchableOpacity>
              ) : null}
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("YourEntriesScreen")
                }
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>Your entries</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("PreferencesScreen")
                }
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>Preferences</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              {/* <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("OfflineSettingsScreen")
                }
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>Offline settings</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity> */}
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("TermsAndConditionsScreen")
                }
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>{"Terms & Conditions"}</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() =>
                  this.props.navigation.navigate("PrivacyPolicyScreen")
                }
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>Privacy Policy</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() => this.onLogout()}
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.heading5}>Logout</Text>
                </View>
                <View style={styles.accountItemRight}>
                  <Image
                    source={Images.arrowRight}
                    style={styles.listItemArrow}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </SafeAreaView>
        {this.state.logoutLoading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }

  onLogout = async () => {
    await AsyncStorage.multiRemove([
      "user",
      "token",
      "refreshToken",
      "expires_in",
    ]);
    this.props.navigation.navigate("LoginNavigator");
  };

  sigoutGoogle = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };
}
export default function (props) {
  const isFocused = useIsFocused();
  return <AccountScreen {...props} isFocused={isFocused} />;
}
