import ActionTypes from './types'
import Api from '../../Services/Api';
import Constants from '../../Lib/Constants';

const countryActions = {
    getCountries
}

function getCountries() {
    // return dispatch => {
    //     // if (Constants.isOnline) {
    //     //     dispatch({
    //     //         type: ActionTypes.COUNTRIES_LOADING,
    //     //         loading: true,
    //     //     })
    //     //     const mApi = Api.create()
    //     //     mApi.getCountries().then(response => {
    //     //         if (response.ok) {
    //     //             if (response.data.success) {
    //     //                 const data = response.data.data;
    //     //                 var arr = []
    //     //                 if (data.length > 0) {
    //     //                     data.map((item, index) => {
    //     //                         // item.label = item.name
    //     //                         // item.value = item.id
    //     //                         arr.push({ label: item.name, value: item.id })
    //     //                     })
    //     //                 }
    //     //                 dispatch({
    //     //                     type: ActionTypes.COUNTRIES,
    //     //                     arrCountries: arr,
    //     //                     arrCountryData: data
    //     //                 })
    //     //             } else {
    //     //                 Helper.showAlert(response.data.message)
    //     //             }
    //     //         } else {
    //     //             Helper.showAlert(response.problem)
    //     //         }
    //     //         dispatch({
    //     //             type: ActionTypes.COUNTRIES_LOADING,
    //     //             loading: false,
    //     //         })
    //     //     }).catch(err => {
    //     //         console.log('error: ', err);
    //     //         dispatch({
    //     //             type: ActionTypes.COUNTRIES_LOADING,
    //     //             loading: false,
    //     //         })
    //     //     })
    //     // }
    //     // else{
    //         // Helper.showAlert(Constants.ERROR_MESSAGES.NO_INTERNET)
    //         dispatch({
    //             type: ActionTypes.COUNTRIES,
    //             arrCountries: [
    //                              {
    //                                "key": 1,
    //                                "label": "Afghanistan"
    //                              },
    //                              {
    //                                "key": 2,
    //                                "label": "Albania"
    //                              },
    //                              {
    //                                "key": 3,
    //                                "label": "Algeria"
    //                              },
    //                              {
    //                                "key": 4,
    //                                "label": "American Samoa"
    //                              },
    //                              {
    //                                "key": 5,
    //                                "label": "Andorra"
    //                              },
    //                              {
    //                                "key": 6,
    //                                "label": "Angola"
    //                              },
    //                              {
    //                                "key": 7,
    //                                "label": "Anguilla"
    //                              },
    //                              {
    //                                "key": 8,
    //                                "label": "Antarctica"
    //                              },
    //                              {
    //                                "key": 9,
    //                                "label": "Antigua and Barbuda"
    //                              },
    //                              {
    //                                "key": 10,
    //                                "label": "Argentina"
    //                              },
    //                              {
    //                                "key": 11,
    //                                "label": "Armenia"
    //                              },
    //                              {
    //                                "key": 12,
    //                                "label": "Aruba"
    //                              },
    //                              {
    //                                "key": 13,
    //                                "label": "Australia"
    //                              },
    //                              {
    //                                "key": 14,
    //                                "label": "Austria"
    //                              },
    //                              {
    //                                "key": 15,
    //                                "label": "Azerbaijan"
    //                              },
    //                              {
    //                                "key": 16,
    //                                "label": "Bahamas"
    //                              },
    //                              {
    //                                "key": 17,
    //                                "label": "Bahrain"
    //                              },
    //                              {
    //                                "key": 18,
    //                                "label": "Bangladesh"
    //                              },
    //                              {
    //                                "key": 19,
    //                                "label": "Barbados"
    //                              },
    //                              {
    //                                "key": 20,
    //                                "label": "Belarus"
    //                              },
    //                              {
    //                                "key": 21,
    //                                "label": "Belgium"
    //                              },
    //                              {
    //                                "key": 22,
    //                                "label": "Belize"
    //                              },
    //                              {
    //                                "key": 23,
    //                                "label": "Benin"
    //                              },
    //                              {
    //                                "key": 24,
    //                                "label": "Bermuda"
    //                              },
    //                              {
    //                                "key": 25,
    //                                "label": "Bhutan"
    //                              },
    //                              {
    //                                "key": 26,
    //                                "label": "Bolivia"
    //                              },
    //                              {
    //                                "key": 27,
    //                                "label": "Bosnia and Herzegovina"
    //                              },
    //                              {
    //                                "key": 28,
    //                                "label": "Botswana"
    //                              },
    //                              {
    //                                "key": 29,
    //                                "label": "Bouvet Island"
    //                              },
    //                              {
    //                                "key": 30,
    //                                "label": "Brazil"
    //                              },
    //                              {
    //                                "key": 31,
    //                                "label": "British Indian Ocean Territory"
    //                              },
    //                              {
    //                                "key": 32,
    //                                "label": "Brunei Darussalam"
    //                              },
    //                              {
    //                                "key": 33,
    //                                "label": "Bulgaria"
    //                              },
    //                              {
    //                                "key": 34,
    //                                "label": "Burkina Faso"
    //                              },
    //                              {
    //                                "key": 35,
    //                                "label": "Burundi"
    //                              },
    //                              {
    //                                "key": 36,
    //                                "label": "Cambodia"
    //                              },
    //                              {
    //                                "key": 37,
    //                                "label": "Cameroon"
    //                              },
    //                              {
    //                                "key": 38,
    //                                "label": "Canada"
    //                              },
    //                              {
    //                                "key": 39,
    //                                "label": "Cape Verde"
    //                              },
    //                              {
    //                                "key": 40,
    //                                "label": "Cayman Islands"
    //                              },
    //                              {
    //                                "key": 41,
    //                                "label": "Central African Republic"
    //                              },
    //                              {
    //                                "key": 42,
    //                                "label": "Chad"
    //                              },
    //                              {
    //                                "key": 43,
    //                                "label": "Chile"
    //                              },
    //                              {
    //                                "key": 44,
    //                                "label": "China"
    //                              },
    //                              {
    //                                "key": 45,
    //                                "label": "Christmas Island"
    //                              },
    //                              {
    //                                "key": 46,
    //                                "label": "Cocos (Keeling) Islands"
    //                              },
    //                              {
    //                                "key": 47,
    //                                "label": "Colombia"
    //                              },
    //                              {
    //                                "key": 48,
    //                                "label": "Comoros"
    //                              },
    //                              {
    //                                "key": 49,
    //                                "label": "Congo"
    //                              },
    //                              {
    //                                "key": 50,
    //                                "label": "Cook Islands"
    //                              },
    //                              {
    //                                "key": 51,
    //                                "label": "Costa Rica"
    //                              },
    //                              {
    //                                "key": 52,
    //                                "label": "Cote D'Ivoire"
    //                              },
    //                              {
    //                                "key": 53,
    //                                "label": "Croatia"
    //                              },
    //                              {
    //                                "key": 54,
    //                                "label": "Cuba"
    //                              },
    //                              {
    //                                "key": 55,
    //                                "label": "Cyprus"
    //                              },
    //                              {
    //                                "key": 56,
    //                                "label": "Czech Republic"
    //                              },
    //                              {
    //                                "key": 57,
    //                                "label": "Denmark"
    //                              },
    //                              {
    //                                "key": 58,
    //                                "label": "Djibouti"
    //                              },
    //                              {
    //                                "key": 59,
    //                                "label": "Dominica"
    //                              },
    //                              {
    //                                "key": 60,
    //                                "label": "Dominican Republic"
    //                              },
    //                              {
    //                                "key": 61,
    //                                "label": "East Timor"
    //                              },
    //                              {
    //                                "key": 62,
    //                                "label": "Ecuador"
    //                              },
    //                              {
    //                                "key": 63,
    //                                "label": "Egypt"
    //                              },
    //                              {
    //                                "key": 64,
    //                                "label": "El Salvador"
    //                              },
    //                              {
    //                                "key": 65,
    //                                "label": "Equatorial Guinea"
    //                              },
    //                              {
    //                                "key": 66,
    //                                "label": "Eritrea"
    //                              },
    //                              {
    //                                "key": 67,
    //                                "label": "Estonia"
    //                              },
    //                              {
    //                                "key": 68,
    //                                "label": "Ethiopia"
    //                              },
    //                              {
    //                                "key": 69,
    //                                "label": "Falkland Islands (Malvinas)"
    //                              },
    //                              {
    //                                "key": 70,
    //                                "label": "Faroe Islands"
    //                              },
    //                              {
    //                                "key": 71,
    //                                "label": "Fiji"
    //                              },
    //                              {
    //                                "key": 72,
    //                                "label": "Finland"
    //                              },
    //                              {
    //                                "key": 74,
    //                                "label": "France"
    //                              },
    //                              {
    //                                "key": 75,
    //                                "label": "French Guiana"
    //                              },
    //                              {
    //                                "key": 76,
    //                                "label": "French Polynesia"
    //                              },
    //                              {
    //                                "key": 77,
    //                                "label": "French Southern Territories"
    //                              },
    //                              {
    //                                "key": 78,
    //                                "label": "Gabon"
    //                              },
    //                              {
    //                                "key": 79,
    //                                "label": "Gambia"
    //                              },
    //                              {
    //                                "key": 80,
    //                                "label": "Georgia"
    //                              },
    //                              {
    //                                "key": 81,
    //                                "label": "Germany"
    //                              },
    //                              {
    //                                "key": 82,
    //                                "label": "Ghana"
    //                              },
    //                              {
    //                                "key": 83,
    //                                "label": "Gibraltar"
    //                              },
    //                              {
    //                                "key": 84,
    //                                "label": "Greece"
    //                              },
    //                              {
    //                                "key": 85,
    //                                "label": "Greenland"
    //                              },
    //                              {
    //                                "key": 86,
    //                                "label": "Grenada"
    //                              },
    //                              {
    //                                "key": 87,
    //                                "label": "Guadeloupe"
    //                              },
    //                              {
    //                                "key": 88,
    //                                "label": "Guam"
    //                              },
    //                              {
    //                                "key": 89,
    //                                "label": "Guatemala"
    //                              },
    //                              {
    //                                "key": 90,
    //                                "label": "Guinea"
    //                              },
    //                              {
    //                                "key": 91,
    //                                "label": "Guinea-Bissau"
    //                              },
    //                              {
    //                                "key": 92,
    //                                "label": "Guyana"
    //                              },
    //                              {
    //                                "key": 93,
    //                                "label": "Haiti"
    //                              },
    //                              {
    //                                "key": 94,
    //                                "label": "Heard and Mc Donald Islands"
    //                              },
    //                              {
    //                                "key": 95,
    //                                "label": "Honduras"
    //                              },
    //                              {
    //                                "key": 96,
    //                                "label": "Hong Kong"
    //                              },
    //                              {
    //                                "key": 97,
    //                                "label": "Hungary"
    //                              },
    //                              {
    //                                "key": 98,
    //                                "label": "Iceland"
    //                              },
    //                              {
    //                                "key": 99,
    //                                "label": "India"
    //                              },
    //                              {
    //                                "key": 100,
    //                                "label": "Indonesia"
    //                              },
    //                              {
    //                                "key": 101,
    //                                "label": "Iran (Islamic Republic of)"
    //                              },
    //                              {
    //                                "key": 102,
    //                                "label": "Iraq"
    //                              },
    //                              {
    //                                "key": 103,
    //                                "label": "Ireland"
    //                              },
    //                              {
    //                                "key": 104,
    //                                "label": "Israel"
    //                              },
    //                              {
    //                                "key": 105,
    //                                "label": "Italy"
    //                              },
    //                              {
    //                                "key": 106,
    //                                "label": "Jamaica"
    //                              },
    //                              {
    //                                "key": 107,
    //                                "label": "Japan"
    //                              },
    //                              {
    //                                "key": 108,
    //                                "label": "Jordan"
    //                              },
    //                              {
    //                                "key": 109,
    //                                "label": "Kazakhstan"
    //                              },
    //                              {
    //                                "key": 110,
    //                                "label": "Kenya"
    //                              },
    //                              {
    //                                "key": 111,
    //                                "label": "Kiribati"
    //                              },
    //                              {
    //                                "key": 112,
    //                                "label": "North Korea"
    //                              },
    //                              {
    //                                "key": 113,
    //                                "label": "Korea, Republic of"
    //                              },
    //                              {
    //                                "key": 114,
    //                                "label": "Kuwait"
    //                              },
    //                              {
    //                                "key": 115,
    //                                "label": "Kyrgyzstan"
    //                              },
    //                              {
    //                                "key": 116,
    //                                "label": "Lao People's Democratic Republic"
    //                              },
    //                              {
    //                                "key": 117,
    //                                "label": "Latvia"
    //                              },
    //                              {
    //                                "key": 118,
    //                                "label": "Lebanon"
    //                              },
    //                              {
    //                                "key": 119,
    //                                "label": "Lesotho"
    //                              },
    //                              {
    //                                "key": 120,
    //                                "label": "Liberia"
    //                              },
    //                              {
    //                                "key": 121,
    //                                "label": "Libyan Arab Jamahiriya"
    //                              },
    //                              {
    //                                "key": 122,
    //                                "label": "Liechtenstein"
    //                              },
    //                              {
    //                                "key": 123,
    //                                "label": "Lithuania"
    //                              },
    //                              {
    //                                "key": 124,
    //                                "label": "Luxembourg"
    //                              },
    //                              {
    //                                "key": 125,
    //                                "label": "Macau"
    //                              },
    //                              {
    //                                "key": 126,
    //                                "label": "FYROM"
    //                              },
    //                              {
    //                                "key": 127,
    //                                "label": "Madagascar"
    //                              },
    //                              {
    //                                "key": 128,
    //                                "label": "Malawi"
    //                              },
    //                              {
    //                                "key": 129,
    //                                "label": "Malaysia"
    //                              },
    //                              {
    //                                "key": 130,
    //                                "label": "Maldives"
    //                              },
    //                              {
    //                                "key": 131,
    //                                "label": "Mali"
    //                              },
    //                              {
    //                                "key": 132,
    //                                "label": "Malta"
    //                              },
    //                              {
    //                                "key": 133,
    //                                "label": "Marshall Islands"
    //                              },
    //                              {
    //                                "key": 134,
    //                                "label": "Martinique"
    //                              },
    //                              {
    //                                "key": 135,
    //                                "label": "Mauritania"
    //                              },
    //                              {
    //                                "key": 136,
    //                                "label": "Mauritius"
    //                              },
    //                              {
    //                                "key": 137,
    //                                "label": "Mayotte"
    //                              },
    //                              {
    //                                "key": 138,
    //                                "label": "Mexico"
    //                              },
    //                              {
    //                                "key": 139,
    //                                "label": "Micronesia, Federated States of"
    //                              },
    //                              {
    //                                "key": 140,
    //                                "label": "Moldova, Republic of"
    //                              },
    //                              {
    //                                "key": 141,
    //                                "label": "Monaco"
    //                              },
    //                              {
    //                                "key": 142,
    //                                "label": "Mongolia"
    //                              },
    //                              {
    //                                "key": 143,
    //                                "label": "Montserrat"
    //                              },
    //                              {
    //                                "key": 144,
    //                                "label": "Morocco"
    //                              },
    //                              {
    //                                "key": 145,
    //                                "label": "Mozambique"
    //                              },
    //                              {
    //                                "key": 146,
    //                                "label": "Myanmar"
    //                              },
    //                              {
    //                                "key": 147,
    //                                "label": "Namibia"
    //                              },
    //                              {
    //                                "key": 148,
    //                                "label": "Nauru"
    //                              },
    //                              {
    //                                "key": 149,
    //                                "label": "Nepal"
    //                              },
    //                              {
    //                                "key": 150,
    //                                "label": "Netherlands"
    //                              },
    //                              {
    //                                "key": 151,
    //                                "label": "Netherlands Antilles"
    //                              },
    //                              {
    //                                "key": 152,
    //                                "label": "New Caledonia"
    //                              },
    //                              {
    //                                "key": 153,
    //                                "label": "New Zealand"
    //                              },
    //                              {
    //                                "key": 154,
    //                                "label": "Nicaragua"
    //                              },
    //                              {
    //                                "key": 155,
    //                                "label": "Niger"
    //                              },
    //                              {
    //                                "key": 156,
    //                                "label": "Nigeria"
    //                              },
    //                              {
    //                                "key": 157,
    //                                "label": "Niue"
    //                              },
    //                              {
    //                                "key": 158,
    //                                "label": "Norfolk Island"
    //                              },
    //                              {
    //                                "key": 159,
    //                                "label": "Northern Mariana Islands"
    //                              },
    //                              {
    //                                "key": 160,
    //                                "label": "Norway"
    //                              },
    //                              {
    //                                "key": 161,
    //                                "label": "Oman"
    //                              },
    //                              {
    //                                "key": 162,
    //                                "label": "Pakistan"
    //                              },
    //                              {
    //                                "key": 163,
    //                                "label": "Palau"
    //                              },
    //                              {
    //                                "key": 164,
    //                                "label": "Panama"
    //                              },
    //                              {
    //                                "key": 165,
    //                                "label": "Papua New Guinea"
    //                              },
    //                              {
    //                                "key": 166,
    //                                "label": "Paraguay"
    //                              },
    //                              {
    //                                "key": 167,
    //                                "label": "Peru"
    //                              },
    //                              {
    //                                "key": 168,
    //                                "label": "Philippines"
    //                              },
    //                              {
    //                                "key": 169,
    //                                "label": "Pitcairn"
    //                              },
    //                              {
    //                                "key": 170,
    //                                "label": "Poland"
    //                              },
    //                              {
    //                                "key": 171,
    //                                "label": "Portugal"
    //                              },
    //                              {
    //                                "key": 172,
    //                                "label": "Puerto Rico"
    //                              },
    //                              {
    //                                "key": 173,
    //                                "label": "Qatar"
    //                              },
    //                              {
    //                                "key": 174,
    //                                "label": "Reunion"
    //                              },
    //                              {
    //                                "key": 175,
    //                                "label": "Romania"
    //                              },
    //                              {
    //                                "key": 176,
    //                                "label": "Russian Federation"
    //                              },
    //                              {
    //                                "key": 177,
    //                                "label": "Rwanda"
    //                              },
    //                              {
    //                                "key": 178,
    //                                "label": "Saint Kitts and Nevis"
    //                              },
    //                              {
    //                                "key": 179,
    //                                "label": "Saint Lucia"
    //                              },
    //                              {
    //                                "key": 180,
    //                                "label": "Saint Vincent and the Grenadines"
    //                              },
    //                              {
    //                                "key": 181,
    //                                "label": "Samoa"
    //                              },
    //                              {
    //                                "key": 182,
    //                                "label": "San Marino"
    //                              },
    //                              {
    //                                "key": 183,
    //                                "label": "Sao Tome and Principe"
    //                              },
    //                              {
    //                                "key": 184,
    //                                "label": "Saudi Arabia"
    //                              },
    //                              {
    //                                "key": 185,
    //                                "label": "Senegal"
    //                              },
    //                              {
    //                                "key": 186,
    //                                "label": "Seychelles"
    //                              },
    //                              {
    //                                "key": 187,
    //                                "label": "Sierra Leone"
    //                              },
    //                              {
    //                                "key": 188,
    //                                "label": "Singapore"
    //                              },
    //                              {
    //                                "key": 189,
    //                                "label": "Slovakia"
    //                              },
    //                              {
    //                                "key": 190,
    //                                "label": "Slovenia"
    //                              },
    //                              {
    //                                "key": 191,
    //                                "label": "Solomon Islands"
    //                              },
    //                              {
    //                                "key": 192,
    //                                "label": "Somalia"
    //                              },
    //                              {
    //                                "key": 193,
    //                                "label": "South Africa"
    //                              },
    //                              {
    //                                "key": 194,
    //                                "label": "South Georgia &amp; South Sandwich Islands"
    //                              },
    //                              {
    //                                "key": 195,
    //                                "label": "Spain"
    //                              },
    //                              {
    //                                "key": 196,
    //                                "label": "Sri Lanka"
    //                              },
    //                              {
    //                                "key": 197,
    //                                "label": "St. Helena"
    //                              },
    //                              {
    //                                "key": 198,
    //                                "label": "St. Pierre and Miquelon"
    //                              },
    //                              {
    //                                "key": 199,
    //                                "label": "Sudan"
    //                              },
    //                              {
    //                                "key": 200,
    //                                "label": "Suriname"
    //                              },
    //                              {
    //                                "key": 201,
    //                                "label": "Svalbard and Jan Mayen Islands"
    //                              },
    //                              {
    //                                "key": 202,
    //                                "label": "Swaziland"
    //                              },
    //                              {
    //                                "key": 203,
    //                                "label": "Sweden"
    //                              },
    //                              {
    //                                "key": 204,
    //                                "label": "Switzerland"
    //                              },
    //                              {
    //                                "key": 205,
    //                                "label": "Syrian Arab Republic"
    //                              },
    //                              {
    //                                "key": 206,
    //                                "label": "Taiwan"
    //                              },
    //                              {
    //                                "key": 207,
    //                                "label": "Tajikistan"
    //                              },
    //                              {
    //                                "key": 208,
    //                                "label": "Tanzania, United Republic of"
    //                              },
    //                              {
    //                                "key": 209,
    //                                "label": "Thailand"
    //                              },
    //                              {
    //                                "key": 210,
    //                                "label": "Togo"
    //                              },
    //                              {
    //                                "key": 211,
    //                                "label": "Tokelau"
    //                              },
    //                              {
    //                                "key": 212,
    //                                "label": "Tonga"
    //                              },
    //                              {
    //                                "key": 213,
    //                                "label": "Trinidad and Tobago"
    //                              },
    //                              {
    //                                "key": 214,
    //                                "label": "Tunisia"
    //                              },
    //                              {
    //                                "key": 215,
    //                                "label": "Turkey"
    //                              },
    //                              {
    //                                "key": 216,
    //                                "label": "Turkmenistan"
    //                              },
    //                              {
    //                                "key": 217,
    //                                "label": "Turks and Caicos Islands"
    //                              },
    //                              {
    //                                "key": 218,
    //                                "label": "Tuvalu"
    //                              },
    //                              {
    //                                "key": 219,
    //                                "label": "Uganda"
    //                              },
    //                              {
    //                                "key": 220,
    //                                "label": "Ukraine"
    //                              },
    //                              {
    //                                "key": 221,
    //                                "label": "United Arab Emirates"
    //                              },
    //                              {
    //                                "key": 222,
    //                                "label": "United Kingdom"
    //                              },
    //                              {
    //                                "key": 223,
    //                                "label": "United States"
    //                              },
    //                              {
    //                                "key": 224,
    //                                "label": "United States Minor Outlying Islands"
    //                              },
    //                              {
    //                                "key": 225,
    //                                "label": "Uruguay"
    //                              },
    //                              {
    //                                "key": 226,
    //                                "label": "Uzbekistan"
    //                              },
    //                              {
    //                                "key": 227,
    //                                "label": "Vanuatu"
    //                              },
    //                              {
    //                                "key": 228,
    //                                "label": "Vatican City State (Holy See)"
    //                              },
    //                              {
    //                                "key": 229,
    //                                "label": "Venezuela"
    //                              },
    //                              {
    //                                "key": 230,
    //                                "label": "Viet Nam"
    //                              },
    //                              {
    //                                "key": 231,
    //                                "label": "Virgin Islands (British)"
    //                              },
    //                              {
    //                                "key": 232,
    //                                "label": "Virgin Islands (U.S.)"
    //                              },
    //                              {
    //                                "key": 233,
    //                                "label": "Wallis and Futuna Islands"
    //                              },
    //                              {
    //                                "key": 234,
    //                                "label": "Western Sahara"
    //                              },
    //                              {
    //                                "key": 235,
    //                                "label": "Yemen"
    //                              },
    //                              {
    //                                "key": 237,
    //                                "label": "Democratic Republic of Congo"
    //                              },
    //                              {
    //                                "key": 238,
    //                                "label": "Zambia"
    //                              },
    //                              {
    //                                "key": 239,
    //                                "label": "Zimbabwe"
    //                              },
    //                              {
    //                                "key": 240,
    //                                "label": "Jersey"
    //                              },
    //                              {
    //                                "key": 241,
    //                                "label": "Guernsey"
    //                              },
    //                              {
    //                                "key": 242,
    //                                "label": "Montenegro"
    //                              },
    //                              {
    //                                "key": 243,
    //                                "label": "Serbia"
    //                              },
    //                              {
    //                                "key": 244,
    //                                "label": "Aaland Islands"
    //                              },
    //                              {
    //                                "key": 245,
    //                                "label": "Bonaire, Sint Eustatius and Saba"
    //                              },
    //                              {
    //                                "key": 246,
    //                                "label": "Curacao"
    //                              },
    //                              {
    //                                "key": 247,
    //                                "label": "Palestinian Territory, Occupied"
    //                              },
    //                              {
    //                                "key": 248,
    //                                "label": "South Sudan"
    //                              },
    //                              {
    //                                "key": 249,
    //                                "label": "St. Barthelemy"
    //                              },
    //                              {
    //                                "key": 250,
    //                                "label": "St. Martin (French part)"
    //                              },
    //                              {
    //                                "key": 251,
    //                                "label": "Canary Islands"
    //                              }
    //                             ],
    //             arrCountryData: [
    //                              {
    //                                "key": 1,
    //                                "label": "Afghanistan"
    //                              },
    //                              {
    //                                "key": 2,
    //                                "label": "Albania"
    //                              },
    //                              {
    //                                "key": 3,
    //                                "label": "Algeria"
    //                              },
    //                              {
    //                                "key": 4,
    //                                "label": "American Samoa"
    //                              },
    //                              {
    //                                "key": 5,
    //                                "label": "Andorra"
    //                              },
    //                              {
    //                                "key": 6,
    //                                "label": "Angola"
    //                              },
    //                              {
    //                                "key": 7,
    //                                "label": "Anguilla"
    //                              },
    //                              {
    //                                "key": 8,
    //                                "label": "Antarctica"
    //                              },
    //                              {
    //                                "key": 9,
    //                                "label": "Antigua and Barbuda"
    //                              },
    //                              {
    //                                "key": 10,
    //                                "label": "Argentina"
    //                              },
    //                              {
    //                                "key": 11,
    //                                "label": "Armenia"
    //                              },
    //                              {
    //                                "key": 12,
    //                                "label": "Aruba"
    //                              },
    //                              {
    //                                "key": 13,
    //                                "label": "Australia"
    //                              },
    //                              {
    //                                "key": 14,
    //                                "label": "Austria"
    //                              },
    //                              {
    //                                "key": 15,
    //                                "label": "Azerbaijan"
    //                              },
    //                              {
    //                                "key": 16,
    //                                "label": "Bahamas"
    //                              },
    //                              {
    //                                "key": 17,
    //                                "label": "Bahrain"
    //                              },
    //                              {
    //                                "key": 18,
    //                                "label": "Bangladesh"
    //                              },
    //                              {
    //                                "key": 19,
    //                                "label": "Barbados"
    //                              },
    //                              {
    //                                "key": 20,
    //                                "label": "Belarus"
    //                              },
    //                              {
    //                                "key": 21,
    //                                "label": "Belgium"
    //                              },
    //                              {
    //                                "key": 22,
    //                                "label": "Belize"
    //                              },
    //                              {
    //                                "key": 23,
    //                                "label": "Benin"
    //                              },
    //                              {
    //                                "key": 24,
    //                                "label": "Bermuda"
    //                              },
    //                              {
    //                                "key": 25,
    //                                "label": "Bhutan"
    //                              },
    //                              {
    //                                "key": 26,
    //                                "label": "Bolivia"
    //                              },
    //                              {
    //                                "key": 27,
    //                                "label": "Bosnia and Herzegovina"
    //                              },
    //                              {
    //                                "key": 28,
    //                                "label": "Botswana"
    //                              },
    //                              {
    //                                "key": 29,
    //                                "label": "Bouvet Island"
    //                              },
    //                              {
    //                                "key": 30,
    //                                "label": "Brazil"
    //                              },
    //                              {
    //                                "key": 31,
    //                                "label": "British Indian Ocean Territory"
    //                              },
    //                              {
    //                                "key": 32,
    //                                "label": "Brunei Darussalam"
    //                              },
    //                              {
    //                                "key": 33,
    //                                "label": "Bulgaria"
    //                              },
    //                              {
    //                                "key": 34,
    //                                "label": "Burkina Faso"
    //                              },
    //                              {
    //                                "key": 35,
    //                                "label": "Burundi"
    //                              },
    //                              {
    //                                "key": 36,
    //                                "label": "Cambodia"
    //                              },
    //                              {
    //                                "key": 37,
    //                                "label": "Cameroon"
    //                              },
    //                              {
    //                                "key": 38,
    //                                "label": "Canada"
    //                              },
    //                              {
    //                                "key": 39,
    //                                "label": "Cape Verde"
    //                              },
    //                              {
    //                                "key": 40,
    //                                "label": "Cayman Islands"
    //                              },
    //                              {
    //                                "key": 41,
    //                                "label": "Central African Republic"
    //                              },
    //                              {
    //                                "key": 42,
    //                                "label": "Chad"
    //                              },
    //                              {
    //                                "key": 43,
    //                                "label": "Chile"
    //                              },
    //                              {
    //                                "key": 44,
    //                                "label": "China"
    //                              },
    //                              {
    //                                "key": 45,
    //                                "label": "Christmas Island"
    //                              },
    //                              {
    //                                "key": 46,
    //                                "label": "Cocos (Keeling) Islands"
    //                              },
    //                              {
    //                                "key": 47,
    //                                "label": "Colombia"
    //                              },
    //                              {
    //                                "key": 48,
    //                                "label": "Comoros"
    //                              },
    //                              {
    //                                "key": 49,
    //                                "label": "Congo"
    //                              },
    //                              {
    //                                "key": 50,
    //                                "label": "Cook Islands"
    //                              },
    //                              {
    //                                "key": 51,
    //                                "label": "Costa Rica"
    //                              },
    //                              {
    //                                "key": 52,
    //                                "label": "Cote D'Ivoire"
    //                              },
    //                              {
    //                                "key": 53,
    //                                "label": "Croatia"
    //                              },
    //                              {
    //                                "key": 54,
    //                                "label": "Cuba"
    //                              },
    //                              {
    //                                "key": 55,
    //                                "label": "Cyprus"
    //                              },
    //                              {
    //                                "key": 56,
    //                                "label": "Czech Republic"
    //                              },
    //                              {
    //                                "key": 57,
    //                                "label": "Denmark"
    //                              },
    //                              {
    //                                "key": 58,
    //                                "label": "Djibouti"
    //                              },
    //                              {
    //                                "key": 59,
    //                                "label": "Dominica"
    //                              },
    //                              {
    //                                "key": 60,
    //                                "label": "Dominican Republic"
    //                              },
    //                              {
    //                                "key": 61,
    //                                "label": "East Timor"
    //                              },
    //                              {
    //                                "key": 62,
    //                                "label": "Ecuador"
    //                              },
    //                              {
    //                                "key": 63,
    //                                "label": "Egypt"
    //                              },
    //                              {
    //                                "key": 64,
    //                                "label": "El Salvador"
    //                              },
    //                              {
    //                                "key": 65,
    //                                "label": "Equatorial Guinea"
    //                              },
    //                              {
    //                                "key": 66,
    //                                "label": "Eritrea"
    //                              },
    //                              {
    //                                "key": 67,
    //                                "label": "Estonia"
    //                              },
    //                              {
    //                                "key": 68,
    //                                "label": "Ethiopia"
    //                              },
    //                              {
    //                                "key": 69,
    //                                "label": "Falkland Islands (Malvinas)"
    //                              },
    //                              {
    //                                "key": 70,
    //                                "label": "Faroe Islands"
    //                              },
    //                              {
    //                                "key": 71,
    //                                "label": "Fiji"
    //                              },
    //                              {
    //                                "key": 72,
    //                                "label": "Finland"
    //                              },
    //                              {
    //                                "key": 74,
    //                                "label": "France"
    //                              },
    //                              {
    //                                "key": 75,
    //                                "label": "French Guiana"
    //                              },
    //                              {
    //                                "key": 76,
    //                                "label": "French Polynesia"
    //                              },
    //                              {
    //                                "key": 77,
    //                                "label": "French Southern Territories"
    //                              },
    //                              {
    //                                "key": 78,
    //                                "label": "Gabon"
    //                              },
    //                              {
    //                                "key": 79,
    //                                "label": "Gambia"
    //                              },
    //                              {
    //                                "key": 80,
    //                                "label": "Georgia"
    //                              },
    //                              {
    //                                "key": 81,
    //                                "label": "Germany"
    //                              },
    //                              {
    //                                "key": 82,
    //                                "label": "Ghana"
    //                              },
    //                              {
    //                                "key": 83,
    //                                "label": "Gibraltar"
    //                              },
    //                              {
    //                                "key": 84,
    //                                "label": "Greece"
    //                              },
    //                              {
    //                                "key": 85,
    //                                "label": "Greenland"
    //                              },
    //                              {
    //                                "key": 86,
    //                                "label": "Grenada"
    //                              },
    //                              {
    //                                "key": 87,
    //                                "label": "Guadeloupe"
    //                              },
    //                              {
    //                                "key": 88,
    //                                "label": "Guam"
    //                              },
    //                              {
    //                                "key": 89,
    //                                "label": "Guatemala"
    //                              },
    //                              {
    //                                "key": 90,
    //                                "label": "Guinea"
    //                              },
    //                              {
    //                                "key": 91,
    //                                "label": "Guinea-Bissau"
    //                              },
    //                              {
    //                                "key": 92,
    //                                "label": "Guyana"
    //                              },
    //                              {
    //                                "key": 93,
    //                                "label": "Haiti"
    //                              },
    //                              {
    //                                "key": 94,
    //                                "label": "Heard and Mc Donald Islands"
    //                              },
    //                              {
    //                                "key": 95,
    //                                "label": "Honduras"
    //                              },
    //                              {
    //                                "key": 96,
    //                                "label": "Hong Kong"
    //                              },
    //                              {
    //                                "key": 97,
    //                                "label": "Hungary"
    //                              },
    //                              {
    //                                "key": 98,
    //                                "label": "Iceland"
    //                              },
    //                              {
    //                                "key": 99,
    //                                "label": "India"
    //                              },
    //                              {
    //                                "key": 100,
    //                                "label": "Indonesia"
    //                              },
    //                              {
    //                                "key": 101,
    //                                "label": "Iran (Islamic Republic of)"
    //                              },
    //                              {
    //                                "key": 102,
    //                                "label": "Iraq"
    //                              },
    //                              {
    //                                "key": 103,
    //                                "label": "Ireland"
    //                              },
    //                              {
    //                                "key": 104,
    //                                "label": "Israel"
    //                              },
    //                              {
    //                                "key": 105,
    //                                "label": "Italy"
    //                              },
    //                              {
    //                                "key": 106,
    //                                "label": "Jamaica"
    //                              },
    //                              {
    //                                "key": 107,
    //                                "label": "Japan"
    //                              },
    //                              {
    //                                "key": 108,
    //                                "label": "Jordan"
    //                              },
    //                              {
    //                                "key": 109,
    //                                "label": "Kazakhstan"
    //                              },
    //                              {
    //                                "key": 110,
    //                                "label": "Kenya"
    //                              },
    //                              {
    //                                "key": 111,
    //                                "label": "Kiribati"
    //                              },
    //                              {
    //                                "key": 112,
    //                                "label": "North Korea"
    //                              },
    //                              {
    //                                "key": 113,
    //                                "label": "Korea, Republic of"
    //                              },
    //                              {
    //                                "key": 114,
    //                                "label": "Kuwait"
    //                              },
    //                              {
    //                                "key": 115,
    //                                "label": "Kyrgyzstan"
    //                              },
    //                              {
    //                                "key": 116,
    //                                "label": "Lao People's Democratic Republic"
    //                              },
    //                              {
    //                                "key": 117,
    //                                "label": "Latvia"
    //                              },
    //                              {
    //                                "key": 118,
    //                                "label": "Lebanon"
    //                              },
    //                              {
    //                                "key": 119,
    //                                "label": "Lesotho"
    //                              },
    //                              {
    //                                "key": 120,
    //                                "label": "Liberia"
    //                              },
    //                              {
    //                                "key": 121,
    //                                "label": "Libyan Arab Jamahiriya"
    //                              },
    //                              {
    //                                "key": 122,
    //                                "label": "Liechtenstein"
    //                              },
    //                              {
    //                                "key": 123,
    //                                "label": "Lithuania"
    //                              },
    //                              {
    //                                "key": 124,
    //                                "label": "Luxembourg"
    //                              },
    //                              {
    //                                "key": 125,
    //                                "label": "Macau"
    //                              },
    //                              {
    //                                "key": 126,
    //                                "label": "FYROM"
    //                              },
    //                              {
    //                                "key": 127,
    //                                "label": "Madagascar"
    //                              },
    //                              {
    //                                "key": 128,
    //                                "label": "Malawi"
    //                              },
    //                              {
    //                                "key": 129,
    //                                "label": "Malaysia"
    //                              },
    //                              {
    //                                "key": 130,
    //                                "label": "Maldives"
    //                              },
    //                              {
    //                                "key": 131,
    //                                "label": "Mali"
    //                              },
    //                              {
    //                                "key": 132,
    //                                "label": "Malta"
    //                              },
    //                              {
    //                                "key": 133,
    //                                "label": "Marshall Islands"
    //                              },
    //                              {
    //                                "key": 134,
    //                                "label": "Martinique"
    //                              },
    //                              {
    //                                "key": 135,
    //                                "label": "Mauritania"
    //                              },
    //                              {
    //                                "key": 136,
    //                                "label": "Mauritius"
    //                              },
    //                              {
    //                                "key": 137,
    //                                "label": "Mayotte"
    //                              },
    //                              {
    //                                "key": 138,
    //                                "label": "Mexico"
    //                              },
    //                              {
    //                                "key": 139,
    //                                "label": "Micronesia, Federated States of"
    //                              },
    //                              {
    //                                "key": 140,
    //                                "label": "Moldova, Republic of"
    //                              },
    //                              {
    //                                "key": 141,
    //                                "label": "Monaco"
    //                              },
    //                              {
    //                                "key": 142,
    //                                "label": "Mongolia"
    //                              },
    //                              {
    //                                "key": 143,
    //                                "label": "Montserrat"
    //                              },
    //                              {
    //                                "key": 144,
    //                                "label": "Morocco"
    //                              },
    //                              {
    //                                "key": 145,
    //                                "label": "Mozambique"
    //                              },
    //                              {
    //                                "key": 146,
    //                                "label": "Myanmar"
    //                              },
    //                              {
    //                                "key": 147,
    //                                "label": "Namibia"
    //                              },
    //                              {
    //                                "key": 148,
    //                                "label": "Nauru"
    //                              },
    //                              {
    //                                "key": 149,
    //                                "label": "Nepal"
    //                              },
    //                              {
    //                                "key": 150,
    //                                "label": "Netherlands"
    //                              },
    //                              {
    //                                "key": 151,
    //                                "label": "Netherlands Antilles"
    //                              },
    //                              {
    //                                "key": 152,
    //                                "label": "New Caledonia"
    //                              },
    //                              {
    //                                "key": 153,
    //                                "label": "New Zealand"
    //                              },
    //                              {
    //                                "key": 154,
    //                                "label": "Nicaragua"
    //                              },
    //                              {
    //                                "key": 155,
    //                                "label": "Niger"
    //                              },
    //                              {
    //                                "key": 156,
    //                                "label": "Nigeria"
    //                              },
    //                              {
    //                                "key": 157,
    //                                "label": "Niue"
    //                              },
    //                              {
    //                                "key": 158,
    //                                "label": "Norfolk Island"
    //                              },
    //                              {
    //                                "key": 159,
    //                                "label": "Northern Mariana Islands"
    //                              },
    //                              {
    //                                "key": 160,
    //                                "label": "Norway"
    //                              },
    //                              {
    //                                "key": 161,
    //                                "label": "Oman"
    //                              },
    //                              {
    //                                "key": 162,
    //                                "label": "Pakistan"
    //                              },
    //                              {
    //                                "key": 163,
    //                                "label": "Palau"
    //                              },
    //                              {
    //                                "key": 164,
    //                                "label": "Panama"
    //                              },
    //                              {
    //                                "key": 165,
    //                                "label": "Papua New Guinea"
    //                              },
    //                              {
    //                                "key": 166,
    //                                "label": "Paraguay"
    //                              },
    //                              {
    //                                "key": 167,
    //                                "label": "Peru"
    //                              },
    //                              {
    //                                "key": 168,
    //                                "label": "Philippines"
    //                              },
    //                              {
    //                                "key": 169,
    //                                "label": "Pitcairn"
    //                              },
    //                              {
    //                                "key": 170,
    //                                "label": "Poland"
    //                              },
    //                              {
    //                                "key": 171,
    //                                "label": "Portugal"
    //                              },
    //                              {
    //                                "key": 172,
    //                                "label": "Puerto Rico"
    //                              },
    //                              {
    //                                "key": 173,
    //                                "label": "Qatar"
    //                              },
    //                              {
    //                                "key": 174,
    //                                "label": "Reunion"
    //                              },
    //                              {
    //                                "key": 175,
    //                                "label": "Romania"
    //                              },
    //                              {
    //                                "key": 176,
    //                                "label": "Russian Federation"
    //                              },
    //                              {
    //                                "key": 177,
    //                                "label": "Rwanda"
    //                              },
    //                              {
    //                                "key": 178,
    //                                "label": "Saint Kitts and Nevis"
    //                              },
    //                              {
    //                                "key": 179,
    //                                "label": "Saint Lucia"
    //                              },
    //                              {
    //                                "key": 180,
    //                                "label": "Saint Vincent and the Grenadines"
    //                              },
    //                              {
    //                                "key": 181,
    //                                "label": "Samoa"
    //                              },
    //                              {
    //                                "key": 182,
    //                                "label": "San Marino"
    //                              },
    //                              {
    //                                "key": 183,
    //                                "label": "Sao Tome and Principe"
    //                              },
    //                              {
    //                                "key": 184,
    //                                "label": "Saudi Arabia"
    //                              },
    //                              {
    //                                "key": 185,
    //                                "label": "Senegal"
    //                              },
    //                              {
    //                                "key": 186,
    //                                "label": "Seychelles"
    //                              },
    //                              {
    //                                "key": 187,
    //                                "label": "Sierra Leone"
    //                              },
    //                              {
    //                                "key": 188,
    //                                "label": "Singapore"
    //                              },
    //                              {
    //                                "key": 189,
    //                                "label": "Slovakia"
    //                              },
    //                              {
    //                                "key": 190,
    //                                "label": "Slovenia"
    //                              },
    //                              {
    //                                "key": 191,
    //                                "label": "Solomon Islands"
    //                              },
    //                              {
    //                                "key": 192,
    //                                "label": "Somalia"
    //                              },
    //                              {
    //                                "key": 193,
    //                                "label": "South Africa"
    //                              },
    //                              {
    //                                "key": 194,
    //                                "label": "South Georgia &amp; South Sandwich Islands"
    //                              },
    //                              {
    //                                "key": 195,
    //                                "label": "Spain"
    //                              },
    //                              {
    //                                "key": 196,
    //                                "label": "Sri Lanka"
    //                              },
    //                              {
    //                                "key": 197,
    //                                "label": "St. Helena"
    //                              },
    //                              {
    //                                "key": 198,
    //                                "label": "St. Pierre and Miquelon"
    //                              },
    //                              {
    //                                "key": 199,
    //                                "label": "Sudan"
    //                              },
    //                              {
    //                                "key": 200,
    //                                "label": "Suriname"
    //                              },
    //                              {
    //                                "key": 201,
    //                                "label": "Svalbard and Jan Mayen Islands"
    //                              },
    //                              {
    //                                "key": 202,
    //                                "label": "Swaziland"
    //                              },
    //                              {
    //                                "key": 203,
    //                                "label": "Sweden"
    //                              },
    //                              {
    //                                "key": 204,
    //                                "label": "Switzerland"
    //                              },
    //                              {
    //                                "key": 205,
    //                                "label": "Syrian Arab Republic"
    //                              },
    //                              {
    //                                "key": 206,
    //                                "label": "Taiwan"
    //                              },
    //                              {
    //                                "key": 207,
    //                                "label": "Tajikistan"
    //                              },
    //                              {
    //                                "key": 208,
    //                                "label": "Tanzania, United Republic of"
    //                              },
    //                              {
    //                                "key": 209,
    //                                "label": "Thailand"
    //                              },
    //                              {
    //                                "key": 210,
    //                                "label": "Togo"
    //                              },
    //                              {
    //                                "key": 211,
    //                                "label": "Tokelau"
    //                              },
    //                              {
    //                                "key": 212,
    //                                "label": "Tonga"
    //                              },
    //                              {
    //                                "key": 213,
    //                                "label": "Trinidad and Tobago"
    //                              },
    //                              {
    //                                "key": 214,
    //                                "label": "Tunisia"
    //                              },
    //                              {
    //                                "key": 215,
    //                                "label": "Turkey"
    //                              },
    //                              {
    //                                "key": 216,
    //                                "label": "Turkmenistan"
    //                              },
    //                              {
    //                                "key": 217,
    //                                "label": "Turks and Caicos Islands"
    //                              },
    //                              {
    //                                "key": 218,
    //                                "label": "Tuvalu"
    //                              },
    //                              {
    //                                "key": 219,
    //                                "label": "Uganda"
    //                              },
    //                              {
    //                                "key": 220,
    //                                "label": "Ukraine"
    //                              },
    //                              {
    //                                "key": 221,
    //                                "label": "United Arab Emirates"
    //                              },
    //                              {
    //                                "key": 222,
    //                                "label": "United Kingdom"
    //                              },
    //                              {
    //                                "key": 223,
    //                                "label": "United States"
    //                              },
    //                              {
    //                                "key": 224,
    //                                "label": "United States Minor Outlying Islands"
    //                              },
    //                              {
    //                                "key": 225,
    //                                "label": "Uruguay"
    //                              },
    //                              {
    //                                "key": 226,
    //                                "label": "Uzbekistan"
    //                              },
    //                              {
    //                                "key": 227,
    //                                "label": "Vanuatu"
    //                              },
    //                              {
    //                                "key": 228,
    //                                "label": "Vatican City State (Holy See)"
    //                              },
    //                              {
    //                                "key": 229,
    //                                "label": "Venezuela"
    //                              },
    //                              {
    //                                "key": 230,
    //                                "label": "Viet Nam"
    //                              },
    //                              {
    //                                "key": 231,
    //                                "label": "Virgin Islands (British)"
    //                              },
    //                              {
    //                                "key": 232,
    //                                "label": "Virgin Islands (U.S.)"
    //                              },
    //                              {
    //                                "key": 233,
    //                                "label": "Wallis and Futuna Islands"
    //                              },
    //                              {
    //                                "key": 234,
    //                                "label": "Western Sahara"
    //                              },
    //                              {
    //                                "key": 235,
    //                                "label": "Yemen"
    //                              },
    //                              {
    //                                "key": 237,
    //                                "label": "Democratic Republic of Congo"
    //                              },
    //                              {
    //                                "key": 238,
    //                                "label": "Zambia"
    //                              },
    //                              {
    //                                "key": 239,
    //                                "label": "Zimbabwe"
    //                              },
    //                              {
    //                                "key": 240,
    //                                "label": "Jersey"
    //                              },
    //                              {
    //                                "key": 241,
    //                                "label": "Guernsey"
    //                              },
    //                              {
    //                                "key": 242,
    //                                "label": "Montenegro"
    //                              },
    //                              {
    //                                "key": 243,
    //                                "label": "Serbia"
    //                              },
    //                              {
    //                                "key": 244,
    //                                "label": "Aaland Islands"
    //                              },
    //                              {
    //                                "key": 245,
    //                                "label": "Bonaire, Sint Eustatius and Saba"
    //                              },
    //                              {
    //                                "key": 246,
    //                                "label": "Curacao"
    //                              },
    //                              {
    //                                "key": 247,
    //                                "label": "Palestinian Territory, Occupied"
    //                              },
    //                              {
    //                                "key": 248,
    //                                "label": "South Sudan"
    //                              },
    //                              {
    //                                "key": 249,
    //                                "label": "St. Barthelemy"
    //                              },
    //                              {
    //                                "key": 250,
    //                                "label": "St. Martin (French part)"
    //                              },
    //                              {
    //                                "key": 251,
    //                                "label": "Canary Islands"
    //                              }
    //                             ]
    //         })
    //         // if (Constants.dbRefrence == '') {
    //         //     SQLHelper.openDatabase((db) => {
    //         //         this._db = db
    //         //     })
    //         // }
    //         // else{
    //         //     this._db = Constants.dbRefrence
    //         // }
    //         // const countriesQuery = `select * from countries where is_active = 1 order by name asc`
    //         // SQLHelper.performTransactionWithQuery(this._db, countriesQuery, (arrayCountries) => {
    //         //     if (arrayCountries.length > 0) {
    //         //         var arr = []
    //         //         arrayCountries.map((item, index) => {
    //         //             // item.label = item.name
    //         //             // item.value = item.id
    //         //             arr.push({ label: item.name, key: item.id })
    //         //         })
    //         //     }
    //         // })
    //     // }
    // }

    return [
      {
        "key": 1,
        "label": "Afghanistan"
      },
      {
        "key": 2,
        "label": "Albania"
      },
      {
        "key": 3,
        "label": "Algeria"
      },
      {
        "key": 4,
        "label": "American Samoa"
      },
      {
        "key": 5,
        "label": "Andorra"
      },
      {
        "key": 6,
        "label": "Angola"
      },
      {
        "key": 7,
        "label": "Anguilla"
      },
      {
        "key": 8,
        "label": "Antarctica"
      },
      {
        "key": 9,
        "label": "Antigua and Barbuda"
      },
      {
        "key": 10,
        "label": "Argentina"
      },
      {
        "key": 11,
        "label": "Armenia"
      },
      {
        "key": 12,
        "label": "Aruba"
      },
      {
        "key": 13,
        "label": "Australia"
      },
      {
        "key": 14,
        "label": "Austria"
      },
      {
        "key": 15,
        "label": "Azerbaijan"
      },
      {
        "key": 16,
        "label": "Bahamas"
      },
      {
        "key": 17,
        "label": "Bahrain"
      },
      {
        "key": 18,
        "label": "Bangladesh"
      },
      {
        "key": 19,
        "label": "Barbados"
      },
      {
        "key": 20,
        "label": "Belarus"
      },
      {
        "key": 21,
        "label": "Belgium"
      },
      {
        "key": 22,
        "label": "Belize"
      },
      {
        "key": 23,
        "label": "Benin"
      },
      {
        "key": 24,
        "label": "Bermuda"
      },
      {
        "key": 25,
        "label": "Bhutan"
      },
      {
        "key": 26,
        "label": "Bolivia"
      },
      {
        "key": 27,
        "label": "Bosnia and Herzegovina"
      },
      {
        "key": 28,
        "label": "Botswana"
      },
      {
        "key": 29,
        "label": "Bouvet Island"
      },
      {
        "key": 30,
        "label": "Brazil"
      },
      {
        "key": 31,
        "label": "British Indian Ocean Territory"
      },
      {
        "key": 32,
        "label": "Brunei Darussalam"
      },
      {
        "key": 33,
        "label": "Bulgaria"
      },
      {
        "key": 34,
        "label": "Burkina Faso"
      },
      {
        "key": 35,
        "label": "Burundi"
      },
      {
        "key": 36,
        "label": "Cambodia"
      },
      {
        "key": 37,
        "label": "Cameroon"
      },
      {
        "key": 38,
        "label": "Canada"
      },
      {
        "key": 39,
        "label": "Cape Verde"
      },
      {
        "key": 40,
        "label": "Cayman Islands"
      },
      {
        "key": 41,
        "label": "Central African Republic"
      },
      {
        "key": 42,
        "label": "Chad"
      },
      {
        "key": 43,
        "label": "Chile"
      },
      {
        "key": 44,
        "label": "China"
      },
      {
        "key": 45,
        "label": "Christmas Island"
      },
      {
        "key": 46,
        "label": "Cocos (Keeling) Islands"
      },
      {
        "key": 47,
        "label": "Colombia"
      },
      {
        "key": 48,
        "label": "Comoros"
      },
      {
        "key": 49,
        "label": "Congo"
      },
      {
        "key": 50,
        "label": "Cook Islands"
      },
      {
        "key": 51,
        "label": "Costa Rica"
      },
      {
        "key": 52,
        "label": "Cote D'Ivoire"
      },
      {
        "key": 53,
        "label": "Croatia"
      },
      {
        "key": 54,
        "label": "Cuba"
      },
      {
        "key": 55,
        "label": "Cyprus"
      },
      {
        "key": 56,
        "label": "Czech Republic"
      },
      {
        "key": 57,
        "label": "Denmark"
      },
      {
        "key": 58,
        "label": "Djibouti"
      },
      {
        "key": 59,
        "label": "Dominica"
      },
      {
        "key": 60,
        "label": "Dominican Republic"
      },
      {
        "key": 61,
        "label": "East Timor"
      },
      {
        "key": 62,
        "label": "Ecuador"
      },
      {
        "key": 63,
        "label": "Egypt"
      },
      {
        "key": 64,
        "label": "El Salvador"
      },
      {
        "key": 65,
        "label": "Equatorial Guinea"
      },
      {
        "key": 66,
        "label": "Eritrea"
      },
      {
        "key": 67,
        "label": "Estonia"
      },
      {
        "key": 68,
        "label": "Ethiopia"
      },
      {
        "key": 69,
        "label": "Falkland Islands (Malvinas)"
      },
      {
        "key": 70,
        "label": "Faroe Islands"
      },
      {
        "key": 71,
        "label": "Fiji"
      },
      {
        "key": 72,
        "label": "Finland"
      },
      {
        "key": 74,
        "label": "France"
      },
      {
        "key": 75,
        "label": "French Guiana"
      },
      {
        "key": 76,
        "label": "French Polynesia"
      },
      {
        "key": 77,
        "label": "French Southern Territories"
      },
      {
        "key": 78,
        "label": "Gabon"
      },
      {
        "key": 79,
        "label": "Gambia"
      },
      {
        "key": 80,
        "label": "Georgia"
      },
      {
        "key": 81,
        "label": "Germany"
      },
      {
        "key": 82,
        "label": "Ghana"
      },
      {
        "key": 83,
        "label": "Gibraltar"
      },
      {
        "key": 84,
        "label": "Greece"
      },
      {
        "key": 85,
        "label": "Greenland"
      },
      {
        "key": 86,
        "label": "Grenada"
      },
      {
        "key": 87,
        "label": "Guadeloupe"
      },
      {
        "key": 88,
        "label": "Guam"
      },
      {
        "key": 89,
        "label": "Guatemala"
      },
      {
        "key": 90,
        "label": "Guinea"
      },
      {
        "key": 91,
        "label": "Guinea-Bissau"
      },
      {
        "key": 92,
        "label": "Guyana"
      },
      {
        "key": 93,
        "label": "Haiti"
      },
      {
        "key": 94,
        "label": "Heard and Mc Donald Islands"
      },
      {
        "key": 95,
        "label": "Honduras"
      },
      {
        "key": 96,
        "label": "Hong Kong"
      },
      {
        "key": 97,
        "label": "Hungary"
      },
      {
        "key": 98,
        "label": "Iceland"
      },
      {
        "key": 99,
        "label": "India"
      },
      {
        "key": 100,
        "label": "Indonesia"
      },
      {
        "key": 101,
        "label": "Iran (Islamic Republic of)"
      },
      {
        "key": 102,
        "label": "Iraq"
      },
      {
        "key": 103,
        "label": "Ireland"
      },
      {
        "key": 104,
        "label": "Israel"
      },
      {
        "key": 105,
        "label": "Italy"
      },
      {
        "key": 106,
        "label": "Jamaica"
      },
      {
        "key": 107,
        "label": "Japan"
      },
      {
        "key": 108,
        "label": "Jordan"
      },
      {
        "key": 109,
        "label": "Kazakhstan"
      },
      {
        "key": 110,
        "label": "Kenya"
      },
      {
        "key": 111,
        "label": "Kiribati"
      },
      {
        "key": 112,
        "label": "North Korea"
      },
      {
        "key": 113,
        "label": "Korea, Republic of"
      },
      {
        "key": 114,
        "label": "Kuwait"
      },
      {
        "key": 115,
        "label": "Kyrgyzstan"
      },
      {
        "key": 116,
        "label": "Lao People's Democratic Republic"
      },
      {
        "key": 117,
        "label": "Latvia"
      },
      {
        "key": 118,
        "label": "Lebanon"
      },
      {
        "key": 119,
        "label": "Lesotho"
      },
      {
        "key": 120,
        "label": "Liberia"
      },
      {
        "key": 121,
        "label": "Libyan Arab Jamahiriya"
      },
      {
        "key": 122,
        "label": "Liechtenstein"
      },
      {
        "key": 123,
        "label": "Lithuania"
      },
      {
        "key": 124,
        "label": "Luxembourg"
      },
      {
        "key": 125,
        "label": "Macau"
      },
      {
        "key": 126,
        "label": "FYROM"
      },
      {
        "key": 127,
        "label": "Madagascar"
      },
      {
        "key": 128,
        "label": "Malawi"
      },
      {
        "key": 129,
        "label": "Malaysia"
      },
      {
        "key": 130,
        "label": "Maldives"
      },
      {
        "key": 131,
        "label": "Mali"
      },
      {
        "key": 132,
        "label": "Malta"
      },
      {
        "key": 133,
        "label": "Marshall Islands"
      },
      {
        "key": 134,
        "label": "Martinique"
      },
      {
        "key": 135,
        "label": "Mauritania"
      },
      {
        "key": 136,
        "label": "Mauritius"
      },
      {
        "key": 137,
        "label": "Mayotte"
      },
      {
        "key": 138,
        "label": "Mexico"
      },
      {
        "key": 139,
        "label": "Micronesia, Federated States of"
      },
      {
        "key": 140,
        "label": "Moldova, Republic of"
      },
      {
        "key": 141,
        "label": "Monaco"
      },
      {
        "key": 142,
        "label": "Mongolia"
      },
      {
        "key": 143,
        "label": "Montserrat"
      },
      {
        "key": 144,
        "label": "Morocco"
      },
      {
        "key": 145,
        "label": "Mozambique"
      },
      {
        "key": 146,
        "label": "Myanmar"
      },
      {
        "key": 147,
        "label": "Namibia"
      },
      {
        "key": 148,
        "label": "Nauru"
      },
      {
        "key": 149,
        "label": "Nepal"
      },
      {
        "key": 150,
        "label": "Netherlands"
      },
      {
        "key": 151,
        "label": "Netherlands Antilles"
      },
      {
        "key": 152,
        "label": "New Caledonia"
      },
      {
        "key": 153,
        "label": "New Zealand"
      },
      {
        "key": 154,
        "label": "Nicaragua"
      },
      {
        "key": 155,
        "label": "Niger"
      },
      {
        "key": 156,
        "label": "Nigeria"
      },
      {
        "key": 157,
        "label": "Niue"
      },
      {
        "key": 158,
        "label": "Norfolk Island"
      },
      {
        "key": 159,
        "label": "Northern Mariana Islands"
      },
      {
        "key": 160,
        "label": "Norway"
      },
      {
        "key": 161,
        "label": "Oman"
      },
      {
        "key": 162,
        "label": "Pakistan"
      },
      {
        "key": 163,
        "label": "Palau"
      },
      {
        "key": 164,
        "label": "Panama"
      },
      {
        "key": 165,
        "label": "Papua New Guinea"
      },
      {
        "key": 166,
        "label": "Paraguay"
      },
      {
        "key": 167,
        "label": "Peru"
      },
      {
        "key": 168,
        "label": "Philippines"
      },
      {
        "key": 169,
        "label": "Pitcairn"
      },
      {
        "key": 170,
        "label": "Poland"
      },
      {
        "key": 171,
        "label": "Portugal"
      },
      {
        "key": 172,
        "label": "Puerto Rico"
      },
      {
        "key": 173,
        "label": "Qatar"
      },
      {
        "key": 174,
        "label": "Reunion"
      },
      {
        "key": 175,
        "label": "Romania"
      },
      {
        "key": 176,
        "label": "Russian Federation"
      },
      {
        "key": 177,
        "label": "Rwanda"
      },
      {
        "key": 178,
        "label": "Saint Kitts and Nevis"
      },
      {
        "key": 179,
        "label": "Saint Lucia"
      },
      {
        "key": 180,
        "label": "Saint Vincent and the Grenadines"
      },
      {
        "key": 181,
        "label": "Samoa"
      },
      {
        "key": 182,
        "label": "San Marino"
      },
      {
        "key": 183,
        "label": "Sao Tome and Principe"
      },
      {
        "key": 184,
        "label": "Saudi Arabia"
      },
      {
        "key": 185,
        "label": "Senegal"
      },
      {
        "key": 186,
        "label": "Seychelles"
      },
      {
        "key": 187,
        "label": "Sierra Leone"
      },
      {
        "key": 188,
        "label": "Singapore"
      },
      {
        "key": 189,
        "label": "Slovakia"
      },
      {
        "key": 190,
        "label": "Slovenia"
      },
      {
        "key": 191,
        "label": "Solomon Islands"
      },
      {
        "key": 192,
        "label": "Somalia"
      },
      {
        "key": 193,
        "label": "South Africa"
      },
      {
        "key": 194,
        "label": "South Georgia &amp; South Sandwich Islands"
      },
      {
        "key": 195,
        "label": "Spain"
      },
      {
        "key": 196,
        "label": "Sri Lanka"
      },
      {
        "key": 197,
        "label": "St. Helena"
      },
      {
        "key": 198,
        "label": "St. Pierre and Miquelon"
      },
      {
        "key": 199,
        "label": "Sudan"
      },
      {
        "key": 200,
        "label": "Suriname"
      },
      {
        "key": 201,
        "label": "Svalbard and Jan Mayen Islands"
      },
      {
        "key": 202,
        "label": "Swaziland"
      },
      {
        "key": 203,
        "label": "Sweden"
      },
      {
        "key": 204,
        "label": "Switzerland"
      },
      {
        "key": 205,
        "label": "Syrian Arab Republic"
      },
      {
        "key": 206,
        "label": "Taiwan"
      },
      {
        "key": 207,
        "label": "Tajikistan"
      },
      {
        "key": 208,
        "label": "Tanzania, United Republic of"
      },
      {
        "key": 209,
        "label": "Thailand"
      },
      {
        "key": 210,
        "label": "Togo"
      },
      {
        "key": 211,
        "label": "Tokelau"
      },
      {
        "key": 212,
        "label": "Tonga"
      },
      {
        "key": 213,
        "label": "Trinidad and Tobago"
      },
      {
        "key": 214,
        "label": "Tunisia"
      },
      {
        "key": 215,
        "label": "Turkey"
      },
      {
        "key": 216,
        "label": "Turkmenistan"
      },
      {
        "key": 217,
        "label": "Turks and Caicos Islands"
      },
      {
        "key": 218,
        "label": "Tuvalu"
      },
      {
        "key": 219,
        "label": "Uganda"
      },
      {
        "key": 220,
        "label": "Ukraine"
      },
      {
        "key": 221,
        "label": "United Arab Emirates"
      },
      {
        "key": 222,
        "label": "United Kingdom"
      },
      {
        "key": 223,
        "label": "United States"
      },
      {
        "key": 224,
        "label": "United States Minor Outlying Islands"
      },
      {
        "key": 225,
        "label": "Uruguay"
      },
      {
        "key": 226,
        "label": "Uzbekistan"
      },
      {
        "key": 227,
        "label": "Vanuatu"
      },
      {
        "key": 228,
        "label": "Vatican City State (Holy See)"
      },
      {
        "key": 229,
        "label": "Venezuela"
      },
      {
        "key": 230,
        "label": "Viet Nam"
      },
      {
        "key": 231,
        "label": "Virgin Islands (British)"
      },
      {
        "key": 232,
        "label": "Virgin Islands (U.S.)"
      },
      {
        "key": 233,
        "label": "Wallis and Futuna Islands"
      },
      {
        "key": 234,
        "label": "Western Sahara"
      },
      {
        "key": 235,
        "label": "Yemen"
      },
      {
        "key": 237,
        "label": "Democratic Republic of Congo"
      },
      {
        "key": 238,
        "label": "Zambia"
      },
      {
        "key": 239,
        "label": "Zimbabwe"
      },
      {
        "key": 240,
        "label": "Jersey"
      },
      {
        "key": 241,
        "label": "Guernsey"
      },
      {
        "key": 242,
        "label": "Montenegro"
      },
      {
        "key": 243,
        "label": "Serbia"
      },
      {
        "key": 244,
        "label": "Aaland Islands"
      },
      {
        "key": 245,
        "label": "Bonaire, Sint Eustatius and Saba"
      },
      {
        "key": 246,
        "label": "Curacao"
      },
      {
        "key": 247,
        "label": "Palestinian Territory, Occupied"
      },
      {
        "key": 248,
        "label": "South Sudan"
      },
      {
        "key": 249,
        "label": "St. Barthelemy"
      },
      {
        "key": 250,
        "label": "St. Martin (French part)"
      },
      {
        "key": 251,
        "label": "Canary Islands"
      }
     ];
}

export { countryActions };
