import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import LoginNavigator from "./authen";
import AppTabNav from "./appNav";
import { navigationRef } from "./navigate";
import { NavigatorName } from "./screen";

const Stack = createNativeStackNavigator();

const RootNavigation = ({ ...props }) => (
  <NavigationContainer ref={navigationRef}>
    <Stack.Navigator
      screenOptions={{
        cardOverlayEnabled: true,
        gestureEnabled: false,
      }}
    >
      <Stack.Screen
        name={NavigatorName.LoginNavigator}
        component={LoginNavigator}
        options={{
          gestureEnabled: false,
          headerShown: false,
          headerStyle: { backgroundColor: "transparent" },
        }}
      />
      <Stack.Screen
        name={NavigatorName.HomeNavigator}
        component={AppTabNav}
        options={{
          gestureEnabled: false,
          headerShown: false,
          headerStyle: { backgroundColor: "transparent" },
        }}
      />
    </Stack.Navigator>
  </NavigationContainer>
);
export default RootNavigation;
