import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'

export default StyleSheet.create({
    ...ApplicationStyles.screen,
    drawer: {
        flex: 1,
        backgroundColor: Colors.white,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 45,
        width:'100%',
        borderBottomWidth:1,
        borderColor:Colors.gray
    },
    rightIcon: {
        padding: 15,
    },
    leftIcon: {
        padding: 15
    },
    menu: {
        width: Metrics.screenWidth / 13,
        height: Metrics.screenWidth / 13,
        resizeMode: 'contain'
    },
    logoIcon: {
        width: Metrics.screenWidth / 10,
        height: Metrics.screenWidth / 10,
        resizeMode: 'contain'
    },
    sidebar:{
        flexDirection:'row',
        // alignItems:'flex-start'
    },
    sidebarMenu:{
        backgroundColor:Colors.backgroundGray,
        flex:1,
        borderBottomRightRadius:20,       
        paddingHorizontal:15
    },
    sidebarImageView:{
        flex:1,
        paddingVertical:Metrics.screenHeight / 10
    },
    sidebarImage:{
        width:Metrics.screenWidth / 2,
        height: Metrics.screenWidth / 2,
        resizeMode:'contain'
    },
    navItem:{
        paddingVertical:Metrics.screenHeight / 32,
        borderTopWidth:1,
        borderColor:'#b3b4b6'
    },
    navItemText:{
        fontSize:Metrics.screenWidth / 17,
        color:Colors.gray,
        fontWeight:'300'
    },
})
