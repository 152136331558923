import React from "react";
import { StyleSheet, Image, View, Text, Pressable } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AsyncStorage from "@react-native-async-storage/async-storage";

//Screens
import FavouritesScreen from "../Container/FavouritesScreen";
import ListingScreen from "../Container/ListingScreen";
import SearchScreen from "../Container/SearchScreen";
import EntryListingScreen from "../Container/EntryListingScreen";

const Navigation = createNativeStackNavigator();

const getUserDetails = async () => {
  console.log("get user from async");
  let user = await AsyncStorage.getItem("user");
  return JSON.parse(user);
};

class FavouriteStack extends React.Component {
  render() {
    let fsProps = this.props;
    return (
      <Navigation.Navigator>
        <Navigation.Group>
          <Navigation.Screen
            name={"FavouritesScreen"}
            component={FavouritesScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"ListingScreen"}
            component={ListingScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"SearchScreen"}
            component={SearchScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"EntryListingScreen"}
            component={EntryListingScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
        </Navigation.Group>
      </Navigation.Navigator>
    );
  }
}

export default FavouriteStack;
