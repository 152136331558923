import Fonts from "./Fonts";
import Metrics from "./Metrics";
import Colors from "./Colors";
import { Dimensions, Platform } from "react-native";
// import * as Helper from '../Lib/Helper'

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android

const ApplicationStyles = {
  screen: {
    frm: {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    mainContainer: {
      flex: 1,
      backgroundColor: Colors.transparent,
    },
    container: {
      flex: 1,
      backgroundColor: Colors.transparent,
    },
    ImageBackground: {
      width: "100%",
      height: "100%",
      resizeMode: "cover",
      flex: 1,
      paddingBottom: 30
    },
    LinearGradient: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      height: "100%",
    },
    content: {
      padding: 20,
    },
    ScrollView: {
      flexGrow: 1,
    },
    h1: {
      fontSize: (Metrics.screenWidth * 36) / 414,
      color: "#fff",
      fontFamily: "medium",
    },
    h5: {
      fontSize: (Metrics.screenWidth * 22) / 414,
      color: "#fff",
      fontFamily: "base",
    },
    heading1: {
      fontSize: (Metrics.screenWidth * 36) / 414,
      color: "#fff",
      fontFamily: "semiBold",
      marginBottom: 15,
    },
    heading5: {
      fontSize: (Metrics.screenWidth * 18) / 414,
      color: "#fff",
      fontFamily: "robotoBold",
      lineHeight: 26,
    },
    logo: {
      width: Metrics.screenWidth / 4.5,
      height: Metrics.screenWidth / 4.5,
      resizeMode: "contain",
      alignSelf: "center",
    },
    landingLogo: {
      width: Metrics.screenWidth / 4,
      height: Metrics.screenWidth / 4,
      resizeMode: "contain",
      alignSelf: "center",
    },
    socialButton: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 2,
      borderColor: "#fff",
      height: (Metrics.screenWidth * 50) / 414,
      borderRadius: (Metrics.screenWidth * 50) / 828,
      marginTop: 10,
    },
    socialButtonText: {
      fontSize: (Metrics.screenWidth * 18) / 414,
      fontFamily: "robotoBlack",
      textAlign: "center",
      color: "#fff",
      marginLeft: 10,
      marginRight: 10,
    },
    loginButton: {
      borderColor: Colors.transparent,
    },
    TextPlaceHolder: {
      fontSize: (Metrics.screenWidth * 24) / 414,
      fontFamily: "semiBold",
      color: "rgba(255, 255, 255, 0.7)",
      flex: 1,
    },
    TextInput: {
      fontSize: 24,
      fontFamily: "semiBold",
      color: "#fff",
      // flex: 1
    },
    msg: {
      fontFamily: "robotoLightItalic",
      fontSize: 13,
      color: "#fff",
    },
    marginBottom: {
      marginBottom: 10,
    },
    slide: {
      flex: 1,
      padding: 20,
      paddingTop: 70,
    },
    showsButtonsText: {
      fontFamily: "robotoBase",
      fontSize: 16,
      color: "#fff",
      flexDirection: "row",
      padding: 10,
      paddingTop: 16,
    },
    rightIcon: {
      paddingVertical: 10,
      paddingHorizontal: 20,
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    rightIconWithText: {
      paddingVertical: 10,
      paddingHorizontal: 0,
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
    },
    leftIcon: {
      paddingVertical: 10,
      height: "100%",
      paddingHorizontal: 20,
      flexDirection: "row",
      alignItems: "center",
    },
    arrow: {
      width: 12,
      height: 12,
      resizeMode: "contain",
      tintColor: "#fff",
    },
    headerText: {
      fontSize: 16,
      color: Colors.white,
      fontFamily: "robotoBase",
      paddingHorizontal: 10,
    },
    button: {
      height: (Metrics.screenWidth * 50) / 414,
      borderRadius: (Metrics.screenWidth * 50) / 828,
      backgroundColor: "#26A7F2",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 20,
      alignSelf: "center",
      paddingLeft: 15,
      paddingRight: 15,
      // shadowColor: 'rgba(0,0,0,0.17)',
      // margin: 5,
      // shadowOffset: { width: 2, height: 2 },
      // shadowOpacity: 1,
      // shadowRadius: 8,
      // elevation: 4,
    },
    buttonLeft: {
      alignSelf: "flex-start",
    },
    buttonText: {
      color: "#FFFFFF",
      fontFamily: "robotoBlack",
      fontSize: (Metrics.screenWidth * 18) / 414,
      textAlign: "center",
      paddingLeft: 15,
      paddingRight: 15,
    },
    disable: {
      opacity: 0.3,
    },
    alignCenter: {
      textAlign: "center",
    },
    bold: {
      fontFamily: "robotoBold",
    },
    passwordRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    visibility: {
      width: Metrics.screenWidth / 10,
      height: Metrics.screenWidth / 10,
      resizeMode: "contain",
    },
    listItem: {
      flexDirection: "row",
      alignItems: "center",
      paddingTop: (Metrics.screenWidth * 20) / 414,
      paddingBottom: (Metrics.screenWidth * 20) / 414,
      borderBottomWidth: 1,
      borderColor: "rgba(255,255,255,0.3)",
    },
    listItemMarginHorizontal: {
      marginLeft: (Metrics.screenWidth * 20) / 414,
      marginRight: (Metrics.screenWidth * 20) / 414,
    },
    listBorderNone: {
      borderBottomWidth: 0,
    },
    listRow: {
      paddingTop: 0,
      paddingBottom: (Metrics.screenWidth * 15) / 414,
      borderBottomWidth: 1,
      borderColor: "rgba(242,242,242,0.2)",
    },
    listItemBody: {
      flex: 1,
      marginLeft: 10,
      marginRight: 10,
    },
    listBody: {
      flex: 1,
    },
    listItemRight: {
      flexDirection: "row",
      alignItems: "center",
    },
    listItemText: {
      fontSize: (Metrics.screenWidth * 18) / 414,
      fontFamily: "robotoBlack",
      color: "#fff",
    },
    listRowBottom: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    flex: {
      flex: 1,
    },
    smallText: {
      fontSize: (Metrics.screenWidth * 12) / 414,
      fontFamily: "robotoBase",
      color: "#fff",
      flexDirection: "row",
      alignItems: "center",
    },
    countText: {
      fontSize: (Metrics.screenWidth * 12) / 414,
      fontFamily: "robotoBase",
      color: "#fff",
      marginLeft: 8,
      marginRight: 8,
    },
    listItemBigText: {
      fontSize: (Metrics.screenWidth * 22) / 414,
      fontFamily: "robotoBold",
      color: "#fff",
      marginTop: 3,
      marginBottom: 3,
    },
    listItemBigNormalText: {
      fontSize: (Metrics.screenWidth * 22) / 414,
      fontFamily: "robotoBase",
      color: "#fff",
    },
    normalText: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      fontFamily: "robotoBase",
      color: "#fff",
      lineHeight: 24,
    },
    normalTextLight: {
      fontFamily: "robotoLight",
    },
    fontNornal: {
      fontFamily: "robotoBase",
    },
    fontLight: {
      fontFamily: "robotoBase",
      opacity: 0.7,
      marginTop: 10,
    },
    listIcon: {
      width: Metrics.screenWidth / 15,
      height: Metrics.screenWidth / 15,
      resizeMode: "contain",
    },
    listItemArrow: {
      width: Metrics.screenWidth / 30,
      height: Metrics.screenWidth / 30,
      resizeMode: "contain",
      tintColor: "#fff",
    },
    searchHeader: {
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: 5,
      paddingHorizontal: 10,
      backgroundColor: "rgba(255,255,255,0.3)",
      height: (Metrics.screenWidth * 50) / 414,
      borderRadius: (Metrics.screenWidth * 50) / 828,
      margin: 10,
    },
    search: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchInputContainer: {
      backgroundColor: "rgba(255,255,255,0.3)",
      flexDirection: "row",
      alignItems: "center",
      padding: 5,
      height: (Metrics.screenWidth * 40) / 414,
      borderRadius: (Metrics.screenWidth * 40) / 828,
      paddingLeft: 15,
      paddingRight: 15,
    },
    searchIcon: {
      width: Metrics.screenWidth / 18,
      height: Metrics.screenWidth / 18,
      resizeMode: "contain",
    },
    searchInput: {
      fontSize: (Metrics.screenWidth * 14) / 414,
      color: "#000",
      fontFamily: "robotoBase",
      height: (Metrics.screenWidth * 40) / 414,
      flex: 1,
      paddingLeft: 5,
      paddingRight: 15,
    },
    searchBtnText: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      color: "#fff",
      fontFamily: "robotoBase",
      paddingLeft: 15,
      paddingRight: 15,
    },
    nevHeading: {
      fontSize: (Metrics.screenWidth * 36) / 414,
      color: "#fff",
      fontFamily: "semiBold",
      flex: 1,
      paddingLeft: 20,
      paddingRight: 20,
    },
    nevHeadingMedium: {
      fontSize: (Metrics.screenWidth * 22) / 414,
    },
    header: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: Platform.OS == "android" ? 25 : 0,
      paddingBottom: 10,
    },
    headerLogo: {
      width: Metrics.screenWidth / 8.5,
      height: Metrics.screenWidth / 8.5,
      resizeMode: "contain",
      marginRight: 20,
    },
    headerBody: {
      flex: 1,
    },
    headerTitle: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      color: "#fff",
      fontFamily: "robotoBold",
      textAlign: "center",
      marginRight: 50,
    },
    headerRight: {
      flexDirection: "row",
    },
    headerLeft: {
      flexDirection: "row",
      alignItems: "center",
    },
    searchLarge: {
      padding: 15,
      backgroundColor: "rgba(255,255,255,0.20)",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: 8,
    },
    searchLargeIcon: {
      width: Metrics.screenWidth / 14,
      height: Metrics.screenWidth / 14,
      resizeMode: "contain",
    },
    searchLargeInput: {
      flex: 1,
      fontSize: (Metrics.screenWidth * 18) / 414,
      color: "#fff",
      fontFamily: "osSemiBold",
      paddingLeft: 15,
      paddingRight: 15,
    },
    locationContent: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 20,
      paddingBottom: 20,
    },
    location: {
      width: (Metrics.screenWidth * 22) / 414,
      height: (Metrics.screenWidth * 22) / 414,
      resizeMode: "contain",
    },
    locationText: {
      fontSize: (Metrics.screenWidth * 18) / 414,
      color: "#fff",
      fontFamily: "robotoBold",
      paddingLeft: 10,
      paddingRight: 10,
    },
    tabContainer: {
      paddingTop: (Metrics.screenWidth * 20) / 414,
      paddingBottom: (Metrics.screenWidth * 20) / 414,
    },
    tabNav: {
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: (Metrics.screenWidth * 20) / 414,
    },
    tabNavItem: {
      paddingBottom: (Metrics.screenWidth * 13) / 414,
      borderBottomWidth: 3,
      borderColor: Colors.transparent,
      paddingLeft: 15,
      paddingRight: 15,
      justifyContent: "center",
      alignItems: "center",
    },
    tabNavItemfull: {
      flex: 1,
    },
    tabNavItemselected: {
      borderColor: "#74C8B0",
    },
    tabNavItemText: {
      fontSize: (Metrics.screenWidth * 22) / 414,
      color: "#fff",
      fontFamily: "robotoBlack",
      opacity: 0.5,
    },
    selected: {
      opacity: 1,
    },
    grid: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    gridItem: {
      flexDirection: "row",
      backgroundColor: "rgba(255,255,255,0.2)",
      borderRadius: 5,
      padding: 10,
      width: "49%",
      marginBottom: "2%",
      height: (Metrics.screenWidth * 80) / 414,
      marginRight: "2%",
    },
    gridItemText: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      color: "#fff",
      fontFamily: "robotoBold",
      flex: 1,
    },
    gridItemIcon: {
      width: (Metrics.screenWidth * 28) / 414,
      height: (Metrics.screenWidth * 28) / 414,
      resizeMode: "contain",
      alignSelf: "flex-end",
    },
    headerIcon: {
      width: (Metrics.screenWidth * 20) / 414,
      height: (Metrics.screenWidth * 20) / 414,
      resizeMode: "contain",
      tintColor: "#fff",
    },
    italic: {
      fontFamily: "robotoLightItalic",
    },
    buttonGroup: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 10,
    },
    filterButton: {
      height: (Metrics.screenWidth * 30) / 414,
      borderRadius: (Metrics.screenWidth * 30) / 828,
      borderWidth: 1,
      borderColor: "#fff",
      paddingLeft: 10,
      paddingRight: 10,
      flexDirection: "row",
      alignItems: "center",
      margin: 5,
    },
    filterButtonText: {
      fontFamily: "medium",
      fontSize: (Metrics.screenWidth * 13) / 414,
      color: "#fff",
      paddingLeft: 10,
      paddingRight: 10,
    },
    filterButtonIcon: {
      width: (Metrics.screenWidth * 18) / 414,
      height: (Metrics.screenWidth * 18) / 414,
      tintColor: "#fff",
      resizeMode: "contain",
    },
    Modalclose: {
      width: (Metrics.screenWidth * 13) / 414,
      height: (Metrics.screenWidth * 13) / 414,
      tintColor: "#fff",
      resizeMode: "contain",
      transform: [{ rotate: "45deg" }],
    },
    searchBox: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
    },
    searchFrm: {
      height: (Metrics.screenWidth * 40) / 414,
      borderRadius: (Metrics.screenWidth * 40) / 828,
      backgroundColor: "rgba(255,255,255,0.3)",
      fontSize: (Metrics.screenWidth * 14) / 414,
      fontFamily: "robotoBase",
      color: "#fff",
      paddingLeft: 15,
      paddingRight: 15,
    },
    listBodyInline: {
      flexDirection: "row",
      alignItems: "flex-end",
    },
    thirtheenStyle: {
      fontFamily: "robotoBase",
      fontSize: (Metrics.screenWidth * 13) / 414,
      color: "#fff",
    },
    thirtheenStyleLight: {
      fontFamily: "robotoLight",
      fontSize: (Metrics.screenWidth * 13) / 414,
      color: "#fff",
    },
    sub: {
      marginBottom: 0,
      marginLeft: 5,
    },
    listClickView: {
      padding: 10,
    },
    listClickIcon: {
      width: (Metrics.screenWidth * 25) / 414,
      height: (Metrics.screenWidth * 25) / 414,
      tintColor: "#fff",
      resizeMode: "contain",
    },
    _row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    listInputIDR: {
      fontFamily: "robotoBase",
      fontSize: (Metrics.screenWidth * 22) / 414,
      color: "#fff",
      borderWidth: 0,
      flex: 1,
      height: (Metrics.screenWidth * 40) / 414,
      paddingTop: 0,
      paddingBottom: 0,
    },
    listInputAUD: {
      fontFamily: "robotoLight",
      fontSize: (Metrics.screenWidth * 13) / 414,
      color: "#fff",
      flex: 1,
      height: (Metrics.screenWidth * 30) / 414,
      paddingTop: 0,
      paddingBottom: 0,
    },
    listInput: {
      fontFamily: "robotoBase",
      fontSize: (Metrics.screenWidth * 16) / 414,
      color: "#fff",
      borderWidth: 0,
      flex: 1,
      height: (Metrics.screenWidth * 40) / 414,
      paddingTop: 0,
      paddingBottom: 0,
    },
    imageUpload: {
      flexDirection: "row",
      alignItems: "center",
      marginTop: 10,
    },
    imagePlaceholder: {
      width: (Metrics.screenWidth * 60) / 414,
      height: (Metrics.screenWidth * 60) / 414,
      backgroundColor: "rgba(255,255,255,0.23)",
      borderRadius: 6,
      justifyContent: "center",
      alignItems: "center",
      marginRight: 10,
      position: "relative",
      overflow: "hidden",
    },
    placeholderImg: {
      width: (Metrics.screenWidth * 25) / 414,
      height: (Metrics.screenWidth * 25) / 414,
      tintColor: "#fff",
      resizeMode: "contain",
    },
    uploadedImage: {
      width: "100%",
      height: "100%",
      resizeMode: "cover",
    },
    modalDialog: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    dialog: {
      minWidth: 180,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 25,
      paddingBottom: 25,
      backgroundColor: "rgba(0,0,0,0.7)",
      borderRadius: 6,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogIcon: {
      width: (Metrics.screenWidth * 58) / 414,
      height: (Metrics.screenWidth * 58) / 414,
      resizeMode: "contain",
      marginBottom: 10,
    },
    dialogText: {
      fontFamily: "base",
      fontSize: (Metrics.screenWidth * 22) / 414,
      color: "#fff",
      textAlign: "center",
    },
    notFound: {
      justifyContent: "center",
      alignItems: "center",
      padding: 30,
    },
    notFoundIcon: {
      width: (Metrics.screenWidth * 80) / 414,
      height: (Metrics.screenWidth * 80) / 414,
      resizeMode: "contain",
      marginBottom: 20,
      alignSelf: "center",
    },
    notFoundTitle: {
      fontFamily: "base",
      fontSize: (Metrics.screenWidth * 22) / 414,
      color: "#fff",
      textAlign: "center",
      marginBottom: 5,
    },
    notFoundText: {
      fontFamily: "robotoBase",
      fontSize: (Metrics.screenWidth * 16) / 414,
      color: "#fff",
      textAlign: "center",
    },
    textTagView: {
      flexDirection: "row",
    },
    textTag: {
      marginTop: 5,
      marginRight: (Metrics.screenWidth * 20) / 414,
    },
    fillStar: {
      width: (Metrics.screenWidth * 21) / 414,
      height: (Metrics.screenWidth * 21) / 414,
      resizeMode: "contain",
      marginRight: 10,
    },
    pushNotifications: {
      width: (Metrics.screenWidth * 284) / 414,
      height: (Metrics.screenWidth * 60) / 414,
      // resizeMode: 'contain',
      marginBottom: 10,
      marginLeft: -15,
    },

    pre: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      color: "#fff",
      fontFamily: "robotoBase",
      paddingBottom: 10,
      lineHeight: (Metrics.screenWidth * 24) / 414,
    },
    dot: {
      width: 5,
      height: 5,
      borderRadius: 2.5,
      backgroundColor: "#FFBE45",
      alignSelf: "center",
    },
    inputField: {
      fontFamily: "robotoBold",
      fontSize: (Metrics.screenWidth * 18) / 414,
      color: "#fff",
      borderWidth: 0,
      flex: 1,
      height: (Metrics.screenWidth * 30) / 414,
      paddingTop: 0,
      paddingBottom: 0,
    },
    map: {
      width: Metrics.screenWidth,
      height: (Metrics.screenHeight * 420) / 983,
    },
    mapImage: {
      width: Metrics.screenWidth,
      height: (Metrics.screenHeight * 420) / 983,
      resizeMode: "cover",
      alignItems: "center",
      justifyContent: "center",
    },
    radiusCircle: {
      width: (Metrics.screenWidth * 188) / 414,
      height: (Metrics.screenWidth * 188) / 414,
      borderRadius: (Metrics.screenWidth * 188) / 828,
      marginLeft: -(Metrics.screenWidth * 188) / 828,
      marginTop: -(Metrics.screenWidth * 188) / 828,
      backgroundColor: "rgba(27,137,201,0.2)",
      borderWidth: 2,
      borderColor: "#1B89C9",
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 1,
    },
    switchBtn: {
      width: (Metrics.screenWidth * 100) / 414,
      height: (Metrics.screenWidth * 40) / 414,
      borderRadius: (Metrics.screenWidth * 40) / 828,
      backgroundColor: "rgba(255,255,255,0.2)",
      flexDirection: "row",
      alignItems: "center",
    },
    switchOn: {
      flexDirection: "row-reverse",
    },
    switchIcon: {
      width: (Metrics.screenWidth * 60) / 414,
      height: (Metrics.screenWidth * 60) / 414,
      marginLeft: -8,
    },
    switchIconOn: {
      marginLeft: 0,
      marginRight: -8,
    },
    switchText: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      fontFamily: "base",
      color: "#fff",
      flex: 1,
      textAlign: "right",
      paddingRight: 15,
    },
    imagePickerModal: {
      flex: 1,
    },
    bodyClose: {
      flex: 1,
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    imagePickerStyle: {
      backgroundColor: "#fff",
      paddingLeft: 10,
      paddingRight: 10,
      position: "relative",
      zIndex: 1,
      width: "100%",
    },
    imagePickerOption: {
      flexDirection: "row",
      alignItems: "center",
      paddingTop: 15,
      paddingBottom: 15,
      borderBottomWidth: 1,
      marginLeft: 16,
      borderColor: "#EBEBEB",
    },

    imagePickerOptionText: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      fontFamily: "robotoBase",
      color: "#000",
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
    },
    imagePickerOptionIcon: {
      width: (Metrics.screenWidth * 22) / 414,
      height: (Metrics.screenWidth * 22) / 414,
      resizeMode: "contain",
    },
    imagePreview: {
      width: (Metrics.screenWidth * 100) / 414,
      height: (Metrics.screenWidth * 100) / 414,
      borderRadius: 7,
      overflow: "hidden",
    },
    imagePreviewAdd: {
      width: Metrics.screenWidth - 20,
      height: (Metrics.screenWidth * 300) / 414,
      borderRadius: 7,
      overflow: "hidden",
    },
    imageZoomModal: {
      flex: 1,
      backgroundColor: "rgba(0,0,0,0.7)",
    },
    tooltipStyle: {
      backgroundColor: Colors.transparent,
      width: Metrics.screenWidth,
      maxWidth: Metrics.screenWidth,
      alignSelf: "center",
    },
    categoriesList: {
      marginTop: (Metrics.screenWidth * 0) / 414,
      marginBottom: (Metrics.screenWidth * 20) / 414,
    },
    categoriesListTitle: {
      fontSize: (Metrics.screenWidth * 16) / 414,
      fontFamily: "base",
      color: "#fff",
      flex: 1,
      paddingLeft: (Metrics.screenWidth * 20) / 414,
      paddingRight: (Metrics.screenWidth * 20) / 414,
      borderBottomWidth: 2,
      borderColor: "rgba(255,255,255,0.2)",
      paddingTop: 10,
      paddingBottom: 10,
    },
    dropModal: {
      flex: 1,
      backgroundColor: "rgba(0,0,0,0.7)",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    dropdown: {
      backgroundColor: "#fff",
      padding: 0,
      position: "relative",
      zIndex: 1,
      width: "100%",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    modalbodyClose: {
      flex: 1,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    optionItem: {
      padding: 20,
      borderBottomWidth: 1,
      borderColor: "rgba(0,0,0,0.1)",
    },
    optionText: {
      color: "#000",
      fontFamily: "robotoBase",
      fontSize: (Metrics.screenWidth * 18) / 414,
      textAlign: "center",
    },
    colorRed: {
      color: "#f16059",
    },
    SafeAreaDownloading: {
      backgroundColor: "#000",
    },
    downloadItem: {
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: (Metrics.screenWidth * 20) / 414,
      paddingRight: (Metrics.screenWidth * 20) / 414,
    },
    downloadItemText: {
      color: "#fff",
      fontFamily: "base",
      fontSize: (Metrics.screenWidth * 22) / 414,
      lineHeight: 36,
      paddingTop: 10,
      paddingBottom: 10,
      flex: 1,
    },
    downloadArrow: {
      width: (Metrics.screenWidth * 14) / 414,
      height: (Metrics.screenWidth * 14) / 414,
      resizeMode: "contain",
      tintColor: "#fff",
    },
    offlineMode: {
      backgroundColor: "#000",
      justifyContent: "center",
      alignItems: "center",
      padding: (Metrics.screenWidth * 20) / 414,
      paddingBottom: (Metrics.screenWidth * 50) / 414,
    },
    offlineModeHeader: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    offlineIcon: {
      width: (Metrics.screenWidth * 19) / 414,
      height: (Metrics.screenWidth * 19) / 414,
      resizeMode: "contain",
      tintColor: "#fff",
      marginRight: 10,
    },
    offlineModeTitle: {
      color: "#fff",
      fontFamily: "base",
      fontSize: (Metrics.screenWidth * 22) / 414,
      lineHeight: 36,
    },
    offlineModeContent: {
      paddingLeft: (Metrics.screenWidth * 15) / 414,
      paddingRight: (Metrics.screenWidth * 15) / 414,
      paddingBottom: (Metrics.screenWidth * 20) / 414,
    },
    offlineModeText: {
      color: "#fff",
      fontFamily: "robotoBase",
      fontSize: (Metrics.screenWidth * 16) / 414,
      lineHeight: 24,
      textAlign: "center",
    },
    offlineModeBottom: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    emLabel: {
      color: "#fff",
      fontFamily: "robotoLightItalic",
      fontSize: (Metrics.screenWidth * 13) / 414,
      lineHeight: 15,
      marginLeft: (Metrics.screenWidth * 20) / 414,
    },
    closeOfflineMode: {
      position: "absolute",
      top: (Metrics.screenWidth * 20) / 414,
      right: (Metrics.screenWidth * 25) / 414,
    },
    closeOfflineModeIcon: {
      width: (Metrics.screenWidth * 19) / 414,
      height: (Metrics.screenWidth * 19) / 414,
      resizeMode: "contain",
      tintColor: "#fff",
      opacity: 0.5,
    },
    largerLeft: {
      width: 20,
      height: 20,
    },
    topLeftArrow: {
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 5,
    },
    topRight: {
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 5,
    },
    tag: {
      backgroundColor: "#FFF",
      height: 40,
      padding: 5,
      borderRadius: 100,
    },
    tagText: {
      fontFamily: "robotoBase",
      padding: 0,
      margin: 0,
    },
    inputText: {
      color: "#FFF",
      fontFamily: "robotoBase",
      padding: 5,
      margin: 0,
      borderColor: "white",
      borderRadius: 8,
      borderWidth: 1,
    },
    inputStyle: {
      marginTop: 8,
    },
    disabledInputStyle: {
      borderColor: "grey",
    },
    entriesRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

export default ApplicationStyles;
