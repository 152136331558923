import React from "react";
import { Alert, Image, ImageBackground, Keyboard, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native';
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from '../Lib/Helper';
import Api from "../Services/Api";
import { Images } from "../Themes";
import styles from "./Styles/SignupScreenStyles";

class ForgotPasswordScreen extends BaseComponent {
    static navigationOptions = ({ navigation }) => ({
        headerLeft: () => <TouchableOpacity style={styles.leftIcon} onPress={() => {
            Keyboard.dismiss()
            navigation.dispatch(navigation.goBack());
        }}>
            <Image source={Images.close} style={styles.arrow} />
        </TouchableOpacity>,

        headerRight: () => <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => navigation.state.params.onRecoverPassword()}>
            <Text style={styles.headerText}>Recover Password</Text>
            <Image source={Images.arrowRight} style={[styles.arrow]} />
        </TouchableOpacity>
    });

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            loading: false,
        }
    }

    componentDidMount() {
        super.componentDidMount()

        this.props.navigation.setParams({
            onRecoverPassword: this.onPressRecoverPassword.bind(this)
        })
    }

    onPressRecoverPassword = () => {
        Keyboard.dismiss()
        const email = this.state.email
        if (Constants.isOnline) {
            if (email.trim() == '') {
                Helper.showAlert(Constants.ERROR_MESSAGES.EMAIL)
            } else if (!Helper.validateEmail(email)) {
                Helper.showAlert(Constants.ERROR_MESSAGES.VALID_EMAIL)
            } else {
                if (Constants.isOnline) {
                    this.setState({ loading: true })
                    const mApi = Api.create()
                    mApi.forgotPassword(email).then(response => {
                        if (response.ok) {
                            if (response.data.success) {
                                Alert.alert(Constants.APP_NAME, 'Your new password has been sent on your registered email.',
                                    [
                                        {
                                            text: 'Ok',
                                            onPress: () => this.handleBackButtonClick()
                                        }
                                    ],
                                    { cancelable: false })
                            } else {
                                Helper.showAlert(response.data.message)
                            }
                        } else {
                            Helper.showAlert(response.problem)
                        }
                        this.setState({ loading: false })
                    }).catch(err => {
                        console.log('error: ', err);
                        this.setState({ loading: false })
                    })
                } else {
                    Helper.showAlert(Constants.ERROR_MESSAGES.NO_INTERNET)
                }            
            }   
        }
        else{
            // Helper.showAlert(Constants.ERROR_MESSAGES.NO_INTERNET)
        }
    }

    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.mainContainer} >
                    <View style={styles.content}>
                        <View style={styles.frm}>
                            <Text style={[styles.h1, styles.marginBottom]}>Forgot password</Text>
                            <TextInput
                                underlineColorAndroid="transparent"
                                placeholder="Enter your email"
                                placeholderTextColor="rgba(255, 255, 255, 0.7)"
                                style={styles.TextInput}
                                value={this.state.email}
                                onChangeText={(email) => this.setState({ email })}
                                returnKeyType='go'
                                onSubmitEditing={(event) => this.onPressRecoverPassword()}
                            />

                            <Text style={styles.msg}>You will be emailed your password</Text>
                        </View>
                    </View>
                </SafeAreaView>
                {this.state.loading ? <LoadingComponent /> : null}
            </ImageBackground>
        )
    }
}



export default (ForgotPasswordScreen)