import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  SafeAreaView,
  FlatList,
  ScrollView,
} from "react-native";
import BaseComponent from "../Components/BaseComponent";
import FooterNavComponents from "../Components/FooterNavComponents";
import { Images } from "../Themes";
import styles from "./Styles/FavouritesScreenStyles";
import ActionCreators from "../Redux/actions";
import { connect } from "react-redux";
import LoadingComponent from "../Components/LoadingComponent";
import * as Helper from "../Lib/Helper";
import Constants from "../Lib/Constants";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import NoPriceModalComponent from "../Components/NoPriceModalComponent";
import { favouriteActions } from "../Redux/actions/Favourite";
import { useIsFocused } from "@react-navigation/native";

class FavouritesScreen extends BaseComponent {
  constructor() {
    super();
    this.state = {
      arrFavourite: [],
      loading: false,
      isFavRemovedVisible: false,
      favDialogMessage: "",
      activityUnreadCount: Constants.activityUnreadCount,
      noPriceModalVisible: true,
    };
    this.getUserDetail = this.getUserDetail.bind(this);
    this.getFavourites = this.getFavourites.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getUserDetail();
    // // setTimeout(() => {
    //   this.props.getActivityUnreadCount();
    // // }, 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused == false && this.props.isFocused == true) {
      this.getUserDetail();
    }
    // if (prevProps.activityUnreadCount != this.props.activityUnreadCount) {
    //   this.setState({ activityUnreadCount: this.props.activityUnreadCount });
    // }
    // if (prevProps.favouriteReducer != this.props.favouriteReducer) {
    //   if (
    //     prevProps.favouriteReducer.loading !=
    //     this.props.favouriteReducer.loading
    //   ) {
    //     this.setState({loading: this.props.favouriteReducer.loading});
    //   }
    //   if (
    //     prevProps.favouriteReducer.arrFavourite !=
    //     this.props.favouriteReducer.arrFavourite
    //   ) {
    //     this.setState({arrFavourite: this.props.favouriteReducer.arrFavourite});
    //   }
    //   if (
    //     prevProps.favouriteReducer.removeLoading !=
    //     this.props.favouriteReducer.removeLoading
    //   ) {
    //     this.setState(
    //       {loading: this.props.favouriteReducer.removeLoading},
    //       () => {
    //         if (!this.state.loading) {
    //           this.setState(
    //             {
    //               isFavRemovedVisible: true,
    //               favDialogMessage: Constants.REMOVED_FAV_POP_UP,
    //             },
    //             () => {
    //               setTimeout(() => {
    //                 this.setState({
    //                   isFavRemovedVisible: false,
    //                   favDialogMessage: '',
    //                 });
    //               }, Constants.TIME_FOR_POP_UPS);
    //             },
    //           );
    //         }
    //       },
    //     );
    //   }
    // }
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      this.getFavourites(userdetails);
    }
  }

  getFavourites = async (user) => {
    let favs = await favouriteActions.favouriteList(user);
    console.log("favs: ", favs.data);
    if (favs.status == 200) {
      this.setState({
        arrFavourite: favs.data.data,
      });
    }
  };

  onPressRemoveFavourite = async (item) => {
    // this.props.removeFavourite(item.id, item.cat_id, item.tag_ids);
    // Revove favourite needs to be done
    let userdetails = await this.props.route.params.getUserDetails();
    let fav = await ActionCreators.favouriteActions.favouriteRemove(item.id);
    console.log("fav", fav.data);
    if (fav.status == 200) {
      if (fav.data.success) {
        this.getFavourites(userdetails);
        this.setState({
          isFavRemovedVisible: true,
          favDialogMessage: "Favourite has been removed successfully.",
        });
        setTimeout(() => {
          this.setState({
            isFavRemovedVisible: false,
            favDialogMessage: "",
          });
        }, 1500);
      }
    }
  };

  renderRowText(item) {
    console.log("Item", item);
    return (
      <View style={styles.listBody}>
        <Text style={styles.smallText}>
          {item.categories.split(",").join(", ")}
        </Text>
        <Text style={styles.listItemBigText}>
          {item.tags.split(",").join(", ")}
        </Text>
      </View>
    );
  }

  renderDefaultTitle = (item) => {
    return (
      <View style={styles.listBody}>
        <Text style={styles.smallText}>{item.category}</Text>
        <Text style={styles.listItemBigText}>{item.title}</Text>
        <Text style={styles.normalText}>{item.other_tags}</Text>
      </View>
    );
  };

  renderFavouriteItem = (item, index) => {
    console.log("item fav: ", item);
    return (
      <TouchableOpacity
        style={styles.listItem}
        onPress={() =>
          this.props.navigation.navigate("SearchScreen", {
            searchItem: item.categories,
            searchTags: item.tags,
          })
        }
      >
        <TouchableOpacity
          style={styles.listItemLeft}
          onPress={() => this.onPressRemoveFavourite(item)}
        >
          <Image source={Images.favourites_filled} style={styles.fillStar} />
        </TouchableOpacity>
        <View style={styles.listItemBody}>
          {this.renderRowText(item)}
          <View style={styles.textTag}>
            <Text style={styles.thirtheenStyleLight}>
              {item.localPrice.length > 0
                ? `${
                    item.localPrice[0].currency_code
                  }${item.localPrice[0].averagePrice.toFixed(2)} Average`
                : "No prices available for this location"}
            </Text>
          </View>
        </View>
        <View style={styles.listItemRight}>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  renderEmptyComponent = () => {
    return (
      <View style={[styles.notFound, { flex: 1 }]}>
        <Image source={Images.favourites} style={styles.notFoundIcon} />
        <Text style={styles.notFoundText}>
          You haven't favourited any listings yet!
        </Text>
      </View>
    );
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.nevHeading}>Favourites</Text>
            <Image source={Images.logo} style={styles.headerLogo} />
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            {this.state.arrFavourite.length == 0 && !this.state.loading ? (
              this.renderEmptyComponent()
            ) : (
              <View style={styles.content}>
                <FlatList
                  extraData={this.state}
                  data={this.state.arrFavourite}
                  keyExtractor={(item, index) => index + ""}
                  renderItem={({ item, index }) =>
                    this.renderFavouriteItem(item, index)
                  }
                />
              </View>
            )}
          </ScrollView>
        </SafeAreaView>
        <PopupDialogComponent
          visible={this.state.isFavRemovedVisible}
          message={this.state.favDialogMessage}
          isRemoveType={true}
        />
        {/* {!this.state.loading && !this.state.isFavRemovedVisible && this.state.arrFavourite.length == 0 ?
          <NoPriceModalComponent
            visible={this.state.noPriceModalVisible}
            onPressGoBack={() => this.setState({ noPriceModalVisible: false }, () => this.props.navigation.goBack())}
            onPressEnterPrice={() => this.setState({ noPriceModalVisible: false }, () => { this.props.navigation.goBack(); this.props.navigation.navigate('AddPriceScreen') })}
          />
          : null} */}
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

export default function (props) {
  const isFocused = useIsFocused();
  return <FavouritesScreen {...props} isFocused={isFocused} />;
}
