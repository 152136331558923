import React from "react";
import { ImageBackground, Text, TextInput, View, Keyboard, TouchableOpacity, Image } from 'react-native';
import { SafeAreaView } from 'react-native';
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import ActionCreators from "../Redux/actions";
import { Images } from "../Themes";
import styles from "./Styles/SignupScreenStyles";
import * as Helper from '../Lib/Helper'
import Constants from "../Lib/Constants";
import Api from '../Services/Api';
const mApi = Api.create();

class SignupEmailScreen extends BaseComponent {

    static navigationOptions = ({ navigation }) => ({
        headerRight: () => <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => navigation.state.params.onNext()}>
            <Text style={styles.headerText}>Next</Text>
            <Image source={Images.arrowRight} style={[styles.arrow]} />
        </TouchableOpacity>
    });

    constructor(props) {
        super(props)
        this.state = {
            email: ""
        }
    }

    componentDidMount() {
        super.componentDidMount()

        this.props.navigation.setParams({
            onNext: this.onPressNext.bind(this)
        })
    }

    onPressNext = () => {
        Keyboard.dismiss()
        const email = this.state.email
        if (email.trim() == '') {
            Helper.showAlert(Constants.ERROR_MESSAGES.EMAIL)
        } else if (!Helper.validateEmail(email)) {
            Helper.showAlert(Constants.ERROR_MESSAGES.VALID_EMAIL)
        } else {
            this.checkEmail()
        }
    }

    checkEmail(){
        mApi.checkEmail(this.state.email).then(response => {
            console.log('response',response);
          if (response.ok) {
            if (response.data.success) {
                Helper.showAlert(Constants.ERROR_MESSAGES.EMAIL_ALREADY_REGISTERED);
            } else {
                this.props.navigation.navigate('SignupDateScreen')
            }
          } else {
            Helper.showAlert(response.problem);
          }
          this.setState({loading: false});
        })
        .catch(err => {
          console.log('error: ', err);
          this.setState({loading: false});
        });
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.userDetail){
            if (prevProps.userDetail.email != this.props.userDetail.email) {
                this.setState({ email: this.props.userDetail.email })
            }
        }
    }

    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.mainContainer} >
                    <View style={styles.content}>
                        <View style={styles.frm}>
                            <Text style={[styles.h1, styles.marginBottom, {marginTop:30}]}>What's your email address?</Text>
                            <TextInput
                                underlineColorAndroid="transparent"
                                placeholder="Email"
                                placeholderTextColor="rgba(255, 255, 255, 0.7)"
                                style={styles.TextInput}
                                keyboardType='email-address'
                                value={this.state.email}
                                onChangeText={(email) => this.props.setEmail(email)}
                                returnKeyType='next'
                                onSubmitEditing={(event) => this.onPressNext()}
                            />
                        </View>
                    </View>
                </SafeAreaView>
            </ImageBackground>
        )
    }
}

// mapStateToProps = state => {
//     // console.log('>>>>> User Detail Email', state);
//     return {
//         userDetail: state.userReducer.userDetail
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         setEmail: (email) => dispatch(ActionCreators.userActions.setEmail(email))
//     }
// }

export default (SignupEmailScreen)
