import * as Font from "expo-font";
import React from "react";
import { StyleSheet, Dimensions, Platform } from "react-native";

// const type = {
//   base: 'Oswald-Regular',
//   bold: 'Oswald-Bold',
//   medium: 'Oswald-Medium',
//   semiBold: 'Oswald-SemiBold',
//   light: 'Oswald-Light',
//   robotoBase: 'Roboto-Regular',
//   robotoBold: 'Roboto-Bold',
//   robotoMedium: 'Roboto-Medium',
//   robotoLight: 'Roboto-Light',
//   robotoBlack:'Roboto-Black',
//   robotoLightItalic: 'Roboto-LightItalic',
//   osBase: 'OpenSans-Regular',
//   osBold: 'OpenSans-Bold',
//   osSemiBold: 'OpenSans-SemiBold',
//   osLight: 'OpenSans-Italic',
// }

export const STYLES = StyleSheet.create({
  textWithFontStyle: (
    size,
    color = "#000",
    font = "base",
    align,
    textCase
  ) => {
    return {
      fontSize: size,
      fontFamily: font,
      color: color,
      textAlign: align,
      textTransform: textCase,
      lineHeight: size * 1.4,
    };
  },
});


const size = {
  h1: 22,
  h2: 20,
  h3: 18,
  h4: 16,
  h5: 14,
  h6: 13,
  input: 18,
  regular: 16,
  medium: 14,
  small: 12,
  tiny: 8.5
}

const style = {
  // h1: {
  //   //fontFamily: type.base,
  //   fontSize: size.h1,
  //   color:'#000',
  //   lineHeight: 30
  // },
  h1:{
    ...STYLES.textWithFontStyle(
      size.h1,
      "#000",
      "base",
      "left"
    )
  },
  // h2: {
  //   // fontFamily: type.base,
  //   fontSize: size.h2,
  //   color:'#000'
  // },
  h2:{
    ...STYLES.textWithFontStyle(
      size.h2,
      "#000",
      "base",
      "left"
    )
  },
  h3:{
    ...STYLES.textWithFontStyle(
      size.h3,
      "#000",
      "base",
      "left"
    )
  },
  // h3: {
  //   fontFamily: type.base,
  //   fontSize: size.h3,
  //   color:'#000'
  // },
  h4: {
    ...STYLES.textWithFontStyle(
      size.h4,
      "#000",
      "base",
      "left"
    )
  },
  // h4: {
  //   fontFamily: type.base,
  //   fontSize: size.h4,
  //   color:'#000'
  // },
  h5:{
    ...STYLES.textWithFontStyle(
      size.h5,
      "#000",
      "base",
      "left"
    )
  },
  // h5: {
  //   fontFamily: type.base,
  //   fontSize: size.h5,
  //   color:'#000'
  // },
  h6:{
    ...STYLES.textWithFontStyle(
      size.h6,
      "#989898",
      "base",
      "left"
    )
  },
  // h6: {
  //   fontFamily: type.base,
  //   fontSize: size.h6,
  //   color: '#989898',
  // },
  normal:{
    ...STYLES.textWithFontStyle(
      size.regular,
      "#989898",
      "base",
      "left"
    )
  },
  // normal: {
  //   fontFamily: type.base,
  //   fontSize: size.regular
  // },
  description:{
    ...STYLES.textWithFontStyle(
      size.medium,
      "#000",
      "base",
      "left"
    )
  },
  // description: {
  //   fontFamily: type.base,
  //   fontSize: size.medium
  // }
}

export default {
  // type,
  size,
  style
}
