import moment from "moment";
import React from "react";
import {
  Alert,
  Image,
  ImageBackground,
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native";
import DateTimePicker from "react-native-modal-datetime-picker";
import RNPickerSelect from "react-native-picker-select";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import Api from "../Services/Api";
import { Colors, Fonts, Images, Metrics } from "../Themes";
import styles from "./Styles/AccountScreenStyles";
import NetInfo from "@react-native-community/netinfo";

class EditAccountScreen extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: null,
      showPicker: false,
      name: null,
      email: null,
      dob: null,
      gender: null,
      arrGender: [
        {
          label: "Female",
          value: "Female",
        },
        {
          label: "Male",
          value: "Male",
        },
      ],
      loading: true,
    };
    this.getUserDetail = this.getUserDetail.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getUserDetail();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userDetail != this.props.userDetail) {
      this.getUserDetail();
    }
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      this.setState({
        loading: false,
        userDetail: userdetails,
        name: userdetails.name,
        email: userdetails.email,
        dob: userdetails.dob,
        gender: userdetails.gender,
      });
    }
  }

  onPressSave = async () => {
    console.log("press save: ");
    Keyboard.dismiss();
    const network = await NetInfo.fetch();
    if (network.isConnected) {
      if (this.state.name.trim() == "") {
        Helper.showAlert(Constants.ERROR_MESSAGES.NAME);
      } else if (!Helper.validateName(this.state.name)) {
        Helper.showAlert(Constants.ERROR_MESSAGES.VALID_NAME);
      } else if (this.state.email.trim() == "") {
        Helper.showAlert(Constants.ERROR_MESSAGES.EMAIL);
      } else if (!Helper.validateEmail(this.state.email)) {
        Helper.showAlert(Constants.ERROR_MESSAGES.VALID_EMAIL);
      } else if (this.state.dob == "") {
        Helper.showAlert(Constants.ERROR_MESSAGES.DOB);
      } else if (this.state.gender == "") {
        Helper.showAlert(Constants.ERROR_MESSAGES.GENDER);
      } else {
        this.setState({ loading: true });
        const mApi = Api.createSecure();
        const formatedDate = moment(this.state.dob).format(
          Constants.DATE_FORMAT_DMY
        );
        console.log("Sending DOB", formatedDate);
        mApi
          .updateProfile(
            this.state.name,
            this.state.email,
            formatedDate,
            this.state.gender
          )
          .then((response) => {
            if (response.ok) {
              if (response.data.success) {
                this.props.setUserDetail(response.data.data);
                Alert.alert(
                  Constants.APP_NAME,
                  "Your account details has been updated successfully.",
                  [
                    {
                      text: "Ok",
                      onPress: () => this.handleBackButtonClick(),
                    },
                  ],
                  { cancelable: false }
                );
              } else {
                Helper.showAlert(
                  response.data.message,
                  this.props.navigation,
                  response.data.status
                );
              }
            } else {
              Helper.showAlert(response.problem, this.props.navigation);
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log("error: ", error);
            this.setState({ loading: false });
          });
      }
    } else {
      Helper.showAlert(Constants.ERROR_MESSAGES.NO_INTERNET);
    }
  };

  showDatePicker = (isShow) => {
    this.setState({ showPicker: isShow });
  };

  handleDatePicked = (date) => {
    console.log("date", date);
    const selectedDate = moment(date).format(Constants.DATE_FORMAT);
    console.log("selectedDate", selectedDate);
    this.setState({ dob: selectedDate });
    this.showDatePicker(false);
  };

  render() {
    const dob_formated = moment(this.state.dob).format(
      Constants.DATE_FORMAT_DMY
    );

    if (this.state.loading) {
      return <LoadingComponent />;
    }

    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.handleBackButtonClick()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Edit account details</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                style={styles.rightIconWithText}
                onPress={() => this.onPressSave()}
              >
                <Text style={styles.headerText}>Save</Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={styles.accountList}>
              <View style={styles.accountItem}>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Username</Text>
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="Username"
                    placeholderTextColor="rgba(255,255,255,0.7)"
                    value={this.state.name}
                    style={styles.inputField}
                    onChangeText={(name) => this.setState({ name })}
                  />
                </View>
              </View>
              <View style={styles.accountItem}>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Email</Text>
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="Email"
                    placeholderTextColor="rgba(255,255,255,0.7)"
                    value={this.state.email}
                    style={styles.inputField}
                    editable={
                      this.state.userDetail.social_type === "Normal"
                        ? true
                        : false
                    }
                    onChangeText={(email) => this.setState({ email })}
                    keyboardType="email-address"
                  />
                </View>
              </View>
              <TouchableOpacity
                style={styles.accountItem}
                onPress={() => this.showDatePicker(true)}
              >
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Date of birth</Text>
                  <TextInput
                    underlineColorAndroid="transparent"
                    placeholder="Date of birth"
                    placeholderTextColor="rgba(255,255,255,0.7)"
                    value={dob_formated}
                    style={styles.inputField}
                    editable={false}
                  />
                  <DateTimePicker
                    mode={"date"}
                    isVisible={this.state.showPicker}
                    onConfirm={(date) => this.handleDatePicked(date)}
                    onCancel={() => this.showDatePicker(false)}
                    maximumDate={new Date()}
                    date={new Date(this.state.dob)}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={styles.accountItem}>
                <View style={styles.accountItemBody}>
                  <Text style={styles.smallText}>Gender</Text>
                  <RNPickerSelect
                    placeholder={{
                      label: "Gender",
                      value: "",
                      fontSize: (Metrics.screenWidth * 24) / 414,
                      fontFamily: "semiBold",
                    }}
                    placeholderTextColor="rgba(255,255,255,0.7)"
                    items={this.state.arrGender}
                    onValueChange={(value, index) =>
                      this.state.gender != value
                        ? this.setState({ gender: value })
                        : null
                    }
                    style={{ ...pickerSelectStyles }}
                    value={this.state.gender}
                    useNativeAndroidPickerStyle={false} //android only
                    hideIcon={true}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </SafeAreaView>
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> Edit Account Screen', state);
//     return {
//         userDetail: JSON.parse(state.userReducer.userDetail),
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         getUserDetail: () => dispatch(ActionCreators.userActions.getUserDetail()),
//         setUserDetail: (userData) => dispatch(ActionCreators.userActions.setUserDetail(userData))
//     }
// }
export default EditAccountScreen;

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    backgroundColor: Colors.transparent,
    fontFamily: "robotoBold",
    fontSize: (Metrics.screenWidth * 18) / 414,
    color: "#fff",
  },
  inputAndroid: {
    backgroundColor: Colors.transparent,
    fontFamily: "robotoBold",
    fontSize: (Metrics.screenWidth * 18) / 414,
    color: "#fff",
  },
});
