import React from "react";
import {
  StyleSheet,
  Image,
  View,
  Text,
  Pressable,
  TouchableOpacity,
} from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Images } from "../Themes";

//Screens
import HomeScreen from "../Container/HomeScreen";
import SearchLocationScreen from "../Container/SearchLocationScreen";
import SearchCityScreen from "../Container/SearchCityScreen";
import EnterPriceScreen from "../Container/EnterPriceScreen";
import SearchScreen from "../Container/SearchScreen";
import ListingScreen from "../Container/ListingScreen";
import AddPriceScreen from "../Container/AddPriceScreen";
import MainAddPrice from "../Container/MainAddPrice";
import EntryListingScreen from "../Container/EntryListingScreen";

const Navigation = createNativeStackNavigator();

// HomeScreen: { screen: HomeScreen },
// ProductScreen: { screen: ProductScreen },
// ProductListScreen: { screen: ProductListScreen },
// ListingScreen: { screen: ListingScreen },
// SearchScreen: { screen: SearchScreen },
// FavouritesScreen: { screen: FavouritesScreen },
// ActivityScreen: { screen: ActivityScreen },
// AccountScreen: { screen: AccountScreen },
// EditAccountScreen: { screen: EditAccountScreen },
// ChangePasswordScreen: { screen: ChangePasswordScreen },
// YourEntriesScreen: { screen: YourEntriesScreen },
// PreferencesScreen: { screen: PreferencesScreen },
// SearchLocationScreen: { screen: SearchLocationScreen },
// SearchRadiusScreen: { screen: SearchRadiusScreen },
// HomeCurrencyScreen: { screen: HomeCurrencyScreen },
// OfflineSettingsScreen: { screen: OfflineSettingsScreen },
// TermsAndConditionsScreen: { screen: TermsAndConditionsScreen },
// PrivacyPolicyScreen: { screen: PrivacyPolicyScreen },
// EntryDetailsScreen: { screen: EntryDetailsScreen },
// CityScreen: { screen: CityScreen , navigationOptions: () => ({ gestureEnabled: false }) },
// SearchCityScreen: { screen: SearchCityScreen },
// TooltipScreen: { screen: TooltipScreen },
// EnterPriceScreen: { screen: EnterPriceScreen },
// AddImageScreen: { screen: AddImageScreen },
// AddPriceScreen: { screen: AddPriceScreen }

const getUserDetails = async () => {
  console.log("get user from async");
  let user = await AsyncStorage.getItem("user");
  return JSON.parse(user);
};

class HomeStack extends React.Component {
  render() {
    let fsProps = this.props;
    return (
      <Navigation.Navigator>
        <Navigation.Group>
          <Navigation.Screen
            name={"HomeScreen"}
            component={HomeScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"SearchLocationScreen"}
            component={SearchLocationScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"SearchCityScreen"}
            component={SearchCityScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"EnterPriceScreen"}
            component={EnterPriceScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"SearchScreen"}
            component={SearchScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"ListingScreen"}
            component={ListingScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"AddPriceScreen"}
            component={AddPriceScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"MainAddPrice"}
            component={MainAddPrice}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
          <Navigation.Screen
            name={"EntryListingScreen"}
            component={EntryListingScreen}
            initialParams={{
              getUserDetails: getUserDetails,
            }}
            options={({ ...props }) => ({
              animationEnabled: false,
              transitionConfig: () => ({
                transitionSpec: {
                  duration: 0,
                  timing: 0,
                },
              }),
              headerTitle: "",
              headerShown: false,
              headerTitleAlign: "center",
            })}
          />
        </Navigation.Group>
      </Navigation.Navigator>
    );
  }
}

export default HomeStack;
