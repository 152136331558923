import React, { Component } from 'react'
import { BackHandler } from 'react-native'

// this component user for when you press android back button to go back stack screen
export default class BaseComponent extends Component {
    constructor(props) {
        super(props)
        this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    }
    componentDidMount() { BackHandler.addEventListener('hardwareBackPress', this.handleBackButtonClick);}
    componentWillUnmount() { BackHandler.removeEventListener('hardwareBackPress', this.handleBackButtonClick); }
    handleBackButtonClick() {
        this.props.navigation.goBack(null);
        return true;
    }
}