import * as Helper from '../../Lib/Helper';
import Api from '../../Services/Api';
import ActionTypes from './types';
import Constants from '../../Lib/Constants';

const mApi = Api.createSecure()

const offlineDataActions = {
    getOfflineData
}

function getOfflineData(tableName, lastId = '') {
    return dispatch => {
        // dispatch({
        //     type: ActionTypes.OFFLINEDATA_DOWNLOADING,
        //     loading: true,
        // })
        if (Constants.isOnline) {
            var dictResponse = {};
            dictResponse['tableName'] = tableName
            dictResponse[tableName] = []
            mApi.getOfflineTableData(tableName, lastId).then(response => {
                if (response.ok) {
                    if (response.data.success) {
                        dictResponse[tableName] = response.data.data
                    } else {
                        Helper.showAlert(response.data.message)
                    }
                } else {
                    Helper.showAlert(response.problem)
                }
                dispatch({
                    type: ActionTypes.OFFLINEDATA_RESPONSE,
                    dictOfflineData: dictResponse,
                })
            }).catch(error => {
                console.log('error:', error);
                dictResponse[tableName] = []
                dispatch({
                    type: ActionTypes.OFFLINEDATA_RESPONSE,
                    dictOfflineData: dictResponse,
                })
                // dispatch({
                //     type: ActionTypes.OFFLINEDATA_DOWNLOADING,
                //     loading: false,
                // })
            })

        }
    }
}

export { offlineDataActions };

