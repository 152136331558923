import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Modal } from 'react-native';
import styles from './Styles/NoPriceModalComponentStyles'


export default class NoPriceModalComponent extends Component {

    componentDidUpdate(prevProps, prevState) {
        if (prevProps != this.props) {
            this.setState({
                visible: this.props.visible
            })
        }
    }

    render() {
        return (
            <Modal
                animationType="slide"
                transparent={true}
                visible={this.props.visible}
                onRequestClose={() => {
                    // Alert.alert('Modal has been closed.');
                }}>
                <View style={[styles.notFound,styles.imageZoomModal]}>
                    <View style={[styles.modalView]}>
                        <Text style={[styles.notFoundTitle,styles.textBlackLeftAlign]}>This product has no prices!</Text>
                        <Text style={[styles.notFoundText,styles.textBlackLeftAlign]}>No price have been entered for this product in this location! Continue by entering a price or go back to safety</Text>
                    </View>
                    <View style={{flexDirection:'row',alignContent:'space-between',}}>
                        <TouchableOpacity style={[styles.button,{marginHorizontal:20}]} onPress={() => this.props.onPressGoBack()}>
                            <Text style={styles.buttonText}>Go Back</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.button,{marginHorizontal:20}]} onPress={() => this.props.onPressEnterPrice()}>
                            <Text style={styles.buttonText}>Enter a price</Text>
                        </TouchableOpacity>
                    </View>
                </View>

            </Modal>
        )
    }
}