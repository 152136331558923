import * as Location from "expo-location";
import { apiRequests } from "../apiRequests";

const getLocationPermissionNow = async () => {
  console.log("get user current location permission service");

  let setUserLocation = {};

  let userLocation = await Location.getForegroundPermissionsAsync();
  console.log("userLocation: ", userLocation);
  if (userLocation.status == "undetermined") {
    userLocation = await Location.requestForegroundPermissionsAsync();
    console.log("ask for permission location: ", userLocation);
    if (userLocation.granted) {
      console.log("granted was given now get location");
      setUserLocation = await Location.getCurrentPositionAsync({
        accuracy: 1,
      });
    }
  } else if (userLocation.granted == false && userLocation.canAskAgain) {
    console.log("location perms not granted but can ask again");
    userLocation = await Location.requestForegroundPermissionsAsync();
    if (userLocation.granted) {
      setUserLocation = await Location.getCurrentPositionAsync({
        accuracy: 1,
      });
    }
  } else if (userLocation.status == "granted" && userLocation.granted) {
    console.log("permission granted now get location");
    setUserLocation = await Location.getCurrentPositionAsync({
      accuracy: 1,
    });
    console.log("set user loc: ", setUserLocation);
  }

  let address = await Location.reverseGeocodeAsync({
    latitude: setUserLocation.coords.latitude,
    longitude: setUserLocation.coords.longitude,
  });

  console.log("current address: ", address);

  let locationData = {
    country: address[0].country,
    latitude: setUserLocation.coords.latitude,
    location: `${address[0].city}, ${address[0].region}`,
    longitude: setUserLocation.coords.longitude,
    short_name: address[0].isoCountryCode,
  };

  let updateLocation = await apiRequests.updateLocation(locationData);

  console.log("update the user loocation: ", updateLocation);

  if (updateLocation.status == 200) {
    if (updateLocation.data.success) {
      console.log("location was updated: ", updateLocation.data);
    }
  }
};

export default getLocationPermissionNow;