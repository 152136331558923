import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import mime from "mime";
import secrets from "../../../secrets.json";

export const api = async function (endpoint, type, data, formData = false) {
  // const domain = secrets.LOCALDOMAIN;
  const domain = secrets.APIDOMAIN;
  const token = await AsyncStorage.getItem("token");
  const expiresIn = await AsyncStorage.getItem("expires_in");

  var duration = moment.duration(
    moment(expiresIn, "YYYY-MM-DD HH:mm:ss").diff(moment())
  );
  var difInMins = duration.asMinutes();

  console.log("expiresIn: ", expiresIn);
  console.log("difInMins: ", difInMins);

  if (difInMins <= -1) {
    console.log("expired token");
    await AsyncStorage.removeItem("token");
    await AsyncStorage.removeItem("refreshToken");
    await AsyncStorage.removeItem("expires_in");
    return "expired";
  } else if (difInMins <= 120) {
    await refreshToken();
  } else if (difInMins == NaN) {
    return "expired";
  }

  if (token) {
    let headerObj = {
      authorization: `Bearer ${token}`,
    };

    console.log("FormData check = ", formData);

    if (formData) {
      headerObj["Content-Type"] = "multipart/form-data";
    }

    var newData = null;
    if (formData) {
      console.log("use form data");
      const formDataDetails = data.data;
      console.log("data for form: ", formDataDetails);
      // console.log("data : ", data);
      newData = new FormData();

      newData.append("endpoint", data.endpoint);
      newData.append("type", data.type);

      for (let am = 0; am < formDataDetails.arrMedia.length; am++) {
        const element = formDataDetails.arrMedia[am];
        const fileExtension = mime.getType(element.uri);

        newData.append(
          "photos",
          {
            type: fileExtension,
            uri: element.uri,
            name:
              element.name ||
              `image-${moment().format("YYYY-MM-DD-HH-mm-ss")}.jpg`,
          },
          element.name || `image-${moment().format("YYYY-MM-DD-HH-mm-ss")}.jpg`
        );
      }

      if (formDataDetails.arrMedia.length == 0) {
        newData.append("photos", null);
      }

      newData.append("price", formDataDetails.price);
      newData.append("categories", formDataDetails.categories);
      newData.append("latitude", formDataDetails.latitude);
      newData.append("longitude", formDataDetails.longitude);
      newData.append("location", formDataDetails.location);
      newData.append("country", formDataDetails.country);
      newData.append("tags", formDataDetails.tags);
      newData.append("description", formDataDetails.description);
      newData.append("user_id", formDataDetails.user_id);
    } else {
      newData = data;
    }

    console.log("pass this data: ", newData);

    console.log("obj for axios: ", {
      method: type,
      url: domain + endpoint,
      headers: headerObj,
      data: newData,
    });

    try {
      let request = await axios({
        method: type,
        url: domain + endpoint,
        headers: headerObj,
        data: newData,
      });
      // alert(JSON.stringify(request));
      return request;
    } catch (error) {
      console.log("domain: ", domain);
      console.log("Endpoint: ", endpoint);
      console.log("API Error: ", error);
      console.log("Error: ", error.response);
      // alert(JSON.stringify(error));
      // alert(JSON.stringify(error.response));
      return error;
    }
  } else {
    console.log("token does not exist");
    return false;
  }
};

export const refreshToken = async function () {
  console.log("refresh token, its about to expire");
  const refreshToken = await AsyncStorage.getItem("refreshToken");

  if (refreshToken !== null) {
    try {
      let request = await axios({
        method: "POST",
        url: secrets.auth0Domain + "/oauth/token",
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({
          grant_type: "refresh_token",
          client_id: secrets.auth0ClientId,
          client_secret: secrets.auth0ClientSecret,
          refresh_token: refreshToken,
        }),
      });
      if (request.status == 200) {
        let result = request.data;
        AsyncStorage.setItem("token", result.access_token);
        AsyncStorage.setItem("refreshToken", result.refresh_token);
        let expiry = moment().add(result.expires_in, "seconds");
        AsyncStorage.setItem("expires_in", String(expiry));
      }
    } catch (error) {
      if (error.response.status == 403) {
        AsyncStorage.removeItem("token");
        AsyncStorage.removeItem("refreshToken");
        AsyncStorage.removeItem("expires_in");
      }
      console.log("API Error: ", error.response);
      console.log("API Error: ", error);
    }
  }
};
