import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native";
// import MapView, { Circle } from "react-native-maps";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import CustomMarker from "../Components/CustomMarker";
import * as Helper from "../Lib/Helper";
import { Images, Metrics } from "../Themes";
import styles from "./Styles/SearchRadiusScreenStyles";
import Constants from "../Lib/Constants";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import LoadingComponent from "../Components/LoadingComponent";
import ActionCreators from "../Redux/actions";
import { apiRequests } from "../apiRequests";
import MyMap from "../Components/myMap";
class SearchRadiusScreen extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.route.params;
    (this.state = {
      isFromPreference:
        param && param.isFromPreference ? param.isFromPreference : false,

      userDetail: null,
      radius: 0,
      loading: true,
      isPopUpVisible: false,
      popUpMessage: "",
    }),
      (this.getUserDetail = this.getUserDetail.bind(this));
  }

  componentDidMount() {
    this.getUserDetail();
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      this.setState({
        loading: false,
        userDetail: userdetails,
        radius: userdetails.search_radius,
      });
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.searchLocationReducer != this.props.searchLocationReducer) {
  //         if (prevProps.searchLocationReducer.loading != this.props.searchLocationReducer.loading) {
  //             this.setState({ loading: this.props.searchLocationReducer.loading })
  //         }
  //         if (prevProps.searchLocationReducer.isSearchLocationUpdated != this.props.searchLocationReducer.isSearchLocationUpdated) {
  //             if (this.props.searchLocationReducer.isSearchLocationUpdated) {
  //                 if (this.state.isFromPreference) {
  //                     this.handleBackButtonClick()
  //                     this.props.navigation.state.params.showPopup(true, Constants.RADIUS_SAVED)
  //                 } else {
  //                     this.setState({ isPopUpVisible: true, popUpMessage: Constants.RADIUS_SAVED }, () => {
  //                         setTimeout(() => {
  //                             this.setState({ isPopUpVisible: false, popUpMessage: '' }, () => this.handleBackButtonClick())
  //                         }, Constants.TIME_FOR_POP_UPS);
  //                     });
  //                 }
  //             }
  //         }
  //     }
  // }

    // Callback function to handle radius change
    handleRadiusChange = (newRadius) => {
      this.setState({ radius: newRadius / 1000 }); // Convert back to kilometers for internal state
    };

  onPressSave = async () => {
    var locationData = {
      latitude: this.state.userDetail.search_latitude,
      longitude: this.state.userDetail.search_longitude,
      location: this.state.userDetail.search_location,
      country: this.state.userDetail.search_country.name,
      short_name: this.state.userDetail.search_country.iso_code_2,
      radius: this.state.radius,
    };
    console.log("save search location detils");
    let updateLocation = await apiRequests.updateLocation(locationData);
    console.log(updateLocation);
    if (updateLocation.status == 200) {
      if (this.state.isFromPreference) {
        this.handleBackButtonClick();
        this.props.route.params.showPopup(true, Constants.RADIUS_SAVED);
      } else {
        this.setState(
          { isPopUpVisible: true, popUpMessage: Constants.RADIUS_SAVED },
          () => {
            setTimeout(() => {
              this.setState({ isPopUpVisible: false, popUpMessage: "" }, () =>
                this.handleBackButtonClick()
              );
            }, Constants.TIME_FOR_POP_UPS);
          }
        );
      }
    } else {
      Helper.showAlert(
        "Could not update radius, please try again",
        this.props.navigation
      );
    }
  };

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }

    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.handleBackButtonClick()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Search radius</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                activeOpacity={1}
                style={styles.rightIconWithText}
                onPress={() => this.onPressSave()}
              >
                <Text style={styles.headerText}>Save</Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View>
              {/* <Image source={Images.map} style={styles.mapImage} />
                            <TouchableOpacity style={styles.radiusCircle}></TouchableOpacity> */}
            {console.log("user detail set; ", this.state.userDetail)}
             {this.state.userDetail && (
                <MyMap
                  entryDetail={{
                    latitude: this.state.userDetail.search_latitude,
                    longitude: this.state.userDetail.search_longitude,
                  }}
                  initialRadius={this.state.radius * 1000} // Convert radius to meters
                  onRadiusChange={this.handleRadiusChange} // Pass the callback
                />
              )}
            </View>
            <View style={styles.rangeSliderContainer}>
              {/* <MultiSlider
                values={[this.state.radius]}
                sliderLength={Metrics.screenWidth - 60}
                onValuesChange={(values) =>
                  this.setState({ radius: values[0] })
                }
                min={1}
                max={1000}
                step={1}
                selectedStyle={{
                  backgroundColor: "rgba(255,255,255,0.2)",
                }}
                trackStyle={{
                  height: 6,
                  backgroundColor: "rgba(255,255,255,0.2)",
                  marginTop: -3,
                }}
                customMarker={CustomMarker}
              /> */}
              <View style={styles.rangeSliderValues}>
                <Text style={[styles.smallText, styles.normalTextLight]}>
                  1km
                </Text>
                <Text style={[styles.smallText, styles.normalTextLight]}>
                  1000km
                </Text>
              </View>
              <View>
                <Text
                  style={[
                    styles.heading1,
                    styles.alignCenter,
                    { marginBottom: 0 },
                  ]}
                >
                  {this.state.radius + "km"}
                </Text>
                <View style={[styles.locationContent, { paddingTop: 0 }]}>
                  <Image source={Images.location} style={styles.location} />
                  <Text style={styles.locationText}>
                    {this.state.userDetail.search_location}
                  </Text>
                </View>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
        <PopupDialogComponent
          visible={this.state.isPopUpVisible}
          message={this.state.popUpMessage}
        />
      </ImageBackground>
    );
  }
}


export default SearchRadiusScreen;
