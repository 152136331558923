import React from "react";
import {
  Image,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  Platform,
  Alert,
} from "react-native";
import { SafeAreaView } from "react-native";
import BaseComponent from "../Components/BaseComponent";
import { Images } from "../Themes";
import styles from "./Styles/SearchLocationScreenStyles";
import * as Helper from "../Lib/Helper";
import LoadingComponent from "../Components/LoadingComponent";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import { connect } from "react-redux";
import ActionCreators from "../Redux/actions";
import Constants from "../Lib/Constants";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Location from "expo-location";
import { apiRequests } from "../apiRequests";

class SearchLocationScreen extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.route.params;
    this.state = {
      isFromPreference:
        param && param.isFromPreference ? param.isFromPreference : false,
      loading: false,
      isPopUpVisible: false,
      popUpMessage: "",
    };

    this.setSearchLocation = this.setSearchLocation.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchLocationReducer != this.props.searchLocationReducer) {
      if (
        prevProps.searchLocationReducer.loading !=
        this.props.searchLocationReducer.loading
      ) {
        this.setState({ loading: this.props.searchLocationReducer.loading });
      }
      if (
        prevProps.searchLocationReducer.isSearchLocationUpdated !=
        this.props.searchLocationReducer.isSearchLocationUpdated
      ) {
        if (this.props.searchLocationReducer.isSearchLocationUpdated) {
          console.log("isFromPrefernece: ", this.state.isFromPreference);
          if (this.state.isFromPreference) {
            // this.handleBackButtonClick()
            if (this.props.route.params.showPopup) {
              this.props.route.params.showPopup(true, Constants.PREF_SAVED);
            } else {
              this.setState(
                { isPopUpVisible: true, popUpMessage: Constants.PREF_SAVED },
                () => {
                  setTimeout(() => {
                    this.setState(
                      { isPopUpVisible: false, popUpMessage: "" },
                      () => this.handleBackButtonClick()
                    );
                  }, Constants.TIME_FOR_POP_UPS);
                }
              );
            }
            setTimeout(() => {
              this.props.navigation.goBack();
            }, 3000);
          } else {
            this.setState(
              { isPopUpVisible: true, popUpMessage: Constants.PREF_SAVED },
              () => {
                setTimeout(() => {
                  this.setState(
                    { isPopUpVisible: false, popUpMessage: "" },
                    () => this.handleBackButtonClick()
                  );
                }, Constants.TIME_FOR_POP_UPS);
              }
            );
          }
        }
      }
    }
  }

  getLocationPermission = async () => {
    // const locationPermission = Platform.select({
    //     ios: PERMISSIONS.IOS.LOCATION_WHEN_IN_USE,
    //     android: PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
    // })
    // request(locationPermission).then(result => {
    //     console.log('Permission result: SearchLoaction', JSON.stringify(result));
    //     if (result == RESULTS.DENIED || result == RESULTS.BLOCKED) {
    //         console.log('RESULTS.DENIED || RESULTS.BLOCKED');
    //         Helper.openSetting(Constants.MESSAGE_ACCESS_LOCATION)
    //     }
    //     else if (result == RESULTS.GRANTED){
    //         this.getCurrentLocation()
    //     }
    //     // switch (result) {
    //     //     case RESULTS.UNAVAILABLE:
    //     //         console.log('This feature is not available on this device');
    //     //         break;

    //     //     case RESULTS.GRANTED:
    //     //         this.getCurrentLocation()
    //     //         break;

    //     //     case RESULTS.DENIED || RESULTS.BLOCKED:
    //     //         openSettings().catch(() => console.warn('cannot open settings'));
    //     //         break;
    //     // }
    // }).catch(error => console.log('Permission Error: ', error))

    //get current location permissions
    console.log("GET LOCATION PERMISSIONS");
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      setErrorMsg("Permission to access location was denied");
      return;
    } else {
      this.getCurrentLocation();
    }
  };

  async setSearchLocation(locationData) {
    console.log("update search location");
    console.log(locationData);
    let updateLocation = await apiRequests.updateLocation(locationData);

    if (updateLocation.status == 200) {
      if (updateLocation.data.success) {
        if (this.props.route.params.isListingScreen) {
          this.props.route.params.getListingDetails();
        }
        if (this.state.isFromPreference) {
          this.props.route.params.getUserDetails();
          if (this.props.route.params.showPopup) {
            this.props.route.params.showPopup(true, Constants.LOCATION_SAVED);
          } else {
            this.setState(
              { isPopUpVisible: true, popUpMessage: Constants.PREF_SAVED },
              () => {
                setTimeout(() => {
                  this.setState(
                    { isPopUpVisible: false, popUpMessage: "" },
                    () => this.handleBackButtonClick()
                  );
                }, Constants.TIME_FOR_POP_UPS);
              }
            );
          }
        }
        if (this.props.route.params.isFromSearch) {
          this.props.route.params.getUserDetails();
          this.props.route.params.searchKeyword(
            this.props.route.params.keyword
          );
        }
        this.props.navigation.goBack();
      }
    }
  }

  getCurrentLocation = async () => {
    console.log("get Current location deets");
    //use expo to check online - build function
    this.setState({ loading: true });
    await AsyncStorage.setItem(
      Constants.IS_CURRENT_LOCATION,
      JSON.stringify("true")
    );
    Helper.getCurrentLocationData((success, locationData) => {
      if (success) {
        this.setSearchLocation(locationData);
      }
      this.setState({ loading: false });
    });
  };

  onPressSearch = () => {
    Alert.alert(
      "Change search location?",
      "By changing your search location any offline data you have saved will be removed and new information will be synced",
      [
        {
          text: "Change Location",
          onPress: () =>
            this.props.navigation.navigate("SearchCityScreen", {
              isFromSearch: this.props.route.params.isFromSearch,
              getUserDetails: this.props.route.params.getUserDetails,
              searchKeyword: this.props.route.params.searchKeyword,
              keyword: this.props.route.params.keyword,
              isListingScreen: this.props.route.params.isListingScreen,
              getListingDetails: this.props.route.params.getListingDetails,
            }),
        },
        {
          text: "Cancel",
          onPress: () => console.log("cancel"),
        },
      ],
      {
        cancelable: false,
      }
    );
  };

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.mainContainer}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.handleBackButtonClick()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Search location</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                activeOpacity={1}
                style={styles.rightIconWithText}
                onPress={() => {
                  this.handleBackButtonClick();
                  if (this.props.route.params.showPopup) {
                    this.props.route.params.showPopup(
                      true,
                      Constants.LOCATION_SAVED
                    );
                  } else {
                    this.setState(
                      {
                        isPopUpVisible: true,
                        popUpMessage: Constants.PREF_SAVED,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState(
                            { isPopUpVisible: false, popUpMessage: "" },
                            () => this.handleBackButtonClick()
                          );
                        }, Constants.TIME_FOR_POP_UPS);
                      }
                    );
                  }
                }}
              >
                <Text style={styles.headerText}>Done</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.list}>
              <TouchableOpacity
                style={styles.listItem}
                onPress={() => this.onPressSearch()}
              >
                <Image source={Images.search} style={styles.listIcon} />
                <View style={styles.listItemBody}>
                  <Text style={styles.listItemText}>
                    Search for a suburb, city or country
                  </Text>
                </View>
                <Image
                  source={Images.arrowRight}
                  style={styles.listItemArrow}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.listItem}
                onPress={() => this.getLocationPermission()}
              >
                <Image source={Images.location} style={styles.listIcon} />
                <View style={styles.listItemBody}>
                  <Text style={styles.listItemText}>
                    Use your current position
                  </Text>
                </View>
                <Image
                  source={Images.arrowRight}
                  style={styles.listItemArrow}
                />
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>

        <PopupDialogComponent
          visible={this.state.isPopUpVisible}
          message={this.state.popUpMessage}
        />
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> SearchLocation Screen', state);
//     return {
//         searchLocationReducer: state.searchLocationReducer
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         setSearchLocation: (locationData) => dispatch(ActionCreators.searchLocationActions.setSearchLocation(locationData)),
//     }
// }
export default SearchLocationScreen;
