import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,       
    rangeSliderContainer:{
        justifyContent:'center',
        alignItems:'center',
        padding:20
    },
    rangeSliderValues:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        width: '100%'
    },    
})
