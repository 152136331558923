import { StyleSheet, Platform } from 'react-native'
import { Metrics, ApplicationStyles, Colors, Fonts } from '../../Themes/'
export default StyleSheet.create({
    ...ApplicationStyles.screen,

    notFound: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: (Metrics.screenWidth / 5),
        paddingVertical: 24
    },
    buttonAddPrice: {
      height: (Metrics.screenWidth * 40) / 414,
      borderRadius: (Metrics.screenWidth * 20) / 414,
    },
})
