import React, { Component } from "react";
import {
  FlatList,
  View,
  TouchableOpacity,
  Text,
  Image,
  Keyboard,
  ScrollView,
} from "react-native";
import styles from "./Styles/SearchComponentStyles";
import { Images } from "../Themes";
import * as Helper from "../Lib/Helper";
import NoSearchResult from "./NoSearchResult";

// this component user for when you press android back button to go back stack screen
export default class SearchComponent extends Component {
  static defaultProps = { arrTags: [], arrCategories: [] };
  constructor(props) {
    super(props);
    this.state = {
      arrTags: this.props.arrTags,
      arrCategories: this.props.arrCategories,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.arrTags != this.props.arrTags) {
      this.setState({ arrTags: this.props.arrTags });
    }
    if (prevProps.arrCategories != this.props.arrCategories) {
      this.setState({ arrCategories: this.props.arrCategories });
    }
  }

  moveToListScreen = (item) => {
    Keyboard.dismiss();
    console.log("item", item);
    if (this.props.onPress) {
      this.props.onPress(item);
    } else {
      this.props.navigation.navigate("ListingScreen", {
        catId: item.cat_id,
        tagIds: item.tag_ids || "",
        isFromSearch: true,
        item: item,
        searchKeyword: this.props.searchKeyword,
        keyword: this.props.keyword,
      });
    }
  };

  renderItemCategories = (item, index) => {
    return (
      <TouchableOpacity
        style={[styles.listItem, styles.listItemMarginHorizontal]}
        onPress={() => this.moveToListScreen(item)}
      >
        <View style={styles.listBody}>
          <Text style={[styles.smallText, { marginTop: 3 }]}>
            {item.categoryArr ? item.categoryArr.join(" - ") : ""}
          </Text>
          <Text style={styles.listItemBigText}>{item.name}</Text>
          {/* <Text style={styles.normalText}>{'item.other_tags'}</Text> */}
        </View>
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entries_count}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  // renderItemTags = (item, index) => {
  //     console.log("remder item: ", item)
  //     return (
  //         <TouchableOpacity style={[styles.listItem, styles.listItemMarginHorizontal]} onPress={() => this.moveToListScreen(item)}>
  //             <View style={styles.listBody}>
  //                 <Text style={[styles.smallText, { marginTop: 3 }]}>{item.category}</Text>
  //                 <Text style={styles.listItemBigText}>{item.main_tag}</Text>
  //                 {item.other_tags ? <Text style={[styles.normalText, { marginBottom: 2 }]}>{item.other_tags}</Text> : null}

  //             </View>
  //             <View style={styles.listItemRight}>
  //                 <Text style={styles.countText}>{item.entries_count}</Text>
  //                 <Image source={Images.arrowRight} style={styles.listItemArrow} />
  //             </View>
  //         </TouchableOpacity>
  //     )
  // }

  renderItemTags = (item, index) => {
    console.log("remder item: ", item);
    return (
      <TouchableOpacity
        style={[styles.listItem, styles.listItemMarginHorizontal]}
        onPress={() => this.moveToListScreen(item)}
      >
        <View style={styles.listBody}>
          <Text style={[styles.smallText, { marginTop: 3 }]}>
            {item.categories.split(",").join(", ")}
          </Text>
          <Text style={styles.listItemBigText}>
            {item.tags.split(",").join(", ")}
          </Text>
        </View>
        <View style={styles.listItemRight}>
          <Text style={styles.countText}>{item.entryCount}</Text>
          <Image source={Images.arrowRight} style={styles.listItemArrow} />
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <View styles={[styles.container, { paddingBottom: 100 }]}>
        <ScrollView
          contentContainerStyle={styles.ScrollView}
          keyboardShouldPersistTaps="never"
        >
          <FlatList
            data={this.state.arrTags}
            renderItem={({ item, index }) => this.renderItemTags(item, index)}
            keyExtractor={(item, index) => index + ""}
            key={"tags"}
            ListEmptyComponent={
              this.state.arrTags.length == 0 &&
              this.state.arrCategories.length == 0
                ? this.renderEmptyComponent
                : null
            }
            extraData={this.state}
          />
          <View style={styles.categoriesList}>
            <FlatList
              data={this.state.arrCategories}
              renderItem={({ item, index }) =>
                this.renderItemCategories(item, index)
              }
              ListHeaderComponent={
                this.state.arrCategories.length > 0
                  ? this.renderCategoryHeaderComponent
                  : null
              }
              keyExtractor={(item, index) => index + ""}
              key={"categories"}
              extraData={this.state}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
  renderCategoryHeaderComponent = () => {
    return <Text style={styles.categoriesListTitle}>Categories</Text>;
  };

  renderEmptyComponent = () => {
    return (
      <View style={styles.notFound}>
        <Image source={Images.search} style={styles.notFoundIcon} />
        <Text style={styles.notFoundTitle}>Search Tengah</Text>
        <Text style={styles.notFoundText}>
          Find the average price for anything
        </Text>
      </View>
    );
  };

  // renderEmptyComponent = () => {
  //     return (
  //         <NoSearchResult
  //             containerStyle={{paddingTop:100}}
  //             title={'No categories match your search!'}
  //             onPressClearSearch={() => this.props.onPressClearSearch()} />
  //     )
  // }
}
