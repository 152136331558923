import React, { Component, Fragment } from "react";
import {
  View,
  Text,
  KeyboardAvoidingView,
  Image,
  ImageBackground,
  TouchableOpacity,
  FlatList,
  Modal,
  ScrollView,
} from "react-native";
import { Images, Colors, Metrics, Fonts } from "../Themes";
import styles from "./Styles/YourEntriesScreenStyles";
import { SafeAreaView } from "react-native";
import { LinearGradient } from 'expo-linear-gradient';
import Icon from "react-native-vector-icons/FontAwesome";
import BaseComponent from "../Components/BaseComponent";
import ActionCreators from "../Redux/actions";
import { connect } from "react-redux";
import LoadingComponent from "../Components/LoadingComponent";
import { moveToListScreen } from "../Lib/Helper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment-timezone";

class YourEntriesScreen extends BaseComponent {
  constructor() {
    super();
    this.state = {
      loading: false,
      arrMyEntry: [],
    };
    this.getUserEntries = this.getUserEntries.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getUserEntries();
  }

  getUserEntries = async () => {
    var userDetails = await AsyncStorage.getItem("user");
    userDetails = JSON.parse(userDetails);
    let param = {
      latitude: userDetails.search_latitude,
      longitude: userDetails.search_longitude,
      distanceInMetres: userDetails.search_radius,
    };

    const userEntries = await ActionCreators.entryActions.myEntries(
      param,
      userDetails.id
    );
    if (userEntries.status == 200) {
      const result = userEntries.data.data;
      // console.log(result);
      this.setState({
        arrMyEntry: result,
      });
    }
  };

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.entryReducer != this.props.entryReducer) {
  //         if (prevProps.entryReducer.myEntriesLoading != this.props.entryReducer.myEntriesLoading) {
  //             this.setState({ loading: this.props.entryReducer.myEntriesLoading })
  //         }
  //         if (prevProps.entryReducer.arrMyEntry != this.props.entryReducer.arrMyEntry) {
  //             this.props.entryReducer.arrMyEntry.forEach(entry => {
  //                 var arrTags = []
  //                 entry.tags.forEach(tag => {
  //                     if (tag == entry.tags[0]) {
  //                         entry['title'] = tag.name
  //                     }
  //                     else {
  //                         arrTags.push(tag.name)
  //                     }
  //                 });
  //                 entry['other_tags'] = arrTags.join(' • ')
  //             });

  //             this.setState({ arrMyEntry: this.props.entryReducer.arrMyEntry })
  //         }
  //     }
  // }

  onPressEntryItem = (item) => {
    var tagIds = "";
    var arrTagIds = [];
    console.log("onPressEntryItem", item);
    this.props.navigation.navigate("SearchScreen", {
      isFromSearch: false,
      searchItem: item.entryKey.split("|")[0],
      searchTags: item.entryKey.split("|")[1],
      editPrice: true,
      specificListing: item.entryKeyId,
      enteredValues: {
        price: item.price,
        currencyCode: item.curr_code,
      },
    });
    // item.tags.forEach(tag => {
    //     arrTagIds.push(tag.id)
    // });
    // tagIds = arrTagIds.join(',')
    // moveToListScreen(
    //     this.props.navigation,
    //     item.cat_id,
    //     tagIds,
    // )
  };

  renderItemTitle = (item) => {
    return (
      <View style={styles.listBody}>
        <Text style={styles.smallText}>{item.categories}</Text>
        <Text style={styles.listItemBigText}>
          {item.tags.replace(/,/g, ", ")}
        </Text>
      </View>
    );
  };

  renderMyEntryItem(item, index) {
    // console.log('Item',item);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let utcDate = moment.tz(item.created_at, "UTC");
    let createdDate = moment(utcDate).tz(timezone).format("LLL");
    return (
      <TouchableOpacity
        style={styles.listRow}
        onPress={() => {
          this.onPressEntryItem(item);
        }}
      >
        <View style={[styles.listItem, styles.listBorderNone]}>
          {this.renderItemTitle(item)}
          <View style={styles.listItemRight}>
            <Image source={Images.arrowRight} style={styles.listItemArrow} />
          </View>
        </View>
        {item.localPrice !== false && (
          <View style={styles.listRowBottom}>
            <View style={[styles.textTag, styles.flex]}>
              <Text style={styles.thirtheenStyle}>
                {item.localPrice[0].currency_code}
                {item.localPrice[0].averagePrice.toFixed(2)}{" "}
              </Text>
            </View>
            <Text style={[styles.thirtheenStyleLight, styles.italic]}>
              {createdDate}
            </Text>
          </View>
        )}
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.props.navigation.navigate("AccountScreen")}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Your Entries</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity style={styles.rightIcon}>
                <Text style={styles.headerText}> </Text>
              </TouchableOpacity>
            </View>
          </View>
          <ScrollView contentContainerStyle={styles.ScrollView}>
            <View style={[styles.content, { paddingTop: 0 }]}>
              <FlatList
                data={this.state.arrMyEntry}
                renderItem={({ item, index }) =>
                  this.renderMyEntryItem(item, index)
                }
                keyExtractor={(item, index) => index + ""}
                key={"tags"}
                extraData={this.state}
              />
            </View>
          </ScrollView>
        </SafeAreaView>

        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>> YourEntries Screen', state);
//     return {
//         entryReducer: state.entryReducer
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         getMyEntryList: (navigation) => dispatch(ActionCreators.entryActions.myEntries(navigation))
//     }
// }

export default YourEntriesScreen;
