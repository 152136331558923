import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import {
  ImageBackground,
  Keyboard,
  Text,
  TouchableOpacity,
  View,
  Platform,
  KeyboardAvoidingView,
  Pressable,
  ScrollView,
} from "react-native";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from "../Lib/Helper";
import ActionCreators from "../Redux/actions";
import { Fonts, Images, Metrics } from "../Themes";
import styles from "./Styles/SignupScreenStyles";
import { apiRequests } from "../apiRequests";
import { api } from "../../src/utils/api";

class SearchCityScreen extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.route.params;
    this.state = {
      loading: false,
      isEditing: param && param.isEditing ? param.isEditing : false,
      isMainAdd: param && param.isMainAdd ? param.isMainAdd : false,
      countryCities: [],
    };
    this.selectCountryCity = this.selectCountryCity.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchLocationReducer != this.props.searchLocationReducer) {
      if (
        prevProps.searchLocationReducer.loading !=
        this.props.searchLocationReducer.loading
      ) {
        this.setState({ loading: this.props.searchLocationReducer.loading });
      }
      if (
        prevProps.searchLocationReducer.isSearchLocationUpdated !=
        this.props.searchLocationReducer.isSearchLocationUpdated
      ) {
        if (this.props.searchLocationReducer.isSearchLocationUpdated) {
          this.handleBackButtonClick();
        }
      }
    }
  }

  async onPressRow(data, details) {
    Keyboard.dismiss();
    this.setState({ loading: true });

    var locationData = {
      latitude: "",
      longitude: "",
      location: "",
      country: "",
    };

    // console.log('ONPRESS: ', JSON.stringify(data));
    // console.log('ONPRESS2: ', JSON.stringify(details));
    if (details != null) {
      if (details.types.includes("country")) {
        const cities = await api("v1/api/request", "POST", {
          endpoint: "cities",
          type: "POST",
          data: {
            country: details.address_components[0].long_name,
          },
        });
        this.setState({
          loading: false,
          countryCities: cities.data.data,
        });
      } else {
        if (details.address_components) {
          locationData = Helper.getLocationDataFromAddressComponent(
            details,
            locationData
          );
        }

        let shortName = "";
        for (let lc = 0; lc < details.address_components.length; lc++) {
          const element = details.address_components[lc];
          if (element.types.includes("country")) {
            shortName = element.short_name;
          }
        }

        if (details.geometry && details.geometry.location) {
          const latLng = details.geometry.location;
          locationData = {
            ...locationData,
            short_name: shortName,
            latitude: latLng.lat,
            longitude: latLng.lng,
          };
        }
        console.log("locationData", locationData);
        if (this.state.isEditing) {
          await apiRequests.updateLocation(locationData);
          if (this.props.route.params.updateLocation) {
            this.props.route.params.updateLocation(locationData, true);
          }
          this.props.navigation.navigate("EnterPriceScreen");
        } else if (this.state.isMainAdd) {
          await apiRequests.updateLocation(locationData);
          if (this.props.route.params.updateLocation) {
            this.props.route.params.updateLocation(locationData, true);
          }
          this.props.navigation.goBack();
        } else {
          await AsyncStorage.removeItem(Constants.IS_CURRENT_LOCATION);
          //console.log("Call update location");
          await apiRequests.updateLocation(locationData);
          this.props.navigation.goBack();
          if (this.props.route.params.isListingScreen) {
            this.props.route.params.getListingDetails();
            this.props.navigation.goBack();
          }
          if (this.props.route.params.isFromSearch) {
            this.props.route.params.getUserDetails();
            this.props.route.params.searchKeyword(
              this.props.route.params.keyword
            );
            this.props.navigation.goBack();
          }
          // apiRequests.updateLocation(locationData);
          // this.props.setSearchLocation(locationData)
        }
      }
      this.setState({ loading: false });
    }
  }

  async selectCountryCity(data) {
    console.log("selected location: ", data);
    var locationData = {
      latitude: data.lat,
      longitude: data.lng,
      location: data.city,
      country: data.country,
    };

    if (this.state.isEditing) {
      await apiRequests.updateLocation(locationData);
      if (this.props.route.params.updateLocation) {
        this.props.route.params.updateLocation(locationData, true);
      }
      this.props.navigation.navigate("EnterPriceScreen");
    } else if (this.state.isMainAdd) {
      await apiRequests.updateLocation(locationData);
      if (this.props.route.params.updateLocation) {
        this.props.route.params.updateLocation(locationData, true);
      }
      this.props.navigation.goBack();
    } else {
      await AsyncStorage.removeItem(Constants.IS_CURRENT_LOCATION);
      //console.log("Call update location");
      await apiRequests.updateLocation(locationData);
      this.props.navigation.goBack();
      if (this.props.route.params.isListingScreen) {
        this.props.route.params.getListingDetails();
        this.props.navigation.goBack();
      }
      if (this.props.route.params.isFromSearch) {
        this.props.route.params.getUserDetails();
        this.props.route.params.searchKeyword(this.props.route.params.keyword);
        this.props.navigation.goBack();
      }
      // apiRequests.updateLocation(locationData);
      // this.props.setSearchLocation(locationData)
    }
  }

  render() {
    const parentThis = this;
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={[styles.container, { paddingTop: 30 }]}>
          <KeyboardAvoidingView
            keyboardVerticalOffset={60}
            behavior={Platform.OS == "android" ? "height" : "padding"}
          >
            <GooglePlacesAutocomplete
              placeholder="Search for a suburb, city or country"
              placeholderTextColor="rgba(255, 255, 255, 0.7)"
              minLength={2}
              autoFocus={false}
              returnKeyType="search"
              fetchDetails={true}
              suppressDefaultStyles={true}
              listViewDisplayed={true}
              currentLocation={false}
              enablePoweredByContainer={false}
              ref={(ref) => (this._googlePlacesTextInput = ref)}
              renderRightButton={() => {
                return (
                  <TouchableOpacity
                    style={styles.searchBtn}
                    onPress={() => {
                      if (this._googlePlacesTextInput) {
                        this._googlePlacesTextInput.setAddressText("");
                      }
                      this.handleBackButtonClick();
                    }}
                  >
                    <Text style={styles.searchBtnText}>Cancel</Text>
                  </TouchableOpacity>
                );
              }}
              query={{
                // available options: https://developers.google.com/places/web-service/autocomplete
                key: Constants.GOOGLE_API_KEY,
                language: "en_AU", // language of the results
                // types: '(cities)'
                types: "geocode", // request for regions containing search item
                // types: 'address' // default: 'geocode'
              }}
              renderRow={(rowData) => {
                console.log("PLACES", rowData);
                const mainText =
                  rowData.structured_formatting &&
                  rowData.structured_formatting.main_text
                    ? rowData.structured_formatting.main_text
                    : rowData.description;
                const secondaryText =
                  rowData.structured_formatting &&
                  rowData.structured_formatting.secondary_text
                    ? rowData.structured_formatting.secondary_text
                    : "";
                return (
                  <View style={styles.listItem}>
                    <View style={styles.listItemBody}>
                      <Text style={[styles.listItemText, styles.fontNornal]}>
                        {mainText}
                      </Text>
                      <Text style={[styles.listItemText, styles.fontLight]}>
                        {secondaryText}
                      </Text>
                    </View>
                  </View>
                );
              }}
              onPress={(data, details = null) => {
                // 'details' is provided when fetchDetails = true
                this.onPressRow(data, details);
              }}
              styles={{
                textInputContainer: {
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 10,
                },
                textInput: {
                  borderRadius: (Metrics.screenWidth * 40) / 828,
                  backgroundColor: "rgba(255,255,255,0.3)",
                  flex: 1,
                  paddingLeft: 15,
                  paddingRight: 15,
                  height: (Metrics.screenWidth * 40) / 414,
                  fontSize: (Metrics.screenWidth * 14) / 414,
                  color: "#000",
                  fontFamily: "base",
                },
                listView: {
                  paddingTop: 10,
                },
              }}
            />
          </KeyboardAvoidingView>
          {this.state.countryCities.length > 0 && (
            <ScrollView>
              {this.state.countryCities.map(function (item) {
                return (
                  <Pressable
                    style={styles.listItem}
                    onPress={() => parentThis.selectCountryCity(item)}
                  >
                    <View style={styles.listItemBody}>
                      <Text style={[styles.listItemText, styles.fontNornal]}>
                        {item.city}
                      </Text>
                      <Text style={[styles.listItemText, styles.fontLight]}>
                        {item.country}
                      </Text>
                    </View>
                  </Pressable>
                );
              })}
            </ScrollView>
          )}
        </SafeAreaView>
        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

// mapStateToProps = state => {
//     // console.log('>>>>>Search City Screen', state);
//     return {
//         searchLocationReducer: state.searchLocationReducer
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         setSearchLocation: (locationData) => dispatch(ActionCreators.searchLocationActions.setSearchLocation(locationData)),
//         setLocationEnterPrice: (locationData) => dispatch(ActionCreators.enterPriceActions.setLocation(locationData)),
//     }
// }
export default SearchCityScreen;
