import React, { Component } from "react";
import { Image, Platform, Text, View } from 'react-native';
import { walkthroughable } from 'react-native-copilot';
import styles from './Styles/FooterNavComponentsStyles';

const WalkthroughableText = walkthroughable(Text);
const WalkthroughableImage = walkthroughable(Image);

class stepNumberComponent extends Component {
    constructor() {
        super();
        this.state = {
            
        }
    }     

    render() {
        return (
            <View style={styles.stepNumberComponent}>
                
            </View>
        )
    }
}


export default stepNumberComponent
