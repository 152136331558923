const Constants = {
  APP_NAME: "Tengah",
  ALERT_ERROR: "Error",
  DOT: " • ",

  SHARE_DOMAIN: "https://tengah.dev-applications.net/",

  TERMS_CONDITION: "https://tengah.dev-applications.net/terms-co",
  PRIVACY_POLICY: "https://tengah.dev-applications.net/privacy-policy",

  GEO_LOCATION_OPTIONS: {
    timeout: 3 * 1000,
    maximumAge: 1 * 1000,
    enableHighAccuracy: true,
  },

  SEARCH_TIMER: 800,
  //For PopUp
  TIME_FOR_POP_UPS: 3 * 1000,
  REMOVED_FAV_POP_UP: "Removed from\nfavourites",
  ADDED_FAV_POP_UP: "Added to\nfavourites",
  PREF_SAVED: "Preference saved",
  RADIUS_SAVED: "Radius Saved",
  LOCATION_SAVED: "Search location updated",
  PRICE_FILTER: "Prices filtered",
  PRICE_ADDED: "Price Added!",
  TAG_ADDED: "Tag added",

  IS_CURRENT_LOCATION: "is_current_location",

  // GOOGLE_API_KEY: 'AIzaSyCm5pVqg6ZfzcxMY-_mlbQJTGzsveQIZyw',   //testingcaresolutions1
  // GOOGLE_WEB_CLIENT_ID: '586568826072-ilcbudhnvfajung3djmp2hatk9rumcqo.apps.googleusercontent.com', //testingcaresolutions1

  // GOOGLE_API_KEY: 'AIzaSyDT01oaBjZ3RgFY-VPLre-CX6sPDtmeQ8Q',   //client
  GOOGLE_API_KEY: "AIzaSyDasMwEuGPqcT2iiC_4UKAXO7ARYOfcJKY", //client (By HP in Skype)Latest
  GOOGLE_WEB_CLIENT_ID:
    "339764164144-2pun9hpb7uqs9hqmusjnunobsshf2sp1.apps.googleusercontent.com", //client

  DATE_FORMAT: "YYYY-MM-DD",
  DATE_FORMAT_DMY: "DD-MM-YYYY",
  DATE_FORMAT_MDY: "MM-DD-YYYY",
  DATE_STAMP_FORMAT: "YYYYMMDD",
  FULL_DATE_TIME_STAMP_FORMAT: "YYYY-MM-DD HH:mm:ss",

  KEY_FIRST_TIME_LOCATION: "firstTimeLocation",
  KEY_IS_LOGIN: "isLogin",
  KEY_TOKEN: "token",
  KEY_USER_DETAIL: "userDetail",
  KEY_USER_ID: "userId",
  KEY_SEARCH_MONTHS: "search_months",

  SOCIAL_TYPES: {
    NORMAL: "Normal",
    GOOGLE: "Google",
    FACEBOOK: "Facebook",
    APPLE: "Apple",
  }, //social_type ('Normal','Google','Facebook','Apple')

  //Tooltips
  HOME_TOOLTIP: "homeTooltip",
  OFFLINE_SYNC: "offlineSync",
  PRICE_TOOLTIP: "priceTooltip",
  PRODUCT_TOOLTIP: "productTooltip",
  PRODUCT_LIST_TOOLTIP: "productListTooltip",

  //Error Messages
  ERROR_MESSAGES: {
    NAME: "Please enter your username",
    VALID_NAME: "Please enter your valid username",
    EMAIL: "Please enter your email address",
    EMAIL_ALREADY_REGISTERED:
      "The email address you have entered is already registered",
    VALID_EMAIL: "Please enter valid email",
    DOB: "Please select your date of birth",
    GENDER: "Please make a selection",
    PASSWORD: "Please enter password",
    VALID_PASSWORD: "Password must be 8 characters long",
    COUNTRY: "Please select country",
    NO_INTERNET: "No internet connection!",
  },

  MESSAGE_ACCESS_LOCATION:
    "We need access to your current location so that you can see prices based on your location preference.",
  MESSAGE_ACCESS_STORAGE:
    "We need access to your storage so that you can choose a picture.",
  MESSAGE_ACCESS_CAMERA:
    "We need access to your camera so that you can click a picture.",
  isHomeTooltipShown: "",

  DATABASE_NAME: "Tengah.db",
  NUMBEROFWS: 12,
  RESPONSE_COUNT: 0,
  LAST_OFFLINE_DATA_DOWNLOAD_DATE: "OfflineDownloadDate",
  LAST_API_CALLED: "LastApiCalled",
  API_PROGRESS: "ApiProgress",
  OFFLINE_SYNC_DIFFRENCE_DAYS: 1,
  isOnline: false,
  SQL_STATMENT: {
    INSERT_1: "INSERT OR REPLACE INTO ",
    INSERT_2_VALUES: " VALUES ",
    INSERT_3_UPDATE: " ON DUPLICATE KEY UPDATE ",
  },
  SQL_TABLE_NAMES: {
    COUNTRIES_1: "countries",
    USERS_2: "users",
    CATEGORIES_3: "categories",
    TAGS_4: "tags",
    ENTRY_TAGS_5: "entry_tags",
    ACTIVITIES_6: "activities",
    ENTRIES_7: "entries",
    FAVOURITES_8: "favourites",
    ENTRY_MEDIA_9: "entry_media",
    CURRENCYRATE_10: "currency_rates",
    RECENT_SEARCHES_11: "recent_searches",
    USER_VIEW_ENTRIES_12: "user_view_entries",
  },
  SQL_COLUMN_NAMES: {
    COUNTRIES_1:
      "(id, name, iso_code_2, iso_code_3, currency, currency_code, currency_symbol, country_code, is_active, is_order, created_at, updated_at, deleted_at)",
  },
  dbRefrence: "",
  activityUnreadCount: 0,
  hideTellUsWhatYouPaid: false,
};

export default Constants;
