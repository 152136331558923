import ActionTypes from './types'
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from '../../Lib/Constants';
import Api from '../../Services/Api'

const enterPriceActions = {
    //set details while regostering
    setCategory, setTags, setLocation
}

function setCategory(cat_id, category, parentName,entryCount) {
    return dispatch => {
        dispatch({
            type: ActionTypes.ENTRY_CATEGORY,       
            cat_id: cat_id,
            category: category,
            parentName: parentName,
            entryCount: entryCount,
        })
    }
}

function setTags(tag_ids, main_tag) {
    return dispatch => {
        dispatch({
            type: ActionTypes.ENTRY_TAGS,
            tag_ids: tag_ids,
            main_tag: main_tag
        })
    }
}

function setLocation(locationData) {
    return dispatch => {
        const countriesQuery = `select id,currency_symbol,currency_code from countries where name = "${locationData.country}" and  is_active = 1 order by name asc`
        console.log('locationData locationData locationData',locationData);
        SQLHelper.performTransactionWithQuery(Constants.dbRefrence, countriesQuery, (arrayCountries) => {
            if (arrayCountries.length > 0) {
                const selectedCountry = arrayCountries[0]
                console.log('arrayCountriesarrayCountriesarrayCountries',arrayCountries);
                dispatch({
                    type: ActionTypes.ENTRY_LOCATION,
                    locationData: {
                        search_location: locationData.location,
                        search_latitude: locationData.latitude,
                        search_longitude: locationData.longitude,
                        search_country_id: selectedCountry.id,
                        currency_code:selectedCountry.currency_code,
                        currency_symbol:selectedCountry.currency_symbol,
                        countryName: locationData.country,
                    },
                })
            }
        })
        
    }
}

export { enterPriceActions }; 