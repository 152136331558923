import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Circle, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Helper component to change map zoom level dynamically
const ChangeMapZoom = ({ zoom }) => {
  const map = useMap();
  map.setZoom(zoom);
  return null;
};

const MyMap = ({ entryDetail, initialRadius, onRadiusChange }) => {
  const [radius, setRadius] = useState(initialRadius); // Initialize with the provided radius
  const [zoom, setZoom] = useState(getZoomLevel(initialRadius / 1000)); // Initialize zoom based on radius in km

  const position = [entryDetail.latitude * 1, entryDetail.longitude * 1];

  const mapStyles = {
    height: "55vh",  // Adjust height as needed
    width: "100%",   // Full width
  };

  const handleSliderChange = (event) => {
    const newRadius = event.target.value * 1000; // Convert km to meters
    setRadius(newRadius); // Update radius state
    onRadiusChange(newRadius); // Call the parent callback function
    setZoom(getZoomLevel(event.target.value)); // Update zoom level based on new radius in km
  };

  // Function to calculate zoom level based on radius up to 1000 km
  function getZoomLevel(radiusKm) {
    if (radiusKm <= 1) return 13;  // Street level view
    if (radiusKm <= 5) return 12;  // Neighborhood level view
    if (radiusKm <= 10) return 11; // Small town level view
    if (radiusKm <= 20) return 10; // City level view
    if (radiusKm <= 50) return 9;  // Larger city/area level view
    if (radiusKm <= 100) return 8; // Regional level view
    if (radiusKm <= 200) return 7; // Broader regional view
    if (radiusKm <= 400) return 6; // State/large region view
    if (radiusKm <= 600) return 5; // Very large region view
    if (radiusKm <= 800) return 4; // Multi-state view
    if (radiusKm <= 1000) return 3; // Country-wide view
    return 2; // Continent view for anything larger
  }

  return (
    <div>
      {/* Map Container */}
      <MapContainer
        center={position}
        zoom={zoom}
        style={mapStyles}
        scrollWheelZoom={false}
        dragging={false}
        doubleClickZoom={false}
        zoomControl={false}
        attributionControl={false}
      >
        {/* Dynamically change zoom level */}
        <ChangeMapZoom zoom={zoom} />

        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Marker */}
        <Marker position={position} />

        {/* Dynamic Circle */}
        <Circle
          center={position}
          radius={radius} // Radius in meters
          pathOptions={{
            color: "#1B89C9",
            fillColor: "rgba(27,137,201,0.2)",
            fillOpacity: 0.5,
          }}
        />
      </MapContainer>

      {/* Slider for Radius */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <label htmlFor="radiusSlider" style={{ marginRight: "10px" }}>
          Adjust Radius (km):
        </label>
        <input
          type="range"
          id="radiusSlider"
          min="1"
          max="1000" // Maximum radius in km
          step="1"
          value={radius / 1000} // Convert meters back to km for display
          onChange={handleSliderChange}
          style={{ width: "80%" }}
        />
        {/* <div style={{ marginTop: "10px" }}>
          Current Radius: {radius / 1000} km
        </div> */}
      </div>
    </div>
  );
};

export default MyMap;