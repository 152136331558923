import React from 'react';
import { FlatList, Image, ImageBackground, SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import BaseComponent from '../Components/BaseComponent';
import ImagePickerComponent from '../Components/ImagePickerComponent';
import * as Helper from '../Lib/Helper';
import { Images } from '../Themes';
import styles from './Styles/AddImageScreenStyles';


class AddImageScreen extends BaseComponent {

    constructor(props) {
        super(props)
        const param = this.props.navigation.state.params

        this.state = {
            arrMedia: param && param.arrMedia ? param.arrMedia : [],
            imagePickerVisible: false,
            imageSource:''
        }
    }
    
    openImage(imageSource){
        this.setState({
            imageSource
        })
    }

    renderImage = (item, index) => {        
        return (
            // <TouchableOpacity style={styles.listItem} onPress={() => this.openImage({ uri: item.path })}>
                <View style={styles.listItem}>
                    <View style={styles.listItemBody}>
                        <View style={[styles.imagePlaceholder, { borderRadius: 0 }]}>
                            <Image source={{ uri: item.path }} style={styles.uploadedImage} />
                        </View>
                    </View>
                    <TouchableOpacity style={styles.listItemRight} onPress={() => {
                        this.state.arrMedia.splice(index, 1)
                        this.setState({})
                    }}>
                        <Image source={Images.addCircle} style={[styles.listItemArrow, { transform: [{ rotate: '45deg' }] }]} />
                    </TouchableOpacity>
                </View>
            // </TouchableOpacity>
        )
    }

    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.container}>
                    <View style={[styles.header, { paddingLeft: 0, paddingRight: 0, }]}>
                        <View style={styles.headerRight}>
                            <TouchableOpacity style={styles.rightIcon} onPress={() => {
                                this.handleBackButtonClick()
                            }}>
                                <Text style={styles.headerText}>Cancel</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.headerBody}>
                            <Text style={styles.headerTitle}>Add image</Text>
                        </View>
                        <View style={styles.headerRight}>
                            <TouchableOpacity style={styles.rightIconWithText} onPress={() => {
                                this.handleBackButtonClick()
                                this.props.navigation.state.params.onBack(this.state.arrMedia)
                            }}>
                                <Text style={styles.headerText}>Save</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <ScrollView contentContainerStyle={styles.ScrollView}>
                        <View style={[styles.content]}>
                            <FlatList
                                data={this.state.arrMedia}
                                style={{ marginTop: 10 }}
                                extraData={this.state}
                                keyExtractor={(item, index) => index + ''}
                                renderItem={({ item, index }) => this.renderImage(item, index)}
                            />
                            <TouchableOpacity style={[styles.listItem, { borderBottomWidth: 0 }]} onPress={() => {
                                if (this.state.arrMedia.length < 3) {
                                    this.setImagePicker(true)
                                } else {
                                    Helper.showAlert('You have reached to maximum image limit')
                                }

                            }}>
                                <View style={styles.listBody}>
                                    <View style={styles.imageUpload}>
                                        <View style={styles.imagePlaceholder}>
                                            <Image source={Images.camera} style={styles.placeholderImg} />
                                        </View>
                                        <Text style={styles.normalText}>Tap to add image</Text>
                                    </View>
                                </View>

                            </TouchableOpacity>
                        </View>
                        {/* <Image source={this.state.imageSource} style={styles.imagePreviewAdd} /> */}
                    </ScrollView>
                </SafeAreaView>

                <ImagePickerComponent
                    visible={this.state.imagePickerVisible}
                    onClose={() => this.setImagePicker(false)}
                    onSelectedImage={(image) => this.onImageSelect(image)} />

            </ImageBackground>
        )
    }

    setImagePicker = (visible) => {
        this.setState({ imagePickerVisible: visible })
    }

    onImageSelect = (image) => {
        console.log('imageAdd', image);
        this.state.arrMedia.push(image)
    }
}

export default AddImageScreen