import moment from 'moment';
import React from "react";
import { Image, ImageBackground, Text, TextInput, TouchableOpacity, View } from 'react-native';
import DateTimePicker from "react-native-modal-datetime-picker";
import { SafeAreaView } from 'react-native';
import { connect } from "react-redux";
import BaseComponent from "../Components/BaseComponent";
import Constants from '../Lib/Constants';
import * as Helper from '../Lib/Helper';
import ActionCreators from "../Redux/actions";
import { Images } from "../Themes";
import styles from "./Styles/SignupScreenStyles";

class SignupDateScreen extends BaseComponent {
    constructor(props) {
        super(props);
        console.log(this.props.userDetail);
        this.state = {
            name: this.props.state?.name,
            dob: this.props.state?.dob,
            gender: this.props.state?.gender,
            countryName: this.props.state?.countryName,
            countryId: this.props.state?.countryId,
            showPicker: false,
        }
    }
    componentDidMount() {
        super.componentDidMount()

        this.props.navigation.setOptions({
            headerRight: () => {
            return(
                <TouchableOpacity activeOpacity={1} style={styles.rightIcon} onPress={() => {this.onPressNext()}}>
                    <Text style={styles.headerText}>Next</Text>
                    <Image source={Images.arrowRight} style={[styles.arrow]} />
                </TouchableOpacity>
            )
            },
            headerLeft: () => {
                return (
                    <TouchableOpacity activeOpacity={1} style={[styles.leftIcon, {paddingLeft: 10}]} onPress={() => { this.props.navigation.navigate('SignupScreen')}}>
                        <Image source={Images.arrowLeft} style={[styles.arrow]} />
                    </TouchableOpacity>
                )
            }
        })
        // this.showDatePicker(true)
    }

    onPressNext = () => {
        const dob = this.state.dob
        if (dob == '') {
            Helper.showAlert(Constants.ERROR_MESSAGES.DOB)
        } else {
            this.props.navigation.navigate('SignupGenderScreen', {
                dob: this.state.dob,
                setSignupDetails:this.props.route.params.setSignupDetails,
                completeSignup:this.props.route.params.completeSignup
            })
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if(prevProps.userDetail){
    //         if (prevProps.userDetail.dob != this.props.userDetail.dob) {
    //             this.setState({ dob: this.props.userDetail.dob })
    //         }
    //     }
    // }

    showDatePicker = (isShow) => {
        this.setState({ showPicker: isShow });
    }

    handleDatePicked = (date) => {
        const selectedDate = moment(date).format(Constants.DATE_FORMAT_DMY)
        this.showDatePicker(false);
        this.setState({
            dob: selectedDate
        });
        this.props.route.params.setSignupDetails({dob:selectedDate})
    }

    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.mainContainer} >
                    <View style={styles.content}>
                        <View style={styles.frm}>
                            <Text style={[styles.h1, styles.marginBottom, {
                              marginTop:30
                            }]}>What's your date of birth?</Text>
                            <TouchableOpacity style={styles.TextInput} onPress={() => this.showDatePicker(true)}>
                                {this.state.dob ?
                                    <Text style={[styles.TextInput]}>{this.state.dob}</Text>
                                    : <Text style={[styles.TextInput]}>Choose a date</Text>}
                                <DateTimePicker
                                    mode={'date'}
                                    value={moment(this.state.dob).isValid() ? moment(this.state.dob).toDate() : new Date()}
                                    isVisible={this.state.showPicker}
                                    onConfirm={(date) => this.handleDatePicked(date)}
                                    onCancel={() => this.showDatePicker(false)}
                                    maximumDate={new Date()}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                </SafeAreaView>
            </ImageBackground>
        )
    }
}
// mapStateToProps = state => {
//     // console.log('>>>>> User Detail DOB', state);
//     return {
//         userDetail: state.userReducer.userDetail
//     }
// }

// mapDispatchToProps = dispatch => {
//     return {
//         setDOB: (date) => dispatch(ActionCreators.userActions.setDOB(date))
//     }
// }

export default (SignupDateScreen)
