import { StyleSheet, Platform } from "react-native";
import { Metrics, ApplicationStyles, Colors, Fonts } from "../../Themes/";
export default StyleSheet.create({
  ...ApplicationStyles.screen,
  mainContainer: {
    flex: 1,
    paddingTop: 50,
  },
  content: {
    flex: 1,
    padding: 20,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  signupFooter: {
    paddingBottom: 20,
  },
});
