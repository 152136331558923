import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React, { useState } from 'react';
import {NavigatorName, ScreenNames} from '../screen';
import Landing1 from '../../../screens/auth/landing';
import HomeTab from '../home';
import RegisterNav from '../register';

const Navigation = createNativeStackNavigator();

function LoginNavigator() {
  return (
    <Navigation.Navigator initialRouteName={ScreenNames.Splash}>
      <Navigation.Screen
        name={ScreenNames.Splash}
        component={Landing1}
        options={({navigation}) => ({
          gestureEnabled: false,
          headerShown: false,
          headerStyle: {
            shadowColor: 'transparent',
            backgroundColor: 'transparent',
          },
          headerTitle: '',
          // headerLeft: () => <ButtonBack onPress={() => navigation.goBack()} />,
        })}
      />
      <Navigation.Screen
        name={NavigatorName.RegisterNavigator}
        component={RegisterNav}
        options={({navigation}) => ({
          gestureEnabled: false,
          headerShown: false,
          headerStyle: {
            shadowColor: 'transparent',
            backgroundColor: 'transparent',
          },
          headerTitle: '',
          // headerLeft: () => <ButtonBack onPress={() => navigation.goBack()} />,
        })}
      />
      <Navigation.Screen
        name={ScreenNames.Home}
        component={HomeTab}
        options={({navigation}) => ({
          gestureEnabled: false,
          headerShown: false,
          headerStyle: {
            shadowColor: 'transparent',
            backgroundColor: 'transparent',
          },
          headerTitle: '',
          // headerLeft: () => <ButtonBack onPress={() => navigation.goBack()} />,
        })}
      />
    </Navigation.Navigator>
  );
}
export default LoginNavigator;
