import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import AppNavigation from '../../../App/Navigation/AppNavigation';
import HomePage from '../../../screens/home';


const Navigation = createNativeStackNavigator();

function HomeTab({...props}) {
  return (
    <AppNavigation  />
  );
}

export default HomeTab;
