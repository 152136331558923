import React, { Component } from "react";
import { BackHandler, Image, ImageBackground, Platform, Text, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native';
import { connect } from "react-redux";
import LoadingComponent from "../Components/LoadingComponent";
import Constants from "../Lib/Constants";
import * as Helper from '../Lib/Helper';
import ActionCreators from "../Redux/actions";
import { Images, Metrics } from "../Themes";
import styles from "./Styles/SignupScreenStyles";

class CityScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            return true
        });
    }
    componentWillUnmount() {
        if (this.backHandler) {
            this.backHandler.remove()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchLocationReducer != this.props.searchLocationReducer) {
            if (prevProps.searchLocationReducer.loading != this.props.searchLocationReducer.loading) {
                this.setState({ loading: this.props.searchLocationReducer.loading })
            }
            if (prevProps.searchLocationReducer.isSearchLocationUpdated != this.props.searchLocationReducer.isSearchLocationUpdated) {
                if (this.props.searchLocationReducer.isSearchLocationUpdated) {
                    this.goBack()
                }
            }
        }
    }

    goBack = () => {
        this.props.navigation.goBack(null);
    }

    getLocationPermission = async () => {
        // const locationPermission = Platform.select({
        //     ios: PERMISSIONS.IOS.LOCATION_WHEN_IN_USE,
        //     android: PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
        // })
        // request(locationPermission).then(result => {
        //     console.log('Permission result: ', JSON.stringify(result));
        //     if (result == RESULTS.DENIED || result == RESULTS.BLOCKED) {
        //         console.log('RESULTS.DENIED || RESULTS.BLOCKED');
        //         Helper.openSetting(Constants.MESSAGE_ACCESS_LOCATION)
        //     }
        //     else if (result == RESULTS.GRANTED){
        //         this.getCurrentLocation()
        //     }
        //     // switch (result) {
        //     //     case RESULTS.UNAVAILABLE:
        //     //         console.log('This feature is not available on this device');
        //     //         break;

        //     //     case RESULTS.GRANTED:
        //     //         this.getCurrentLocation()
        //     //         break;

        //     //     case RESULTS.DENIED || RESULTS.BLOCKED:
        //     //         openSettings().catch(() => console.warn('cannot open settings'));
        //     //         break;
        //     // }
        // }).catch(error => console.log('Permission Error: ', error))

    }

    getCurrentLocation = async () => {
        if (Constants.isOnline) {
            this.setState({ loading: true })
            Helper.getCurrentLocationData((success, locationData) => {
                if (success) {
                    this.props.setSearchLocation(locationData)
                }
                this.setState({ loading: false })
            })
        }
    }
    render() {
        return (
            <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
                <SafeAreaView style={styles.mainContainer} >
                    <View style={styles.content}>
                        <View style={styles.frm}>
                            <Text style={[styles.h1, { marginBottom: Metrics.screenWidth / 10 }]}>Where are you checking prices for?</Text>
                            <View style={styles.list}>
                                <TouchableOpacity style={styles.listItem} onPress={() => this.props.navigation.navigate('SearchCityScreen')}>
                                    <Image source={Images.search} style={styles.listIcon} />
                                    <View style={styles.listItemBody}>
                                        <Text style={styles.listItemText}>Search for a suburb, city or country</Text>
                                    </View>
                                    <Image source={Images.arrowRight} style={styles.listItemArrow} />
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.listItem} onPress={() => this.getLocationPermission()}>
                                    <Image source={Images.location} style={styles.listIcon} />
                                    <View style={styles.listItemBody}>
                                        <Text style={styles.listItemText}>Use your current position</Text>
                                    </View>
                                    <Image source={Images.arrowRight} style={styles.listItemArrow} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
                {this.state.loading ? <LoadingComponent /> : null}
            </ImageBackground>
        )
    }
}


export default CityScreen;