import React, { Component } from "react";
import { View, Text, KeyboardAvoidingView, Image, ImageBackground, TouchableOpacity, FlatList, Modal } from 'react-native';
import { Images } from "../Themes";
import styles from "./Styles/AccountScreenStyles";
import { SafeAreaView } from 'react-native';

class TermsAndConditionsScreen extends Component {

  render() {
    return (
      <ImageBackground source={Images.grediantBackground} style={styles.ImageBackground}>
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity style={styles.rightIcon} onPress={() => this.props.navigation.navigate('AccountScreen')}>
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>{'Terms & Conditions'}</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity style={styles.rightIcon}>
                <Text style={styles.headerText}> </Text>
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
      </ImageBackground>
    );
  }
}
export default TermsAndConditionsScreen;
