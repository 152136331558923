import React, { Component } from "react";
import {
  Image,
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
  ScrollView,
} from "react-native";
import { RadioButton, RadioGroup } from "react-native-flexi-radio-button";
import { SafeAreaView } from "react-native";
import { connect } from "react-redux";
import ActionCreators from "../Redux/actions";
import { Images, Metrics } from "../Themes";
import styles from "./Styles/HomeCurrencyScreenStyles";
import BaseComponent from "../Components/BaseComponent";
import LoadingComponent from "../Components/LoadingComponent";
import * as Helper from "../Lib/Helper";
import Constants from "../Lib/Constants";
import PopupDialogComponent from "../Components/PopupDialogComponent";
import { countryActions } from "../Redux/actions/Country";
import { apiRequests } from "../apiRequests";
class HomeCurrencyScreen extends BaseComponent {
  constructor(props) {
    super(props);
    const param = this.props.route.params;
    this.state = {
      isFromPreference:
        param && param.isFromPreference ? param.isFromPreference : false,

      userDetail: null,
      arrCountry: [],
      arrFilterCountry: [],
      isGpsOn: true,
      currentCountry: "",
      selectedHomeCountry: "",
      selectedIndex: -1,
      searchText: "",
      isPopUpVisible: false,
      popUpMessage: "",
      loading: true,
    };
    this.getUserDetail = this.getUserDetail.bind(this);
    this.getCurrentLocation = this.getCurrentLocation.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
    this.getCountries();
    this.getUserDetail();
    this.getCurrentLocation();
  }

  async getUserDetail() {
    if (this.props.route) {
      let userdetails = await this.props.route.params.getUserDetails();
      this.setState({
        loading: false,
        userDetail: userdetails,
        radius: userdetails.search_radius,
      });
    }
  }

  getCountries() {
    let countries = countryActions.getCountries();
    this.setState({
      arrCountry: countries,
    });
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.countryReducer != this.props.countryReducer) {
  //         if (prevProps.countryReducer.loading != this.props.countryReducer.loading) {
  //             this.setState({ loading: this.props.countryReducer.loading })
  //         }
  //         if (prevProps.countryReducer.arrCountryData != this.props.countryReducer.arrCountryData) {
  //             this.setState({ arrCountry: this.props.countryReducer.arrCountryData, arrFilterCountry: this.props.countryReducer.arrCountryData }, () => this.checkLocationPermission())
  //         }
  //     }
  //     if (prevProps.userDetail != this.props.userDetail) {
  //         this.setState({ userDetail: this.props.userDetail })
  //     }

  //     if (prevProps.searchLocationReducer != this.props.searchLocationReducer) {
  //         if (prevProps.searchLocationReducer.loading != this.props.searchLocationReducer.loading) {
  //             this.setState({ loading: this.props.searchLocationReducer.loading })
  //         }
  //         if (prevProps.searchLocationReducer.isSearchLocationUpdated != this.props.searchLocationReducer.isSearchLocationUpdated) {
  //             if (this.props.searchLocationReducer.isSearchLocationUpdated) {
  //                 if (this.state.isFromPreference) {
  //                     this.handleBackButtonClick()
  //                     this.props.navigation.state.params.showPopup(true, Constants.PREF_SAVED)
  //                 } else {
  //                     this.setState({ isPopUpVisible: true, popUpMessage: Constants.PREF_SAVED }, () => {
  //                         setTimeout(() => {
  //                             this.setState({ isPopUpVisible: false, popUpMessage: '' }, () => this.handleBackButtonClick())
  //                         }, Constants.TIME_FOR_POP_UPS);
  //                     });
  //                 }
  //             }
  //         }
  //     }
  // }

  checkLocationPermission = async () => {
    // const locationPermission = Platform.select({
    //     ios: PERMISSIONS.IOS.LOCATION_WHEN_IN_USE,
    //     android: PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
    // })
    // check(locationPermission).then(result => {
    //     console.log('Permission result: HomeCurrency', JSON.stringify(result));
    //     if (result == RESULTS.DENIED || result == RESULTS.BLOCKED) {
    //         console.log('RESULTS.DENIED || RESULTS.BLOCKED');
    //         Helper.openSetting(Constants.MESSAGE_ACCESS_LOCATION)
    //     }
    //     else if (result == RESULTS.GRANTED){
    //         this.getCurrentLocation()
    //     }
    //     // switch (result) {
    //     //     case RESULTS.UNAVAILABLE:
    //     //         console.log('This feature is not available on this device');
    //     //         break;
    //     //     case RESULTS.GRANTED:
    //     //         this.getCurrentLocation()
    //     //         break;
    //     //     case RESULTS.DENIED || RESULTS.BLOCKED:
    //     //         console.log('Location permission denied or blocked');
    //     //         break;
    //     // }
    // }).catch(error => console.log('Permission Error: ', error))
  };

  getLocationPermission = async () => {
    // const locationPermission = Platform.select({
    //     ios: PERMISSIONS.IOS.LOCATION_WHEN_IN_USE,
    //     android: PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
    // })
    // request(locationPermission).then(result => {
    //     console.log('Permission result: ', JSON.stringify(result));
    //     if (result == RESULTS.DENIED || result == RESULTS.BLOCKED) {
    //         console.log('RESULTS.DENIED || RESULTS.BLOCKED');
    //         Helper.openSetting(Constants.MESSAGE_ACCESS_LOCATION)
    //     }
    //     else if (result == RESULTS.GRANTED){
    //         this.getCurrentLocation()
    //     }
    //     // switch (result) {
    //     //     case RESULTS.UNAVAILABLE:
    //     //         console.log('This feature is not available on this device');
    //     //         break;
    //     //     case RESULTS.GRANTED:
    //     //         this.getCurrentLocation()
    //     //         break;
    //     //     case RESULTS.DENIED || RESULTS.BLOCKED:
    //     //         openSettings().catch(() => console.warn('cannot open settings'));
    //     //         break;
    //     // }
    // }).catch(error => console.log('Permission Error: ', error))
  };

  getCurrentLocation = async () => {
    this.setState({ loading: true });
    Helper.getCurrentLocationData((success, locationData) => {
      console.log(
        "HOME CURRENCY",
        success + "\n" + JSON.stringify(locationData)
      );
      if (success) {
        if (locationData.country != "") {
          const currentLocation = this.state.arrCountry.filter(
            (element) =>
              element.label.toLowerCase() === locationData.country.toLowerCase()
          );
          console.log(currentLocation);
          if (currentLocation.length > 0) {
            this.setState({
              currentCountry: currentLocation[0],
              selectedHomeCountry: currentLocation[0].label,
              selectedIndex: 0,
              isGpsOn: true,
            });
          }
        }
      }
    });
  };

  onPressSave = async () => {
    if (this.state.selectedHomeCountry != "") {
      console.log("home country: ", this.state.selectedHomeCountry);
      var locationData = {
        latitude: this.state.userDetail.search_latitude,
        longitude: this.state.userDetail.search_longitude,
        location: this.state.userDetail.search_location,
        country: this.state.userDetail.search_country.name,
        short_name: this.state.userDetail.search_country.iso_code_2,
        homeCountry: this.state.selectedHomeCountry,
      };

      let updateLocation = await apiRequests.updateLocation(locationData);
      if (updateLocation.status == 200) {
        if (this.state.isFromPreference) {
          this.handleBackButtonClick();
          this.props.route.params.showPopup(true, "Home Country Updated");
        } else {
          this.setState(
            { isPopUpVisible: true, popUpMessage: "Home Country Updated" },
            () => {
              setTimeout(() => {
                this.setState({ isPopUpVisible: false, popUpMessage: "" }, () =>
                  this.handleBackButtonClick()
                );
              }, Constants.TIME_FOR_POP_UPS);
            }
          );
        }
      } else {
        Helper.showAlert(
          "Could not update radius, please try again",
          this.props.navigation
        );
      }
    }
  };

  render() {
    if (this.state.loading) {
      return <LoadingComponent />;
    }
    return (
      <ImageBackground
        source={Images.grediantBackground}
        style={styles.ImageBackground}
      >
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <TouchableOpacity
                style={styles.rightIcon}
                onPress={() => this.handleBackButtonClick()}
              >
                <Image source={Images.arrowLeft} style={styles.arrow} />
              </TouchableOpacity>
            </View>
            <View style={styles.headerBody}>
              <Text style={styles.headerTitle}>Home currency</Text>
            </View>
            <View style={styles.headerRight}>
              <TouchableOpacity
                activeOpacity={1}
                style={styles.rightIconWithText}
                onPress={() => this.onPressSave()}
              >
                <Text style={styles.headerText}>Save</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.currencyHeader}>
            <Image source={Images.home_currency} style={styles.currencyIcon} />
            <View style={styles.currencyBody}>
              <Text style={styles.smallText}>Home currency</Text>
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text style={styles.heading5}>
                  {this.state.userDetail.country.name}
                </Text>
                <Text style={[styles.normalText, { marginLeft: 15 }]}>
                  {this.state.userDetail.country.currency_code}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.content}>
            <View style={[styles.search, { paddingLeft: 0, paddingRight: 0 }]}>
              <View style={styles.searchInputContainer}>
                <Image source={Images.search} style={styles.searchIcon} />
                <TextInput
                  underlineColorAndroid="transparent"
                  placeholder="Search by country"
                  placeholderTextColor="rgba(255, 255, 255, 0.7)"
                  style={styles.searchInput}
                  value={this.state.searchText}
                  onChangeText={(text) => {
                    const arr = this.state.arrCountry.filter((item) =>
                      item.label.toLowerCase().includes(text.toLowerCase())
                    );
                    this.setState({ searchText: text, arrFilterCountry: arr });
                  }}
                />
              </View>
              {!this.state.loading && !this.state.isGpsOn ? (
                <TouchableOpacity
                  style={styles.searchBtn}
                  onPress={() => this.getLocationPermission()}
                >
                  <Image source={Images.gps} style={styles.gpsIcon} />
                </TouchableOpacity>
              ) : null}
            </View>
            <ScrollView
              style={{ maxHeight: "83%" }}
              contentContainerStyle={[styles.ScrollView]}
              showsVerticalScrollIndicator={false}
            >
              <RadioGroup
                onSelect={(index, value) =>
                  this.setState({
                    selectedHomeCountry: value,
                    selectedIndex: index,
                  })
                }
                color="#fff"
                activeColor="#fff"
                selectedIndex={this.state.selectedIndex}
                size={15}
              >
                {
                  this.state.isGpsOn ? (
                    <RadioButton
                      style={[styles.listItem, { borderBottomWidth: 0 }]}
                      value={this.state.currentCountry.name}
                      key={this.state.currentCountry.name}
                    >
                      <View style={{ width: Metrics.screenWidth - 60 }}>
                        <View style={styles.locationContent}>
                          <Image
                            source={Images.location}
                            style={styles.locationArrow}
                          />
                          <Text style={[styles.locationText, { fontSize: 12 }]}>
                            Your current location
                          </Text>
                        </View>
                        <Text style={styles.listItemText}>
                          {this.state.currentCountry.name}
                        </Text>
                        <Text style={styles.normalText}>
                          {this.state.currentCountry.currency_code}
                        </Text>
                      </View>
                    </RadioButton>
                  ) : null
                  //for this null condition add condition in radioGroup class if radioButton exist then return <RadioButton/> else null
                }

                {this.state.arrFilterCountry.map((item, index) => {
                  return (
                    <RadioButton
                      style={styles.listItem}
                      value={item.label}
                      key={item.label}
                    >
                      <View style={{ width: Metrics.screenWidth - 60 }}>
                        <Text style={styles.listItemText}>{item.label}</Text>
                        <Text style={styles.normalText}>
                          {item.currency_code}
                        </Text>
                      </View>
                    </RadioButton>
                  );
                })}
              </RadioGroup>
            </ScrollView>
          </View>
        </SafeAreaView>

        <PopupDialogComponent
          visible={this.state.isPopUpVisible}
          message={this.state.popUpMessage}
        />

        {this.state.loading ? <LoadingComponent /> : null}
      </ImageBackground>
    );
  }
}

export default HomeCurrencyScreen;
